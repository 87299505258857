import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import isEmpty from "lodash/isEmpty";
import ListItemText from "@material-ui/core/ListItemText";
import { getUserDetailsById } from "../../Redux/Actions/UserRegistration";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle";
import { iconArray } from "../../constant/IconArray/Isons";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [accessible, setAccessible] = React.useState(
    !localStorage.getItem("sendApiClientId")
      ? ""
      : JSON.parse(localStorage.getItem("sendApiClientId"))
  );
  const [collebleState, setCollebleState] = React.useState("");
  const [logoPath, setLogoPath] = React.useState("");
  const [activeClass, setActiveClass] = React.useState(false);
  const [submenuActiveClass, setSubMenuActiveClass] = React.useState(false);
  const classes = useStyles();
  console.log("sidebar props++++++++++++++",props)

  let name = "";
  if (window.location.pathname.includes("admin")) {
    name =
      props.userRole.userList &&
      props.userRole.userList.Role_Permission_Data &&
      props.userRole.userList.Role_Permission_Data.UserName;
  } else if (window.location.pathname.includes("client")) {
    name = "Client";
  } else if (window.location.pathname.includes("cfo")) {
    name = "CFO";
  } else if (window.location.pathname.includes("frontDesk")) {
    name = "Front Desk";
  } else if (window.location.pathname.includes("patient")) {
    name =
      props.userRole.userList &&
      props.userRole.userList.Role_Permission_Data &&
      props.userRole.userList.Role_Permission_Data.UserName;
  }

  const handleOnClick = (item) => {
    setSubMenuActiveClass(false);
    if (collebleState === item) {
      setCollebleState("");
      setActiveClass(false);
    } else {
      setCollebleState(item);
      setActiveClass(true);
    }
  };

  const selectSubMenu = (j) => {
    if (submenuActiveClass === j) {
      setSubMenuActiveClass(false);
    } else {
      setSubMenuActiveClass(j);
    }
  };

  const changeReportRoute = (name, displayName, Category_Name = "") => {
    setActiveClass(true);
    props.setState();
    if (name.includes("Users")) {
      props.changeUserFormMode("init");
      props.changeRoleFormMode("init");
      props.setUserSearchEmpty();
    }
    const barName = Category_Name
      ? `${Category_Name} / ${displayName}`
      : displayName;
    props.setAppBarName(barName);
    props.emptyPatientSelect();
    localStorage.removeItem("PayoutSummary");
    localStorage.removeItem("PayoutDetails");
    let userid = localStorage.getItem("userId");
    props.getUserDetailsById(userid);
    localStorage.removeItem("AdjustmentDetails");
    props.history.push(
      `/${props.userDetails.layout}/${name.replace(/\s/g, "")}`
    );
    if (!windowWidth) {
      props.setopen();
    }
  };
  const { color } = props;
  const listItemClasses = classNames({
    [` ${classes[color]}`]: true
  });
  const whiteFontClasses = classNames({
    [` ${classes.whiteFont}`]: true
  });

  const viewPermission = (item) => {
    const viewPermissionSidebar = item.Options.filter(
      (v) => v.name == "View" || v.name == "view"
    );
    return viewPermissionSidebar[0].permission;
  };
  const links = (
    <List className={classes.list}>
      {props.app.app &&
        props.app.app.leftMenu &&
        props.app.app.leftMenu.data.LeftMenu.map((v, i) => {
          if (v.Permission) {
            const icon = iconArray.filter(
              (iconItem) => iconItem.name === v.Menu_Display_Name
            );
            const IconShow = icon && icon[0].icon;
            return (
              <>
                <ListItem
                  key={`${`${i}new`}`}
                  button
                  className={`${props.userDetails.appBarName === v.Menu_Display_Name
                    ? classes.itemLink + listItemClasses
                    : classes.itemLink
                    }`}
                  style={{
                    backgroundColor:
                      collebleState == i && activeClass && "#2ba992",
                    color: collebleState == i && activeClass && "white"
                  }}
                  onClick={() =>
                    v.Page_Menu
                      ? handleOnClick(i)
                      : changeReportRoute(
                        v.Menu_Display_Name,
                        v.Menu_Display_Name
                      )
                  }
                >
                  <IconShow className={classNames(classes.itemIcon)} />
                  {(props.open || props.hoverDrawer) && (
                    <ListItemText
                      className={classNames(classes.itemText)}
                      primary={v.Menu_Display_Name}
                      disableTypography
                    />
                  )}
                  {console.log(v.Menu_Display_Name,"{v.Menu_Display_Name++++++++++")}
                  {v.Page_Menu &&
                    (props.open || props.hoverDrawer) &&
                    (collebleState === i ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    ))}
                </ListItem>

                <Collapse
                  in={collebleState === i}
                  timeout="auto"
                  unmountOnExit
                  style={{ margin: "0px" }}
                >
                  {v.IsPageMenu &&
                    v.Page_Menu.map((item, j) => {
                      if (viewPermission(item) && item.ShowInLeftMenu) {
                        return (
                          <ListItem
                            key={j}
                            button
                            style={{
                              height: "auto",
                              paddingLeft:
                                (props.open || props.hoverDrawer) && "24px",
                              backgroundColor:
                                collebleState == i &&
                                activeClass &&
                                submenuActiveClass === j &&
                                "#d5eee9"
                            }}
                            className={`${props.userDetails.appBarName ===
                              item.Menu_Display_Name
                              ? classes.itemLink + listItemClasses
                              : classes.itemLink
                              }`}
                            onClick={() => {
                              changeReportRoute(
                                item.Page_Name,
                                item.Menu_Display_Name,
                                v.Category_Name
                              );
                              selectSubMenu(j);
                            }}
                          >
                            <div className={classes.itemTextSub}>
                              {item.Page_Name.match(/\b(\w)/g).join("")}
                            </div>
                            {(props.open || props.hoverDrawer) && (
                              <ListItemText
                                className={classNames(
                                  classes.itemTextSubMessage,
                                  whiteFontClasses
                                )}
                                style={{ paddingLeft: "10px" }}
                                primary={item.Menu_Display_Name}
                                disableTypography
                              />
                            )}
                            {/* {console.log(item.Menu_Display_Name,"item.Menu_Display_Name+++++++++++")}
                            {console.log(whiteFontClasses,"whiteFontClasses.whiteFontClasses+++++++++++")} */}

                          </ListItem>
                        );
                      }
                    })}
                </Collapse>
              </>
            );
          }
        })}
    </List>
  );
  const openDrawerOnHover = () => {
    setTimeout(() => {
      if (!props.hoverDrawer) {
        props.setOpenDrawerHover(true);
      }
    }, 200);
  };
  const closeDrawerOnHover = () => {
    if (props.hoverDrawer) {
      props.setOpenDrawerHover(false);
    }
  };
  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (props.app.app.portalConfig) {
      const x = props.app.app.portalConfig.data[0]?.Logo_Image;
      logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        const localImage = require("assets/newImg/ThePatientOffer-72.png");
        setLogoPath(localImage);
      };
    }
  }

  const brand = (
    <div className={classes.logo}>
      <img
        src={logoPath || "assets/newImg/ThePatientOffer-72.png"}
        className={classes.logoWidth}
      />
    </div>
  );
  const openBrand = (
    <div className={classes.smallLogo}>
      <img
        src={logoPath || "assets/newImg/ThePatientOffer-72.png"}
        className={classes.logoWidth}
        style={{
          objectFit: "contain",
          height: "65px",
          maxHeight: "65px",
          maxWidth: "65px"
        }}
      />
    </div>
  );

  return (
    <div
      onMouseEnter={() => (windowWidth ? openDrawerOnHover() : {})}
      onMouseLeave={() => (windowWidth ? closeDrawerOnHover() : {})}
    >
      <Drawer
        PaperProps={{
          sx: { width: "90%" }
        }}
        variant={windowWidth ? "permanent" : "presentation"}
        onClose={() => props.setopen()}
        open={props.open || props.hoverDrawer}
        classes={{
          paper: classNames(
            props.open || props.hoverDrawer
              ? windowWidth
                ? classes.drawerPaperOpen
                : ""
              : classes.drawerPaper
          )
        }}
      >
        {props.open || props.hoverDrawer ? brand : openBrand}
        <hr className={classes.hrStyle} />
        <div className={classes.profileName}>
          <Avatar className={classes.avtarColor} />
          {(props.open || props.hoverDrawer) && (
            <div className={classes.userName}>{name}</div>
          )}
        </div>
        <hr className={classes.hrStyle} />
        <Scrollbars
          className={classes.dialogScrollBar}
          renderView={(props) => <div style={{ margin: "0px" }} />}
        >
          <div style={{ margin: "0px" }}>{links}</div>
        </Scrollbars>
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  image: PropTypes.string,
  setOpenDrawerHover: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  userRole: PropTypes.object,
  hoverDrawer: PropTypes.bool,
  userDetails: PropTypes.object,
  open: PropTypes.bool,
  app: PropTypes.object,
  setState: PropTypes.func,
  changeRoleFormMode: PropTypes.func,
  emptyPatientSelect: PropTypes.func,
  setopen: PropTypes.func,
  setAppBarName: PropTypes.object,
  getUserList: PropTypes.object,
  history: PropTypes.object,
  color: PropTypes.any,
  setUserSearchEmpty: PropTypes.func,
  getUserDetailsById: PropTypes.func
};