import React from "react";
import PropTypes from "prop-types";
import qs from "qs";
import moment from "moment";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import { Button } from "@material-ui/core";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";
import {
  exportToCSV,
  exportToCSVWithPayout,
  accountingNumberformat,
  getQueryParams,
  handleClickTable,
  handleRowDataCheck
} from "../../utils/common";
import {
  fetchPayoutApprovalList,
  fetchProcessorList,
  fetchAgentList,
  updatePayoutApprovalStatus
} from "../../actions/payoutApproval.action";
import { TablePageData } from "../../utils/constants";
import SearchComponent from "../../components/PayoutApprovalSearch/SearchFields";
import SearchedChips from "../../components/PayoutApprovalSearch/SearchChips";
import LongMenu from "./LongMenu";
import ChangeStatusModal from "./updateStatusModal";
import FilterFields from "./filterFields";
import LastCalculationDialog from "../../components/LastCalculationDialog/LastCalculationDialog";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { exportToPDF } from "modules/reports/utils/common";
import { exportNumberToDashFormat } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ExportIcon } from "../../../../assets/svgIcons/ExportIcon.svg";
import Switch from "@material-ui/core/Switch";
import Zoom from "@material-ui/core/Zoom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  actionPermission,
  permissionCheck
} from "../../../../Utils/commonUtils";
import AppLoader from "components/AppLoader/Index";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px"
  },
  tooltipPlacementTop: {
    margin: "4px 0"
  }
}))(Tooltip);

const transitionEffectUseStyles = makeStyles((theme) => ({
  root: {
    height: 180
  },
  container: {
    display: "flex"
  },
  paper: {
    margin: theme.spacing(1)
  },
  svg: {
    width: 100,
    height: 100
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1
  }
}));

const headCellsItems = [
  {
    id: "ResidualMonth",
    label: "Residual Month",
    isSort: true,
    sortProperty: "ResidualMonth",
    actionsCellStyle: "left"
  },
  {
    id: "AgentName",
    label: "Agent",
    isSort: true,
    sortProperty: "AgentName",
    actionsCellStyle: "center"
  },
  {
    id: "RepCode",
    label: "RepCode",
    isSort: true,
    sortProperty: "RepCode",
    actionsCellStyle: "center"
  },
  {
    id: "TxnCount",
    label: "Transactions",
    isSort: true,
    sortProperty: "TxnCount",
    actionsCellStyle: "center"
  },
  {
    id: "TxnVolume",
    label: "Volume ($)",
    isSort: true,
    sortProperty: "TxnVolume",
    actionsCellStyle: "center"
  },
  {
    id: "Income",
    label: "Income ($)",
    isSort: true,
    sortProperty: "Income",
    actionsCellStyle: "center"
  },
  {
    id: "Expense",
    label: "Expense ($)",
    isSort: true,
    sortProperty: "Expense",
    actionsCellStyle: "center"
  },
  {
    id: "GrossProfit",
    label: "Gross Profit ($)",
    isSort: true,
    sortProperty: "GrossProfit",
    actionsCellStyle: "center"
  },
  {
    id: "PayouttoOthers",
    label: "Payout to Others ($)",
    isSort: true,
    sortProperty: "PayouttoOthers",
    actionsCellStyle: "center"
  },
  {
    id: "Payout",
    label: "Payout ($)",
    isSort: true,
    sortProperty: "Payout",
    actionsCellStyle: "center"
  },

  {
    id: "Adjustments",
    label: "Adjustments ($)",
    isSort: true,
    sortProperty: "Adjustment",
    actionsCellStyle: "center"
  },
  {
    id: "Payout",
    label: "Net Payout ($)",
    isSort: true,
    sortProperty: "Payout",
    actionsCellStyle: "center"
  },
  {
    id: "status",
    label: "Status",
    isSort: true,
    sortProperty: "status",
    actionsCellStyle: "center"
  },
  {
    id: "action",
    label: "",
    isSort: true,
    sortProperty: "action",
    actionsCellStyle: "right"
  }
];

const getLastMonth = () => {
  let makeDate = new Date();
  makeDate.setMonth(makeDate.getMonth());

  const month = makeDate.getMonth();
  const day = makeDate.getDate();
  const year = makeDate.getFullYear();
  makeDate = `${year}/${month}/${day}`;
  return makeDate;
};

const labelName = {
  processor: "Processor",
  status: "Status",
  fromMonth: "From",
  toMonth: "To",
  mid: "MID"
};

function queryStringToObject(queryString) {
  const pairs = queryString.substring(1).split("&");
  const array = pairs.map((el) => {
    const parts = el.split("=");
    return parts;
  });
  return Object.fromEntries(array);
}

function PayoutApproval({
  fetchPayoutApprovalList,
  fetchProcessorList,
  fetchAgentList,
  updatePayoutApprovalStatus,
  users
}) {
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  let searchInitialData = {
    processor: "",
    status: "1",
    fromMonth: "",
    toMonth: "",
    mid: ""
  };
  const dataParameter = "AgentID";
  const processorParameter = "AgentName";
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("ResidualMonth");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [payoutList, setPayoutList] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState({
    processor: "",
    status: "1",
    fromMonth: "",
    toMonth: "",
    mid: ""
  });
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const [chipValue, setChipValue] = React.useState({ ...searchInitialData });
  const [searchBar, setSearchBar] = React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);
  const [processorList, setProcessorList] = React.useState([]);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [selectedList, setSelectedList] = React.useState([]);
  const [changeStatusModal, setChangeStatusModal] = React.useState(false);
  const [selectedAgentsList, setSelectedAgentsList] = React.useState([]);
  const anchorRef = React.useRef(null);
  const transitionEffect = transitionEffectUseStyles();
  const [checked, setChecked] = React.useState(false);
  const [initialCallLoading, setInitialCallLoading] = React.useState(true);

  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };
  const isSelected = (value, selected) =>
    selectedList.indexOf(value.toString()) !== -1;

  const findAgentName = (id) => {
    if (!isEmpty(agentsList)) {
      const name = agentsList.find((x) => x.UserID == id);
      return name ? name.AgentName.split("[")[0] : id;
    }
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorShortName : id;
    }
  };

  const agentName = React.useMemo(
    () => findAgentName(searchValues.mid),
    [searchValues.mid, agentsList]
  );
  const processorName = React.useMemo(
    () => findProcessorName(searchValues.processor),
    [searchValues.processor, processorList]
  );

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleSubmitSearch = () => {
    handleSearchClose();
    setPage(1);
    getPayoutList({
      ...searchValues,
      fromMonth:
        isEmpty(searchValues.fromMonth) && !isEmpty(searchValues.toMonth)
          ? moment().subtract(2, "years").startOf("month").format("YYYY-MM-DD")
          : searchValues.fromMonth
    });
    setChipValue({
      ...searchValues,
      fromMonth:
        isEmpty(searchValues.fromMonth) && !isEmpty(searchValues.toMonth)
          ? moment().subtract(2, "years").startOf("month").format("YYYY-MM-DD")
          : searchValues.fromMonth
    });
  };

  const handleClearSearch = () => {
    setSearchValues({
      ...searchInitialData,
      fromMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
        .startOf("month")
        .format("YYYY-MM-DD"),
      toMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
        .endOf("month")
        .format("YYYY-MM-DD")
    });
    setChipValue({
      ...searchInitialData,
      fromMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
        .startOf("month")
        .format("YYYY-MM-DD"),
      toMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
        .endOf("month")
        .format("YYYY-MM-DD")
    });
    getPayoutList({
      ...searchInitialData,
      fromMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
        .startOf("month")
        .format("YYYY-MM-DD"),
      toMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
        .endOf("month")
        .format("YYYY-MM-DD")
    });
    handleSearchClose();
    setPage(1);
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getAgentList = (agentName = "") => {
    // if (searchAgentName === agentName) {
    //   return;
    // }
    // searchAgentName = agentName;
    fetchAgentList(
      (data) => {
        if (data.status || data.status === "success") {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: 919863231596669598,
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      { search: agentName }
    );
  };

  const handleDeleteChip = (chip) => {
    setPage(1);
    if (chipValue) {
      const temp = { ...searchValues };
      const tempChip = { ...chipValue };
      temp[chip] = "";
      setSearchValues({
        ...temp,
        fromMonth:
          isEmpty(temp.fromMonth) && !isEmpty(temp.toMonth)
            ? moment()
                .subtract(2, "years")
                .startOf("month")
                .format("YYYY-MM-DD")
            : temp.fromMonth
      });
      setChipValue({
        ...temp,
        processor: temp.processor != "" ? tempChip.processor : "",
        fromMonth:
          isEmpty(temp.fromMonth) && !isEmpty(temp.toMonth)
            ? moment()
                .subtract(2, "years")
                .startOf("month")
                .format("YYYY-MM-DD")
            : temp.fromMonth
      });
      getPayoutList({
        ...temp,
        fromMonth:
          isEmpty(temp.fromMonth) && !isEmpty(temp.toMonth)
            ? moment()
                .subtract(2, "years")
                .startOf("month")
                .format("YYYY-MM-DD")
            : temp.fromMonth
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const viewPayoutDetails = (details, type) => {
    if (type && type === "adjustment") {
      if (details.Adjustment !== "0.00") {
        const url = `/admin/AdjustmentDetails?mid=${
          details.AgentID
        }&fromMonth=${
          moment(searchValues.fromMonth).format("YYYY-MM-DD") || ""
        }&toMonth=${
          moment(searchValues.toMonth).format("YYYY-MM-DD") || ""
        }&processorId=${details.ProcessorID}&repCode=${
          searchValues.repCode
        }&userId=${localStorage.getItem("userId")}`;
        history.push(url);
        localStorage.setItem("AdjustmentDetails", url);
      }
    } else if (type && type === "action") {
      const url = `/admin/Adjustment/add?mid=${details.AgentID}&fromMonth=${
        moment(searchValues.fromMonth).format("YYYY-MM-DD") || ""
      }&toMonth=${
        moment(searchValues.toMonth).format("YYYY-MM-DD") || ""
      }&processorId=${details.ProcessorID}&userId=${localStorage.getItem(
        "userId"
      )}`;
      history.push(url);
      // localStorage.setItem("AdjustmentDetails", url);
    } else {
      const url = `/admin/PayoutDetail?agentId=${
        details.AgentID || ""
      }&processor=${
        details.ProcessorID || ""
      }&searchBy=ResidualMonth&fromMonth=${
        moment(searchValues.fromMonth).format("YYYY-MM-DD") || ""
      }&toMonth=${
        moment(searchValues.toMonth).format("YYYY-MM-DD") || ""
      }&dbaName=&repCode=`;
      history.push(url);
      localStorage.setItem("PayoutDetails", url);
    }
  };

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    fetchPayoutApprovalList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => ({
              "Residual Month": row.ResidualMonth,
              "Payout Month": row.PayoutMonth,
              Processor: row.Processor,
              Agent: row.AgentName,
              RepCode: row.RepCode,
              Transactions: exportNumberToDashFormat(row.TxnCount),
              Volume: exportNumberToDashFormat(row.TxnVolume),
              Income: exportNumberToDashFormat(row.Income),
              Expense: exportNumberToDashFormat(row.Expense),
              "Gross Profit": exportNumberToDashFormat(row.GrossProfit),
              "Payout to Others": exportNumberToDashFormat(row.PayouttoOthers),
              Payout: exportNumberToDashFormat(row.Payout),
              Adjustments: exportNumberToDashFormat(row.Adjustment),
              "Net Payout": exportNumberToDashFormat(row.NetPayout)
            }));
            exportToCSV(
              mappedLogs,
              "Payout Approval",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              processorName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const exportToPDFList = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    fetchPayoutApprovalList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => [
              row.ResidualMonth,
              row.PayoutMonth,
              row.Processor,
              row.AgentName,
              row.RepCode,
              exportNumberToDashFormat(row.TxnCount),
              exportNumberToDashFormat(row.TxnVolume),
              exportNumberToDashFormat(row.Income),
              exportNumberToDashFormat(row.Expense),
              exportNumberToDashFormat(row.GrossProfit),
              exportNumberToDashFormat(row.PayouttoOthers),
              exportNumberToDashFormat(row.Payout),
              exportNumberToDashFormat(row.Adjustment),
              exportNumberToDashFormat(row.NetPayout)
            ]);
            const headersName = [
              "Residual Month",
              "Payout Month",
              "Processor",
              "Agent",
              "RepCode",
              "Transactions",
              "Volume",
              "Income",
              "Expense",
              "Gross Profit",
              "Payout to Others",
              "Payout",
              "Adjustments",
              "Net Payout"
            ];
            exportToPDF(
              headersName,
              mappedLogs,
              "Payout Approval",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              processorName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const exportListDataWithPayout = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    fetchPayoutApprovalList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => ({
              "Residual Month": row.ResidualMonth,
              "Payout Month": row.PayoutMonth,
              Processor: row.Processor,
              Agent: row.AgentName,
              RepCode: row.RepCode,
              Transactions: exportNumberToDashFormat(row.TxnCount),
              Volume: exportNumberToDashFormat(row.TxnVolume),
              Income: exportNumberToDashFormat(row.Income),
              Expense: exportNumberToDashFormat(row.Expense),
              "Gross Profit": exportNumberToDashFormat(row.GrossProfit),
              "Payout to Others": exportNumberToDashFormat(row.PayouttoOthers),
              Payout: exportNumberToDashFormat(row.Payout),
              Adjustments: exportNumberToDashFormat(row.Adjustment),
              "Net Payout": exportNumberToDashFormat(row.NetPayout),
              "Gross Profit (From Month-1)": exportNumberToDashFormat(
                row.LastMonth1GrossProfit
              ),
              "Gross Profit (From Month-2)": exportNumberToDashFormat(
                row.LastMonth2GrossProfit
              ),
              "Payout (From Month-1)": exportNumberToDashFormat(
                row.LastMonth1Payout
              ),
              "Payout Profit (From Month-2)": exportNumberToDashFormat(
                row.LastMonth2GrossProfit
              ),
              "Adjustment Profit (From Month-1)": exportNumberToDashFormat(
                row.LastMonth1Adjustment
              ),
              "Adjustment Profit (From Month-2)": exportNumberToDashFormat(
                row.LastMonth2Adjustment
              ),
              "NetPayout Profit (From Month-1)": exportNumberToDashFormat(
                row.LastMonth1NetPayout
              ),
              "NetPayout Profit (From Month-2)": exportNumberToDashFormat(
                row.LastMonth2NetPayout
              )
            }));
            exportToCSVWithPayout(
              mappedLogs,
              "Payout Approval",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              processorName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getPayoutList = function (pre) {
    let filterData = {
      ...pre,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page,
      userId: localStorage.getItem("userId"),
      searchBy: "ResidualMonth"
    };

    fetchPayoutApprovalList(
      { ...filterData },
      (records) => {
        setInitialCallLoading(false);
        if (records.status === "success") {
          const content = get(records, "data", []);
          const { totalCount } = records;
          setPayoutList(content);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < totalCount ? page * rowsPerPage : totalCount,
            total: totalCount
          });
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  React.useEffect(() => {
    let filterData = { ...searchValues };
    if (users.payout.lastCalcDate && users.payout.lastCalcDate.data) {
      filterData.processor =
        searchValues.processor != ""
          ? searchValues.processor
          : users.payout.lastCalcDate.data[0].ProcessorID;
      setSearchValues({
        ...filterData
      });
      setChipValue({
        ...filterData
      });
      getPayoutList(filterData);
    }
  }, [orderBy, order, page, rowsPerPage]);

  React.useEffect(() => {
    let filterData = { ...searchInitialData };
    if (users.payout.lastCalcDate && users.payout.lastCalcDate.data) {
      filterData.fromMonth = moment(
        users.payout.lastCalcDate.data[0].LastCalcMonthYear
      )
        .startOf("month")
        .format("YYYY-MM-DD");
      filterData.toMonth = moment(
        users.payout.lastCalcDate.data[0].LastCalcMonthYear
      )
        .endOf("month")
        .format("YYYY-MM-DD");
      filterData.processor = users.payout.lastCalcDate.data[0].ProcessorID;
    }
    setSearchValues({
      ...filterData
    });
    setChipValue({
      ...filterData
    });
    getPayoutList(filterData);
  }, [users.payout.lastCalcDate]);

  React.useEffect(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
    if (isEmpty(agentsList)) {
      getAgentList();
    }
  }, []);

  const onCheck = (event, agents) => {
    if (Array.isArray(event) && Array.isArray(agents)) {
      setSelectedList(event.map((item) => item.toString()));
      setSelectedAgentsList(agents.map((item) => item.toString()));
    }
  };

  const handleAssignReassignModal = () => {
    setChangeStatusModal(true);
    setChecked(true);
  };

  const updateStatusCall = (values) => {
    const profileIds = [];
    selectedList.map((x) => {
      profileIds.push({ id: x });
    });
    const payload = {
      statusId: values.status,
      profileIds,
      userId: localStorage.getItem("userId"),
      month:
        1 + moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear).month(),
      year: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear).year(),
      processorId: users.payout.lastCalcDate.data[0].ProcessorID
    };
    updatePayoutApprovalStatus(
      payload,
      (data) => {
        if (data.status === "success") {
          setChangeStatusModal(false);
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
          setSelectedList([]);
          getPayoutList();
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  return (
    <>
      {initialCallLoading ? (
        <AppLoader loader={initialCallLoading} />
      ) : (
        <>
          {actionPermission(
            permissionCheck.Category.residual,
            permissionCheck.Page.PayoutApproval,
            permissionCheck.option.view
          ) && (
            <>
              <Grow
                in={checked}
                style={{ transformOrigin: "0 0 0" }}
                {...(checked ? { timeout: 12000 } : {})}
              >
                <Paper elevation={4} className={transitionEffect.paper}>
                  <ChangeStatusModal
                    open={changeStatusModal}
                    onClose={() => setChangeStatusModal(false)}
                    updateStatusCall={updateStatusCall}
                    selectedList={selectedList}
                    handleAssignReassignModal={handleAssignReassignModal}
                    // selectedMerchant={selectedMerchant}
                  />
                </Paper>
              </Grow>

              <TableContainer
                className={classes.TableContainer}
                component={Paper}
              >
                {windowWidth && (
                  <Grid container className={classes.searchContainer}>
                    <Grid item className={classes.margin}>
                      <div
                        className={classes.searchWrapper}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexWrap: "nowrap"
                        }}
                      >
                        <LastCalculationDialog />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            alignContent: "center",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            minWidth: "600px"
                          }}
                        >
                          <SearchComponent
                            searchBar={searchBar}
                            handleSearchClose={handleSearchClose}
                            handleSearchOpen={handleSearchOpen}
                            width="100%"
                            Fields={() => (
                              <FilterFields
                                handleSearchClose={() => handleSearchClose()}
                                setSearchValues={setSearchValues}
                                searchValues={searchValues}
                                handleSubmitSearch={handleSubmitSearch}
                                handleClearSearch={handleClearSearch}
                                processorList={processorList}
                              />
                            )}
                          />
                          {actionPermission(
                            permissionCheck.Category.residual,
                            permissionCheck.Page.PayoutApproval,
                            permissionCheck.option.export
                          ) &&
                            (actionPermission(
                              permissionCheck.Category.residual,
                              permissionCheck.Page.PayoutApproval,
                              permissionCheck.option.exportToExcel
                            ) ||
                              actionPermission(
                                permissionCheck.Category.residual,
                                permissionCheck.Page.PayoutApproval,
                                permissionCheck.option.exportToPdf
                              )) && (
                              <IconsTooltip title="Export" placement="top">
                                <div className={classes.appSvgIcons}>
                                  <ExportIcon
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    ref={anchorRef}
                                  />
                                </div>
                              </IconsTooltip>
                            )}

                          <Popper
                            open={openImportFileModal}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                            style={{
                              zIndex: "10"
                            }}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom-start"
                                      ? "left top"
                                      : "left bottom"
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      // autoFocusItem={openImportFileModal}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      {actionPermission(
                                        permissionCheck.Category.residual,
                                        permissionCheck.Page.PayoutApproval,
                                        permissionCheck.option.exportToExcel
                                      ) && (
                                        <MenuItem
                                          onClick={exportListData}
                                          style={{
                                            fontSize: "14px"
                                          }}
                                        >
                                          Agent Export
                                        </MenuItem>
                                      )}
                                      {actionPermission(
                                        permissionCheck.Category.residual,
                                        permissionCheck.Page.PayoutApproval,
                                        permissionCheck.option.exportToPdf
                                      ) && (
                                        <MenuItem
                                          onClick={exportListDataWithPayout}
                                          style={{
                                            fontSize: "14px"
                                          }}
                                        >
                                          MID Export
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={exportToPDFList}
                                        style={{
                                          fontSize: "14px"
                                        }}
                                      >
                                        Export to PDF
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
                {!windowWidth && (
                  <Grid container className={classes.searchContainer}>
                    <Grid item className={classes.expirtItemGrid}>
                      <SearchComponent
                        searchBar={searchBar}
                        handleSearchClose={handleSearchClose}
                        handleSearchOpen={handleSearchOpen}
                        Fields={() => (
                          <FilterFields
                            handleSearchClose={() => handleSearchClose()}
                            setSearchValues={setSearchValues}
                            searchValues={searchValues}
                            handleSubmitSearch={handleSubmitSearch}
                            handleClearSearch={handleClearSearch}
                            processorList={processorList}
                          />
                        )}
                      />
                      {actionPermission(
                        permissionCheck.Category.residual,
                        permissionCheck.Page.PayoutApproval,
                        permissionCheck.option.export
                      ) &&
                        (actionPermission(
                          permissionCheck.Category.residual,
                          permissionCheck.Page.PayoutApproval,
                          permissionCheck.option.exportToExcel
                        ) ||
                          actionPermission(
                            permissionCheck.Category.residual,
                            permissionCheck.Page.PayoutApproval,
                            permissionCheck.option.exportToPdf
                          )) && (
                          <IconsTooltip title="Export" placement="top">
                            <div className={classes.appSvgIcons}>
                              <ExportIcon
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleToggle}
                                ref={anchorRef}
                              />
                            </div>
                          </IconsTooltip>
                        )}

                      <Popper
                        open={openImportFileModal}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                        style={{
                          zIndex: "10"
                        }}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom-start"
                                  ? "left top"
                                  : "left bottom"
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  // autoFocusItem={openImportFileModal}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  {actionPermission(
                                    permissionCheck.Category.residual,
                                    permissionCheck.Page.PayoutApproval,
                                    permissionCheck.option.exportToExcel
                                  ) && (
                                    <>
                                      <MenuItem
                                        onClick={exportListData}
                                        style={{
                                          fontSize: "14px"
                                        }}
                                      >
                                        Agent Export
                                      </MenuItem>

                                      <MenuItem
                                        onClick={exportListDataWithPayout}
                                        style={{
                                          fontSize: "14px"
                                        }}
                                      >
                                        MID Export
                                      </MenuItem>
                                    </>
                                  )}
                                  {actionPermission(
                                    permissionCheck.Category.residual,
                                    permissionCheck.Page.PayoutApproval,
                                    permissionCheck.option.exportToPdf
                                  ) && (
                                    <MenuItem
                                      onClick={exportToPDFList}
                                      style={{
                                        fontSize: "14px"
                                      }}
                                    >
                                      Export to PDF
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Grid>
                  </Grid>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <Button
                    onClick={() => {
                      handleAssignReassignModal();
                    }}
                    className={classes.buttonStyle}
                    variant="contained"
                    color="primary"
                    disabled={isEmpty(selectedList)}
                  >
                    Payout Status
                  </Button>
                  <SearchedChips
                    handleDeleteChip={handleDeleteChip}
                    searchValues={chipValue}
                    labelName={labelName}
                    agentName={agentName}
                    processorName={processorName}
                  />
                </div>
                <CustomTable
                  page={page}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  data={payoutList}
                  headCells={headCellsItems}
                  dataParameter={dataParameter}
                  processorParameter={processorParameter}
                  isCallInitialization={initialCall}
                  selected={selectedList}
                  setSelected={onCheck}
                  isSelection
                  rowsPerPage={rowsPerPage}
                  pageDetails={pageDetails}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  totalCount={pageDetails.total}
                >
                  {payoutList.map((row) => {
                    const isItemSelected = isSelected(
                      row.AgentID,
                      selectedList
                    );
                    return (
                      <TableRow
                        key={row.RowNumber}
                        className={classes.cellHeadSign}
                      >
                        <TableCell
                          // onClick={(event) => handleClick(event, row.ItemName)}
                          align="left"
                          padding="checkbox"
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleRowDataCheck(
                                event,
                                row.AgentID.toString(),
                                row.AgentName,
                                selectedList,
                                selectedAgentsList,
                                onCheck
                              )
                            }
                            name={row.AgentID}
                            inputProps={{ "aria-labelledby": row.AgentID }}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          align="left"
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {row.ShortResidualMonth}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {row.AgentName}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {row.RepCode}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {row.TxnCount}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {accountingNumberformat(row.TxnVolume)}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {accountingNumberformat(row.Income)}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {accountingNumberformat(row.Expense)}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {row.GrossProfit.toString().includes("-") ? (
                            <span style={{ color: "red" }}>
                              ({row.GrossProfit.toString().replace("-", "")})
                            </span>
                          ) : (
                            row.GrossProfit
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {accountingNumberformat(row.PayouttoOthers)}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {row.Payout.toString().includes("-") ? (
                            <span style={{ color: "red" }}>
                              ({row.Payout.toString().replace("-", "")})
                            </span>
                          ) : (
                            row.Payout
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row, "adjustment")}
                        >
                          <span style={{ color: "#0093c9" }}>
                            {row.Adjustment}
                          </span>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {row.NetPayout.toString().includes("-") ? (
                            <span style={{ color: "red" }}>
                              ({row.NetPayout.toString().replace("-", "")})
                            </span>
                          ) : (
                            row.NetPayout
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.cellText}
                          onClick={() => viewPayoutDetails(row)}
                        >
                          {row.StatusName}
                        </TableCell>
                        {actionPermission(
                          permissionCheck.Category.residual,
                          permissionCheck.Page.PayoutApproval,
                          permissionCheck.option.addAdjustment
                        ) && (
                          <TableCell align="right" className={classes.cellText}>
                            <LongMenu
                              addAdjustment={() => {
                                viewPayoutDetails(row, "action");
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </CustomTable>
              </TableContainer>
            </>
          )}
          {!actionPermission(
            permissionCheck.Category.residual,
            permissionCheck.Page.PayoutApproval,
            permissionCheck.option.view
          ) && (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "25%"
              }}
            >
              Access denied.
            </p>
          )}
        </>
      )}
    </>
  );
}
PayoutApproval.propTypes = {
  fetchPayoutApprovalList: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  fetchAgentList: PropTypes.func,
  updatePayoutApprovalStatus: PropTypes.func,
  users: PropTypes.object
};

PayoutApproval.defaultProps = {
  fetchPayoutApprovalList: () => {},
  fetchProcessorList: () => {},
  fetchAgentList: () => {},
  updatePayoutApprovalStatus: () => {}
};

const mapStateToProps = (app) => ({
  users: app,
  tableData: app.users
});

export default connect(mapStateToProps, {
  fetchPayoutApprovalList,
  fetchProcessorList,
  fetchAgentList,
  updatePayoutApprovalStatus
})(PayoutApproval);
