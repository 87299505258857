import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TodayIcon from "@material-ui/icons/Today";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "../../../../assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

function SimpleDialog({
  setSearchValues,
  handleSubmitSearch,
  handleClearSearch,
  searchValues,
  importLogModuleList
}) {
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    allState[type] = value;
    if (type === "fromMonth") {
      allState.fromMonth = moment(value).startOf("month").format("YYYY-MM-DD");
    }
    if (type === "toMonth") {
      allState.toMonth = moment(value).endOf("month").format("YYYY-MM-DD");
    }

    setSearchValues({ ...allState });
  };

  const dateNow = new Date();
  const maxDate = new Date().setMonth(dateNow.getMonth() - 1);
  const minDate = new Date().setMonth(dateNow.getMonth() - 12);

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "3%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Grid style={{ width: "100%" }} item xs={12}>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Module"
            value={searchValues.moduleID}
            onChange={(e) => onChange(e.target.value, "moduleID")}
            name="moduleID"
            style={{ width: "100%" }}
            fullWidth
            id="moduleID"
            select
          >
            {importLogModuleList.map((data) => (
              <MenuItem
                className={classes.dialogBoxDropDown}
                value={data.Module_ID}
              >
                {data.Module_Name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Import Type"
            value={searchValues.importType}
            onChange={(e) => onChange(e.target.value, "importType")}
            name="importType"
            id="importType"
            select
            fullWidth
          >
            <MenuItem className={classes.dialogBoxDropDown} value="CSV">
              CSV
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="TXT ">
              TXT
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="XLS">
              XLS
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="XLSX">
              XLSX
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="File Name"
            value={searchValues.fileName}
            autoFocus={searchValues.fileName != "" && true}
            onChange={(e) => onChange(e.target.value, "fileName")}
            name="fileName"
            id="fileName"
            fullWidth
          ></TextField>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Status"
            value={searchValues.status}
            onChange={(e) => onChange(e.target.value, "status")}
            name="status"
            id="status"
            select
            fullWidth
          >
            <MenuItem className={classes.dialogBoxDropDown} value="C">
              Completed
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="P">
              Partially Completed
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="I">
              InProgress
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="F">
              Failed
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: "2%" }}>
        <Grid item xs>
          <InputLabel id="label-fromMonth">
            From Month
            <span className="spn-required">*</span>
          </InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              fullWidth
              views={["month", "year"]}
              id="fromMonth"
              name="fromMonth"
              labelId="label-fromMonth"
              allowKeyboardControl={false}
              maxDate={searchValues.toMonth || new Date()}
              minDate={minDate}
              minDateMessage=""
              invalidDateMessage=""
              maxDateMessage="Future dates are not allowed."
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {}}>
                      <TodayIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              format="MM/yyyy"
              placeholder="MM/YYYY"
              defaultValue={null}
              value={
                searchValues.fromMonth
                  ? moment(searchValues.fromMonth).format("MM-DD-YYYY")
                  : null
              }
              onChange={(value) => {
                let dateObj = value;
                if (dateObj && dateObj.getMonth()) {
                  const month = dateObj.getMonth() + 1; // months from 1-12
                  const day = dateObj.getDate();
                  const year = dateObj.getFullYear();
                  dateObj = `${year}/${month}/${day}`;
                }
                onChange(dateObj, "fromMonth");
              }}
              KeyboardButtonProps={{
                "aria-label": "start month"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs>
          <InputLabel id="label-toMonth">
            To Month
            <span className="spn-required">*</span>
          </InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              fullWidth
              labelId="label-toMonth"
              views={["month", "year"]}
              id="toMonth"
              name="toMonth"
              allowKeyboardControl={false}
              maxDate={moment(new Date()).endOf("month")}
              minDate={moment(searchValues.fromMonth).format("MM-DD-YYYY")}
              minDateMessage=""
              invalidDateMessage=""
              maxDateMessage="Future dates are not allowed."
              defaultValue={null}
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {}}>
                      <TodayIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              format="MM/yyyy"
              placeholder="MM/YYYY"
              value={
                searchValues.toMonth
                  ? moment(searchValues.toMonth).format("MM-DD-YYYY")
                  : null
              }
              onChange={(value) => {
                let dateObj = value;
                if (dateObj && dateObj.getMonth()) {
                  const month = dateObj.getMonth() + 1; // months from 1-12
                  const day = dateObj.getDate();
                  const year = dateObj.getFullYear();
                  dateObj = `${year}/${month}/${day}`;
                }
                onChange(dateObj, "toMonth");
              }}
              KeyboardButtonProps={{
                "aria-label": "start month"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.margin}
        style={{ justifyContent: "end", marginTop: "3%" }}
      >
        <Button
          variant="contained"
          className={classes.clearButton}
          onClick={() => handleClearSearch()}
        >
          clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          onClick={() => handleSubmitSearch()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  setSearchValues: PropTypes.func,
  searchValues: PropTypes.object,
  handleSubmitSearch: PropTypes.func,
  handleClearSearch: PropTypes.func,
  importLogModuleList: PropTypes.array
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(SimpleDialog);
