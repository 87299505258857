/* eslint-disable import/extensions */
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useSnackbar } from "notistack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { sendMailAgain } from "actions/MerchantSignUp";
import {
  getProviderDetailsById,
  changeProviderFormMode,
  getProviderParameterCall,
  getProviderStatusAction
} from "../../Redux/Actions/ProvideRegistration";
import { getProviderStatusById } from "../../actions/Operations";
import { actionPermission } from "../../Utils/commonUtils";

const useStyles = makeStyles(styles);

function LongMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);
  const PROVIDER_STATUS_CODE =
    process.env.REACT_APP_PROVIDER_STATUS_CODE.split(",");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    props.setValues((pre) => {
      pre.status = props.details.Current_Status;
      return pre;
    });
  };

  const resendMailApiCall = () => {
    props.sendMailAgain(
      { email: props.details.E_mail },
      (data) => {
        if (data.status) {
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
        handleClose();
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
        handleClose();
      }
    );
  };

  const handleClose = (type) => {
    if (type === "edit") {
      props.getProviderDetailsById(props.details.Id);
      props.changeProviderFormMode("Edit");
    }
    setAnchorEl(null);
  };

  const openStatusForm = () => {
    props.getProviderStatusById(
      { statusCode: props.details.Current_Status },
      (data) => {
        props.getProviderStatusAction(data);
        props.setValues((pre) => {
          pre.status = props.details.Current_Status;
          return pre;
        });
        props.openForm();
        props.setStatusPid();
        handleClose();
      },
      () => {
        enqueueSnackbar("Failed to get Data", {
          variant: "error"
        });
      }
    );
  };

  const activity = () => {
    let providerId = props.details.Id;
    props.changeProviderFormMode("");
    props.history.push(
      `/${props.userDetails.layout}/merchantactivity?mid=${providerId}`
    );
  };

  const handleProviderParameter = () => {
    const data = {
      providerId: props.details.Id
    };
    props.getProviderParameterCall(data);
    props.history.push(`/${props.userDetails.layout}/providerParameter`);
  };

  const userType = localStorage.getItem("userType");

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        style={{ padding: "0px" }}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => handleClose("edit")}
        >
          Edit
        </MenuItem>
        <MenuItem className={classes.dialogBoxDropDown} onClick={handleClose}>
          View
        </MenuItem>
        {props.provider && (
          <>
           {actionPermission(2, 1, 10) && (
            <MenuItem
              className={classes.dialogBoxDropDown}
              onClick={() => activity()}
            >
              Activity History
            </MenuItem>
        )}
            {/* hidden because the page is not relevant to the project */}
            {/* {(PROVIDER_STATUS_CODE.some(
              (x) => x === props.details.Current_Status.toString()
            ) &&
              userType === "Internal") && (
              <>
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  onClick={() => handleProviderParameter()}
                >
                  Set Configuration
                </MenuItem>
              </>
            )} */}
          </>
        )}
        {props.details.Client_Id && (
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => {
              props.history.push({
                pathname: `/${props.userDetails.layout}/panelConfigurations`,
                state: { details: props.details }
              });
            }}
          >
            Portal Setup
          </MenuItem>
        )}
        {userType === "Internal" && (
          <>
          {actionPermission( 2, 1, 12) && (
            <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={resendMailApiCall}
          >
            Send merchant signup link
          </MenuItem>
             )}
          </>
        
        )}
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  details: PropTypes.object,
  getProviderDetailsById: PropTypes.func,
  history: PropTypes.any,
  changeProviderFormMode: PropTypes.func,
  openForm: PropTypes.func,
  setStatusPid: PropTypes.func,
  userDetails: PropTypes.object,
  provider: PropTypes.bool,
  getProviderParameterCall: PropTypes.func,
  setValues: PropTypes.func,
  getProviderStatusById: PropTypes.func,
  sendMailAgain: PropTypes.func,
  getProviderStatusAction: PropTypes.func,
  roles: PropTypes.object,
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  roles: app.roles,
});

export default connect(mapStateToProps, {
  getProviderDetailsById,
  changeProviderFormMode,
  sendMailAgain,
  getProviderStatusById,
  getProviderParameterCall,
  getProviderStatusAction
})(LongMenu);
