import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

import { useSnackbar } from "notistack";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";

import CustomTable from "../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";
import { exportToCSV,exportToPDF } from "../../utils/common";
import {
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList,
  fetchIncomeExpenseDetailList
} from "../../actions/payout.action";
import { TablePageData } from "../../utils/constants";
import SearchComponent from "../../components/SearchFields";
import SearchedChips from "../../components/SearchChips";
import {
  getQueryParams,
  accountingNumberformat,
  getDataBlankFormat,
  exportNumberToDashFormat
} from "../../utils/common";

import FilterFields from "./filterFields";
import LastCalculationDialog from "modules/payout/components/LastCalculationDialog/LastCalculationDialog";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ExportIcon } from "../../../../assets/svgIcons/ExportIcon.svg";
import { ReactComponent as AddIconSvg } from "../../../../assets/svgIcons/AddIconSvg.svg";
import {
  actionPermission,
  permissionCheck,
} from "../../../../Utils/commonUtils";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const headCellsItems = [
  {
    id: "ResidualMonth",
    label: "Residual Month",
    isSort: true,
    sortProperty: "ResidualMonth",
    actionsCellStyle: "left"
  },
  {
    id: "PayoutMonth",
    label: "Payout Month",
    isSort: true,
    sortProperty: "PayoutMonth",
    actionsCellStyle: "center"
  },
  {
    id: "Merchant",
    label: "Merchant",
    isSort: true,
    sortProperty: "DBAName",
    actionsCellStyle: "center"
  },
  // {
  //   id: "DBAName",
  //   label: "DBA Name",
  //   isSort: true,
  //   sortProperty: "DBAName",
  //   actionsCellStyle: "center",
  // },
  {
    id: "AgentName",
    label: "Agent",
    isSort: true,
    sortProperty: "AgentName",
    actionsCellStyle: "center"
  },
  // {
  //   id: "RepCode",
  //   label: "Rep Code",
  //   isSort: true,
  //   sortProperty: "RepCode",
  //   actionsCellStyle: "center",
  // },
  {
    id: "Processor",
    label: "Processor",
    isSort: true,
    sortProperty: "Processor",
    actionsCellStyle: "center"
  },
  {
    id: "GroupName",
    label: "Fee Category",
    isSort: true,
    sortProperty: "GroupName",
    actionsCellStyle: "center"
  },
  {
    id: "ItemName",
    label: "Item Name",
    isSort: true,
    sortProperty: "ItemName",
    actionsCellStyle: "center"
  },
  {
    id: "Count",
    label: "Count",
    isSort: true,
    sortProperty: "Count",
    actionsCellStyle: "center"
  },
  // {
  //   id: "TxnCount",
  //   label: "Transactions",
  //   isSort: true,
  //   sortProperty: "TxnCount",
  // },
  {
    id: "Volume",
    label: "Volume ($)",
    isSort: true,
    sortProperty: "Volume",
    actionsCellStyle: "center"
  },
  {
    id: "Income",
    label: "Income ($)",
    isSort: true,
    sortProperty: "Income",
    actionsCellStyle: "center"
  },
  {
    id: "Expense",
    label: "Expense ($)",
    isSort: true,
    sortProperty: "Expense",
    actionsCellStyle: "center"
  },
  {
    id: "GrossProfit",
    label: "Gross Profit ($)",
    isSort: true,
    sortProperty: "GrossProfit",
    actionsCellStyle: "center"
  },
  {
    id: "Split",
    label: "Split %",
    isSort: true,
    sortProperty: "Split",
    actionsCellStyle: "center"
  },
  // {
  //   id: "BuyRate",
  //   label: "Buy Rate",
  //   isSort: true,
  //   sortProperty: "BuyRate",
  // },
  // {
  //   id: "RateType",
  //   label: "Rate Type",
  //   isSort: true,
  //   sortProperty: "RateType",
  // },
  {
    id: "NetProfit",
    label: "Net Profit ($)",
    isSort: true,
    sortProperty: "NetProfit",
    actionsCellStyle: "center"
  }
  // {
  //   id: "Payout",
  //   label: "Payout",
  //   isSort: true,
  //   sortProperty: "Payout",
  //   actionsCellStyle: "right",
  // },
];

const getLastMonth = () => {
  let makeDate = new Date();
  makeDate.setMonth(makeDate.getMonth());

  const month = makeDate.getMonth();
  const day = makeDate.getDate();
  const year = makeDate.getFullYear();
  makeDate = `${year}/${month}/${day}`;
  return makeDate;
};

const labelName = {
  fromMonth: "From Month",
  toMonth: "To Month",
  searchBy: "Searched By",
  processor: "Processor",
  agentId: "Agent",
  repCode: "Rep Code",
  dbaName: "DBA Name",
  payoutId: "Payout ID"
};

function PayoutSummaryReport({
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList,
  fetchIncomeExpenseDetailList,
  users
}) {
  const useStyles = makeStyles(tableStyle);
  const searchInitialData = {
    fromMonth: getQueryParams("fromMonth") || "2022-12-01",
    toMonth: getQueryParams("toMonth") || "2022-12-31",
    searchBy: getQueryParams("searchBy") || "ResidualMonth",
    processor: getQueryParams("processor") || "1",
    agentId: getQueryParams("agentId") || "",
    repCode: getQueryParams("repCode") || "",
    dbaName: "",
    payoutId: getQueryParams("payoutId") || ""
  };
  const classes = useStyles();
  const history = useHistory();

  const theme = useTheme();
  const dataParameter = "RowNumber";
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("ResidualMonth");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [payoutList, setPayoutList] = React.useState([]);
  const [searchBar, setSearchBar] = React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);
  const [chipValue, setChipValue] = React.useState({
    ...searchInitialData
  });
  const [processorList, setProcessorList] = React.useState([]);
  const [repCodeList, setRepCodeList] = React.useState([]);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [showMonthSearchError, setShowMonthSearchError] = React.useState(false);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const findAgentName = (id) => {
    if (!isEmpty(agentsList)) {
      const name = agentsList.find((x) => x.UserID == id);
      return name ? name.AgentName.split("[")[0] : id;
    }
  };

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorShortName : id;
    }
  };

  const agentName = React.useMemo(
    () => findAgentName(searchValues.agentId),
    [searchValues.agentId, agentsList]
  );
  const processorName = React.useMemo(
    () => findProcessorName(searchValues.processor),
    [searchValues.processor, processorList]
  );

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleSubmitSearch = () => {
    setChipValue(searchValues);
    handleSearchClose();
    setPage(1);
    if (searchValues.fromMonth == "" || searchValues.toMonth == "") {
      if (searchValues.fromMonth == "" && searchValues.toMonth != "") {
        setShowMonthSearchError("Please select start date.");
      } else if (searchValues.fromMonth != "" && searchValues.toMonth == "") {
        setShowMonthSearchError("Please select end date.");
      }
    } else {
      setShowMonthSearchError(false);
      getPayoutList({
        ...searchValues
      });
    }
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    handleSearchClose();
    setPage(1);
    setShowMonthSearchError(
      "Please select date range from search menu to get the data."
    );
    getPayoutList({
      ...searchInitialData
    });
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getAgentList = (agentNaame = "") => {
    // if (searchAgentName === agentName) {
    //   return;
    // }
    // searchAgentName = agentName;
    fetchAgentList(
      (data) => {
        if (data.status) {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: 919863231596669598,
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        } else {
        }
      },
      (err) => {},
      { search: agentNaame }
    );
  };

  const getRepCodeList = () => {
    fetchRepCodeList(
      (data) => {
        if (data.status) {
          setRepCodeList(data.data);
        } else {
        }
      },
      (err) => {},
      { userId: searchValues.agentId || "" }
    );
  };

  const handleDeleteChip = (chip) => {
    if (chipValue) {
      let temp = { ...searchValues };
      if (chip === "toMonth") {
        temp.toMonth = "";
        temp.fromMonth = "";
      } else {
        temp[chip] = "";
      }
      setSearchValues({ ...temp });
      setChipValue({ ...temp });
      getPayoutList({
        ...temp
      });
      if (temp.toMonth == "" && temp.fromMonth == "") {
        setShowMonthSearchError(
          "Please select a duration from the search box to see data."
        );
      } else {
        setShowMonthSearchError(false);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;
  const exportListData = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    filterData.userId = localStorage.getItem("userId") || "";
    fetchIncomeExpenseDetailList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => ({
              "Residual Month": row.ResidualMonth,
              "Payout Month": row.PayoutMonth,
              MID: row.MID,
              "DBA Name": row.DBAName,
              "Agent Name": row.AgentName,
              "Rep Code": row.RepCode,
              Processor: row.Processor,
              "Fee Category": row.GroupName,
              "Item Name": row.ItemName,
              Count: exportNumberToDashFormat(row.Count),
              Volume: exportNumberToDashFormat(accountingNumberformat(row.Volume)),
              Income: exportNumberToDashFormat(row.Income),
              Expense: exportNumberToDashFormat(row.Expense),
              "Gross Profit": exportNumberToDashFormat(row.GrossProfit),
              "Split %": exportNumberToDashFormat(row.Split),
              "Net Profit": exportNumberToDashFormat(row.NetProfit)
            }));
            exportToCSV(
              mappedLogs,
              "Income Expense Detail",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              processorName
            );
          }
        } else {
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const exportToPDFList = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    filterData.userId = localStorage.getItem("userId") || "";
    fetchIncomeExpenseDetailList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const totalCount = content.length;
          if (content) {
            const mappedLogs = content.map((row) => [
              row.ResidualMonth,
              row.PayoutMonth,
              row.MID,
              row.DBAName,
              row.AgentName,
              row.RepCode,
              row.Processor,
              row.GroupName,
              row.ItemName,
              exportNumberToDashFormat(row.Count),
              exportNumberToDashFormat(accountingNumberformat(row.Volume)),
              exportNumberToDashFormat(row.Income),
              exportNumberToDashFormat(row.Expense),
              exportNumberToDashFormat(row.GrossProfit),
              exportNumberToDashFormat(row.Split),
              exportNumberToDashFormat(row.NetProfit)
            ]);
            const headersName = [
              "Residual Month",
              "Payout Month",
              "MID",
              "DBA Name",
              "Agent Name",
              "Rep Code",
              "Processor",
              "Fee Category",
              "Item Name",
              "Count",
              "Volume",
              "Income",
              "Expense",
              "Gross Profit",
              "Split %",
              "Net Profit"
            ];
            exportToPDF(
              headersName,
              mappedLogs,
              "Income Expense Detail",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              processorName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getPayoutList = function (searchValues) {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page,
      userId: localStorage.getItem("userId"),
      ...searchValues
    };
    fetchIncomeExpenseDetailList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const { totalCount } = records;
          setPayoutList(content);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < totalCount ? page * rowsPerPage : totalCount,
            total: totalCount
          });
        } else {
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  React.useEffect(() => {
    getPayoutList({
      ...searchValues
    });
  }, [orderBy, order, page, rowsPerPage]);

  React.useEffect(() => {
    if (users.payout.lastCalcDate && users.payout.lastCalcDate.data) {
      if (getQueryParams("fromMonth")) {
        const serarcValuesFromLocal = {
          repCode: getQueryParams("repCode"),
          agentId: getQueryParams("agentId"),
          dbaName: searchValues.dbaName,
          processor: getQueryParams("processor"),
          fromMonth: getQueryParams("fromMonth"),
          toMonth: getQueryParams("toMonth"),
          searchBy: getQueryParams("searchBy"),
          payoutId: getQueryParams("payoutId")
        };
        setChipValue(serarcValuesFromLocal);
        setSearchValues(serarcValuesFromLocal);
        getPayoutList(serarcValuesFromLocal);
      } else {
        const serarcValuesFromLocal = {
          repCode: searchValues.repCode,
          agentId: searchValues.agentId,
          dbaName: searchValues.dbaName,
          fromMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
            .startOf("month")
            .format("YYYY-MM-DD"),
          processor: users.payout.lastCalcDate.data[0].ProcessorID,
          toMonth: moment(users.payout.lastCalcDate.data[0].LastCalcMonthYear)
            .endOf("month")
            .format("YYYY-MM-DD")
        };
        setChipValue(serarcValuesFromLocal);
        setSearchValues(serarcValuesFromLocal);
        getPayoutList(serarcValuesFromLocal);
      }
    }
  }, [users.payout.lastCalcDate]);

  React.useEffect(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
    if (isEmpty(agentsList)) {
      getAgentList();
    }
  }, []);

  React.useEffect(() => {
    if (searchValues.agentId) {
      getRepCodeList();
    }
  }, [searchValues.agentId]);

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid
            container
            className={classes.searchContainer}
            style={{ flexWrap: "nowrap", justifyContent: "end" }}
          >
            <Grid
              item
              className={classes.margin}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start"
              }}
            >
              <div style={{ padding: "0 8px" }}>
                <LastCalculationDialog />
              </div>
            </Grid>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <SearchComponent
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => {
                    return (
                      <FilterFields
                        handleSearchClose={() => handleSearchClose()}
                        setSearchValues={setSearchValues}
                        searchValues={searchValues}
                        agentsList={agentsList}
                        processorList={processorList}
                        repCodeList={repCodeList}
                        getAgentList={getAgentList}
                        handleSubmitSearch={handleSubmitSearch}
                        handleClearSearch={handleClearSearch}
                      />
                    );
                  }}
                />
                  {actionPermission(
                  permissionCheck.Category.reports,
                  permissionCheck.Page.IncomeExpenseDetail,
                  permissionCheck.option.export
                ) && (actionPermission(
                  permissionCheck.Category.reports,
                  permissionCheck.Page.IncomeExpenseDetail,
                  permissionCheck.option.exportToExcel
                ) || actionPermission(
                  permissionCheck.Category.reports,
                  permissionCheck.Page.IncomeExpenseDetail,
                  permissionCheck.option.exportToPdf
                ) ) && (
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>
                   )}
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10"
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            // autoFocusItem={openImportFileModal}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                             {actionPermission(
                              permissionCheck.Category.reports,
                              permissionCheck.Page.IncomeExpenseDetail,
                              permissionCheck.option.exportToExcel
                            ) && (
                            <MenuItem
                              onClick={exportListData}
                              style={{
                                fontSize: "14px"
                              }}
                            >
                              Export to Excel
                            </MenuItem>
                             )}
                              {actionPermission(
                              permissionCheck.Category.reports,
                              permissionCheck.Page.IncomeExpenseDetail,
                              permissionCheck.option.exportToPdf
                            ) && (
                            <MenuItem
                              onClick={exportToPDFList}
                              style={{
                                fontSize: "14px"
                              }}
                            >
                              Export to PDF
                            </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid
              item
              className={classes.margin}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start"
              }}
            >
              <div style={{ padding: "0 8px" }}>
                <LastCalculationDialog />
              </div>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              <SearchComponent
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => {
                  return (
                    <FilterFields
                      handleSearchClose={() => handleSearchClose()}
                      setSearchValues={setSearchValues}
                      searchValues={searchValues}
                      agentsList={agentsList}
                      processorList={processorList}
                      repCodeList={repCodeList}
                      getAgentList={getAgentList}
                      handleSubmitSearch={handleSubmitSearch}
                      handleClearSearch={handleClearSearch}
                    />
                  );
                }}
              />
               {actionPermission(
                  permissionCheck.Category.reports,
                  permissionCheck.Page.IncomeExpenseDetail,
                  permissionCheck.option.export
                ) && (actionPermission(
                  permissionCheck.Category.reports,
                  permissionCheck.Page.IncomeExpenseDetail,
                  permissionCheck.option.exportToExcel
                ) || actionPermission(
                  permissionCheck.Category.reports,
                  permissionCheck.Page.IncomeExpenseDetail,
                  permissionCheck.option.exportToPdf
                ) ) && (
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>
                   )}
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10"
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            // autoFocusItem={openImportFileModal}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                             {actionPermission(
                              permissionCheck.Category.reports,
                              permissionCheck.Page.IncomeExpenseDetail,
                              permissionCheck.option.exportToExcel
                            ) && (
                            <MenuItem
                              onClick={exportListData}
                              style={{
                                fontSize: "14px"
                              }}
                            >
                              Export to Excel
                            </MenuItem>
                             )}
                              {actionPermission(
                              permissionCheck.Category.reports,
                              permissionCheck.Page.IncomeExpenseDetail,
                              permissionCheck.option.exportToPdf
                            ) && (
                            <MenuItem
                              onClick={exportToPDFList}
                              style={{
                                fontSize: "14px"
                              }}
                            >
                              Export to PDF
                            </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
            </Grid>
          </Grid>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "10px"
          }}
        >
          <Grid item style={{ flexWrap: "nowrap" }}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              className={classes.clearButton}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
          <SearchedChips
            handleDeleteChip={handleDeleteChip}
            searchValues={chipValue}
            labelName={labelName}
            agentName={agentName}
            processorName={processorName}
          />
        </div>
        <CustomTable
          page={page}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          data={payoutList}
          headCells={headCellsItems}
          dataParameter={dataParameter}
          isCallInitialization={initialCall}
          selected={[]}
          setSelected={() => {}}
          isSelection={false}
          rowsPerPage={rowsPerPage}
          pageDetails={pageDetails}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          showMonthSearchError={showMonthSearchError}
        >
          {payoutList.map((row) => {
            return (
              <TableRow key={row.RowNumber} className={classes.cellHeadSign}>
                <TableCell
                  className={classes.cellText}
                  align="left"
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.ShortResidualMonth}
                </TableCell>
                <TableCell
                  className={classes.cellText}
                  align="center"
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.ShortPayoutMonth}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.MID}
                  <br />
                  <span style={{ color: "#0093c9" }}>
                    {getDataBlankFormat(row.DBAName)}
                  </span>
                </TableCell>
                {/* <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.DBAName}
                </TableCell> */}
                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.AgentName}
                  <br />
                  <span style={{ color: "#0093c9" }}>
                    {getDataBlankFormat(row.RepCode)}
                  </span>
                </TableCell>
                {/* <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.RepCode}
                </TableCell> */}
                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.Processor}
                </TableCell>

                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.GroupName}
                </TableCell>

                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.ItemName}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.Count}
                </TableCell>

                {/* <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.TxnCount}
                </TableCell> */}
                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {accountingNumberformat(row.Volume)}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.Income}
                  {/* {accountingNumberformat(row.Income)} */}
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.Expense}
                  {/* {accountingNumberformat(row.Expense)} */}
                </TableCell>

                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.GrossProfit.toString().includes("-") ? (
                    <span style={{ color: "red" }}>
                      ({row.GrossProfit.toString().replace("-", "")})
                    </span>
                  ) : (
                    row.GrossProfit
                  )}
                </TableCell>

                <TableCell
                  align="center"
                  className={classes.cellText}
                  style={{}}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.Split}
                </TableCell>
                {/* <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.BuyRate}
                </TableCell> */}
                {/* <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.RateType}
                </TableCell> */}

                <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {row.NetProfit.toString().includes("-") ? (
                    <span style={{ color: "red" }}>
                      ({row.NetProfit.toString().replace("-", "")})
                    </span>
                  ) : (
                    row.NetProfit
                  )}
                </TableCell>
                {/* <TableCell
                  align="center"
                  className={classes.cellText}
                  // onClick={() => handleAddEdit("edit", row)}
                >
                  {accountingNumberformat(row.Payout)}
                </TableCell> */}
              </TableRow>
            );
          })}
        </CustomTable>
      </TableContainer>
    </>
  );
}
PayoutSummaryReport.propTypes = {
  fetchProcessorList: PropTypes.func,
  fetchAgentList: PropTypes.func,
  fetchRepCodeList: PropTypes.func,
  fetchIncomeExpenseDetailList: PropTypes.func,
  users: PropTypes.object
};

PayoutSummaryReport.defaultProps = {
  fetchProcessorList: () => {},
  fetchAgentList: () => {},
  fetchRepCodeList: () => {},
  fetchIncomeExpenseDetailList: () => {}
};

const mapStateToProps = (app) => ({
  users: app
});

export default connect(mapStateToProps, {
  fetchProcessorList,
  fetchAgentList,
  fetchRepCodeList,
  fetchIncomeExpenseDetailList
})(PayoutSummaryReport);
