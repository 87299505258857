import React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import { ThemeProvider, createTheme, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { commonStyle } from "../../assets/style/js/commonStyle";
import TodayIcon from "@material-ui/icons/Today";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import tableStyle from "../../../../assets/jss/material-dashboard-react/components/reportTables";
import Switch from "@material-ui/core/Switch";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles(tableStyle);

const transitionEffectUseStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  container: {
    display: "flex",
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

const ChangeStatusModal = ({
  open,
  onClose,
  updateStatusCall,
  selectedList,
  handleAssignReassignModal
}) => {
  const oldTheme = useTheme();
  const cmClasses = commonStyle();
  const classes = useStyles();
  const transitionEffect = transitionEffectUseStyles();
  const [checked, setChecked] = React.useState(false);
  const theme = createTheme({
    ...oldTheme,
    overrides: {
      MuiAutocomplete: {
        inputRoot: {
          padding: "0px",
        },
        input: {
          padding: "0px",
        },
      },
      MuiOutlinedInput: {
        root: {
          background: "#F9FAFF 0% 0% no-repeat",
          borderRadius: "6px",
          height: "44px",
        },
      },
    },
  });

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      {/* <div className={transitionEffect.root}>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleAssignReassignModal} />}
          label="Show"
        />
        <div className={transitionEffect.container}>
          <Zoom in={checked}>
            <Paper elevation={4} className={transitionEffect.paper}>
              <svg className={transitionEffect.svg}>
                <polygon
                  points="0,100 50,00, 100,100"
                  className={transitionEffect.polygon}
                />
              </svg>
            </Paper>
          </Zoom>
          <Zoom
            in={checked}
            style={{ transitionDelay: checked ? "500ms" : "0ms" }}
          >
            <Paper elevation={4} className={transitionEffect.paper}>
              <svg className={transitionEffect.svg}>
                <polygon
                  points="0,100 50,00, 100,100"
                  className={transitionEffect.polygon}
                />
              </svg>
            </Paper>
          </Zoom>
        </div>
      </div> */}
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        aria-labelledby="sasdasd"
        aria-describedby="simpleasdasdasdan"
        
      >
        <Zoom
          in={open}
          style={{ transitionDelay: open ? "250ms" : "0ms" }}
        >
        <Fade in={open}>
          <Paper
          className={classes.dialogBoxBorder}
            style={{
              width: "400px",
            }}
          >
            <div className={classes.statusModalHeader}>
              <div className={classes.statusHeadingText}>
                Change Payout Status
              </div>
              <ClearIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => onClose(false)}
              />
            </div>
            <Formik
              enableReinitialize
              // eslint-disable-next-line react/jsx-no-bind
              onSubmit={updateStatusCall}
              initialValues={{
                status: "P",
              }}
            >
              {({ values, setFieldValue }) => (
                <Form
                  style={{
                    padding: theme.spacing(2, 2, 3),
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    className={classes.margin}
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h1" style={{ padding: "10px" }}>
                      # of selected payouts:
                      </Typography>
                      {"    "}
                      <Typography
                        component="p"
                        variant="body1"
                        style={{ padding: "10px" }}
                      >
                        {selectedList.length}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h1" style={{ display:"flex", alignItems:"center", padding: "10px" }}>
                        Status:
                      </Typography>
                      {"    "}
                      <Typography
                        component="p"
                        variant="body1"
                        style={{ padding: "10px" }}
                      >
                        <ThemeProvider theme={theme}>
                          <Field
                            as={TextField}
                            SelectProps={{
                              MenuProps: { disablePortal: false },
                            }}
                            name="status"
                            id="status"
                            placeholder="Status"
                            variant="outlined"
                            onChange={(e) => {
                              setFieldValue("status", e.target.value);
                            }}
                            select
                            style={{ minWidth: "280px" }}
                            fullWidth
                          >
                            <MenuItem value="A">Approved</MenuItem>
                            <MenuItem value="D">Declined</MenuItem>
                            <MenuItem value="I">In Review</MenuItem>
                            <MenuItem value="P">Pending</MenuItem>
                          </Field>
                        </ThemeProvider>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    spacing={2}
                    style={{
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                    container
                  >
                    <Grid item>
                      <Button
                        color="primary"
                        type="submit"
                        // disabled={!values.profileId}
                        variant="contained"
                        contained
                        className={classes.actionButtonStyle}
                        style={{
                          textTransform: "none",
                        }}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
        </Zoom>
      </Modal>
    </>
  );
};

ChangeStatusModal.propTypes = {
  selectedList: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  updateStatusCall: PropTypes.func,
  handleAssignReassignModal: PropTypes.func,
};

ChangeStatusModal.defaultProps = {
  open: true,
  onClose: () => {},
  updateStatusCall: () => {},
  handleAssignReassignModal: () => {},
};
export default ChangeStatusModal;
