import "./style.scss";
import "../../../assets/css/CountryCode.scss";

import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Select
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import OTPInput from "otp-input-react";
import { connect } from "react-redux";
// import isNumber from "lodash/isNumber";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import jwt from "jsonwebtoken";
import { useSnackbar } from "notistack";
import React from "react";
import isEmpty from "lodash/isEmpty";
import {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
  getUserLeftMenuCall,
  clientThemeCall,
  clientPortalConfig
} from "../../../Redux/Actions/LoginActions";
import {
  getUserDetailsById,
  getUserProfileImage
} from "../../../Redux/Actions/UserRegistration";
import { lastCalculationDate } from "../../../Redux/Actions/payoutAction";
import { getOtpAction, getCountryList } from "../../../actions/login.action";
import { checkLayoutName } from "../../../Uintls/gloables";

import Validation from "../../../Utils/Validations";
import brandLogo from "../../../assets/img/Animated_Logo.gif";
import useStyles from "./Style";

const adminInitialValues = {
  clientId: "H001",
  id: "",
  password: "",
  countryCode: 1
};
const patientInitialValues = {
  clientId: "",
  id: "",
  password: "",
  userType: "PT",
  countryCode: 1
};

function Login(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [onClickOtp, setOnClickOtp] = React.useState(false);
  const [action, setAction] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [loginType, setloginType] = React.useState("admin");
  const [initialValues, setInitialValues] = React.useState({});
  const [countryCode, setCountryCode] = React.useState([]);

  const handleOtp = (value) => {
    setOtp(value);
  };

  const onOtpSend = function (values) {
    setAction(true);
    localStorage.setItem("email", values.id.toLowerCase());
    const updatedValues = {
      email: values.id,
      clientId: values.clientId || ""
    };
    if (/^\d+$/.test(values.id)) {
      updatedValues.countryCode = values.countryCode;
    }
    setInitialValues(values);
    props.getOtpAction(
      updatedValues,
      (data) => {
        if (data.status) {
          enqueueSnackbar(`One time code is sent to ${values.id}`, {
            variant: "success"
          });
          setOnClickOtp(!onClickOtp);
          setAction(false);
        } else {
          setAction(false);
          enqueueSnackbar(data.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("Failed to send One time code", {
          variant: "error"
        });
      }
    );
  };

  const passwordLogin = function (values) {
    if (props.app.defaultTheme == false) {
      enqueueSnackbar(
        "System Error. Please contact the system administrator.",
        {
          variant: "error"
        }
      );
    } else {
      setAction(true);
      localStorage.setItem("email", values.id.toLowerCase());
      const updatedValues = {
        Email: values.id,
        clientId: values.clientId || "",
        loginBy: "",
        password: values.password
      };
      if (/^\d+$/.test(values.id)) {
        updatedValues.countryCode = values.countryCode;
      }
      props.loginRequest(updatedValues);
    }
  };

  const otpLogin = function () {
    setAction(true);
    const updatedValues = {
      Email: initialValues.id,
      clientId: initialValues.clientId || "",
      loginBy: "OTP",
      password: otp
    };
    if (/^\d+$/.test(initialValues.id)) {
      updatedValues.countryCode = initialValues.countryCode;
    }
    props.loginRequest(updatedValues);
  };

  React.useEffect(() => {
    if (isEmpty(countryCode) && !props.app.defaultTheme == false) {
      props.getCountryList((data) => {
        setCountryCode(data.Country_Data);
      });
    }
  }, []);

  React.useEffect(() => {
    if (props.userData !== false) {
      if (props.userData && props.userData.status) {
        setAction(false);
        localStorage.setItem("token", `bearer ${props.userData.token}`);
        jwt.verify(
          props.userData.token,
          "Support@Vaminfosyshserus",
          (err, decoded) => {
            if (decoded !== undefined) {
              // const idealPath = JSON.parse(localStorage.getItem("idealPath"));
              props.clientThemeCall(decoded.ClientID);
              props.clientPortalConfig(decoded.ClientID);
              const idealPath = "";
              let LandingPage = "/";
              let MainCat = "";
              if (idealPath) {
                LandingPage = idealPath.landingPageName;
              } else {
                LandingPage = decoded.Role_Permission_Data.LandingPage;
                MainCat =
                  decoded.Role_Permission_Data.Main_Category_LandingPage;
              }
              props.setAppBarName(
                MainCat ? `${MainCat} / ${LandingPage}` : LandingPage
              );
              const layoutName = checkLayoutName(
                decoded.Role_Permission_Data.Profile_Code
              );
              props.setLayoutName(layoutName);
              if (idealPath) {
                props.history.push(idealPath.path);
              } else {
                let userType = "";
                if (decoded) {
                  if (
                    decoded.Role_Permission_Data.Profile_Code === "MUR" ||
                    decoded.Role_Permission_Data.Profile_Code === "Merchant"
                  ) {
                    userType = "External";
                  } else {
                    userType = "Internal";
                  }
                }
                if (!isEmpty(userType)) {
                  localStorage.setItem("userType", userType);
                }
                localStorage.setItem("menu", JSON.stringify(decoded));
                localStorage.setItem("clientId", decoded.ClientID);
                localStorage.setItem("userId", decoded.UserID);
                localStorage.setItem(
                  "sendApiClientId",
                  JSON.stringify([decoded.ClientID])
                );
                props.getUserDetailsById(decoded.UserID);
                // props.getUserProfileImage(decoded.UserID);
                props.setUserRole(decoded);
                props.getUserLeftMenuCall();
                props.lastCalculationDate();
                props.history.push(
                  `/${layoutName}/${LandingPage.replace(/\s/g, "")}`
                );
              }
            }
          }
        );
      } else {
        setAction(false);
        enqueueSnackbar(props.userData.message, {
          variant: "error"
        });
      }
    }
  }, [props]);

  React.useEffect(() => {
    if (loginType === "admin") {
      setInitialValues(adminInitialValues);
    }
    if (loginType === "patient") {
      setInitialValues(patientInitialValues);
    }
  }, [loginType]);

  React.useEffect(() => {
    setOtp("");
  }, [onClickOtp]);
  if (props.app.defaultTheme.status == true) {
    var image =
      props.app.defaultTheme && props.app.defaultTheme.data[0].Logo_Image;
  }
  return (
    <div className="dashboardTiles" style={{ position: "relative" }}>
      <Container component="main" className={classes.rootNew}>
        <CssBaseline />
        <Paper
          className={!windowWidth ? classes.paperNewM : classes.paperNew}
          elevation={5}
        >
          <Box
            height="100%"
            p={{
              xs: 3,
              sm: 4,
              md: 5,
              xl: 6
            }}
            display={{ xs: "none", sm: "none", md: "flex" }}
            className={`${classes.panelWidth} auth-left-panel`}
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.brandLogo}>
                <img className="imgf" src={image} alt="appName" />
              </div>
              <Box mt={2} className={classes.brandTag}>
                <Typography component="h6" variant="subtitle1" align="center" />
              </Box>
            </Box>
          </Box>
          <Box
            display={{ xs: "flex", sm: "flex", md: "none" }}
            className="mobileHeader"
            container
            justify="center"
          >
            <div className={classes.brandLogo}>
              <img className="imgm" src={image} alt="appName" />
            </div>
          </Box>
          <Box
            height="100%"
            pt={4}
            pb={4}
            pl={{
              xs: 4,
              sm: 6,
              md: 2,
              xl: 8
            }}
            pr={{
              xs: 4,
              sm: 6,
              md: 2,
              xl: 8
            }}
            display="flex"
            alignItems="center"
            className={
              !windowWidth
                ? `${classes.panelWidth} auth-right-panelM`
                : `${classes.panelWidth} auth-right-panel`
            }
          >
            {isEmpty(loginType) && (
              <Grid
                container
                justify="center"
                spacing={2}
                className="serviceCardList"
              >
                <Grid
                  className={"serviceCard " + "serviceCard_" + "loginAdmin"}
                  xs={9}
                  onClick={() => setloginType("admin")}
                  item
                >
                  <Link>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      className={`${classes.tilesCard} tiles-capsule`}
                    >
                      <Box p={3} className="tiles-head">
                        <Typography variant="h4" component="h2">
                          Admin Login
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>

                <Grid
                  className={"serviceCard " + "serviceCard_" + "loginPatient"}
                  xs={9}
                  onClick={() => setloginType("patient")}
                  item
                >
                  <Link>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      className={`${classes.tilesCard} tiles-capsule`}
                    >
                      <Box p={3} className="tiles-head">
                        <Typography variant="h4" component="h2">
                          Patient Login
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              </Grid>
            )}
            {!isEmpty(loginType) && (
              <>
                {!onClickOtp ? (
                  <>
                    <div className={classes.authInnerForm}>
                      <Formik
                        validationSchema={
                          loginType === "patient"
                            ? Validation.loginPatient
                            : Validation.loginAdmin
                        }
                        enableReinitialize
                        // eslint-disable-next-line react/jsx-no-bind
                        onSubmit={passwordLogin}
                        initialValues={initialValues}
                      >
                        {({ values, errors, setFieldValue, dirty }) => (
                          <>
                            <Form>
                              {/* {!windowWidth && (
                                <Typography
                                  component="h1"
                                  variant="h3"
                                  gutterBottom
                                  className={classes.loginFont}
                                >
                                  TPO
                                </Typography>
                              )} */}
                              <Typography component="p" variant="subtitle2">
                                Log in to your account
                              </Typography>
                              <Typography
                                style={{ marginTop: "10px", color: "#2ba992" }}
                                component="p"
                                variant="subtitle2"
                              >
                                <b>Client ID</b>
                                &nbsp;
                                {`${adminInitialValues.clientId}`}
                              </Typography>
                              <div className={classes.form}>
                                {/* {loginType === "admin" && (
                                  <>
                                    <Field
                                      as={TextField}
                                      margin="normal"
                                      fullWidth
                                      id="clientId"
                                      label={"Enter Client ID" + "*"}
                                      name="clientId"
                                      autoComplete="clientId"
                                      autoFocus={loginType === "admin"}
                                      inputProps={{ readOnly: true }}
                                    />
                                    <ErrorMessage name="clientId">
                                      {msg => (
                                        <Typography
                                          component="span"
                                          className="error"
                                          variant="body2"
                                        >
                                          {msg}
                                        </Typography>
                                      )}
                                    </ErrorMessage>
                                  </>
                                )} */}
                                <Field
                                  as={TextField}
                                  margin="normal"
                                  fullWidth
                                  id="id"
                                  required
                                  autoComplete="new-password"
                                  error={errors.id}
                                  label="Enter Email"
                                  name="id"
                                  autoFocus={loginType !== "admin"}
                                  InputProps={
                                    /^\d+$/.test(values.id) && {
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Select
                                            id="country-menu"
                                            value={values.countryCode}
                                            onChange={(event) => {
                                              setFieldValue(
                                                "countryCode",
                                                event.target.value
                                              );
                                            }}
                                            classes={{ root: "country-list" }}
                                            className="no-underline"
                                            inputProps={{
                                              "aria-label": "Without label"
                                            }}
                                          >
                                            {countryCode.map((country) => (
                                              <MenuItem
                                                value={country.Dial_Code}
                                                option={countryCode}
                                                key={country.Country_Code}
                                                className="country"
                                              >
                                                <span
                                                  className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                                    0,
                                                    2
                                                  )}`}
                                                />
                                                &nbsp;
                                                <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                                &nbsp;
                                                <span className="country-name">
                                                  ({country.Country_Name})
                                                </span>
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </InputAdornment>
                                      )
                                    }
                                  }
                                />
                                <ErrorMessage name="id">
                                  {(msg) => (
                                    <Typography
                                      component="span"
                                      className="error"
                                      variant="body2"
                                    >
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>
                                <ErrorMessage name="countryCode">
                                  {(msg) => (
                                    <Typography
                                      component="span"
                                      className="error"
                                      variant="body2"
                                    >
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>
                                <Field
                                  as={TextField}
                                  margin="normal"
                                  fullWidth
                                  name="password"
                                  error={errors.password}
                                  label={"Password" + "*"}
                                  type="password"
                                  id="password"
                                  autoComplete="new-password"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Button
                                          className={classes.forgotButton}
                                          color="primary"
                                          onClick={() => {
                                            props.history.push("resetPassword");
                                          }}
                                        >
                                          Forgot?
                                        </Button>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                                <ErrorMessage name="password">
                                  {(msg) => (
                                    <Typography
                                      component="span"
                                      className="error"
                                      variant="body2"
                                    >
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>

                                <Grid
                                  container
                                  alignItems="center"
                                  justify="center"
                                  className={classes.spaceTop3}
                                >
                                  <Grid sm={12} xs={12} item>
                                    <Button
                                      size="large"
                                      fullWidth
                                      type="submit"
                                      disabled={
                                        !isEmpty(errors) ||
                                        isEmpty(values.id) ||
                                        (!dirty && isEmpty(values.id)) ||
                                        action
                                      }
                                      variant="contained"
                                      color="primary"
                                      className={classes.submit}
                                    >
                                      Login
                                    </Button>
                                  </Grid>
                                </Grid>
                                {/* <Grid
                                  container
                                  alignItems="center"
                                  justify="center"
                                  className={classes.spaceTop}
                                >
                                  <Grid item>
                                    <Typography
                                      component="p"
                                      variant="colorTextSecondary"
                                    >
                                      OR
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid
                                  container
                                  alignItems="center"
                                  justify="center"
                                  className={classes.spaceTop}
                                >
                                  <Grid sm={12} xs={12} item>
                                    <Button
                                      size="large"
                                      fullWidth
                                      disabled={
                                        errors.id ||
                                        isEmpty(values.id) ||
                                        action
                                      }
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        onOtpSend(values);
                                      }}
                                      className={classes.submit}
                                    >
                                      Request One time code
                                    </Button>
                                  </Grid>
                                </Grid> */}
                                {/* <Grid
                                  container
                                  alignItems="center"
                                  justify="center"
                                  className={classes.loginBackButton}
                                >
                                  <Grid sm={6} xs={6} item>
                                    <Button onClick={() => setloginType("")}>
                                      <Typography
                                        component="p"
                                        variant="button"
                                      >
                                        Back To Home
                                      </Typography>
                                    </Button>
                                  </Grid>
                                </Grid> */}
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </>
                ) : (
                  <div className={classes.authInnerForm}>
                    <Typography component="p" variant="body1" gutterBottom>
                      Please enter the one time code sent to
                    </Typography>
                    <Typography component="p" variant="body1">
                      {initialValues.id}
                    </Typography>
                    <Link onClick={() => setOnClickOtp(false)} color="primary">
                      <b>Change</b>
                    </Link>
                    <div className={classes.form}>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.spaceTop}
                      >
                        <OTPInput
                          value={otp}
                          onChange={handleOtp}
                          OTPLength={6}
                          inputStyles={{
                            border: 0,
                            width: "14px",
                            borderBottom: "1px solid #cbcbcb"
                          }}
                          otpType="number"
                          disabled={false}
                        />
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        style={{ marginTop: "20px" }}
                        className={classes.spaceTop}
                      >
                        <Grid sm={10} xs={10} item>
                          <Button
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={otp.toString().length < 6}
                            className={classes.submit}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={otpLogin}
                          >
                            Verify
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.spaceTop}
                      >
                        <Grid item>
                          <Typography component="p" variant="subtitle2">
                            Not received your code?
                            <Button color="primary">
                              <b>Resend code</b>
                            </Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                )}
              </>
            )}
          </Box>
        </Paper>
      </Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 2rem",
          position: "absolute",
          bottom: "2rem",
          color: "white"
        }}
      >
        <i>Version: 1.0.0</i>
        <i
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          Powered By:
          <img
            style={{
              width: "154px"
            }}
            className="imgf"
            src={"/images/tsc_logo.png"}
            alt="appName"
          />
        </i>
      </div>
    </div>
  );
  // }
}

Login.propTypes = {
  history: PropTypes.object,
  loginRequest: PropTypes.func,
  userData: PropTypes.object,
  setUserRole: PropTypes.func,
  setAppBarName: PropTypes.func,
  setLayoutName: PropTypes.func,
  getOtpAction: PropTypes.func,
  getCountryList: PropTypes.func,
  clientThemeCall: PropTypes.func,
  clientPortalConfig: PropTypes.func,
  getUserLeftMenuCall: PropTypes.func,
  app: PropTypes.object,
  getUserDetailsById: PropTypes.func,
  getUserProfileImage: PropTypes.func,
  lastCalculationDate: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails,
  app,
  formData: app
});

export default connect(mapStateToProps, {
  loginRequest,
  setUserRole,
  setAppBarName,
  clientPortalConfig,
  setLayoutName,
  getOtpAction,
  getCountryList,
  clientThemeCall,
  getUserDetailsById,
  getUserProfileImage,
  getUserLeftMenuCall,
  lastCalculationDate
})(Login);
