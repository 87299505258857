import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import CancelIcon from "@material-ui/icons/Cancel";
import Switch from "@material-ui/core/Switch";
import {
  withStyles,
  makeStyles,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import isEmpty from "lodash/isEmpty";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { Field, Form, Formik } from "formik";
import Input from "@material-ui/core/Input";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Validation from "../../utils/validations";
import { cloneAgentProfile } from "../../actions/agentPayout.action";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Card from "components/Card/Card.js";
import { updateAgentPS } from "modules/payout/actions/agentPayout.action";
import Tooltip from "@material-ui/core/Tooltip";
import tableStyle from "../../components/CustomizeTable/style";

// const TableCell = withStyles((theme) => ({
//   head: {
//     backgroundColor: "#E6E4E4",
//     color: theme.palette.common.black,
//     height: "20px"
//   },
//   body: {
//     fontSize: 14
//   },
//   root: {
//     padding: "8px !important"
//   }
// }))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});
const useBtnStyles = makeStyles(tableStyle);
const theme = createTheme({
  overrides: {
    MuiTypography: {
      body: {
        fontSize: "14px"
      }
    },
    MuiTableCell: {
      root: {
        padding: "8px !important"
      },
      body: {
        fontSize: 14
      },
      head: {
        backgroundColor: "#E6E4E4",
        color: "black",
        height: "20px"
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        MuiOutlinedInput: {
          root: {
            padding: "0px"
          }
        }
      }
    }
  },
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff"
    }
  }
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: "#2ba992",
      "& + $track": {
        backgroundColor: "#CDCDCD",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#2ba992",
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#CDCDCD",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked
    }}
    {...props}
  />
));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px"
  },
  tooltipPlacementTop: {
    margin: "2px 0"
  }
}))(Tooltip);

const EditProcessor = ({
  selectedUser,
  addSplitFlag,
  setAddSplitFlag,
  listData,
  shareType,
  payeeList,
  getPayerList,
  payerList,
  getPayeeList,
  psList,
  getPricingScheduleList,
  onAddSplitProfile,
  onChangeAgentPayoutStatus,
  onRemoveAgentMultiSplit,
  editProfileID,
  handleBack,
  getAgentPayoutMultiSplitList,
  setSelected,
  select
}) => {
  const classes = useStyles();
  const btnClasses = useBtnStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [alterOpen, setAlertOpen] = React.useState(false);
  const [cloneData, setCloneData] = React.useState(false);
  const [open, setOpen] = useState(false);
  const splitList = listData.filter((id) => id.ProfileID == editProfileID)[0];

  const editPS = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const addSplit = () => {
    if (splitList) {
      setAddSplitFlag(true);
      getPayeeList(splitList.ProfileID, splitList.ProcessorID);
      getPayerList(splitList.ProfileID);
      getPricingScheduleList(splitList.ProcessorID);
    }
  };
  const cloneUser = () => {
    setAlertOpen(true);
  };

  const updateAgentProfilePS = (values, actions) => {
    const payload = {
      profileID: values.profileId,
      pricingScheduleId: values.pricingScheduleId
    };
    dispatch(
      updateAgentPS(
        { ...payload },
        (data) => {
          if (data.status === "success") {
            getAgentPayoutMultiSplitList();
            actions.resetForm();
            onClose();
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      )
    );
  };

  const cloneUserCall = () => {
    const data = {
      profileID: editProfileID
    };
    dispatch(
      cloneAgentProfile(
        data,
        (res) => {
          if (res.status == "success") {
            getAgentPayoutMultiSplitList();
            enqueueSnackbar(`${res.message}`, {
              variant: "success"
            });
            setCloneData(res.data);
          } else {
            enqueueSnackbar(`${res.message}`, {
              variant: "error"
            });
          }
        },
        (err) => {
          enqueueSnackbar(`${err.message}`, {
            variant: "error"
          });
        }
      )
    );
    setAlertOpen(false);
  };

  const CloneProfileAlert = () => {
    const handleAlertClose = () => {
      setAlertOpen(false);
    };

    return (
      <Dialog
        open={alterOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Clone Agent Profile"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to Clone the Agent Profile?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            No
          </Button>
          <Button onClick={() => cloneUserCall()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Paper style={{ padding: "2rem" }}>
      <ThemeProvider theme={theme}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 1rem",
            marginTop: "1rem"
          }}
        >
          <Grid item>
            <Box>
              <Box
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Typography>
                  <b style={{ fontWeight: "bold" }}> Agent: </b>
                </Typography>
                <Typography>{selectedUser.AgentName}</Typography>
              </Box>
              <Box
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Typography>
                  <b style={{ fontWeight: "bold" }}>RepCode: </b>
                </Typography>
                <Typography>{splitList.RepCode}</Typography>
              </Box>
              <Box
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Typography>
                  <b style={{ fontWeight: "bold" }}>Processor: </b>
                </Typography>
                <Typography>{splitList.ProcessorName}</Typography>
              </Box>
              <Box
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Typography>
                  <b style={{ fontWeight: "bold" }}>Start From: </b>
                </Typography>
                <Typography>{splitList.Period}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={btnClasses.clearButton}
              onClick={handleBack}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        <Modal open={open} onClose={onClose} align="center">
          <div
            style={{
              width: "auto",
              height: "auto",
              maxHeight: "400px",
              maxWidth: "450px"
            }}
          >
            <Card className={classes.modalRoot}>
              <div className={classes.statusModalHeader}>
                <div
                  className={classes.statusHeadingText}
                  style={{
                    padding: "8px",
                    backgroundColor: "#2ba992",
                    color: "white"
                  }}
                >
                  Update Pricing Schedule
                </div>
                <Formik
                  enableReinitialize
                  // validationSchema={}
                  onSubmit={updateAgentProfilePS}
                  initialValues={{
                    pricingScheduleId: splitList.PS_ID,
                    profileId: splitList.ProfileID
                  }}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <Form>
                      <div style={{ padding: "2rem" }}>
                        <div style={{ marginBottom: "2rem" }}>
                          <Typography
                            style={{ textAlign: "left", fontSize: "14px" }}
                          >
                            Select Pricing Schedule
                          </Typography>
                          <Field
                            fullWidth
                            as={Autocomplete}
                            options={psList}
                            name="pricingScheduleId"
                            value={
                              values?.pricingScheduleId &&
                              psList?.find((option) =>
                                option?.PS_ID === values?.pricingScheduleId
                                  ? values?.pricingScheduleId
                                  : ""
                              )
                            }
                            onChange={(_, value, action) => {
                              if (action === "clear") {
                                setFieldValue("pricingScheduleId", "");
                              } else if (value) {
                                setFieldValue("pricingScheduleId", value.PS_ID);
                              }
                            }}
                            labelId="label-Pricing-Schedule"
                            variant="outlined"
                            placeholder="Pricing Schedule"
                            getOptionLabel={(option) => option.PS_Name || ""}
                            getOptionValue={(option) => option.PS_ID || ""}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" />
                            )}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignContent: "center",
                            alignItems: "center",
                            gap: "1rem"
                          }}
                        >
                          <Button
                            onClick={() => {
                              resetForm();
                              onClose();
                            }}
                            variant="outlined"
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            style={{ backgroundColor: "#2ba992" }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </div>
        </Modal>
        <Formik
          enableReinitialize
          // validationSchema={}
          onSubmit={onAddSplitProfile}
          initialValues={{
            pricingScheduleId: "",
            profileId: splitList.ProfileID,
            splitLevel: JSON.parse(splitList.MultiSplitData || "[]").length + 1,
            profileIDRec: "",
            shareTypeID: 1,
            splitPer: "",
            profileIDPayer: ""
          }}
        >
          {({ values, setFieldValue, resetForm }) => (
            <>
              <Form>
                {!cloneData ? (
                  <>
                    <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
                      <Grid
                        sp
                        container
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <Grid item>
                            <Typography
                              style={{
                                font: "normal normal 600 15px/41px Segoe UI",
                                textDecoration: "underline",
                                color: "#010101",
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                history.push(
                                  `/admin/MerchantMapping?processorId=${splitList.ProcessorID}&profileId=${splitList.ProfileID}&userId=${selectedUser.UserID}`
                                );
                              }}
                            >
                              Assigned Merchants: {splitList.Assigned_Merchants}
                            </Typography>
                          </Grid>
                          <Grid style={{ marginLeft: "20px" }} item>
                            <Typography
                              style={{
                                font: "normal normal 600 15px/41px Segoe UI",
                                textDecoration: "underline",
                                color: "#010101"
                              }}
                            >
                              Versions: {splitList.Versions}
                            </Typography>
                          </Grid>
                        </div>
                        <div>
                          <Grid
                            style={{
                              marginRight: "1.5rem",
                              marginBottom: "8px",
                              width: "100%"
                            }}
                            item
                          >
                            {!isEmpty(splitList.LastCalculation) && (
                              <Button
                                className={btnClasses.ExportButtonStyle}
                                variant="contained"
                                onClick={() => cloneUser()}
                              >
                                Clone Existing Setup and Edit
                              </Button>
                            )}
                            <CloneProfileAlert />
                          </Grid>
                        </div>
                      </Grid>
                    </Box>
                    <Box>
                      <TableContainer
                        style={{
                          borderTopLeftRadius: "22px",
                          borderTopRightRadius: "22px"
                        }}
                        component={Paper}
                      >
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                <b>Processor</b>
                                <br />
                                {splitList.ProcessorName}
                              </TableCell>
                              <TableCell align="center">
                                <b>RepCode</b>
                                <br />
                                {splitList.RepCode}
                              </TableCell>
                              <TableCell align="center">
                                <b>Period</b>
                                <br />
                                {splitList.Period}
                              </TableCell>
                              <TableCell align="center">
                                <b>Pricing Schedule</b>
                                <br />
                                {splitList.PricingSchedule}
                              </TableCell>
                              <TableCell align="center">
                                <b>Last Calculation</b>
                                <br />
                                {splitList.LastCalculation}
                              </TableCell>
                              <TableCell align="center">
                                <FormGroup style={{ alignItems: "center" }}>
                                  <IOSSwitch
                                    checked={splitList.Status === "A"}
                                    onChange={() => {
                                      onChangeAgentPayoutStatus(
                                        splitList.ProfileID
                                      );
                                    }}
                                  />
                                </FormGroup>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                style={{ color: "#7D7D7D", fontWeight: "bold" }}
                                align="center"
                              >
                                Split Level
                              </TableCell>
                              <TableCell
                                style={{ color: "#7D7D7D", fontWeight: "bold" }}
                                align="center"
                              >
                                Agent (RepCode)
                              </TableCell>
                              <TableCell
                                style={{ color: "#7D7D7D", fontWeight: "bold" }}
                                align="center"
                              >
                                Share Type
                              </TableCell>
                              <TableCell
                                style={{ color: "#7D7D7D", fontWeight: "bold" }}
                                align="center"
                              >
                                Pricing Schedule
                              </TableCell>
                              <TableCell
                                style={{ color: "#7D7D7D", fontWeight: "bold" }}
                                align="center"
                              >
                                Paid by
                              </TableCell>
                              <TableCell
                                style={{ color: "#7D7D7D", fontWeight: "bold" }}
                                align="center"
                              />
                            </TableRow>
                            {JSON.parse(splitList.MultiSplitData || "[]").map(
                              (row) => (
                                <TableRow
                                  onClick={() => {
                                    if (!isEmpty(splitList.LastCalculation)) {
                                      setSelected("");
                                    } else {
                                      setSelected(row.SplitLevel);
                                    }
                                  }}
                                  style={{
                                    boxShadow:
                                      select === row.SplitLevel
                                        ? "inset 4px 0px 0px 0px #2ba992"
                                        : "",
                                    background:
                                      select === row.SplitLevel
                                        ? "#2ba99217"
                                        : "",
                                    cursor: "pointer"
                                  }}
                                  key={row.SplitLevel}
                                >
                                  <TableCell
                                    style={{
                                      padding: "0px",
                                      textAlign: "-webkit-center"
                                    }}
                                    align="center"
                                    component="th"
                                    scope="row"
                                  >
                                    <span
                                      style={{
                                        background: "#2ba992",
                                        borderRadius: "50%",
                                        width: "28px",
                                        height: "28px",
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#fff",
                                        justifyContent: "center"
                                      }}
                                    >
                                      {row.SplitLevel}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.Agent}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.ShareType}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.PricingSchedule}
                                  </TableCell>
                                  <TableCell align="center">
                                    {row.PaidBy}
                                  </TableCell>
                                  <TableCell align="center">
                                    {select === row.SplitLevel &&
                                    select ===
                                      JSON.parse(
                                        splitList.MultiSplitData || "[]"
                                      ).length ? (
                                      <IconButton
                                        onClick={() => {
                                          onRemoveAgentMultiSplit(
                                            row.APSplitID
                                          );
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                ) : (
                  cloneData.map((splitList) => (
                    <>
                      <Box style={{ marginTop: "20px", marginLeft: "20px" }}>
                        <Grid
                          sp
                          container
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Grid item>
                              <Typography
                                style={{
                                  font: "normal normal 600 15px/41px Segoe UI",
                                  textDecoration: "underline",
                                  color: "#010101",
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  history.push(
                                    `/admin/MerchantMapping?processorId=${splitList.ProcessorID}&profileId=${splitList.ProfileID}&userId=${selectedUser.UserID}`
                                  );
                                }}
                              >
                                Assigned Merchants:{" "}
                                {splitList.Assigned_Merchants}
                              </Typography>
                            </Grid>
                            <Grid style={{ marginLeft: "20px" }} item>
                              <Typography
                                style={{
                                  font: "normal normal 600 15px/41px Segoe UI",
                                  textDecoration: "underline",
                                  color: "#010101"
                                }}
                              >
                                Versions: {splitList.Versions}
                              </Typography>
                            </Grid>
                          </div>
                        </Grid>
                      </Box>
                      <Box>
                        <TableContainer
                          style={{
                            borderTopLeftRadius: "22px",
                            borderTopRightRadius: "22px"
                          }}
                          component={Paper}
                        >
                          <Table
                            className={classes.table}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">
                                  <b>Processor</b>
                                  <br />
                                  {splitList.ProcessorName}
                                </TableCell>
                                <TableCell align="center">
                                  <b>RepCode</b>
                                  <br />
                                  {splitList.RepCode}
                                </TableCell>
                                <TableCell align="center">
                                  <b>Period</b>
                                  <br />
                                  {splitList.Period}
                                </TableCell>
                                <TableCell align="center">
                                  <b>Pricing Schedule</b>
                                  <br />
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "1rem"
                                    }}
                                  >
                                    <span>{splitList.PS_Name}</span>
                                    {isEmpty(splitList.last_calculation) && (
                                      <CustomTooltip
                                        title="Update pricing schedule"
                                        placement="top"
                                      >
                                        <EditIcon
                                          onClick={editPS}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </CustomTooltip>
                                    )}
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <b>Last Calculation</b>
                                  <br />
                                  {splitList.last_calculation}
                                </TableCell>
                                <TableCell align="center">
                                  <FormGroup style={{ alignItems: "center" }}>
                                    <IOSSwitch
                                      checked={splitList.bStatus === "A"}
                                      onChange={() => {
                                        onChangeAgentPayoutStatus(
                                          splitList.ProfileID
                                        );
                                      }}
                                    />
                                  </FormGroup>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Split Level
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Agent (RepCode)
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Share Type
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Pricing Schedule
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                >
                                  Paid by
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: "#7D7D7D",
                                    fontWeight: "bold"
                                  }}
                                  align="center"
                                />
                              </TableRow>
                              {JSON.parse(splitList.MultiSplitData || "[]").map(
                                (row) => (
                                  <>
                                    <TableRow
                                      onClick={() => {
                                        setSelected(row.SplitLevel);
                                      }}
                                      style={{
                                        boxShadow:
                                          select === row.SplitLevel
                                            ? "inset 4px 0px 0px 0px #2ba992"
                                            : "",
                                        background:
                                          select === row.SplitLevel
                                            ? "#2ba99217"
                                            : "",
                                        cursor: "pointer"
                                      }}
                                      key={row.SplitLevel}
                                    >
                                      <TableCell
                                        style={{
                                          padding: "0px",
                                          textAlign: "-webkit-center"
                                        }}
                                        align="center"
                                        component="th"
                                        scope="row"
                                      >
                                        <span
                                          style={{
                                            background: "#2ba992",
                                            borderRadius: "50%",
                                            width: "28px",
                                            height: "28px",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#fff",
                                            justifyContent: "center"
                                          }}
                                        >
                                          {row.SplitLevel}
                                        </span>
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.Agent}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.ShareType}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.PricingSchedule}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.PaidBy}
                                      </TableCell>
                                      <TableCell align="center">
                                        {select === row.SplitLevel &&
                                        select ===
                                          JSON.parse(
                                            splitList.MultiSplitData || "[]"
                                          ).length ? (
                                          <IconButton
                                            onClick={() => {
                                              onRemoveAgentMultiSplit(
                                                row.APSplitID
                                              );
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        ) : (
                                          ""
                                        )}
                                      </TableCell>
                                    </TableRow>
                                    {addSplitFlag && select ? (
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            padding: "0px",
                                            textAlign: "-webkit-center"
                                          }}
                                          align="center"
                                          component="th"
                                          scope="row"
                                        >
                                          <span
                                            style={{
                                              background: "#2ba992",
                                              borderRadius: "50%",
                                              width: "28px",
                                              height: "28px",
                                              display: "flex",
                                              alignItems: "center",
                                              color: "#fff",
                                              justifyContent: "center"
                                            }}
                                          >
                                            {values.splitLevel}
                                          </span>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            padding: "8px",
                                            textAlign: "-webkit-center"
                                          }}
                                        >
                                          <Field
                                            fullWidth
                                            as={Autocomplete}
                                            name="profileIDRec"
                                            id="profileIDRec"
                                            variant="outlined"
                                            style={{
                                              fontSize: "14px",
                                              "&.focused": {
                                                // color: theme.palette.primary.main,
                                                fontSize: "14px",
                                                fontStyle:
                                                  "Helvetica, Arial,sans-serif",
                                                background:
                                                  "#f9faff00 0% 0% no-repeat"
                                              },
                                              minWidth: "220px"
                                            }}
                                            InputProps={{
                                              style: {
                                                background:
                                                  "#f9faff00 0% 0% no-repeat"
                                              }
                                            }}
                                            autoHighlight
                                            // onInputChange={(e, newInputValue) => {
                                            //   if (newInputValue.length >= 3) {
                                            //     getAgentList(newInputValue);
                                            //   }
                                            // }}
                                            getOptionDisabled={(option) =>
                                              option.UserID ===
                                              919863231596669598
                                            }
                                            onChange={(_, value, action) => {
                                              if (action === "clear") {
                                                setFieldValue(
                                                  "profileIDRec",
                                                  ""
                                                );
                                              } else {
                                                value &&
                                                  setFieldValue(
                                                    "profileIDRec",
                                                    value
                                                  );
                                                setFieldValue(
                                                  "pricingScheduleId",
                                                  value.PS_ID
                                                );
                                              }
                                            }}
                                            selectOnFocus
                                            options={payeeList || []}
                                            getOptionLabel={(option) =>
                                              option
                                                ? `${option.FName} ${option.LName} (${option.RepCode})`
                                                : ""
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                InputLabelProps={{
                                                  classes: {
                                                    // root: classes.activeInputLableColor,
                                                    focused: "focused"
                                                  },
                                                  style: {
                                                    background: "#000000"
                                                  }
                                                }}
                                                required
                                                variant="outlined"
                                                InputProps={{
                                                  classes: {
                                                    // root: classes.activeInputColor,
                                                    // input: classes.resize,
                                                    focused: "focused"
                                                  },
                                                  style: {
                                                    background: "#000000"
                                                  },
                                                  required: true
                                                }}
                                                {...params}
                                                // label="Agent Name"
                                              />
                                            )}
                                            renderOption={(
                                              option,
                                              { inputValue }
                                            ) => {
                                              const matches = match(
                                                `${option.FName} ${option.LName} (${option.RepCode})`,
                                                inputValue,
                                                {
                                                  insideWords: true
                                                }
                                              );
                                              const parts = parse(
                                                `${option.FName} ${option.LName} (${option.RepCode})`,
                                                matches
                                              );

                                              return (
                                                <div>
                                                  {parts.map((part, index) => (
                                                    <span
                                                      style={{
                                                        background:
                                                          part.highlight
                                                            ? "#f3ff0070"
                                                            : "",
                                                        fontWeight:
                                                          part.highlight
                                                            ? 400
                                                            : "",
                                                        fontSize: "14px"
                                                      }}
                                                    >
                                                      {part.text}
                                                    </span>
                                                  ))}
                                                </div>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            padding: "8px",
                                            textAlign: "-webkit-center"
                                          }}
                                        >
                                          <Field
                                            as={Select}
                                            name="shareTypeID"
                                            id="shareTypeID"
                                            sx={{ minWidth: "150px" }}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              setFieldValue(
                                                "shareTypeID",
                                                value
                                              );
                                              if (value === 1 || value === 2) {
                                                setFieldValue("splitPer", "");
                                              } else {
                                                setFieldValue(
                                                  "pricingScheduleId",
                                                  ""
                                                );
                                              }
                                            }}
                                            classes={{
                                              root: "select-min-width"
                                            }}
                                            variant="outlined"
                                            placeholder="Share type*"
                                          >
                                            {shareType.map((type) => (
                                              <MenuItem
                                                value={type.ShareType_ID}
                                              >
                                                {type.ShareType}
                                              </MenuItem>
                                            ))}
                                          </Field>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            padding: "8px",
                                            textAlign: "-webkit-center"
                                          }}
                                        >
                                          {(values.shareTypeID === 1 ||
                                            values.shareTypeID === 2) &&
                                          !isEmpty(psList) ? (
                                            <Field
                                              as={Autocomplete}
                                              options={psList}
                                              disabled={!values.shareTypeID}
                                              name="pricingScheduleId"
                                              value={
                                                psList.find(
                                                  (option) =>
                                                    option.PS_ID ===
                                                    values.pricingScheduleId
                                                ) || {}
                                              }
                                              onChange={(_, value, action) => {
                                                if (action === "clear") {
                                                  setFieldValue(
                                                    "pricingScheduleId",
                                                    ""
                                                  );
                                                } else if (value) {
                                                  setFieldValue(
                                                    "pricingScheduleId",
                                                    value.PS_ID
                                                  );
                                                }
                                              }}
                                              style={{ minWidth: "220px" }}
                                              labelId="label-Pricing-Schedule"
                                              variant="outlined"
                                              placeholder="Pricing Schedule"
                                              getOptionLabel={(option) =>
                                                option.PS_Name || ""
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  // label="Combo box"
                                                  variant="outlined"
                                                />
                                              )}
                                            />
                                          ) : (
                                            <Field
                                              as={TextField}
                                              id="splitPer"
                                              name="splitPer"
                                              variant="outlined"
                                              sx={{ minWidth: "220px" }}
                                              placeholder="Pricing Schedule*"
                                            />
                                          )}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            padding: "8px",
                                            textAlign: "-webkit-center"
                                          }}
                                        >
                                          <Field
                                            as={Autocomplete}
                                            name="profileIDPayer"
                                            id="profileIDPayer"
                                            variant="outlined"
                                            style={{
                                              fontSize: "14px",
                                              "&.focused": {
                                                // color: theme.palette.primary.main,
                                                fontSize: "14px",
                                                fontStyle:
                                                  "Helvetica, Arial,sans-serif",
                                                background:
                                                  "#f9faff00 0% 0% no-repeat"
                                              },
                                              minWidth: "220px"
                                            }}
                                            InputProps={{
                                              style: {
                                                background:
                                                  "#f9faff00 0% 0% no-repeat"
                                              }
                                            }}
                                            autoHighlight
                                            fullWidth
                                            // onInputChange={(e, newInputValue) => {
                                            //   if (newInputValue.length >= 3) {
                                            //     getAgentList(newInputValue);
                                            //   }
                                            // }}
                                            getOptionDisabled={(option) =>
                                              option.UserID ===
                                              919863231596669598
                                            }
                                            onChange={(_, value, action) => {
                                              if (action === "clear") {
                                                setFieldValue(
                                                  "profileIDPayer",
                                                  ""
                                                );
                                              } else {
                                                value &&
                                                  setFieldValue(
                                                    "profileIDPayer",
                                                    value
                                                  );
                                              }
                                            }}
                                            selectOnFocus
                                            options={payerList || []}
                                            getOptionLabel={(option) =>
                                              option
                                                ? `${option.FName} ${option.LName} (${option.RepCode})`
                                                : ""
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                InputLabelProps={{
                                                  classes: {
                                                    // root: classes.activeInputLableColor,
                                                    focused: "focused"
                                                  },
                                                  style: {
                                                    background: "#000000"
                                                  }
                                                }}
                                                required
                                                variant="outlined"
                                                InputProps={{
                                                  classes: {
                                                    // root: classes.activeInputColor,
                                                    // input: classes.resize,
                                                    focused: "focused"
                                                  },
                                                  style: {
                                                    background: "#000000"
                                                  },
                                                  required: true
                                                }}
                                                {...params}
                                                // label="Agent Name"
                                              />
                                            )}
                                            renderOption={(
                                              option,
                                              { inputValue }
                                            ) => {
                                              const matches = match(
                                                `${option.FName} ${option.LName} (${option.RepCode})`,
                                                inputValue,
                                                {
                                                  insideWords: true
                                                }
                                              );
                                              const parts = parse(
                                                `${option.FName} ${option.LName} (${option.RepCode})`,
                                                matches
                                              );

                                              return (
                                                <div>
                                                  {parts.map((part, index) => (
                                                    <span
                                                      style={{
                                                        background:
                                                          part.highlight
                                                            ? "#f3ff0070"
                                                            : "",
                                                        fontWeight:
                                                          part.highlight
                                                            ? 400
                                                            : "",
                                                        fontSize: "14px"
                                                      }}
                                                    >
                                                      {part.text}
                                                    </span>
                                                  ))}
                                                </div>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{
                                            padding: "8px",
                                            textAlign: "-webkit-center"
                                          }}
                                        >
                                          <div>
                                            <Button
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-around",
                                                alignContent: "center",
                                                alignItems: "center"
                                              }}
                                              type="submit"
                                            >
                                              Save
                                            </Button>
                                            <IconButton
                                              onClick={() => {
                                                setAddSplitFlag(false);
                                                setSelected(false);
                                                resetForm();
                                              }}
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      <>
                                        {isEmpty(
                                          splitList.last_calculation
                                        ) && (
                                          <TableRow
                                            style={{
                                              textAlign: "center",
                                              position: "relative",
                                              padding: "8px",
                                              textAlign: "-webkit-center"
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                                margin: "10px",
                                                textAlign: "center",
                                                position: "absolute",
                                                right: 0,
                                                left: 0
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                  textDecoration: "underline",
                                                  color: "#EA1616",
                                                  cursor: "pointer"
                                                }}
                                                onClick={() => {
                                                  setSelected(true);
                                                  addSplit();
                                                }}
                                              >
                                                Click here
                                              </span>{" "}
                                              to add Splits
                                            </div>
                                          </TableRow>
                                        )}
                                      </>
                                    )}
                                  </>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </>
                  ))
                )}
              </Form>
            </>
          )}
        </Formik>
      </ThemeProvider>
    </Paper>
  );
};

EditProcessor.propTypes = {
  selectedUser: PropTypes.object,
  listData: PropTypes.object,
  addSplitFlag: PropTypes.bool,
  setAddSplitFlag: PropTypes.bool,
  shareType: PropTypes.array,
  payeeList: PropTypes.array,
  getPayerList: PropTypes.func,
  getPayeeList: PropTypes.func,
  payerList: PropTypes.array,
  psList: PropTypes.array,
  getPricingScheduleList: PropTypes.func,
  onAddSplitProfile: PropTypes.func,
  onChangeAgentPayoutStatus: PropTypes.func,
  onRemoveAgentMultiSplit: PropTypes.func,
  editProfileID: PropTypes.any,
  handleBack: PropTypes.func,
  getAgentPayoutMultiSplitList: PropTypes.func,
  setSelected: PropTypes.func,
  select: PropTypes.any
};

export default EditProcessor;
