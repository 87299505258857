/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import PersonIcon from "@material-ui/icons/Person";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import RoomIcon from "@material-ui/icons/Room";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(styles);

const ContactInformation = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));

  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const form = (
    <div>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <MenuBookRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Department"
            required
            defaultValue="CustomerService"
            error={props.validationError.type}
            value={props.data.type}
            onChange={(e) => props.handleContactChange(e.target.value, "type")}
            name="type"
            id="type"
            select
          >
            <MenuItem
              className={classes.dialogBoxDropDown}
              value="CustomerService"
            >
              Customer Service
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <MenuBookRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            label="Title"
            error={props.validationError.title}
            helperText={
              props.validationError.title && "Contact Title Name is Required."
            }
            value={props.data.title}
            onChange={(e) => props.handleContactChange(e.target.value, "title")}
            name="title"
            id="title"
            select
          >
            <MenuItem className={classes.dialogBoxDropDown} value="Analyst">
              Analyst
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CEO">
              CEO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="COO">
              COO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CFO">
              CFO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="CTO">
              CTO
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Director">
              Director
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Manager">
              Manager
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Owner">
              Owner
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Other">
              Other
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Supervisor">
              Supervisor
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            error={props.validationError.firstName}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            label="First Name"
            value={props.data.firstName}
            onChange={(e) =>
              props.handleContactChange(e.target.value, "firstName")
            }
            helperText={
              props.validationError.firstName && "First Name is Required."
            }
            name="firstName"
            id="firstName"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            error={props.validationError.middleName}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Middle Name"
            value={props.data.middleName}
            onChange={(e) =>
              props.handleContactChange(e.target.value, "middleName")
            }
            name="middleName"
            id="middleName"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            error={props.validationError.lastName}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            required
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Last Name"
            value={props.data.lastName}
            onChange={(e) =>
              props.handleContactChange(e.target.value, "lastName")
            }
            helperText={
              props.validationError.lastName && "Last Name is Required."
            }
            name="lastName"
            id="lastName"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Phone"
            error={props.validationError.phoneNumber}
            value={props.data.phoneNumber}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value == "") &&
              props.handleContactChange(e.target.value, "phoneNumber")
            }
            name="phoneNumber"
            id="phoneNumber"
            type="text"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Phone Number Extension"
            value={props.data.phoneExt}
            error={props.validationError.phoneExt}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value === "") &&
              props.handleContactChange(e.target.value, "phoneExt")
            }
            name="phoneExt"
            id="phoneExt"
            type="text"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Fax Number"
            error={props.validationError.faxNumber}
            value={props.data.faxNumber}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value == "") &&
              props.handleContactChange(e.target.value, "faxNumber")
            }
            name="faxNumber"
            id="faxNumber"
            type="text"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            helperText={
              props.data.email != "" &&
              !emailRegEx.test(props.data.email) &&
              "Enter a Valid Email Address"
            }
            label="Email Address"
            error={props.validationError.email}
            value={props.data.email}
            onChange={(e) => props.handleContactChange(e.target.value, "email")}
            name="email"
            id="email"
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
    </div>
  );

  return (
    <div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative"
        }}
        className={classes.ownerHeader}
      >
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center"
          }}
        >
          <div>
            <Typography className={classes.ownerTittle}>
              <b>{`Contact ${props.index + 1}`}</b>
            </Typography>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            right: "12px",
            position: "absolute"
          }}
        >
          {(props.kycCheckListFlag ||
            props.ProviderStatus === "New Entry" ||
            props.ProviderStatus === "Sent to Merchant") &&
            props.ProviderStatus !== "Live" &&
            (props.editPermissionFlag || props.createPermissionFlag) && (
              <Tooltip title="Add Contact" aria-label="add">
                <Button
                  className={classes.addIcon}
                  size="sm"
                  disabled={props.disabled}
                  aria-label="open drawer"
                  onClick={() => props.add()}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            )}
          {props.index !== 0 && (
            <Tooltip
              style={{ marginLeft: "10px" }}
              title="Remove"
              aria-label="Remove"
            >
              <Button
                className={classes.deleteIcon}
                aria-label="open drawer"
                disabled={props.disabled}
                onClick={() => props.remove()}
              >
                <DeleteOutlineRoundedIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      </Box>
      <Box>{form}</Box>
    </div>
  );
};

ContactInformation.propTypes = {
  index: PropTypes.any,
  data: PropTypes.object,
  add: PropTypes.func,
  remove: PropTypes.func,
  handleContactChange: PropTypes.func,
  validationError: PropTypes.object,
  disabled: PropTypes.bool,
  ProviderStatus: PropTypes.bool,
  kycCheckListFlag: PropTypes.bool,
  createPermissionFlag: PropTypes.bool,
  editPermissionFlag: PropTypes.bool
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration
});
export default connect(mapStateToProps, null)(ContactInformation);
