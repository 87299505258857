import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";
import { exportToXLS } from "../../../../Utils/commonUtils";
import { TablePageData } from "../../utils/constants";
import {
  fetchPSVersionList,
  fetchPricingScheduleList
} from "../../actions/pricingSchedule.action";
import { fetchAgentList } from "../../actions/agentPayout.action";
import SearchComponent from "../../components/PricingScheduleSearch/SearchFields";
import SearchedChips from "../../components/PricingScheduleSearch/SearchChips";
import FilterFields from "./psActivityHistoryFields";
import { getQueryParams } from "../../utils/common";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { parseISO } from "date-fns";
import { ReactComponent as ExportIcon } from "../../../../assets/svgIcons/ExportIcon.svg";
import { exportToPSHistory } from "../../utils/common";

const headCellsItems = [
  {
    id: "PS_Name",
    label: "Pricing Schedule",
    isSort: true,
    sortProperty: "PS_Name"
  },
  {
    id: "Description",
    label: "Description",
    isSort: true,
    sortProperty: "Description"
  },
  {
    id: "Updated_By_Name",
    label: "Updated By",
    isSort: true,
    sortProperty: "Updated_By_Name"
  },
  {
    id: "Updated_On",
    label: "Updation Date",
    isSort: true,
    sortProperty: "Updated_On"
  },
  {
    id: "Version_Pdf",
    label: "",
    isSort: true,
    sortProperty: "Version_Pdf"
  }
];

const labelName = {
  psId: "Pricing schedule",
  userId: "Updated By",
  fromMonth: "From",
  toMonth: "To"
};

// sorting data
function descendingComparator(a, b, orderBy) {
  if (orderBy == "Updated_On") {
    if (moment(b[orderBy]) < moment(a[orderBy])) {
      return -1;
    }
    if (moment(b[orderBy]) > moment(a[orderBy])) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function PSActivityHistory({
  fetchPSVersionList,
  fetchPricingScheduleList,
  fetchAgentList,
  users
}) {
  const searchInitialData = {
    psId: getQueryParams("psId") || "",
    userId: "",
    fromMonth: null,
    toMonth: null
  };
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dataParameter = "PS_ID";
  const PRICING_SCHEDULE_ID = getQueryParams("psId");
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState("");
  const [order, setOrder] = React.useState("desc");
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [orderBy, setOrderBy] = React.useState("Updated_On");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [psVersionList, setPSVersionList] = React.useState([]);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [chipValue, setChipValue] = React.useState({
    ...searchInitialData
  });
  const [searchBar, setSearchBar] = React.useState(false);
  const [pricingScheduleList, setPricingScheduleList] = React.useState([]);
  const [agentsList, setAgentsList] = React.useState([]);

  const RowWithDescription = ({ row }) => {
    const [showFullTextMap, setShowFullTextMap] = React.useState({});
    const toggleShowFullText = (psId) => {
      setShowFullTextMap((prevState) => ({
        ...prevState,
        [psId]: !prevState[psId]
      }));
    };
    const showFullText = showFullTextMap[row.PS_ID] || false;

    const descriptionToShow = showFullText
      ? row.Description
      : `${row.Description.slice(0, 75)}...`;

    return (
      <>
        {row.Description && (
          <div>
            {descriptionToShow}
            {row.Description.length > 75 && (
              <span
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginLeft: showFullText ? "8px" : "unset"
                }}
                onClick={() => toggleShowFullText(row.PS_ID)}
              >
                {showFullText ? "Show less" : "Show More"}
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const findPSName = (id) => {
    if (!isEmpty(pricingScheduleList)) {
      const name = pricingScheduleList.find((x) => x.PS_ID == id);
      return name ? name.PS_Name : id;
    }
  };

  const psName = React.useMemo(
    () => findPSName(searchValues.psId),
    [searchValues.psId, pricingScheduleList]
  );

  const findAgentName = (id) => {
    if (!isEmpty(agentsList)) {
      const name = agentsList.find((x) => x.UserID == id);
      return name ? name.AgentName.split("[")[0] : id;
    }
  };

  const agent_Name = React.useMemo(
    () => findAgentName(searchValues.userId),
    [searchValues.userId, agentsList]
  );

  const getAgentList = (agentName = "") => {
    fetchAgentList(
      (data) => {
        if (data.status || data.status === "success") {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: 919863231596669598,
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      { search: agentName }
    );
  };

  React.useEffect(() => {
    fetchPricingScheduleList(
      {
        sortColumn: "",
        sortOrder: "desc",
        recordPerPage: "",
        pageNo: 1
      },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          setPricingScheduleList(content);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    if (psVersionList) {
      const mappedLogs = psVersionList.map((row) => ({
        "S.N": row.id,
        "PS Id": row.PS_ID,
        "PS Name": row.PS_Name,
        "Updated By": row.Updated_By_Name,
        "Updated Date": moment(row.Updated_On).format("YYYY-MM-DD")
      }));
      exportToPSHistory(
        mappedLogs,
        "Pricing schedule activity history",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname,
        labelName,
        psName,
        agent_Name
      );
    }
  };

  const handleDeleteChip = (chip) => {
    setPage(1);
    let temp = "";
    if (chipValue) {
      temp = { ...searchValues };
      if (chip === "toMonth") {
        temp.toMonth = "";
        temp.fromMonth = "";
      } else {
        temp[chip] = "";
      }
      setSearchValues({ ...temp });
      setChipValue({
        ...temp
      });
    }
    getPSVersionList({
      ...temp
    });
  };

  const getFilteredPS = (value) => {
    const finalSearchData = {
      ...value,
      psId: value.psId ? value.psId : ""
    };
    setChipValue({
      ...value
    });
    getPSVersionList(finalSearchData);
  };

  const getPSVersionList = function (searchValues) {
    fetchPSVersionList(
      {
        ...searchValues,
        sortColumn: orderBy,
        sortOrder: order,
        recordPerPage: rowsPerPage,
        pageNo: page
      },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const { count } = records;
          setPSVersionList(content);
          setTotalCount(count);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(count / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to: page * rowsPerPage < count ? page * rowsPerPage : count,
            total: count
          });
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  React.useEffect(() => {
    if (isEmpty(agentsList)) {
      getAgentList();
    }
  }, []);

  React.useEffect(() => {
    getPSVersionList({
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page
    });
  }, [orderBy, order, page, rowsPerPage]);

  const handleSearchOpen = () => {
    setSearchBar(true);
  };
  const handleSearchClose = () => {
    setSearchBar(false);
  };
  const handleSubmitSearch = (values) => {
    setSearchValues(values);
    setChipValue({ searchValues });
    getFilteredPS(searchValues);
    handleSearchClose();
  };
  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    getPSVersionList({
      ...searchInitialData,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page
    });
    handleSearchClose();
  };

  // Sort the entire dataset before pagination
  const sortedAndPaginatedData =
    !isEmpty(psVersionList) &&
    stableSort(psVersionList, getComparator(order, orderBy)).slice(
      (page - 1) * rowsPerPage,
      (page - 1) * rowsPerPage + rowsPerPage
    );

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div
                className={classes.searchWrapper}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  justifyContent: "space-between"
                }}
              >
                <CoustomButton
                  onClick={() => {
                    history.goBack();
                  }}
                  className={classes.clearButton}
                  variant="contained"
                  color="primary"
                >
                  Back
                </CoustomButton>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    alignContent: "center",
                    flexWrap: "nowrap",
                    flexDirection: "row",
                    minWidth: "400px",
                    gap: "8px"
                  }}
                >
                  <SearchComponent
                    searchBar={searchBar}
                    handleSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    width="100%"
                    Fields={() => {
                      return (
                        <FilterFields
                          handleSearchClose={() => handleSearchClose()}
                          setSearchValues={setSearchValues}
                          searchValues={searchValues}
                          handleSubmitSearch={handleSubmitSearch}
                          handleClearSearch={handleClearSearch}
                          pricingScheduleList={pricingScheduleList}
                          agentsList={agentsList}
                        />
                      );
                    }}
                  />
                  <ExportIcon
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={exportListData}
                    className={classes.appSvgIcons}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.expirtItemGrid}>
              <SearchComponent
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => {
                  return (
                    <FilterFields
                      handleSearchClose={() => handleSearchClose()}
                      setSearchValues={setSearchValues}
                      searchValues={searchValues}
                      handleSubmitSearch={handleSubmitSearch}
                      handleClearSearch={handleClearSearch}
                      pricingScheduleList={pricingScheduleList}
                      agentsList={agentsList}
                    />
                  );
                }}
              />
              <ExportIcon
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                className={classes.appSvgIcons}
              />
            </Grid>
          </Grid>
        )}
        <Grid item>
          <SearchedChips
            handleDeleteChip={handleDeleteChip}
            searchValues={chipValue}
            labelName={labelName}
            psName={psName}
            agentName={agent_Name}
          />
        </Grid>
        <CustomTable
          page={page}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          data={psVersionList}
          pageDetails={pageDetails}
          headCells={headCellsItems}
          dataParameter={dataParameter}
          isCallInitialization={true}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
        >
          {!isEmpty(psVersionList) &&
            sortedAndPaginatedData.map((row, index) => {
              return (
                <TableRow
                  key={`${row.PS_ID}-${index}`}
                  className={classes.cellHeadSign}
                >
                  <TableCell className={classes.cellText}>
                    {row.PS_Name}
                  </TableCell>
                  <TableCell
                    key={`${row.PS_ID}-${index}`}
                    className={classes.cellText}
                  >
                    {row.Description && <RowWithDescription row={row} />}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    {row.Updated_By_Name}
                  </TableCell>

                  <TableCell className={classes.cellText}>
                    {moment(row.Updated_On).format("MM-DD-YYYY hh:mm:ss A")}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    style={{ textAlign: "center" }}
                  >
                    {row.Version_Pdf && (
                      <a
                        href={row.Version_Pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AttachFileIcon className={classes.cellHeadSign} />
                      </a>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </CustomTable>
      </TableContainer>
    </>
  );
}
PSActivityHistory.propTypes = {
  fetchPSVersionList: PropTypes.func,
  fetchPricingScheduleList: PropTypes.func,
  fetchAgentList: PropTypes.func
};

const mapStateToProps = (app) => ({
  users: app
});

export default connect(mapStateToProps, {
  fetchPSVersionList,
  fetchPricingScheduleList,
  fetchAgentList
})(PSActivityHistory);
