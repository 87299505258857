/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import CoustomButton from "components/CustomButtons/Button";
import Button from "@material-ui/core/Button";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import { useParams, useHistory } from "react-router-dom";
import Card from "components/Card/Card";
import Search from "@material-ui/icons/Search";
import { useSnackbar } from "notistack";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import ChipInput from "material-ui-chip-input";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import { exportToCSV } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/PricingTemplate";
import LongMenu from "../../components/Menu/pricingListActions";
import { getPricingList } from "../../actions/TableData";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { getPricingTemplateExport } from "../../actions/Operations";
import { pricingTempleteCall } from "../../Redux/Actions/ProvideRegistration";
import { getUserDetailsById } from "../../Redux/Actions/UserRegistration";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import { exportToPDF } from "modules/reports/utils/common";
import { exportNumberToDashFormat } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import { ReactComponent as AddIconSvg } from "../../assets/svgIcons/AddIconSvg.svg";
import {
  actionPermission,
  exportToXLS,
  permissionCheck,
} from "../../Utils/commonUtils";
import AppLoader from "components/AppLoader/Index";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const useStyles = makeStyles(tableStyle);

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Template Name",
      headClassName: classes.templateName,
    },
    {
      id: "startDate",
      numeric: true,
      disablePadding: true,
      label: "Start Date",
      headClassName: classes.startDate,
    },
    {
      id: "appLinked",
      numeric: true,
      disablePadding: false,
      label: "App Linked",
      headClassName: classes.OwnershipType,
    },
    {
      id: "createdDate",
      numeric: true,
      disablePadding: true,
      label: "Created Date",
      headClassName: classes.Contact_Name,
    },
    {
      id: "updatedDate",
      numeric: true,
      disablePadding: false,
      label: "Updated Date",
      headClassName: classes.Phone,
    },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
      headClassName: classes.Creation_Date,
    },
    {
      id: "updatedBy",
      numeric: false,
      disablePadding: false,
      label: "Updated By",
      headClassName: classes.Update_Date,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      headClassName: classes.status,
    },
    {
      id: "blank",
      numeric: false,
      disablePadding: false,
      label: "",
      headClassName: classes.status,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={headCell.id === "updatedDate"}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ProviderReport = (props) => {
  const [values, setValues] = React.useState({
    status: "",
    id: "",
  });
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Update_Date");
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState(["Status:Active"]);
  const [searchValues, setSearchValues] = React.useState({
    name: "",
    status: "Active",
  });
  const [temp, setTemp] = React.useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [initialCallLoading, setInitialCallLoading] = React.useState(true);
  const [initialCall, setInitialCall] = React.useState(false);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleClosed = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleAddEdit = (type = "", tempId) => {
    if (type === "edit") {
      history.push(`/admin/PricingTemplate/edit/${tempId}`);
    }
    if (type === "cloneTemplate") {
      history.push(`/admin/PricingTemplate/cloneTemplate/${tempId}`);
    }
    if (type === "add" || type === "") {
      history.push("/admin/PricingTemplate/add");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const dateFormat = (value) =>
    moment(new Date(value * 1000)).format("MM/DD/YYYY");

  const modifyList = (list) => {
    list.forEach((element) => {
      element.createdDate = Date.parse(element.createdDate) / 1000;
      element.startDate = Date.parse(element.startDate) / 1000;
      element.updatedDate = Date.parse(element.updatedDate) / 1000;
      element.updatedBy = element.updatedBy ? element.updatedBy : "NA";
      element.createdBy = element.createdBy ? element.createdBy : "NA";
    });
    return list;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const exportedBy =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;
  const exportListData = () => {
    // const requestData = { ...props.tableData.searchDetails };
    // requestData.recordPerPage = props.tableData.userList.totCount;
    // requestData.pageNo = 1;
    // props.getUserExport(requestData, (data) => {
    if (rows) {
      const mappedLogs = rows.map((row) => ({
        name: row.name,
        "Start Date": row.startDate,
        "App Linked": exportNumberToDashFormat(row.appLinked),
        "Created Date": row.createdDate,
        "Updated Date": row.updatedDate,
        Status: row.status,
        "Application Type": row.applicationType,
        "Template Type": row.templateType,
        "Created By": row.createdBy,
        "Updated By": row.updatedBy,
      }));
      exportToCSV(
        mappedLogs,
        "Pricing Templates Details",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname
      );
    }
    // });
  };

  const exportToPDFList = () => {
    // const requestData = { ...props.tableData.searchDetails };
    // requestData.recordPerPage = props.tableData.userList.totCount;
    // requestData.pageNo = 1;
    // props.getUserExport(requestData, (data) => {
    if (rows) {
      const mappedLogs = rows.map((row) => [
        row.name,
        row.startDate,
        exportNumberToDashFormat(row.appLinked),
        row.createdDate,
        row.updatedDate,
        row.status,
        row.applicationType,
        row.templateType,
        row.createdBy,
        row.updatedBy,
      ]);
      const headersName = [
        "name",
        "Start Date",
        "App Linked",
        "Created Date",
        "Updated Date",
        "Status",
        "Application Type",
        "Template Type",
        "Created By",
        "Updated By",
      ];
      exportToPDF(
        headersName,
        mappedLogs,
        "Pricing Templates Details",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname
      );
    }
    // });
  };
  const getProviderByFilterDataHandle = (value) => {
    setSearchValues(value);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        chipArray.push(
          `${property === "name" ? "Template Name" : "Status"}: ${
            value[property]
          }`
        );
      }
    }
    setChipValue(chipArray);
  };

  const getPricingListCall = (payload = {}) => {
    const updatePayload = {};
    if (payload.name) {
      updatePayload.name = payload.name;
      setPage(0);
    }
    if (payload.status) {
      updatePayload.status = payload.status;
      setPage(0);
    }
    props.getPricingList(
      (data) => {
        setInitialCallLoading(false);
        if (data.status) {
          const x = modifyList(data.data);
          setRows(x);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error",
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      },
      updatePayload
    );
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      setChipValue(chipValue.filter((v) => v !== chip));
      if (chipValue.length !== 0) {
        for (let i = 0; i < chipValue.length; i++) {
          if (chipValue[i] == chip) {
            chipValue.splice(i, 1);
          }
        }
        const chipArr = chipValue.map((e) => ({
          [e.split(":")[0].replace(" ", "")]: e.split(":")[1].replace(" ", ""),
        }));

        const finalObj = {};
        for (let i = 0; i < chipArr.length; i++) {
          Object.assign(finalObj, chipArr[i]);
        }

        const modifiedSearchValues = {
          name: finalObj.TemplateName ? finalObj.TemplateName : "",
          status: finalObj.Status ? finalObj.Status : "",
        };
        getPricingListCall(modifiedSearchValues);
        setSearchValues(modifiedSearchValues);
        setChipValue(chipValue);
      } else {
        setSearchValues({
          name: "",
          status: "",
        });
        props.getProviderList();
        setChipValue(chipValue);
      }
    }
    setTemp(temp);
  };

  React.useEffect(() => {
    if (rows) {
      setInitialCall(true);
    }
  }, [rows]);

  React.useEffect(() => {
    let userid = localStorage.getItem("userId");
    getPricingListCall({
      name: "",
      status: "Active",
    });
    props.getUserDetailsById(userid);
  }, []);

  return (
    <>
     {initialCallLoading ? (
        <AppLoader loader={initialCallLoading} />
      ) : (
        <>
          {actionPermission(
            permissionCheck.Category.setup,
            permissionCheck.Page.pricingTemplate,
            permissionCheck.option.view
          ) && (
            <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ width: "35%" }}>
                    <ChipInput
                      value={chipValue.length > 0 ? chipValue : []}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex" }}
                      classes={{ chipContainer: classes.chipContainer }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Search"
                      onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        close={handleClose}
                        searchValue={searchValues}
                        searchChipData={getProviderByFilterDataHandle}
                        getPricingListCall={getPricingListCall}
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <CoustomButton
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </CoustomButton>
                {actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.pricingTemplate,
                  permissionCheck.option.export
                ) &&
                  (actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.pricingTemplate,
                    permissionCheck.option.exportToExcel
                  ) ||
                    actionPermission(
                      permissionCheck.Category.setup,
                      permissionCheck.Page.pricingTemplate,
                      permissionCheck.option.exportToPdf
                    )) && (
                    <IconsTooltip title="Export" placement="top">
                      <div className={classes.appSvgIcons}>
                        <ExportIcon
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleToggle}
                          ref={anchorRef}
                        />
                      </div>
                    </IconsTooltip>
                  )}
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClosed}>
                          <MenuList
                            // autoFocusItem={openImportFileModal}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.pricingTemplate,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.pricingTemplate,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                {actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.pricingTemplate,
                  permissionCheck.option.add
                ) && (
                  <IconsTooltip title="Add" placement="top">
                    <div className={classes.appSvgIcons}>
                      <AddIconSvg onClick={() => handleAddEdit()} />
                    </div>
                  </IconsTooltip>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <ChipInput
                    defaultValue={chipValue.length > 0 ? chipValue : []}
                    classes={{ chipContainer: classes.chipContainer }}
                    onClick={() => handleClickOpen()}
                    style={{ display: "flex" }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="Search"
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog
                      close={() => handleClose()}
                      searchValue={searchValues}
                      seacrhChipData={getProviderByFilterDataHandle}
                      getPricingListCall={getPricingListCall}
                    />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <CoustomButton
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </CoustomButton>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              {actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.pricingTemplate,
                permissionCheck.option.export
              ) &&
                (actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.pricingTemplate,
                  permissionCheck.option.exportToExcel
                ) ||
                  actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.pricingTemplate,
                    permissionCheck.option.exportToPdf
                  )) && (
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>
                )}
              <Popper
                open={openImportFileModal}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                style={{
                  zIndex: "10",
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClosed}>
                        <MenuList
                          // autoFocusItem={openImportFileModal}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          {actionPermission(
                            permissionCheck.Category.setup,
                            permissionCheck.Page.pricingTemplate,
                            permissionCheck.option.exportToExcel
                          ) && (
                            <MenuItem
                              onClick={exportListData}
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Export to Excel
                            </MenuItem>
                          )}
                          {actionPermission(
                            permissionCheck.Category.setup,
                            permissionCheck.Page.pricingTemplate,
                            permissionCheck.option.exportToPdf
                          ) && (
                            <MenuItem
                              onClick={exportToPDFList}
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Export to PDF
                            </MenuItem>
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              {actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.pricingTemplate,
                permissionCheck.option.add
              ) && (
                <IconsTooltip title="Add" placement="top">
                  <div className={classes.appSvgIcons}>
                    <AddIconSvg onClick={() => handleAddEdit()} />
                  </div>
                </IconsTooltip>
              )}
            </Grid>
          </Grid>
        )}        
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {(!isEmpty(rows) && initialCall) ||
            (isEmpty(rows) && !initialCall) ? (
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow className={classes.cellHeadSign}>
                    <TableCell
                      className={classes.cellText}
                      onClick={() => handleAddEdit("edit", row._id)}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      className={classes.cellText}
                      align="left"
                      onClick={() => handleAddEdit("edit", row._id)}
                    >
                      {dateFormat(row.startDate)}
                    </TableCell>
                    <TableCell
                      className={classes.cellText}
                      align="left"
                      onClick={() => handleAddEdit("edit", row._id)}
                    >
                      {row.appLinked}
                    </TableCell>
                    <TableCell
                      className={classes.cellText}
                      align="left"
                      onClick={() => handleAddEdit("edit", row._id)}
                    >
                      {dateFormat(row.createdDate)}
                    </TableCell>
                    <TableCell
                      className={classes.cellText}
                      align="left"
                      onClick={() => handleAddEdit("edit", row._id)}
                    >
                      {dateFormat(row.updatedDate) || "NA"}
                    </TableCell>
                    <TableCell
                      className={classes.cellText}
                      align="left"
                      onClick={() => handleAddEdit("edit", row._id)}
                    >
                      {row.createdBy}
                    </TableCell>
                    <TableCell
                      className={classes.cellText}
                      align="left"
                      onClick={() => handleAddEdit("edit", row._id)}
                    >
                      {row.updatedBy || "NA"}
                    </TableCell>
                    <TableCell
                      className={classes.cellText}
                      align="left"
                      onClick={() => handleAddEdit("edit", row._id)}
                    >
                      {row.status}
                    </TableCell>
                    <TableCell className={classes.cellText}>
                      <LongMenu
                        details={row}
                        {...props}
                        setValues={setValues}
                        openForm={() => setOpenModel(true)}
                      />
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: rows.length + 1,
                    },
                  ]}
                  colSpan={12}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
      </>
          )}
          {!actionPermission(
            permissionCheck.Category.setup,
            permissionCheck.Page.pricingTemplate,
            permissionCheck.option.view
          ) && (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "25%",
              }}
            >
              Access denied.
            </p>
          )}
        </>
      )}
    </>
  );
};

ProviderReport.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  userDetails: PropTypes.object,
  mainData: PropTypes.object,
  getPricingList: PropTypes.func,
  pricingTempleteCall: PropTypes.func,
  getPricingTemplateExport: PropTypes.func,
  getUserDetailsById: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
});
export default connect(mapStateToProps, {
  getPricingList,
  getPricingTemplateExport,
  pricingTempleteCall,
  getUserDetailsById,
})(ProviderReport);
