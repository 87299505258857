import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { connect } from "react-redux";

const useStyles = makeStyles(styles);

export function SimpleDialog(props) {
  const [values, setValue] = React.useState(props.searchValue);
  const classes = useStyles();

  const handleClose = () => {
    setValue({
      globalSearchText: "",
      fromDate: "",
      toDate: ""
    });
    props.seacrhChipData({
      globalSearchText: "",
      fromDate: "",
      toDate: ""
    });
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  const onFormSubmit = () => {
    props.seacrhChipData(values);
    props.close();
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            label="User / Type / Description"
            value={values.globalSearchText}
            onChange={(e) => onChange(e.target.value, "globalSearchText")}
            name="globalSearchText"
            id="globalSearchText"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ margin: "2% 0" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="From"
            type="date"
            value={values.fromDate}
            onChange={(e) => onChange(e.target.value, "fromDate")}
            name="fromDate"
            id="fromDate"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="To"
            type="date"
            value={values.toDate}
            onChange={(e) => onChange(e.target.value, "toDate")}
            name="toDate"
            id="toDate"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ margin: "2% 0" }}>
        <Grid item xs alignItems="right">
          <Button
            variant="contained"
            className={classes.clearButton}
            onClick={() => handleClose()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={() => onFormSubmit()}
            disabled={
              values.globalSearchText === "" &&
              values.fromDate === "" &&
              values.toDate === ""
            }
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  close: PropTypes.func,
  seacrhChipData: PropTypes.func,
  searchValue: PropTypes.object
};

const mapStateToProps = (app) => ({});

export default connect(mapStateToProps, {})(SimpleDialog);
