/* eslint-disable no-nested-ternary */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
// import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { Prompt } from "react-router-dom";
// core components
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import WebIcon from "@material-ui/icons/Web";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CommentIcon from "@material-ui/icons/Comment";
import { Field, Form, Formik } from "formik";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import Card from "components/Card/Card";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import RadioGroup from "@material-ui/core/RadioGroup";
import Divider from "@material-ui/core/Divider";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import FormLabel from "@material-ui/core/FormLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import IdleTimer from "react-idle-timer/dist/modern";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { Slide, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DateFnsUtils from "@date-io/date-fns";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import Merchant_Acceptance from "../../assets/doc/Merchant_Acceptance.pdf";
import Authority_Acknowledgement from "../../assets/doc/Authority_Acknowledgement.pdf";
import SwipeableViews from "react-swipeable-views";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import PrintIcon from "@material-ui/icons/Print";
import PublicIcon from "@material-ui/icons/Public";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DescriptionIcon from "@material-ui/icons/Description";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import Validations from "../../Utils/Validations";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import RoomIcon from "@material-ui/icons/Room";
import StarIcon from "@material-ui/icons/Star";
import Table from "@material-ui/core/Table";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import Chip from "@material-ui/core/Chip";
import closeImage from "assets/img/Close_Image.png";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { CardActions } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Modal from "@material-ui/core/Modal";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import CancelIcon from "@material-ui/icons/Cancel";
import { locationCity } from "../../constant/locationConstant";
import OwnerInformation from "./OwnerInformation";
import ContactInformation from "./ContactInformation";
import { actionPermission } from "../../Utils/commonUtils";
import {
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  mpaChecklistComment,
  kycChecklistAttechment,
  mpaChecklistAttechment,
  deleteBankDocumentApiCall,
  deleteOwnerApiCall,
} from "../../actions/Operations";
import {
  provideRegistration,
  deleteIncroptration,
  deleteAddressProof,
  deleteIdentityProof,
  getMccList,
  getQuestionData,
  getKycQuestionData,
  getCountryTypeList,
  accountTypeList,
  ownerTypeList,
  updateRegistration,
  pricingTempleteCall,
  setPricingTempleteEmpty,
  getProviderStatus,
  getProviderActivityById,
  changeSingleStatus,
  saveQuestionCall,
  changeProviderFormMode,
  setResetWuestion,
  saveQuestionCallAction,
  getProviderParameterCall,
  getProviderDetailsById,
  getKycResponseUserList,
  clearRegData,
  // getSignNowCall
} from "../../Redux/Actions/ProvideRegistration";
import {
  showCheckList,
  changeStatusPermission,
  checkFormDisabled,
  disbaleAndHide,
  disableSaveDraftButton,
} from "./SetRoleBase";
import {
  getKycResponseFromAlloy,
  postKycQuestionsCall,
  postKycSubQuestions,
  postRegistrationCall,
  putRegistrationCall,
} from "../../actions/Operations";
import { getSignNowCall, getDocStatus } from "../../actions/signNowAction";
import SignOnModal from "./SignOnModal";
import AppLoader from "components/AppLoader/Index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ReactComponent as ActivityIcon } from "../../assets/svgIcons/activityHistoryIcon.svg";
import { ReactComponent as PricingIcon } from "../../assets/svgIcons/viewPricingIcon3.svg";
import { ReactComponent as KYCIcon } from "../../assets/svgIcons/KYCIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/svgIcons/InfoIcon.svg";
import { ReactComponent as NotesIcon } from "../../assets/svgIcons/notesIcon.svg";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Notes from "views/Reports/Notes";
import { useReactToPrint } from "react-to-print";
import EditIcon from "@material-ui/icons/Edit";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import ClearIcon from "@material-ui/icons/Clear";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import Zoom from "@material-ui/core/Zoom";
import MerchantVersion from "./downloadVersion";
import html2pdf from "html2pdf.js";
import UpdateDescriptionModal from "../../components/DialogBox/UpdateVersionDialog";
import { permissionCheck } from "../../Utils/commonUtils";
const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const dialogUseStyles = makeStyles(tableStyle);

const merchantsCatId = 2;

const merchantsInprogessPageId = 1;

const defaults = {
  view: 1,
  create: 2,
  edit: 3,
  changeStatus: 4,
  allowToBoard: 5,
  allowToChecklist: 6,
  allowToLive: 7,
};

const useStyles = makeStyles(styles);

function getKeyByValue(object) {
  return Object.keys(object).find((key) => object[key] === true);
}

function getKeyEmptyValue(object) {
  return Object.keys(object).find((key) => object[key] === "");
}

let kycCheckListFlag = false;
let editPermissionFlag = false;
let createPermissionFlag = false;
let livePermissionFlag = false;
let changeStatusPermissionFlag = false;
let allowToBoardFlag = false;

const mandFields = {
  // businessName: "Business Name is required.",
  legalBusiness: "Legal Business name is required",
  businessEmail: "Business Email Address is required",
  Owner_First_Name: "First Name is required",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanelAttchment(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px" }}>{children}</Box>}
    </div>
  );
}

TabPanelAttchment.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanelKycResponse(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px" }}>{children}</Box>}
    </div>
  );
}

TabPanelKycResponse.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function BusinessForm(props) {
  const [checkC, setCheckC] = React.useState(false);
  const [validationModal, setValidationModal] = React.useState(false);
  const [selectedQuestion, setSelectedQuestion] = React.useState(false);
  const [commentSection, setCommentSection] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [ownerDeleteModal, setOwnerDeleteModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const theme = useTheme();
  const [IP, setIP] = React.useState("");
  const smallDevice = useMediaQuery(theme.breakpoints.down("xs"));
  const [stateInput, setStateInput] = React.useState(true);
  const [checkListModal, setCheckListModal] = React.useState(false);
  const [checkListModalType, setCheckListModalType] = React.useState("");
  const [approveModal, setApproveModal] = React.useState(false);
  const [declineModal, setDeclineModal] = React.useState(false);
  const [kycResponseModal, setKycResponseModal] = React.useState(false);
  const [textAreaText, setTextAreaText] = React.useState("");
  const [ownerSelect, setOwnerSelect] = React.useState("");
  const [kycResponseJson, setKycResponseJson] = React.useState({});
  const [bankDocFileType, setBankDocFileType] = React.useState("");
  const [saveDraftClick, setSaveDraftClick] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [dbaValue, setDBAValue] = React.useState(false);
  const [disabledValue, setDisabledValue] = React.useState(false);
  const [changeStatusValue, setChangeStatusValue] = React.useState(false);
  const classes = useStyles();
  const [alterOpen, setAlertOpen] = React.useState(false);
  const [closeAlterOpen, setCloseAlertOpen] = React.useState(false);
  const [data, setData] = React.useState(false);
  const [mccList, setMccList] = React.useState([]);
  const [signedDocData, setSignedDocData] = React.useState({});
  const [fileHandleAttechment, setFileHandleAttechment] = React.useState({
    files: [],
  });
  const [submissionSuccess, setSubmissionSuccess] = React.useState(
    props.formData.data
  );
  const [accordian, handleAccordClick] = React.useState({
    index: [],
  });
  const [statusUpdate, setStatusUpdate] = React.useState(
    props.formData.statusUpdate
  );
  const [formInEditMode, setFormInEditMode] = React.useState(false);
  const [preUpdateQuestion, setPreUpdateQuestion] = React.useState(
    props.formData.questionUpdate
  );
  const [kycListModal, setKycListModal] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthLG = useMediaQuery(theme.breakpoints.up("lg"));
  const [mpaCheckListStatus, setMpaCheckListStatus] = React.useState({
    pending: 0,
    progress: 0,
    completed: 0,
  });
  const [kycCheckListStatus, setKycCheckListStatus] = React.useState({
    pending: 0,
    progress: 0,
    completed: 0,
  });
  const dialogClasses = dialogUseStyles();
  const [dataPiricng, setDataPiricng] = React.useState(false);
  const [isDocModalOpen, setIsDocModalOpen] = React.useState(false);
  const [docLink, setDocLink] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [openNotes, setOpenNotes] = React.useState(false);
  const [userOptions, setUserOptions] = React.useState(false);
  const [kycValidationError, setKycValidationError] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [isUpdateDescriptionModal, setUpdateDescriptionModal] =
    React.useState(false);

  const idleTimer = React.useRef();
  const timeOutLimit = 1000 * 60 * 2;
  const componentRef = React.useRef(null);
  const itemRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    setText("old boring text");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
    setText("Loading new text...");
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const getPageMargins = () => {
    return `{margin: 10mm !important}`;
  };

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Merchant Application",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    pageStyle: getPageMargins,
  });

  var image =
    props.app.defaultTheme && props.app.defaultTheme.data[0].Logo_Image;

  // generate version pdf code here
  // generate pdf code start
  const generatePDF = async () => {
    const item = itemRef.current;
    if (!item) {
      return;
    }
    const pdfOptions = {
      margin: 5,
      filename: "Merchant Version.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1.5 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    let PdfDetails = await html2pdf()
      .from(item)
      .set(pdfOptions)
      .outputPdf("datauristring");
    return PdfDetails;
  };
  // generate pdf code ends

  React.useEffect(() => {
    if (
      props.formData.providerDetailsById.Providers_Data &&
      props.formData.providerDetailsById.Providers_Data[0].Owner_Information[0]
    ) {
      let x =
        props.formData.providerDetailsById.Providers_Data[0]
          .Owner_Information[0];
      setOwnerSelect({ ...x });
    }
    if (
      props.formData.providerDetailsById &&
      props.formData.providerDetailsById.Providers_Data[0]._id
    ) {
      props.getKycResponseFromAlloy(
        {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
          userId: props.formData.providerDetailsById.Providers_Data[0]._id,
        },
        (data) => {
          if (data.data) {
            setKycResponseJson(data.data[0]);
            // } else {
            //   enqueueSnackbar(data.message, {
            //     variant: "error",
            //   });
          }
        },
        () => {}
      );
      if (props.formData.providerDetailsById.Providers_Data) {
        props.getProviderStatus({
          statusCode:
            props.formData.providerDetailsById.Providers_Data[0].Current_Status,
        });
        onChange(
          props.formData.providerDetailsById.Providers_Data[0].Current_Status,
          "status"
        );
      }
      const dataProviderId = {
        providerId:
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0]._id,
      };
      props.getKycResponseUserList(dataProviderId);
    }
  }, [props.formData.providerDetailsById]);

  React.useEffect(() => {
    if (props.formData.mcclist.MCC_Data) {
      const sortedData = props.formData.mcclist.MCC_Data.map((option) => ({
        MCC_Code: option.MCC_Code,
        MCC_Name: `[${option.MCC_Code}] - ${option.MCC_Name}`,
      }));
      setMccList([...sortedData]);
    }
  }, [props.formData.mcclist]);

  const [values, handleInput] = React.useState({
    businessName: "",
    legalBusiness: "",
    businessType: "",
    businessWebsite: "",
    businessStartDate: "",
    docAdd: false,
    businessDiscrition: "",
    ownerShipType: "",
    businessPhoneNumber: "",
    businessEmail: "",
    businessTaxId: "",
    businessStock: "",
    businessAddress: "",
    businessSuite: "",
    businessCity: "",
    businessState: "",
    USID: "",
    businessPostalCode: "",
    businessInterNational: "",
    deleteCoperation: false,
    businessCountry: "US",
    accountNumber: "",
    routingNumber: "",
    accountName: "",
    accountType: "",
    cardPresent: "",
    masterContactName: "",
    foreignEntityNonresident: false,
    fax: "",
    mailingAddress: "",
    transmitCardholderOption: null,
    transmitCardholderName: "",
    seasonalSales: false,
    comments: "",
    locationAddress: "",
    discription: "",
    ownerArray: {
      owners: [
        {
          Owner_First_Name: "",
          Owner_Last_Name: "",
          Owner_Last4_SSN: "",
          ownerPhotoIdType: "",
          ownerAddressProofType: "",
          Owner_DOB: "",
          Owner_Ownership_Percentage: "",
          Owner_Title: "",
          Owner_Phone: "",
          Owner_Email: "",
          Owner_Middle_Name: "",
          Owner_Type: "",
          Owner_Postal_Code_Ext: "",
          Owner_Street_Address2: "",
          Owner_Street_Address: "",
          Owner_Suite: "",
          Owner_City: "",
          Owner_State_Code: "",
          Owner_Postal_Code: "",
          Owner_International_Province: "",
          Owner_Country_Code: "US",
          Owner_Timezone_Code: "IST",
          ownerAddressProof: false,
          ownerPhotoId: "",
          deleteOwnerPhotoId: false,
          deleteAddressProofId: false,
          ownerAddressProofDetails: {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: "",
            Doc_Type: "",
          },
          ownerPhotoIdDetails: {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: "",
            Doc_Type: "",
          },
        },
      ],
    },
    contactArray: [
      {
        type: "CustomerService",
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        phoneExt: "",
        faxNumber: "",
        email: "",
        // zip: "",
      },
    ],
    cardNotPresent: "",
    accountNumber: "",
    routingNumber: "",
    accountType: "",
    eCommers: "",
    cardVolumeMonth: "",
    averageTicketSize: "",
    highTicket: "",
    ussidTypeInput: "",
    eCheckVolumeMonth: "",
    incorportionDocument: "",
    incroprationDocDelete: false,
    bankDocumentDelete: false,
    bankDocumentType: "",
    bankDocuments: "",
    incorportionDocumentDetails: {
      File_Name: "",
      File_Type: "",
      File_Path: "",
      Upload_Date_Time: "",
    },
    bankDocumentsDetails: {
      File_Name: "",
      File_Type: "",
      File_Path: "",
      Upload_Date_Time: "",
    },
    eCheckAverage: "",
    eCheckHigh: "",
    status: "",
    checkedB: "",
    checkedC: "",
    editable: false,
    titleTermsCondition: "",
    nameTermsCondition: "",
  });
  const [preDataProviderById, setPreDataProviderById] = React.useState(false);
  const [validationError, setVlidationError] = React.useState({
    businessName: false,
    legalBusiness: false,
    businessStartDate: false,
    businessStartDateMessage: false,
    businessType: false,
    businessWebsite: false,
    businessDiscrition: false,
    ownerShipType: false,
    businessPhoneNumber: false,
    businessEmail: false,
    businessTaxId: false,
    businessStock: false,
    businessAddress: false,
    businessSuite: false,
    businessCity: false,
    businessState: false,
    USID: false,
    incorportionDocument: false,
    businessPostalCode: false,
    businessInterNational: false,
    businessCountry: false,
    accountNumber: false,
    routingNumber: false,
    accountType: false,
    bankDocuments: false,
    cardPresent: false,
    masterContactName: false,
    foreignEntityNonresident: false,
    fax: false,
    mailingAddress: false,
    transmitCardholderOption: false,
    transmitCardholderName: false,
    seasonalSales: false,
    comments: false,
    locationAddress: false,
    discription: false,
    ownerArray: {
      owners: [
        {
          Owner_First_Name: false,
          Owner_Last_Name: false,
          Owner_Last4_SSN: false,
          Owner_DOB: false,
          Owner_Ownership_Percentage: false,
          Owner_Title: false,
          Owner_Phone: false,
          Owner_Email: false,
          Owner_Middle_Name: false,
          Owner_Type: false,
          Owner_Postal_Code_Ext: false,
          Owner_Street_Address: false,
          Owner_Street_Address2: false,
          Owner_Suite: false,
          Owner_City: false,
          Owner_State_Code: false,
          Owner_Postal_Code: false,
          Owner_International_Province: false,
          Owner_Country_Code: false,
          Owner_Timezone_Code: false,
          ownerAddressProof: false,
          ownerPhotoId: false,
          ownerAddressProofDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false,
          },
          ownerPhotoIdDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false,
          },
        },
      ],
    },
    contactArray: [
      {
        type: false,
        title: false,
        firstName: false,
        middleName: false,
        lastName: false,
        phoneNumber: false,
        phoneExt: false,
        faxNumber: false,
        email: false,
      },
    ],
    cardNotPresent: false,
    eCommers: false,
    cardVolumeMonth: false,
    averageTicketSize: false,
    highTicket: false,
    ussidTypeInput: false,
    eCheckVolumeMonth: false,
    eCheckAverage: false,
    // eCheckHigh: false,
    status: "",
    checkedB: false,
    checkedC: false,
    Owner_First_Name: false,
    titleTermsCondition: false,
    nameTermsCondition: false,
  });
  const handleClose = () => {
    setValidationModal(false);
  };

  const handleDecriptionModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setUpdateDescriptionModal(false);
    }
  };

  React.useEffect(() => {
    if (!dataPiricng) {
      if (
        dataPiricng !== props.formData.pricingTemplete &&
        props.formData.pricingTemplete.data
      ) {
        const preValues = [...props.formData.pricingTemplete.data?.Pricing];
        setDataPiricng(preValues);
      }
    }
  }, [props.formData.pricingTemplete]);

  React.useEffect(() => {
    const documentStatus =
      props.formData.providerDetailsById &&
      props.formData.providerDetailsById.Providers_Data[0].termAndConditions;
    if (documentStatus.docStatus == "Signed") {
      setCheckC(true);
    } else {
      setCheckC(false);
    }
  }, [props.formData.providerDetailsById]);

  const checkValidation = (saveDraft = false, autoValidate = false) => {
    let validate = true;
    const error = { ...validationError };

    for (const key in values) {
      if (saveDraft) {
        if (
          values[key] === "" &&
          (key === "legalBusiness" || key === "businessEmail")
        ) {
          error[key] = true;
          validate = false;
        }
        if (key === "ownerArray") {
          values[key].owners.forEach((item, i) => {
            if (item.Owner_First_Name === "") {
              error[key].owners[i].Owner_First_Name = true;
              validate = false;
            }
            // const findTheEmptyField = getKeyByValue(error[key].owners[i]);
            // if (findTheEmptyField) {
            //   validate = false;
            // }
          });
        }
        // const findTheEmptyFieldNew = getKeyByValue(error);
        // if (findTheEmptyFieldNew) {
        //   validate = false;
        // }
        if (saveDraft && error.gasPosTerm) {
          error.gasPosTerm = false;
          validate = true;
        }
      }
      if (!saveDraft) {
        const findTheEmptyFieldNo = getKeyByValue(error);
        if (findTheEmptyFieldNo) {
          validate = false;
        }
        if (
          (values[key] === "" ||
            values[key] === null ||
            values[key] === undefined) &&
          (key === "ownerShipType" ||
            key === "businessPhoneNumber" ||
            key === "businessTaxId" ||
            key === "businessType" ||
            key === "legalBusiness" ||
            key === "businessAddress" ||
            key === "cardVolumeMonth" ||
            key === "averageTicketSize" ||
            key === "highTicket" ||
            key === "businessState" ||
            key === "businessCity" ||
            key === "businessPostalCode" ||
            key === "USID" ||
            key === "routingNumber" ||
            key === "accountType" ||
            key === "accountNumber" ||
            key === "bankDocumentType" ||
            key === "titleTermsCondition" ||
            key === "businessStartDate" ||
            key === "businessEmail" ||
            key === "nameTermsCondition")
        ) {
          error[key] = true;
          validate = false;
        }
        if (key === "businessTaxId") {
          if (values[key] === "" || values[key].length !== 9) {
            error[key] = true;
            validate = false;
          }
        }
        if (key === "businessPostalCode") {
          if (values[key] === "" || values[key].length !== 5) {
            error[key] = true;
            validate = false;
          }
        }
        if (key === "businessPhoneNumber") {
          if (values[key] === "" || values[key].length !== 12) {
            error[key] = true;
            validate = false;
          }
        }
        if (key === "businessStartDate") {
          if (values[key] === "" || values[key] === null) {
            error[key] = true;
            validate = false;
          }
          if (error.businessStartDateMessage) {
            validate = false;
          }
        }
        if (key === "averageTicketSize" || key === "highTicket") {
          if (
            parseInt(values.averageTicketSize) > parseInt(values.highTicket)
          ) {
            validate = false;
            error.averageTicketSize = true;
            error.highTicket = true;
            if (error.averageTicketSize || error.highTicket) {
              validate = false;
            }
          } else {
            error.averageTicketSize = false;
            error.highTicket = false;
          }
        }
        if (key === "highTicket" || key === "cardVolumeMonth") {
          if (parseInt(values.highTicket) > parseInt(values.cardVolumeMonth)) {
            validate = false;
            error.cardVolumeMonth = true;
            error.highTicket = true;
            if (error.cardVolumeMonth || error.highTicket) {
              validate = false;
            }
          }
        }

        if (!checkC) {
          validate = false;
          error.gasPosTerm = true;
        }
        if (key === "businessTaxId") {
          if (values[key] === "" || values[key].length !== 9) {
            validate = false;
          }
          if (error[key]) {
            validate = false;
          }
        }
        if (key === "businessPostalCode") {
          if (values[key] === "" || values[key].length !== 5) {
            validate = false;
          }
          if (error[key]) {
            validate = false;
          }
        }
        if (key === "averageTicketSize" || key === "highTicket") {
          if (
            parseInt(values.averageTicketSize) > parseInt(values.highTicket)
          ) {
            validate = false;
            error.averageTicketSize = true;
            error.highTicket = true;
            if (error.averageTicketSize || error.highTicket) {
              validate = false;
            }
          } else if (values[key] === "" || values[key] === "") {
            validate = false;
            error.averageTicketSize = true;
          } else {
            error.averageTicketSize = false;
            error.highTicket = false;
          }
        }
        if (key === "highTicket" || key === "cardVolumeMonth") {
          if (parseInt(values.highTicket) > parseInt(values.cardVolumeMonth)) {
            validate = false;
            error.cardVolumeMonth = true;
            error.highTicket = true;
            if (error.cardVolumeMonth || error.highTicket) {
              validate = false;
            }
          } else if (values[key] === "" || values[key] === "") {
            validate = false;
            error.highTicket = true;
          } else {
            error.cardVolumeMonth = false;
            error.highTicket = false;
          }
        }
        if (key === "nameTermsCondition" || key === "titleTermsCondition") {
          if (values[key] === "" || values[key] === null) {
            validate = false;
            error[key] = true;
          }
        }
        if (key === "ownerArray") {
          values[key].owners.forEach((item, i) => {
            if (item.Owner_First_Name === "") {
              error[key].owners[i].Owner_First_Name = true;
              validate = false;
            }
            if (item.Owner_Last_Name === "") {
              error[key].owners[i].Owner_Last_Name = true;
              validate = false;
            }
            if (
              item.Owner_Email === "" ||
              item.Owner_Email === undefined ||
              item.Owner_Email === null
            ) {
              error[key].owners[i].Owner_Email = true;
              validate = false;
            }
            if (item.Owner_Title === "") {
              error[key].owners[i].Owner_Title = true;
              validate = false;
            }
            if (
              item.Owner_Type === null ||
              item.Owner_Type === undefined ||
              item.Owner_Type === ""
            ) {
              error[key].owners[i].Owner_Type = true;
              validate = false;
            }
            if (
              item.Owner_Ownership_Percentage === "" ||
              item.Owner_Ownership_Percentage === null
            ) {
              error[key].owners[i].Owner_Ownership_Percentage = true;
              validate = false;
            }
            if (item.Owner_Last4_SSN === "") {
              error[key].owners[i].Owner_Last4_SSN = true;
              validate = false;
            }
            if (item.Owner_Street_Address === "") {
              error[key].owners[i].Owner_Street_Address = true;
              validate = false;
            }
            if (item.Owner_State_Code === "") {
              error[key].owners[i].Owner_State_Code = true;
              validate = false;
            }
            if (item.Owner_City === "") {
              error[key].owners[i].Owner_City = true;
              validate = false;
            }
            if (item.Owner_Postal_Code === "") {
              error[key].owners[i].Owner_Postal_Code = true;
              validate = false;
            }
            if (item.Owner_Phone === "" || item.Owner_Phone.length !== 12) {
              error[key].owners[i].Owner_Phone = true;
              validate = false;
            }
            if (item.Owner_DOB === "") {
              error[key].owners[i].Owner_DOB = true;
              validate = false;
            }
            if (item.Owner_DOB === undefined) {
              error[key].owners[i].Owner_DOB = true;
              validate = false;
            }
            if (item.Owner_DOB === null) {
              error[key].owners[i].Owner_DOB = true;
              validate = false;
            }
            if (item.Owner_DOB !== "") {
              if (moment().diff(item.Owner_DOB, "days") < 0) {
                error[key].owners[i].Owner_DOB = true;
                validate = false;
              }
            }
            if (
              item.ownerPhotoIdDetails.File_Name === "" ||
              item.ownerPhotoId === ""
            ) {
              error[key].owners[i].ownerPhotoId = true;
              validate = false;
            }
          });
        }
        if (key === "contactArray") {
          values[key].forEach((item, i) => {
            if (item.type === "") {
              error[key][i].type = true;
              validate = false;
            }
            if (item.title === "") {
              error[key][i].title = true;
              validate = false;
            }
            if (item.firstName === "") {
              error[key][i].firstName = true;
              validate = false;
            }
            if (item.lastName === "") {
              error[key][i].lastName = true;
              validate = false;
            }
          });
        }
        if (key === "bankDocumentType") {
          if (
            values.bankDocumentType === "" ||
            values.bankDocumentsDetails.File_Name === ""
          ) {
            validate = false;
            error[key] = true;
          }
        }
        if (formInEditMode && key === "discription") {
          if (isEmpty(values[key])) {
            error[key] = true;
            validate = false;
          }
        }
      }
    }
    if (!saveDraft) {
      if (checkC) {
        error.gasPosTerm = false;
      }
    }

    setVlidationError(error);
    if (!saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    if (saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    // KYC VALIDATION for agents before final submit
    if (values.status != 1 && values.status != 10 && !saveDraft) {
      let buttonText = "Approve";
      if (
        props.formData.questionData.confirmButton != buttonText ||
        props.formData.kycQuestionData.confirmButton != buttonText
      ) {
        setValidationModal(true);
        setKycValidationError(true);
        validate = false;
      }
    }
    return validate;
  };

  React.useEffect(() => {
    if (!props.formData.mcclist) {
      props.getMccList();
    }
    if (!props.formData.accountTypeList) {
      props.accountTypeList();
    }
    if (!props.formData.ownerShipTypeList) {
      props.ownerTypeList();
    }
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
    if (
      props.formData.providerDetailsById &&
      !props.formData.questionData &&
      props.formData.formMode === "Edit"
    ) {
      const data = {
        providerId:
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0]._id,
        ownerId: ownerSelect.Owner_ID,
      };
      props.getQuestionData(data);

      const dataProviderId = {
        providerId:
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0]._id,
      };
      props.getKycResponseUserList(dataProviderId);
      props.setPricingTempleteEmpty();
      const callPricingTempleteData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
      };
      props.pricingTempleteCall(callPricingTempleteData);
    }
  }, []);

  const handlelistBack = () => {
    // props.getProviderStatus();
    // props.history.push(`/${props.mainData.app.layout}/Merchant`);
  };
  React.useEffect(() => {
    if (props.mainData.roles.roleTab && !userOptions) {
      const filterSetUp =
        props.mainData.roles.roleTab.Roles_Data.Master_Permission.filter(
          (key) => key.catCode === 2
        );
      const filterPageMenu =
        filterSetUp.length > 0 &&
        filterSetUp[0].Page_Menu.filter((key) => key.Page_Code === 1);
      const filterOption =
        filterPageMenu &&
        filterPageMenu[0]?.Options?.filter((key) => key?.value === 4);
      setUserOptions(filterOption[0]);
    }
    if (preDataProviderById === props.formData.providerDetailsById) {
      setPreDataProviderById(props.formData.providerDetailsById);
      setData(false);
    }
    if (!props.formData.providerDetailsById) {
      handlelistBack();
    }
    if (submissionSuccess !== props.formData.data) {
      setValidationModal(false);
      if (
        props.formData.data &&
        props.formData.data.status &&
        !props.formData.data.errors
      ) {
        props.changeProviderFormMode("Edit");
        setSubmissionSuccess(props.formData.data);
        enqueueSnackbar("Application Saved Successfully", {
          variant: "success",
        });
      } else if (
        props.formData.data &&
        !props.formData.data.status &&
        props.formData.data.errors
      ) {
        setErrorMessage(props.formData.data.errors);
        const allValidation = { ...validationError };
        props.formData.data.errors.forEach((key) => {
          if (key.param === "Business_Name") {
            allValidation.businessName = true;
          }
          if (key.param === "businessType") {
            allValidation.businessType = true;
          }
          if (key.param === "Legal_Name") {
            allValidation.legalBusiness = true;
          }
          if (key.param === "Business_Start_Date") {
            allValidation.businessStartDate = true;
          }
          if (key.param === "MCC") {
            allValidation.USID = true;
          }
          if (key.param === "Business_Description") {
            allValidation.businessDiscrition = true;
          }
          if (key.param === "Ownership_Type") {
            allValidation.ownerShipType = true;
          }
          if (key.param === "Business_Phone") {
            allValidation.businessPhoneNumber = true;
          }
          if (key.param === "Business_EMail") {
            allValidation.businessEmail = true;
          }
          if (key.param === "Website") {
            allValidation.businessWebsite = true;
          }
          if (key.param === "Fedral_Tax_ID") {
            allValidation.businessTaxId = true;
          }
          if (key.param === "Stock_Symbol") {
            allValidation.businessStock = true;
          }
          if (key.param === "Business_Address") {
            allValidation.businessAddress = true;
          }
          if (key.param === "Suite") {
            allValidation.businessSuite = true;
          }
          if (key.param === "Country_Code") {
            allValidation.businessCountry = true;
          }
          if (key.param === "State_Code") {
            allValidation.businessState = true;
          }
          if (key.param === "City") {
            allValidation.businessCity = true;
          }
          if (key.param === "International_Province") {
            allValidation.businessInterNational = true;
          }
          if (key.param === "Postal_Code") {
            allValidation.businessPostalCode = true;
          }
          if (key.param === "Account_Number") {
            allValidation.accountNumber = true;
          }
          if (key.param === "Routing_Number") {
            allValidation.routingNumber = true;
          }
          if (key.param === "Name_On_Account") {
            allValidation.legalBusiness = true;
          }
          if (key.param === "Account_Type") {
            allValidation.accountType = true;
          }
          if (key.param === "Card_Swiped") {
            allValidation.cardPresent = true;
          }
          if (key.param === "Card_Not_Present") {
            allValidation.cardNotPresent = true;
          }
          if (key.param === "ECommerce") {
            allValidation.eCommers = true;
          }
          if (key.param === "Monthly_Card_Volume") {
            allValidation.cardVolumeMonth = true;
          }

          if (key.param === "Average_Ticket_Size") {
            allValidation.averageTicketSize = true;
          }
          if (key.param === "High_Ticket") {
            allValidation.highTicket = true;
          }
          if (key.param === "Monthly_ACH_Volume") {
            allValidation.eCheckVolumeMonth = true;
          }
          if (key.param === "Average_ACH") {
            allValidation.eCheckAverage = true;
          }
          if (key.param === "High_ACH") {
            allValidation.eCheckHigh = true;
          }

          if (key.param === "masterContactName") {
            allValidation.masterContactName = true;
          }
          if (key.param === "foreignEntityNonresident") {
            allValidation.foreignEntityNonresident = true;
          }
          if (key.param === "nameTermsCondition") {
            allValidation.nameTermsCondition = true;
          }
          if (key.param === "titleTermsCondition") {
            allValidation.titleTermsCondition = true;
          }
          if (key.param === "fax") {
            allValidation.fax = true;
          }
          if (key.param === "mailingAddress") {
            allValidation.mailingAddress = true;
          }
          if (key.param === "transmitCardholderOption") {
            allValidation.transmitCardholderOption = true;
          }
          if (key.param === "transmitCardholderName") {
            allValidation.transmitCardholderName = true;
          }
          if (key.param === "seasonalSales") {
            allValidation.seasonalSales = true;
          }
          if (key.param === "comments") {
            allValidation.comments = true;
          }
          if (key.param === "locationAddress") {
            allValidation.locationAddress = true;
          }
          if (key.param === "Owner_Information[0].Owner_Last_Name") {
            allValidation.ownerArray.owners[0].Owner_Last_Name = true;
          }
          if (key.param === "Owner_Information[0].Owner_Last4_SSN") {
            allValidation.ownerArray.owners[0].Owner_Last4_SSN = true;
          }
          if (key.param === "Owner_Information[0].Owner_Ownership_Percentage") {
            allValidation.ownerArray.owners[0].Owner_Ownership_Percentage = true;
          }
          if (key.param === "Owner_Information[0].Owner_Title") {
            allValidation.ownerArray.owners[0].Owner_Title = true;
          }
          if (key.param === "Owner_Information[0].Owner_Phone") {
            allValidation.ownerArray.owners[0].Owner_Phone = true;
          }
          if (key.param === "Owner_Information[0].Owner_Street_Address") {
            allValidation.ownerArray.owners[0].Owner_Street_Address = true;
          }
          if (key.param === "Owner_Information[0].Owner_State_Code") {
            allValidation.ownerArray.owners[0].Owner_State_Code = true;
          }
          if (key.param === "Owner_Information[0].Owner_City") {
            allValidation.ownerArray.owners[0].Owner_City = true;
          }
          if (key.param === "Owner_Information[0].Owner_Postal_Code") {
            allValidation.ownerArray.owners[0].Owner_Postal_Code = true;
          }
          if (key.param === "Owner_Information[0].Owner_Postal_Code_Ext") {
            allValidation.ownerArray.owners[0].Owner_Postal_Code_Ext = true;
          }
          if (key.param === "Owner_Information[0].Owner_Email") {
            allValidation.ownerArray.owners[0].Owner_Email = true;
          }
          if (key.param === "Owner_Information[0].ownerPhotoId") {
            allValidation.ownerArray.owners[0].ownerPhotoId = true;
          }
          if (key.param === "Owner_Information[0].ownerAddressProof") {
            allValidation.ownerArray.owners[0].ownerAddressProof = true;
          }
        });
        setVlidationError(allValidation);
        setSubmissionSuccess(props.formData.data);
        setValidationModal(true);
      }
    }

    if (statusUpdate !== props.formData.statusUpdate) {
      if (props.formData.statusUpdate) {
        if (props.formData.statusUpdate.status) {
          setStatusUpdate(props.formData.statusUpdate);
          enqueueSnackbar(props.formData.statusUpdate.message, {
            variant: "success",
          });
        } else if (!props.formData.statusUpdate.status) {
          enqueueSnackbar(props.formData.statusUpdate.message, {
            variant: "error",
          });
        }
        handlelistBack();
      } else if (
        props.formData.statusUpdate &&
        !props.formData.statusUpdate.status &&
        props.formData.statusUpdate.message ===
          "Application status already setup"
      ) {
        enqueueSnackbar(props.formData.statusUpdate.message, {
          variant: "error",
        });
        setSubmissionSuccess(props.formData.data);
      }
    }
    if (props.formData.questionUpdate !== preUpdateQuestion) {
      setPreUpdateQuestion(props.formData.questionUpdate);
      if (
        props.formData.questionUpdate &&
        props.formData.questionUpdate.status
      ) {
        const data = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
          ownerId: ownerSelect.Owner_ID,
        };
        props.getQuestionData(data);
        props.getKycQuestionData(data);
        enqueueSnackbar(props.formData.questionUpdate.message, {
          variant: "success",
        });
      }
    }
  }, [submissionSuccess, props]);

  React.useEffect(() => {
    if (ownerSelect && props.formData.providerDetailsById?.Providers_Data) {
      const data = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        ownerId: ownerSelect.Owner_ID,
      };
      props.getKycQuestionData(data);
    }
  }, [ownerSelect]);

  React.useEffect(() => {
    let formdisabled =
      props.formData.formMode === "Edit"
        ? checkFormDisabled(props, values) ||
          actionPermission(props.roles, 2, 1, 3)
        : actionPermission(props.roles, 2, 1, 3);
    if (formdisabled == true) {
      setDisabled(formdisabled);
    } else {
      setDisabled(false);
    }
  }, []);

  const makeFormEditable = () => {
    if (checkKycDisabled() && values.status != 5) {
      setDisabled(false);
      setFormInEditMode(true);
    }
  };

  const disabledEditForm = () => {
    setCloseAlertOpen(true);
  };

  // close alert box
  function EditCloseAlert() {
    const handleAlertClose = () => {
      setCloseAlertOpen(false);
    };

    const handleAlertModalClose = () => {
      setDisabled(true);
      setCloseAlertOpen(false);
      setFormInEditMode(false);
    };

    return (
      <Dialog
        open={closeAlterOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Close edit mode?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            App is in edit mode, do you want to close it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            No
          </Button>
          <Button onClick={handleAlertModalClose} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const makeFormEditablePermission = () => {
    let RoleProfile =
      props.mainData.roles.roleTab &&
      props.mainData.roles.roleTab.Roles_Data.Profile;
    if (RoleProfile == "SuperAdmin") {
      return true;
    } else return false;
  };

  const handleAddNew = () => {
    props.changeProviderFormMode("Create");
    handleInput({
      businessName: "",
      legalBusiness: "",
      businessType: "",
      businessWebsite: "",
      businessStartDate: "",
      docAdd: false,
      businessDiscrition: "",
      ownerShipType: "",
      businessPhoneNumber: "",
      businessEmail: "",
      businessTaxId: "",
      businessStock: "",
      businessAddress: "",
      businessSuite: "",
      businessCity: "",
      businessState: "",
      USID: "",
      businessPostalCode: "",
      businessInterNational: "",
      deleteCoperation: false,
      businessCountry: "US",
      accountNumber: "",
      routingNumber: "",
      accountName: "",
      accountType: "",
      cardPresent: "",
      masterContactName: "",
      foreignEntityNonresident: false,
      fax: "",
      mailingAddress: "",
      transmitCardholderOption: null,
      transmitCardholderName: "",
      seasonalSales: false,
      comments: "",
      locationAddress: "",
      discription: "",
      ownerArray: {
        owners: [
          {
            Owner_First_Name: "",
            Owner_Last_Name: "",
            Owner_Last4_SSN: "",
            ownerPhotoIdType: "",
            ownerAddressProofType: "",
            Owner_DOB: "",
            Owner_Ownership_Percentage: "",
            Owner_Title: "",
            Owner_Phone: "",
            Owner_Email: "",
            Owner_Middle_Name: "",
            Owner_Type: "",
            Owner_Postal_Code_Ext: "",
            Owner_Street_Address2: "",
            Owner_Street_Address: "",
            Owner_Suite: "",
            Owner_City: "",
            Owner_State_Code: "",
            Owner_Postal_Code: "",
            Owner_International_Province: "",
            Owner_Country_Code: "US",
            Owner_Timezone_Code: "IST",
            ownerAddressProof: false,
            ownerPhotoId: false,
            deleteOwnerPhotoId: false,
            deleteAddressProofId: false,
            ownerAddressProofDetails: {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: "",
              Doc_Type: "",
            },
            ownerPhotoIdDetails: {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: "",
              Doc_Type: "",
            },
          },
        ],
      },
      contactArray: [
        {
          type: "",
          title: "",
          firstName: "",
          middleName: "",
          lastName: "",
          phoneNumber: "",
          phoneExt: "",
          faxNumber: "",
          email: "",
        },
      ],
      cardNotPresent: "",
      accountNumber: "",
      routingNumber: "",
      accountType: "",
      eCommers: "",
      cardVolumeMonth: "",
      averageTicketSize: "",
      highTicket: "",
      ussidTypeInput: "",
      eCheckVolumeMonth: "",
      incorportionDocument: "",
      incroprationDocDelete: false,
      bankDocumentDelete: false,
      bankDocumentType: "",
      bankDocuments: "",
      incorportionDocumentDetails: {
        File_Name: "",
        File_Type: "",
        File_Path: "",
        Upload_Date_Time: "",
      },
      bankDocumentsDetails: {
        File_Name: "",
        File_Type: "",
        File_Path: "",
        Upload_Date_Time: "",
      },
      eCheckAverage: "",
      eCheckHigh: "",
      status: "",
      checkedB: "",
      checkedC: "",
      titleTermsCondition: "",
      nameTermsCondition: "",
      editable: false,
    });
    setVlidationError({
      businessName: false,
      legalBusiness: false,
      businessStartDate: false,
      businessType: false,
      businessWebsite: false,
      businessDiscrition: false,
      ownerShipType: false,
      businessPhoneNumber: false,
      businessEmail: false,
      businessTaxId: false,
      businessStock: false,
      businessAddress: false,
      businessSuite: false,
      businessCity: false,
      businessState: false,
      USID: false,
      incorportionDocument: false,
      businessPostalCode: false,
      businessInterNational: false,
      businessCountry: false,
      accountNumber: false,
      routingNumber: false,
      accountName: false,
      accountType: false,
      cardPresent: false,
      ownerArray: {
        owners: [
          {
            Owner_First_Name: false,
            Owner_Last_Name: false,
            Owner_Last4_SSN: false,
            Owner_DOB: false,
            Owner_Ownership_Percentage: false,
            Owner_Title: false,
            Owner_Phone: false,
            Owner_Email: false,
            Owner_Middle_Name: false,
            Owner_Type: false,
            Owner_Postal_Code_Ext: false,
            Owner_Street_Address2: false,
            Owner_Street_Address: false,
            Owner_Suite: false,
            Owner_City: false,
            Owner_State_Code: false,
            Owner_Postal_Code: false,
            Owner_International_Province: false,
            Owner_Country_Code: false,
            Owner_Timezone_Code: false,
            ownerAddressProof: false,
            ownerPhotoId: false,
            ownerAddressProofDetails: {
              File_Name: false,
              File_Type: false,
              Upload_Date_Time: false,
            },
            ownerPhotoIdDetails: {
              File_Name: false,
              File_Type: false,
              Upload_Date_Time: false,
            },
          },
        ],
      },
      cardNotPresent: false,
      eCommers: false,
      cardVolumeMonth: false,
      averageTicketSize: false,
      highTicket: false,
      ussidTypeInput: false,
      eCheckVolumeMonth: false,
      eCheckAverage: false,
      eCheckHigh: false,
      status: "",
      titleTermsCondition: false,
      nameTermsCondition: false,
      checkedB: true,
      checkedC: true,
      Owner_First_Name: true,
      contactArray: [
        {
          type: false,
          title: false,
          firstName: false,
          middleName: false,
          lastName: false,
          phoneNumber: false,
          phoneExt: false,
          faxNumber: false,
          email: false,
        },
      ],
    });
    setCheckC(false);
  };

  function formatPhoneNumber(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r.length > 6) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 3) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  const addContact = () => {
    if (values.contactArray.length < 5) {
      const contactData = { ...values };
      const validation = { ...validationError };
      contactData.contactArray.push({
        type: "",
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        phoneExt: "",
        faxNumber: "",
        email: "",
      });
      validation.contactArray.push({
        type: false,
        title: false,
        firstName: false,
        middleName: false,
        lastName: false,
        phoneNumber: false,
        phoneExt: false,
        faxNumber: false,
        email: false,
      });
      setVlidationError(validation);
      handleInput(contactData);
    }
  };

  const addOwner = () => {
    if (values.ownerArray.owners.length < 5) {
      const ownerData = { ...values };
      const validation = { ...validationError };
      values.ownerArray.owners.push({
        Owner_First_Name: "",
        Owner_Last_Name: "",
        Owner_Last4_SSN: "",
        Owner_DOB: "",
        Owner_Ownership_Percentage: "",
        Owner_Title: "",
        Owner_Phone: "",
        Owner_Email: "",
        Owner_Middle_Name: "",
        Owner_Type: "",
        Owner_Postal_Code_Ext: "",
        Owner_Street_Address2: "",
        Owner_Street_Address: "",
        Owner_Suite: "",
        Owner_City: "",
        Owner_State_Code: "",
        Owner_Postal_Code: "",
        Owner_International_Province: "",
        Owner_Country_Code: "USA",
        Owner_Timezone_Code: "IST",
        ownerAddressProof: false,
        ownerPhotoId: false,
        ownerAddressProofDetails: {
          File_Name: "",
          File_Type: "",
          Upload_Date_Time: "",
        },
        ownerPhotoIdDetails: {
          File_Name: "",
          File_Type: "",
          Upload_Date_Time: "",
        },
      });
      validation.ownerArray.owners.push({
        Owner_First_Name: false,
        Owner_Last_Name: false,
        Owner_Last4_SSN: false,
        Owner_DOB: false,
        Owner_Ownership_Percentage: false,
        Owner_Title: false,
        Owner_Phone: false,
        Owner_Email: false,
        Owner_Middle_Name: false,
        Owner_Type: false,
        Owner_Postal_Code_Ext: false,
        Owner_Street_Address2: false,
        Owner_Street_Address: false,
        Owner_Suite: false,
        Owner_City: false,
        Owner_State_Code: false,
        Owner_Postal_Code: false,
        Owner_International_Province: false,
        Owner_Country_Code: false,
        Owner_Timezone_Code: false,
        ownerAddressProof: false,
        ownerPhotoId: false,
        ownerAddressProofDetails: {
          File_Name: false,
          File_Type: false,
          Upload_Date_Time: false,
        },
        ownerPhotoIdDetails: {
          File_Name: false,
          File_Type: false,
          Upload_Date_Time: false,
        },
      });
      setVlidationError(validation);
      handleInput(ownerData);
    }
  };

  const handleAccordian = (i) => {
    const accordianValue = { ...accordian };
    if (accordianValue.index.length > 0) {
      const checkPreValue = accordianValue.index.filter((v) => v === i);
      if (checkPreValue.length > 0) {
        accordianValue.index = accordianValue.index.filter((v) => v !== i);
      } else {
        accordianValue.index.push(i);
      }
    } else {
      accordianValue.index.push(i);
    }
    handleAccordClick(accordianValue);
  };

  const handleCommentSection = (value, type) => {
    setSelectedQuestion(value);
    if (value.comment) {
      setTextAreaText(value.comment);
    } else {
      setTextAreaText("");
    }
    setCommentSection(true);
  };

  function formatSocialSecurity(v) {
    var r = v.replace(/\D/g, "");
    if (r.length > 9) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    } else if (r.length > 4) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
    // // let val;
    // val = val.replace(/\D/g, "");
    // val = val.replace(/^(\d{3})/, "$1-");
    // val = val.replace(/-(\d{2})/, "-$1-");
    // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    // return val;
  }

  const ownerOnchange = (index, data, type, e) => {
    const error = { ...validationError };
    const ownerData = { ...values };
    if (type === "ownerPhotoIdType") {
      error.ownerArray.owners[index].ownerPhotoId = false;
      ownerData.ownerArray.owners[index][type] = data;
      ownerData.ownerArray.owners[index].ownerPhotoIdDetails.Doc_Type = data;
    } else if (type === "ownerAddressProofType") {
      error.ownerArray.owners[index].ownerPhotoId = false;
      ownerData.ownerArray.owners[index][type] = data;
      ownerData.ownerArray.owners[index].ownerAddressProofDetails.Doc_Type =
        data;
    }
    if (type === "Owner_Last4_SSN") {
      if (
        e.nativeEvent.inputType === "deleteContentBackward" ||
        e.nativeEvent.inputType === "deleteContentForward"
      ) {
        ownerData.ownerArray.owners[index][type] = "";
        error.ownerArray.owners[index][type] = true;
      } else if (ownerData.ownerArray.owners[index][type].length !== 10) {
        ownerData.ownerArray.owners[index][type] = formatSocialSecurity(data);
        error.ownerArray.owners[index][type] = true;
      } else {
        ownerData.ownerArray.owners[index][type] = formatSocialSecurity(data);
        error.ownerArray.owners[index][type] = false;
      }
    } else if (type === "Owner_Phone") {
      error.ownerArray.owners[index][type] = false;
      ownerData.ownerArray.owners[index][type] = formatPhoneNumber(data);
    } else if (type === "Owner_Postal_Code") {
      if (!/^[0-9]{5}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type === "Owner_Postal_Code_Ext") {
      if (!/^[0-9]{4}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type === "Owner_Email") {
      const res = data.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      );
      if (res === null) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else {
      error.ownerArray.owners[index][type] = false;
      ownerData.ownerArray.owners[index][type] = data;
    }
    if (type === "Owner_DOB") {
      if (data) {
        var eighteenYearsAgo = moment().subtract(18, "years");
        var birthday = moment(data);
        if (moment(eighteenYearsAgo).isBefore(birthday)) {
          error.ownerArray.owners[index][type] = true;
          ownerData.ownerArray.owners[index][type] = data;
        }
        if (moment(eighteenYearsAgo).isAfter(birthday)) {
          error.ownerArray.owners[index][type] = false;
          ownerData.ownerArray.owners[index][type] = data;
        }
      }
    }
    if (data === "") {
      error.ownerArray.owners[index][type] = false;
    }
    handleInput(ownerData);
    setVlidationError(error);
  };

  const contactOnchange = (index, data, type) => {
    const error = { ...validationError };
    const contactData = { ...values };
    error.contactArray[index][type] = false;
    contactData.contactArray[index][type] = data;
    handleInput(contactData);
    setVlidationError(error);
  };

  const ProviderStatus =
    props.formData.formMode === "Create"
      ? "New Entry"
      : props.formData.statusList &&
        props.formData.statusList.Provider_Status_Data.filter(
          (key) =>
            key.Status_Code ===
            (props.formData.formMode === "Create"
              ? 1
              : props.formData.providerDetailsById &&
                props.formData.providerDetailsById?.Providers_Data[0]
                  ?.Current_Status)
        ).length > 0 &&
        props.formData.statusList.Provider_Status_Data.filter(
          (key) =>
            key.Status_Code ===
            (props.formData.formMode === "Create"
              ? 1
              : props.formData.providerDetailsById &&
                props.formData.providerDetailsById?.Providers_Data[0]
                  ?.Current_Status)
        )[0].Provider_Status;

  const ProviderCode =
    props.formData.formMode === "Create"
      ? 1
      : props.formData.statusList &&
        props.formData.statusList.Provider_Status_Data.filter(
          (key) =>
            key.Status_Code ===
            (props.formData.formMode === "Create"
              ? 1
              : props.formData.providerDetailsById &&
                props.formData.providerDetailsById?.Providers_Data[0]
                  ?.Current_Status)
        ).length > 0 &&
        props.formData.statusList.Provider_Status_Data.filter(
          (key) =>
            key.Status_Code ===
            (props.formData.formMode === "Create"
              ? 1
              : props.formData.providerDetailsById &&
                props.formData.providerDetailsById?.Providers_Data[0]
                  ?.Current_Status)
        )[0].Status_Code;

  const declinedApp = ProviderCode === 5 ? true : false;
  const liveApp = ProviderCode === 9 ? true : false;
  const sentToMerchantApp = ProviderCode === 10 ? true : false;

  const kycResponseViewButton =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById?.Providers_Data[0]?.kycResponseViewButton
      ? true
      : false;

  if (props.mainData.roles.roleTab) {
    const filterSetUp =
      props.mainData.roles.roleTab.Roles_Data.Master_Permission.filter(
        (key) => key.catCode === 2
      );
    const filterPageMenu =
      filterSetUp.length > 0 &&
      filterSetUp[0].Page_Menu.filter((key) => key.Page_Code === 1);
    const kycIndex = filterPageMenu[0]?.Options.findIndex(
      (x) => x.value === permissionCheck.option.allowToChecklist
    );

    const changeStatusIndex = filterPageMenu[0]?.Options.findIndex(
      (x) => x.value === permissionCheck.option.allowToChangeStatus
    );

    const editIndex = filterPageMenu[0]?.Options.findIndex(
      (x) => x.value === 3
    );

    const liveIndex = filterPageMenu[0]?.Options.findIndex(
      (x) => x.value === 7
    );

    const boardIndex = filterPageMenu[0]?.Options.findIndex(
      (x) => x.value === 5
    );

    const createIndex = filterPageMenu[0]?.Options.findIndex(
      (x) => x.value === 2
    );

    allowToBoardFlag =
      boardIndex !== -1
        ? filterPageMenu[0]?.Options[boardIndex].permission
        : false;

    kycCheckListFlag =
      kycIndex !== -1 ? filterPageMenu[0]?.Options[kycIndex].permission : false;

    editPermissionFlag =
      editIndex !== -1
        ? filterPageMenu[0]?.Options[editIndex].permission
        : false;

    createPermissionFlag =
      createIndex !== -1
        ? filterPageMenu[0]?.Options[createIndex].permission
        : false;

    livePermissionFlag =
      liveIndex !== -1
        ? filterPageMenu[0]?.Options[liveIndex].permission
        : false;

    changeStatusPermissionFlag =
      changeStatusIndex !== -1
        ? filterPageMenu[0]?.Options[changeStatusIndex].permission
        : false;
  }

  const onIdle = () => {
    if (checkValidation(true, true) && ProviderStatus === "New Entry") {
      if (props.formData.formMode === "Create") {
        props.changeProviderFormMode("Edit");
        setSaveDraftClick(true);
      }
      onFormSubbmit();
    }
  };

  const deleteContact = (index) => {
    const initialValue = [];
    const initialValidateValue = [];
    const value = { ...values };
    const ValidateErrorDelete = { ...validationError };

    if (value.contactArray.length > 1) {
      value.contactArray.forEach((v, i) => {
        if (i !== index) {
          initialValue.push(v);
        }
      });
      if (ValidateErrorDelete.contactArray.length > 1) {
        ValidateErrorDelete.contactArray.forEach((v, i) => {
          if (i !== index) {
            initialValidateValue.push(v);
          }
        });
        ValidateErrorDelete.contactArray = initialValidateValue;
        value.contactArray = initialValue;
      }

      handleInput(value);
      setVlidationError(ValidateErrorDelete);
    }
  };

  const deleteOwner = () => {
    const initialValue = {
      owners: [],
    };
    const initialValidateValue = {
      owners: [],
    };
    const value = { ...values };
    const ValidateErrorDelete = { ...validationError };
    if (value.ownerArray.owners.length > 1) {
      value.ownerArray.owners.forEach((v, i) => {
        if (i !== ownerDeleteModal) {
          initialValue.owners.push(v);
        }
      });
      if (ValidateErrorDelete.ownerArray.owners.length > 1) {
        ValidateErrorDelete.ownerArray.owners.forEach((v, i) => {
          if (i !== ownerDeleteModal) {
            initialValidateValue.owners.push(v);
          }
        });
        ValidateErrorDelete.ownerArray = initialValidateValue;
        value.ownerArray = initialValue;
      }
      if (values.ownerArray.owners[ownerDeleteModal].Owner_ID !== undefined) {
        const deleteOwnerRequest = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
          ownerId: values.ownerArray.owners[ownerDeleteModal].Owner_ID,
        };
        props.deleteOwnerApiCall(
          deleteOwnerRequest,
          (data) => {
            enqueueSnackbar(data.message, {
              variant: "success",
            });
            props.getProviderDetailsById(
              props.formData.providerDetailsById.Providers_Data[0]._id
            );
          },
          (err) => {
            enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
              variant: "error",
            });
          }
        );
      }
      setOwnerDeleteModal(false);
      handleInput(value);
      setVlidationError(ValidateErrorDelete);
    }
  };

  const approveYesClick = () => {
    if (approveModal && checkListModalType === "MPA") {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Approve",
      };
      props.statusApproveMpa(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success",
          });
          const dataQuestion = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
          };
          props.getQuestionData(dataQuestion);
          setCheckListModalType("");
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error",
          });
        }
      );
      setApproveModal(false);
      setKycValidationError(false);
    }
    if (approveModal && checkListModalType === "KYC") {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Approve",
      };
      props.statusApproveKyc(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success",
          });
          const dataaa = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            ownerId: ownerSelect.Owner_ID,
          };
          props.getKycQuestionData(dataaa);
          setCheckListModalType("");
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error",
          });
        }
      );
      setApproveModal(false);
      setKycValidationError(false);
    }
  };

  const checkDesiredAnswer = (type) => {
    const correctAnsArray = [];
    const wrongAnsArray = [];
    const quesData =
      type == "MPA"
        ? props.formData.questionData.data
        : props.formData.kycQuestionData.data;
    quesData.forEach((item) => {
      const checkMandatoryQues = item.list.filter(
        (ques) => ques.isMandatory == true
      );
      checkMandatoryQues.forEach((listItem) => {
        const desiredResponse = listItem.desiredResponse;
        const options = listItem.options;
        const selectedIndex = options.findIndex(
          (option) => option.optionSelected
        );
        if (desiredResponse >= 0 && desiredResponse == selectedIndex) {
          correctAnsArray.push(true);
        } else if (desiredResponse == -1 && selectedIndex >= 0) {
          correctAnsArray.push(true);
        } else {
          wrongAnsArray.push(false);
        }
      });
    });
    if (wrongAnsArray.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  // desired ans bg colors fnc starts here

  const desiredAnsBgColor = (item) => {
    let result = "";
    const desiredResponse = item.desiredResponse;
    const isMandatory = item.isMandatory;
    const option1 = item.options[0].optionSelected;
    const option2 = item.options[1].optionSelected;
    const selectedIndex = item.options.findIndex(
      (option) => option.optionSelected
    );
    const correctAnsSelected = selectedIndex == desiredResponse ? true : false;
    // if (isMandatory) {
    if (desiredResponse >= 0) {
      if (!option1 && !option2) {
        result = classes.NoAnsStyle;
      } else if (option1 && !option2 && correctAnsSelected) {
        result = classes.rightAnsStyle;
      } else if (option2 && !option1 && correctAnsSelected) {
        result = classes.rightAnsStyle;
      } else if (option1 && !option2 && !correctAnsSelected) {
        result = classes.wrongAnsStyle;
      } else if (option2 && !option1 && !correctAnsSelected) {
        result = classes.wrongAnsStyle;
      }
      return result;
    } else {
      if (!correctAnsSelected) {
        result = classes.rightAnsStyle;
      } else {
        result = classes.NoAnsStyle;
      }
      return result;
    }
  };
  // else {
  //   return classes.NoAnsStyle;
  // }
  // };

  // desired ans bg colors fnc ends here

  // kyc disabled after form aproval

  const checkKycDisabled = () => {
    let disablekyc = false;
    const kycDisabledArr = [5, 9, 4, 7, 6];
    if (kycDisabledArr.includes(values.status)) {
      disablekyc = true;
    } else {
      disablekyc = false;
    }
    return disablekyc;
  };

  const checkKycFormDisabled = () => {
    let disablekyc = false;
    const kycDisabledArr = [5, 9, 4, 7, 6];
    if (!formInEditMode) {
      if (kycDisabledArr.includes(values.status)) {
        disablekyc = true;
      } else {
        disablekyc = false;
      }
    } else {
      disablekyc = false;
    }
    return disablekyc;
  };

  const handleApproveClick = (type) => {
    const isDesiredAnswerSelected = checkDesiredAnswer(type) ? true : false;
    if (isDesiredAnswerSelected) {
      setKycValidationError(false);
      setApproveModal(true);
      setCheckListModalType(type);
    } else {
      setApproveModal(true);
      setKycValidationError(true);
    }
  };

  const declineYesClick = () => {
    if (declineModal && checkListModalType === "MPA") {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Reject",
      };
      props.statusApproveMpa(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success",
          });
          const dataQuestion = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
          };
          props.getQuestionData(dataQuestion);
          setCheckListModalType("");
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error",
          });
        }
      );
      setDeclineModal(false);
    }
    if (declineModal && checkListModalType === "KYC") {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Reject",
      };
      props.statusApproveKyc(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success",
          });
          const dataaa = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            ownerId: ownerSelect.Owner_ID,
          };
          props.getKycQuestionData(dataaa);
          setCheckListModalType("");
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error",
          });
        }
      );
      setDeclineModal(false);
    }
  };

  const commonCallForKyc = (values, item, id, type, i) => {
    const temp = { ...props.formData.kycQuestionData };
    let tempValues = {};
    temp.data[i.i].list[i.index].subQues.map((x) => {
      tempValues[x.fieldKey] = x.value;
    });
    tempValues = { ...tempValues, ...values };
    handleQuestionStatus(item, id, type, i, tempValues);
  };

  const updateKycSubQuestions = (values) => {
    let payload = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
      ownerId: ownerSelect.Owner_ID,
      quesId: values.quesId,
      subQues: [],
    };
    Object.entries(values).map((pair) => {
      if (
        pair[0] !== "quesId" &&
        pair[0] !== "parentIndex" &&
        pair[0] !== "currentIndex"
      ) {
        let subQus = props.formData.kycQuestionData?.data[
          values.parentIndex
        ]?.list[values.currentIndex]?.subQues.find(
          (x) => x.fieldKey === pair[0]
        );
        let x = { subQusId: subQus.subQusId, answer: pair[1] };
        payload.subQues.push(x);
      }
    });
    props.postKycSubQuestions(
      { ...payload },
      (data) => {
        const dataaa = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
          ownerId: ownerSelect.Owner_ID,
        };
        props.getKycQuestionData(dataaa);
        enqueueSnackbar(data.message, {
          variant: "success",
        });
      },
      (err) => {
        enqueueSnackbar(
          err.message ? err.message : "Failed to Update Kyc Checklist",
          {
            variant: "error",
          }
        );
      }
    );
  };
  const handleCheckListModalClose = (type) => {
    if (type === "kyc") {
      setKycListModal(false);
      handleAccordClick({ index: [] });
    }
    if (type === "mpa") {
      setCheckListModal(false);
      handleAccordClick({ index: [] });
    }
  };

  const handleBack = (dispatch) => {
    props.clearRegData();
    props.history.push(`/${props.mainData.app.layout}/Merchant`);
    props.setResetWuestion();
    props.getProviderStatus();
  };

  function convertUTCDateToLocalDate(date) {
    return [
      String(101 + date.getMonth()).substring(1),
      String(100 + date.getDate()).substring(1),
      String(date.getFullYear()),
    ].join("/");
  }

  function convertformat(date) {
    if (!!date) {
      let newDate = date.split("T")[0];

      newDate = newDate.split("-");
      let month = newDate.splice(1, 1);
      newDate.push(month);
      newDate = newDate.reverse("").join("/");
      return newDate;
    }
  }

  if (!data && props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    const allState = { ...values };
    const statusId = {
      statusCode: preData.Current_Status,
    };
    props.getProviderStatus(statusId);
    allState.status = preData.Current_Status;
    allState.editable = preData.Edit_Status;
    allState.businessName = preData.Business_Name;
    allState.businessType = preData.businessType;
    allState.legalBusiness = preData.Legal_Name;
    allState.businessStartDate =
      preData.Business_Start_Date &&
      preData.Business_Start_Date !== "Invalid date"
        ? convertformat(preData.Business_Start_Date)
        : null;
    allState.businessDiscrition = preData.Business_Description;
    allState.ownerShipType = preData.Ownership_Type;
    allState.businessPhoneNumber = preData.Business_Phone
      ? formatPhoneNumber(preData.Business_Phone)
      : "";
    allState.businessEmail = preData.Business_EMail;
    allState.businessWebsite = preData.Website;
    allState.businessTaxId = preData.Fedral_Tax_ID;
    allState.businessStock = preData.Stock_Symbol;
    allState.businessAddress = preData.Business_Address;
    allState.businessSuite = preData.Suite;
    allState.businessCity = preData.City;
    allState.businessState = preData.State_Code;
    allState.USID = preData.MCC;
    allState.businessPostalCode = preData.Postal_Code;
    allState.businessInterNational = preData.International_Province;
    allState.businessCountry = preData.Country_Code
      ? preData.Country_Code
      : "US";
    allState.accountNumber = preData.Sattlement_Account?.Account_Number;
    allState.routingNumber = preData.Sattlement_Account?.Routing_Number;
    allState.accountName = preData.Sattlement_Account?.Name_On_Account;
    allState.accountType = preData.Sattlement_Account?.Account_Type;
    allState.cardPresent = preData.Processing_Method?.Card_Swiped;
    allState.cardNotPresent = preData.Processing_Method?.Card_Not_Present;
    allState.eCommers = preData.Processing_Method?.ECommerce;
    allState.cardVolumeMonth = preData.Processing_Method?.Monthly_Card_Volume;
    allState.averageTicketSize = preData.Processing_Method?.Average_Ticket_Size;
    allState.highTicket = preData.Processing_Method?.High_Ticket;
    allState.ussidTypeInput = "";
    allState.eCheckVolumeMonth = preData.Processing_Method?.Monthly_ACH_Volume;
    allState.eCheckAverage = preData.Processing_Method?.Average_ACH;
    allState.eCheckHigh = preData.Processing_Method?.High_ACH;
    allState.masterContactName = preData.masterContactName;
    allState.foreignEntityNonresident = preData.foreignEntityNonresident;
    allState.fax = preData.fax;
    allState.mailingAddress = preData.mailingAddress;
    allState.transmitCardholderOption = preData.transmitCardholderOption;
    allState.transmitCardholderName = preData.transmitCardholderName;
    allState.seasonalSales = preData.seasonalSales;
    allState.comments = preData.comments;
    allState.locationAddress = preData.locationAddress;
    allState.titleTermsCondition = preData.titleTermsCondition;
    allState.nameTermsCondition = preData.nameTermsCondition;
    allState.incorportionDocumentDetails =
      preData.incorportionDocument === ""
        ? {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: "",
          }
        : preData.incorportionDocument;
    allState.bankDocumentsDetails =
      preData.bankDocument === ""
        ? {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: "",
          }
        : preData.bankDocument;
    allState.bankDocuments = preData.bankDocument
      ? preData.bankDocument.File_Name
      : "";
    allState.docAdd = preData.incorportionDocument !== "";
    allState.bankDocumentType =
      preData.bankDocument === "" ? "" : preData.bankDocumentType;
    allState.incorportionDocument = preData.incorportionDocument
      ? preData.incorportionDocument.File_Name
      : "";
    const ownerData = preData.Owner_Information;
    const newContact = [
      {
        type: "",
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        phoneExt: "",
        faxNumber: "",
        email: "",
      },
    ];
    const contactData = isEmpty(preData.contactArray)
      ? newContact
      : preData.contactArray;
    const preDataArray = [];
    const preDataArrayContact = [];
    const validationAddToOwner = { ...validationError };
    contactData.forEach((key, i) => {
      if (i > 0) {
        validationAddToOwner.contactArray.push({
          type: false,
          title: false,
          firstName: false,
          middleName: false,
          lastName: false,
          phoneNumber: false,
          phoneExt: false,
          faxNumber: false,
          email: false,
        });
      }
      preDataArrayContact.push({ ...key });
    });
    ownerData.forEach((key, i) => {
      if (i > 0) {
        validationAddToOwner.ownerArray.owners.push({
          Owner_First_Name: false,
          Owner_Last_Name: false,
          Owner_Last4_SSN: false,
          Owner_DOB: false,
          Owner_Ownership_Percentage: false,
          Owner_Title: false,
          Owner_Phone: false,
          Owner_Email: false,
          Owner_Middle_Name: false,
          Owner_Type: false,
          Owner_Postal_Code_Ext: false,
          Owner_Street_Address2: false,
          Owner_Street_Address: false,
          Owner_Suite: false,
          Owner_City: false,
          Owner_State_Code: false,
          Owner_Postal_Code: false,
          Owner_International_Province: false,
          Owner_Country_Code: false,
          Owner_Timezone_Code: false,
          ownerAddressProof: false,
          ownerPhotoId: false,
          ownerAddressProofDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false,
          },
          ownerPhotoIdDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false,
          },
        });
      }
      const obj = { ...key };
      obj.ownerAddressProofDetails =
        key.ownerAddressProof === ""
          ? {
              Doc_Type: "",
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: "",
            }
          : {
              Doc_Type: key.ownerAddressProof.Doc_Type,
              File_Type: key.ownerAddressProof.File_Type,
              File_Name: key.ownerAddressProof.File_Name,
              File_Path: key.ownerAddressProof.File_Path,
              Upload_Date_Time: key.ownerAddressProof.Upload_Date_Time,
            };
      obj.ownerAddressProofType =
        key.ownerAddressProof === "" ? "" : key.ownerAddressProof.Doc_Type;
      obj.ownerPhotoIdType =
        key.ownerPhotoId === "" ? "" : key.ownerPhotoId.Doc_Type;
      obj.ownerPhotoIdDetails =
        key.ownerPhotoId === ""
          ? {
              Doc_Type: "",
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: "",
            }
          : {
              Doc_Type: key.ownerPhotoId.Doc_Type,
              File_Type: key.ownerPhotoId.File_Type,
              File_Name: key.ownerPhotoId.File_Name,
              File_Path: key.ownerPhotoId.File_Path,
              Upload_Date_Time: key.ownerPhotoId.Upload_Date_Time,
            };
      obj.ownerAddressProof = key.ownerAddressProof.File_Path;
      obj.ownerPhotoId = key.ownerPhotoId.File_Path;
      obj.Owner_Country_Code = key.Owner_Country_Code
        ? key.Owner_Country_Code
        : "US";
      obj.Owner_DOB = convertformat(key.Owner_DOB);
      obj.Owner_Phone = key.Owner_Phone
        ? formatPhoneNumber(key.Owner_Phone)
        : "";
      preDataArray.push(obj);
    });
    allState.ownerArray.owners = preDataArray;
    allState.contactArray = [...preDataArrayContact];
    handleInput(allState);
    const providerName = {
      providerId:
        props.formData.providerDetailsById &&
        props.formData.providerDetailsById.Providers_Data[0]._id,
    };
    props.statusPreameter(
      providerName,
      (data) => {
        data.status && setMpaCheckListStatus(data.data);
      },
      (err) => {
        enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          variant: "error",
        });
      }
    );
    props.statusPreameterKyc(
      { ...providerName, ownerId: allState.ownerArray.owners[0].Owner_ID },
      (data) => {
        data.status && setKycCheckListStatus(data.data);
      },
      (err) => {
        enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          variant: "error",
        });
      }
    );
    setData(true);
  }

  const saveStatus = () => {
    const data = {
      id: props.formData.providerDetailsById.Providers_Data[0]._id,
      status: values.status,
    };
    props.changeSingleStatus(data);
    setChangeStatusValue(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUploadOnChange = (files, type, ownerType, index) => {
    const allState = { ...values };
    if (type === "main") {
      if (files) {
        // let uploadDate = currentDate.getFullYear() + '-' + (currentDate.getMonth()+1) + '-' + currentDate.getDate() +' '+ currentDate.getHours()+':'+ currentDate.getMinutes()+':'+ currentDate.getSeconds();
        allState.incorportionDocumentDetails.File_Name = files.name;
        allState.incorportionDocumentDetails.File_Path = files.path;
        allState.incorportionDocumentDetails.File_Type =
          files.name.split(".")[files.name.split(".").length - 1];
        allState.incroprationDocDelete = false;
        allState.incorportionDocumentDetails.Upload_Date_Time = moment();
        const reader = new FileReader();
        reader.readAsDataURL(files);
        if (files.size / 1024 / 1024 < 25) {
          reader.onload = function () {
            allState.incorportionDocument = reader.result;
            allState.docAdd = true;
            handleInput(allState);
          };
          reader.onerror = function (error) {};
        } else {
          enqueueSnackbar("Max file size can be upto 25 MB", {
            variant: "error",
          });
        }
      }
    }
    if (type === "bankDocuments") {
      if (files) {
        // let uploadDate = currentDate.getFullYear() + '-' + (currentDate.getMonth()+1) + '-' + currentDate.getDate() +' '+ currentDate.getHours()+':'+ currentDate.getMinutes()+':'+ currentDate.getSeconds();
        allState.bankDocumentsDetails.File_Name = files.name;
        allState.bankDocumentsDetails.File_Path = files.path;
        allState.bankDocumentDelete = false;
        allState.bankDocumentsDetails.File_Type =
          files.name.split(".")[files.name.split(".").length - 1];
        allState.bankDocumentsDetails.Upload_Date_Time = moment();
        const reader = new FileReader();
        reader.readAsDataURL(files);
        if (files.size / 1024 / 1024 < 25) {
          reader.onload = function () {
            allState.bankDocuments = reader.result;
            allState.docAdd = true;
            handleInput(allState);
          };
          reader.onerror = function (error) {};
        } else {
          enqueueSnackbar("Max file size can be upto 25 MB", {
            variant: "error",
          });
        }
      }
    }
    if (type === "owner") {
      if (files) {
        if (ownerType === "ownerAddressProof") {
          allState.ownerArray.owners[index].ownerAddressProofDetails.File_Name =
            files.name;
          allState.ownerArray.owners[index].ownerAddressProofDetails.Doc_Type =
            allState.ownerArray.owners[index].ownerAddressProofDetails.Doc_Type;
          allState.ownerArray.owners[index].ownerAddressProofDetails.File_Type =
            files.name.split(".")[files.name.split(".").length - 1];
          allState.ownerArray.owners[
            index
          ].ownerAddressProofDetails.Upload_Date_Time = moment();
        }
        if (ownerType === "ownerPhotoId") {
          allState.ownerArray.owners[index].ownerPhotoIdDetails.File_Name =
            files.name;
          allState.ownerArray.owners[index].ownerPhotoIdDetails.Doc_Type =
            allState.ownerArray.owners[index].ownerPhotoIdDetails.Doc_Type;
          allState.ownerArray.owners[index].ownerPhotoIdDetails.File_Type =
            files.name.split(".")[files.name.split(".").length - 1];
          allState.ownerArray.owners[
            index
          ].ownerPhotoIdDetails.Upload_Date_Time = moment();
        }
        const reader = new FileReader();
        reader.readAsDataURL(files);
        if (files.size / 1024 / 1024 < 25) {
          reader.onload = function () {
            allState.ownerArray.owners[index][ownerType] = reader.result;
            allState.docAdd = true;
            handleInput(allState);
          };
          reader.onerror = function (error) {};
        } else {
          enqueueSnackbar("Max file size can be upto 25 MB", {
            variant: "error",
          });
        }
      }
    }
  };

  const handleUploadAttechmentOnChange = (files) => {
    if (files) {
      // let uploadDate = currentDate.getFullYear() + '-' + (currentDate.getMonth()+1) + '-' + currentDate.getDate() +' '+ currentDate.getHours()+':'+ currentDate.getMinutes()+':'+ currentDate.getSeconds();
      const preFileValues = { ...fileHandleAttechment };
      const fileValues = {
        file: "",
        File_Name: "",
        File_Path: "",
        File_Type: "",
        Upload_Date_Time: "",
      };
      fileValues.File_Name = files.name;
      fileValues.File_Path = files.path;
      fileValues.File_Type =
        files.name.split(".")[files.name.split(".").length - 1];
      fileValues.Upload_Date_Time = moment();
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function () {
        fileValues.file = reader.result;
        preFileValues.files.push(fileValues);
        setFileHandleAttechment(preFileValues);
        if (kycListModal) {
          const kycAttechmentData = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            quesId: selectedQuestion._id,
            attachment: reader.result,
          };
          props.kycChecklistAttechment(
            kycAttechmentData,
            (data) => {
              enqueueSnackbar(data.message, {
                variant: "success",
              });
            },
            (err) => {
              enqueueSnackbar(
                err.message ? err.message : "Failed to get Data",
                {
                  variant: "error",
                }
              );
            }
          );
        }
        if (checkListModal) {
          const kycAttechmentData = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            quesId: selectedQuestion._id,
            attachment: reader.result,
          };
          props.mpaChecklistAttechment(
            kycAttechmentData,
            (data) => {
              enqueueSnackbar(data.message, {
                variant: "success",
              });
            },
            (err) => {
              enqueueSnackbar(
                err.message ? err.message : "Failed to get Data",
                {
                  variant: "error",
                }
              );
            }
          );
        }
      };
      reader.onerror = function (error) {};
    }
  };

  const sendComment = () => {
    const requestData = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
      quesId: selectedQuestion._id,
      comment: textAreaText,
      checklistName: kycListModal ? "KYCChecklist" : "MPAChecklist",
    };
    props.mpaChecklistComment(
      requestData,
      (data) => {
        const dataQuestion = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        };
        props.getQuestionData(dataQuestion);
        props.getKycQuestionData({
          ...dataQuestion,
          ownerId: ownerSelect.Owner_ID,
        });
        enqueueSnackbar(data.message, {
          variant: "success",
        });
      },
      (err) => {
        enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          variant: "error",
        });
      }
    );
  };

  const onFormPreviewSubmit = async () => {
    setDisabled(true);
    setSaveDraftClick(false);
    handleClose();
    setValidationModal(false);
    if (checkValidation()) {
      if (props.formData.formMode === "Create") {
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        const arrayNew = [];
        values.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          arrayNew.push(obj);
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = true;
        props.getProviderStatus();
        props.provideRegistration({
          ...requestData,
          ipAddress: IP,
          gasPosTerm: checkC,
        });
        handlelistBack();
      }
      if (props.formData.formMode === "Edit" && isEmpty(errorMessage)) {
        const editFormArr = [9, 4, 7, 6];
        let PdfDetails;
        if (editFormArr.includes(values.status)) {
          try {
            PdfDetails = await generatePDF();
          } catch (e) {
            console.log(e);
          }
        }
        const requestData = { ...values };
        if (PdfDetails) {
          requestData.PdfDetails = PdfDetails;
        }

        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        const arrayNew = [];
        values.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          arrayNew.push(obj);
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = true;
        const tCM = props.formData.providerDetailsById.Providers_Data[0]
          .gasPosTerm
          ? true
          : checkC;
        const providerDatabyId =
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0];
        const termAndConditions = {
          docCreatedDate: providerDatabyId.termAndConditions.docCreatedDate
            ? providerDatabyId.termAndConditions.docCreatedDate
            : signedDocData.docCreatedDate,
          docEmailId: providerDatabyId.termAndConditions.docEmailId
            ? providerDatabyId.termAndConditions.docEmailId
            : signedDocData.docEmailId,
          docInviteId: providerDatabyId.termAndConditions.docInviteId
            ? providerDatabyId.termAndConditions.docInviteId
            : signedDocData.docInviteId,
          docRoleId: providerDatabyId.termAndConditions.docRoleId
            ? providerDatabyId.termAndConditions.docRoleId
            : signedDocData.docRoleId,
          docSignDate: providerDatabyId.termAndConditions.docSignDate
            ? providerDatabyId.termAndConditions.docSignDate
            : signedDocData.docSignDate,
          docStatus:
            providerDatabyId.termAndConditions.docStatus == "Signed"
              ? providerDatabyId.termAndConditions.docStatus
              : signedDocData.docStatus,
          documentId: providerDatabyId.termAndConditions.documentId
            ? providerDatabyId.termAndConditions.documentId
            : signedDocData.documentId,
          documentName: providerDatabyId.termAndConditions.documentName
            ? providerDatabyId.termAndConditions.documentName
            : signedDocData.documentName,
          name: requestData.nameTermsCondition,
          templateId: providerDatabyId.termAndConditions.templateId
            ? providerDatabyId.termAndConditions.templateId
            : signedDocData.templateId,
          title: requestData.titleTermsCondition,
          discription: requestData.discription,
        };
        props.getProviderStatus();
        props.updateRegistration(
          {
            ...requestData,
            ipAddress: IP,
            gasPosTerm: tCM,
            termAndConditions: termAndConditions,
          },
          providerDatabyId._id
        );
        handleDecriptionModalClose();
        handlelistBack();
        setFormInEditMode(false);
      }
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const activity = () => {
    props.history.push(`/${props.mainData.app.layout}/merchantactivity`);
  };

  const onFormSubbmit = () => {
    setSaveDraftClick(true);
    handleClose();
    setValidationModal(false);
    if (checkValidation(true)) {
      if (props.formData.formMode === "Create") {
        const arrayNew = [];
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        requestData.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          if (!obj.ownerAddressProof) {
            obj.ownerAddressProof = "";
          }
          if (!obj.ownerPhotoId) {
            obj.ownerPhotoId = "";
          }
          arrayNew.push(obj);
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = false;
        requestData.nameTermsCondition = "";
        requestData.titleTermsCondition = "";
        props.getProviderStatus();
        props.provideRegistration(requestData);
      }
      if (props.formData.formMode === "Edit") {
        const arrayNew = [];
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        requestData.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          if (!obj.ownerAddressProof) {
            obj.ownerAddressProof = "";
          }
          if (!obj.ownerPhotoId) {
            obj.ownerPhotoId = "";
          }
          arrayNew.push(obj);
        });
        const providerDatabyId =
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0];
        const termAndConditions = {
          docCreatedDate: providerDatabyId.termAndConditions.docCreatedDate
            ? providerDatabyId.termAndConditions.docCreatedDate
            : signedDocData.docCreatedDate,
          docEmailId: providerDatabyId.termAndConditions.docEmailId
            ? providerDatabyId.termAndConditions.docEmailId
            : signedDocData.docEmailId,
          docInviteId: providerDatabyId.termAndConditions.docInviteId
            ? providerDatabyId.termAndConditions.docInviteId
            : signedDocData.docInviteId,
          docRoleId: providerDatabyId.termAndConditions.docRoleId
            ? providerDatabyId.termAndConditions.docRoleId
            : signedDocData.docRoleId,
          docSignDate: providerDatabyId.termAndConditions.docSignDate
            ? providerDatabyId.termAndConditions.docSignDate
            : signedDocData.docSignDate,
          docStatus:
            providerDatabyId.termAndConditions.docStatus == "Signed"
              ? providerDatabyId.termAndConditions.docStatus
              : signedDocData.docStatus,
          documentId: providerDatabyId.termAndConditions.documentId
            ? providerDatabyId.termAndConditions.documentId
            : signedDocData.documentId,
          documentName: providerDatabyId.termAndConditions.documentName
            ? providerDatabyId.termAndConditions.documentName
            : signedDocData.documentName,
          name: requestData.nameTermsCondition,
          templateId: providerDatabyId.termAndConditions.templateId
            ? providerDatabyId.termAndConditions.templateId
            : signedDocData.templateId,
          title: requestData.titleTermsCondition,
        };
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = false;
        props.getProviderStatus();
        props.updateRegistration(
          { ...requestData, termAndConditions: termAndConditions },
          props.formData.providerDetailsById.Providers_Data[0]._id
        );

        // if (
        //   requestData.incorportionDocument === "" &&
        //   values.incroprationDocDelete
        // ) {
        //   const data = {
        //     providerId:
        //       props.formData.providerDetailsById.Providers_Data[0]._id,
        //     document:
        //       props.formData.providerDetailsById.Providers_Data[0]
        //         .incorportionDocument
        //   };
        //   props.deleteIncroptration(data);
        // }
        if (requestData.bankDocuments === "" && values.bankDocumentDelete) {
          if (
            props.formData.providerDetailsById.Providers_Data[0].bankDocument
              .File_Name
          ) {
            const data = {
              providerId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              document:
                props.formData.providerDetailsById.Providers_Data[0]
                  .bankDocument.File_Name,
            };
            props.deleteBankDocumentApiCall(
              data,
              (data) => {},
              (err) => {}
            );
          }
        }
      }
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
    handlelistBack();
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleErrorMessage = () => {
    setValidationModal(false);
    setErrorMessage(false);
    setKycValidationError(false);
    setSaveDraftClick(false);
  };

  const handleQuestionStatus = (
    item,
    value,
    type,
    i,
    subQuesValues,
    subQues,
    qusestionFlag
  ) => {
    const providerName = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
    };
    if (type === "MPA_Checklist") {
      const data = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        quesId: item._id,
        userId: localStorage.getItem("userId"),
        option: value,
        comment: "Testing option comment",
        checklistName: "MPAChecklist",
      };
      props.saveQuestionCall(data);
      setTimeout(() => {
        props.statusPreameter(
          providerName,
          (data) => {
            // eslint-disable-next-line no-unused-expressions
            data.status && setMpaCheckListStatus({ ...data.data });
          },
          (err) => {
            enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
              variant: "error",
            });
          }
        );
      }, 1500);
    }
    if (type === "KYC_Checklist") {
      if ((isEmpty(subQues) || !qusestionFlag) && isEmpty(subQuesValues)) {
        const data = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
          quesId: item._id,
          userId: localStorage.getItem("userId"),
          option: value,
          comment: "Testing option comment",
          checklistName: "KYCChecklist",
          ownerId: ownerSelect.Owner_ID,
        };
        props.saveQuestionCall(data);
      } else if (!isEmpty(subQuesValues)) {
        const data = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
          quesId: item._id,
          userId: localStorage.getItem("userId"),
          option: value,
          comment: "Testing option comment",
          checklistName: "KYCChecklist",
          ownerId: ownerSelect.Owner_ID,
        };
        props.postKycQuestionsCall(
          data,
          () => {
            updateKycSubQuestions(subQuesValues);
          },
          () => {}
        );
      } else {
        const temp = { ...props.formData.kycQuestionData };
        temp.data[i.i].list[i.index].options[0].optionSelected = true;
        temp.data[i.i].list[i.index].options[1].optionSelected = false;
        temp.status = false;
        props.saveQuestionCallAction({ ...temp });
      }
      setTimeout(() => {
        props.statusPreameterKyc(
          providerName,
          (data) => {
            // eslint-disable-next-line no-unused-expressions
            data.status && setKycCheckListStatus({ ...data.data });
          },
          (err) => {
            enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
              variant: "error",
            });
          }
        );
      }, 1500);
    }
    // setStatusCall(false);
  };

  const handleDeleteUpload = (type, index, docType) => {
    const allState = { ...values };
    if (type === "main") {
      allState.incorportionDocument = "";
      allState.incorportionDocumentDetails = {
        File_Name: "",
        File_Type: "",
        Upload_Date_Time: "",
      };
      allState.docAdd = true;
      allState.incroprationDocDelete = true;
    }
    if (type === "bankDocuments") {
      allState.bankDocuments = "";
      allState.bankDocumentsDetails = {
        File_Name: "",
        File_Type: "",
        Upload_Date_Time: "",
      };
      allState.docAdd = true;
      allState.bankDocumentDelete = true;
    }
    if (type === "owner") {
      allState.ownerArray.owners[index][docType] = "";
      allState.ownerArray.owners[index][`${docType}Details`] = {
        File_Name: "",
        File_Type: "",
        Upload_Date_Time: "",
      };
      allState.docAdd = true;
    }
    handleInput(allState);
  };

  const handlePricingTemplete = () => {
    props.history.push(`/${props.mainData.app.layout}/pricingTemplete`);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    allState[type] = value;
    if (type === "businessName") {
      setDBAValue(true);
    } else {
      setDBAValue(false);
    }
    if (type === "businessCountry") {
      if (value === "US") {
        setStateInput(true);
        allState.businessState = "";
        error[type] = false;
        allState[type] = value;
      } else {
        setStateInput(false);
        allState.businessState = "";
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessStartDate") {
      const dateOne = new Date(value); //Year, Month, Date
      const dateTwo = new Date();
      if (dateOne > dateTwo) {
        error[type] = true;
        error.businessStartDateMessage = true;
      }
      if (dateOne <= dateTwo) {
        error[type] = false;
        error.businessStartDateMessage = false;
      }
    }
    if (type === "businessWebsite") {
      const res = value.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessPhoneNumber") {
      error[type] = false;
      allState[type] = formatPhoneNumber(value);
    }
    if (type === "accountNumber") {
      if (!/^[0-9]{1,17}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "routingNumber" || type === "businessTaxId") {
      if (!/^[0-9]{9}$/.test(value)) {
        if (value.length !== 9) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 9) {
          error[type] = false;
          allState[type] = value;
        }
      }
    }
    if (type === "businessPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessEmail") {
      const res = value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "discription") {
      if (value.length < 10 || value.length > 1000) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (value === "") {
      error[type] = false;
    }
    handleInput(allState);
    setVlidationError(error);
  };

  const handleUserType = (userData) => {
    const valueKycUserId = userData.id;
    props.getKycResponseFromAlloy(
      {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        userId: valueKycUserId,
      },
      (data) => {
        if (data.data) {
          setKycResponseJson(data.data[0]);
          // } else {
          //   enqueueSnackbar(data.message, {
          //     variant: "error",
          //   });
        }
      },
      () => {}
    );
  };

  const handleAddtionalInformation = () => {
    props.history.push(`/${props.mainData.app.layout}/addtionalInformation`);
  };

  let docData = "";
  let preData =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data[0];

  const handleESign = () => {
    if (props.formData.formMode === "Edit" && isEmpty(errorMessage)) {
      if (
        values.titleTermsCondition == "" ||
        isEmpty(values.titleTermsCondition) ||
        values.nameTermsCondition == "" ||
        isEmpty(values.nameTermsCondition)
      ) {
        enqueueSnackbar(
          "Please fill Title and Name before signing Addendum document",
          {
            variant: "warning",
          }
        );
      } else if (
        (preData.termAndConditions.docStatus == "Signed" || checkC) &&
        values.titleTermsCondition != "" &&
        values.nameTermsCondition != ""
      ) {
        window.open(
          `${process.env.REACT_APP_AWS_PATH}${process.env.REACT_APP_AWS_SIGNED_ADDENDUM}/${preData._id}.pdf`
        );
      } else if (
        preData.termAndConditions.docStatus != "Signed" &&
        values.titleTermsCondition != "" &&
        values.nameTermsCondition != ""
      ) {
        if (preData.termAndConditions.templateId == "") {
          docData = {
            Provider_ID: preData._id,
            Template_ID: "",
            Document_ID: "",
            Document_Name: "",
            Doc_Role_ID: "",
            Doc_Email_ID: preData.Business_EMail,
            Doc_Invite_ID: "",
            Acceptance_Title: values.titleTermsCondition,
            Acceptance_Name: values.nameTermsCondition,
            Template_Key: "",
            Title_field_ID: "",
            Name_field_ID: "",
          };
        } else {
          docData = {
            Provider_ID: preData._id,
            Template_ID: preData.termAndConditions.templateId,
            Document_ID: preData.termAndConditions.documentId,
            Document_Name: preData.termAndConditions.documentName,
            Doc_Role_ID: preData.termAndConditions.docRoleId,
            Doc_Email_ID: preData.Business_EMail,
            Doc_Invite_ID: preData.termAndConditions.docInviteId,
            Acceptance_Title: values.titleTermsCondition,
            Acceptance_Name: values.nameTermsCondition,
            Template_Key: "",
            Title_field_ID: "",
            Name_field_ID: "",
          };
        }
        setLoading(true);
        props.getSignNowCall({ ...docData }, (res) => {
          setLoading(false);
          if (res.status) {
            setIsDocModalOpen(true);
            setDocLink(res.link);
          } else {
            enqueueSnackbar(res.data.errors[0].message, {
              variant: "error",
            });
          }
        });
      }
    } else {
      enqueueSnackbar(
        "Please save the form first by clicking on Save Draft button below",
        {
          variant: "warning",
        }
      );
    }
  };

  function DocAlert() {
    const handleAlertClose = () => {
      setAlertOpen(false);
    };

    const handleAlertModalClose = () => {
      setAlertOpen(false);
      setIsDocModalOpen(false);
    };
    return (
      <Dialog
        open={alterOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sign the Addendum Document"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have not signed the document yet. Do you still want to close the
            window?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            No
          </Button>
          <Button onClick={handleAlertModalClose} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  const closeDocModal = () => {
    const providerId = preData._id;
    props.getDocStatus(providerId, (data) => {
      setSignedDocData(data.data);
      if (data.data.docStatus == "Signed") {
        setCheckC(true);
        setIsDocModalOpen(false);
        setAlertOpen(false);
        enqueueSnackbar("Addendum document successfully signed", {
          variant: "success",
        });
      } else if (data.data.docStatus != "Signed") {
        setCheckC(false);
        setAlertOpen(true);
      }
    });
  };

  const appStatus = () => {
    let statusText = "";
    const statusAfterKYC = [5, 9, 4, 7, 6];
    if (statusAfterKYC.includes(values.status)) {
      switch (values.status) {
        case 5:
          statusText = "Declined";
          break;
        case 9:
          statusText = "Live";
          break;
        case 4:
          statusText = "Under Review";
          break;
        case 7:
          statusText = "Onboarding";
          break;
        case 6:
          statusText = "Approved";
          break;
        default:
          statusText = "";
      }
      return statusText;
    }
    return statusText;
  };

  const showKycButton =
    props.formData.formMode === "Edit" && showCheckList(props, values);
  const changeStatus =
    props.formData.formMode === "Edit" && changeStatusPermission(props, values);

  const userType = localStorage.getItem("userType");
  const createPermission = actionPermission(props.roles, 2, 1, 2);
  let userId =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data[0] &&
    props.formData.providerDetailsById.Providers_Data[0]._id;

  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;

  const exportedBy =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;

  return (
    <div style={{ paddingBottom: "40px" }}>
      <IdleTimer
        ref={idleTimer}
        element={document}
        onIdle={() => onIdle()}
        onAction={() => {
          idleTimer.current.reset();
        }}
        debounce={250}
        timeout={timeOutLimit}
      />
      {/*component version modal description starts*/}
      {formInEditMode && (
        <UpdateDescriptionModal
          isUpdateDescriptionModal={isUpdateDescriptionModal}
          handleClose={handleDecriptionModalClose}
          handleSubmit={onFormPreviewSubmit}
          values={values}
          setFieldValue={(type, value) => onChange(value, type)}
          errors={validationError}
        />
      )}
      {/*component version modal description ends*/}

      <Modal open={kycResponseModal} onClose={() => setKycResponseModal(false)}>
        <Zoom
          in={kycResponseModal}
          style={{ transitionDelay: kycResponseModal ? "250ms" : "0ms" }}
        >
          <div className={classes.modalDiv}>
            <Card
              style={{
                maxWidth: "675px",
                minWidth: "675px",
                borderRadius: "12px",
              }}
              className={dialogClasses.modalRoot}
            >
              <div className={dialogClasses.statusModalHeader}>
                <div className={dialogClasses.statusHeadingText}>
                  KYC Response
                </div>
                <ClearIcon
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={() => {
                    setKycResponseModal(false);
                  }}
                />
              </div>

              <Box
                sx={{
                  width: "100%",
                  paddingBottom: "3%",
                }}
              >
                <Grid
                  style={{
                    display: "flex",
                    marginTop: "24px",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  container
                >
                  <Grid item xs={8}>
                    <Autocomplete
                      id="selectUserType"
                      options={props.formData.provideruserListById?.data || []}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      fullWidth
                      defaultValue={
                        props.formData.provideruserListById &&
                        props.formData.provideruserListById.data[0]
                      }
                      onChange={(event, newValue) => {
                        handleUserType(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Owner/Business"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                      helperText="Please select a Owner/Business"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  // bgcolor: "#e5e5e582",
                  boxShadow: 24,
                  overflow: "scroll",
                  maxHeight: "62vh",
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    bgcolor: "#e5e5e582",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Tags" {...a11yProps(0)} />
                    <Tab label="Raw Response" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanelKycResponse value={value} index={0}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    {kycResponseJson &&
                      kycResponseJson?.KYCResponseData?.summary?.tags.map(
                        (data) => (
                          <Grid style={{ padding: "1%" }}>
                            <Chip label={`${data}`} variant="outlined" />
                          </Grid>
                        )
                      )}
                  </Grid>
                  {/* {JSON.stringify(kycResponseJson?.KYCResponseData?.summary?.tags, null, 2)} */}
                </TabPanelKycResponse>
                <TabPanelKycResponse value={value} index={1}>
                  <pre>
                    <code>
                      {JSON.stringify(
                        kycResponseJson?.KYCResponseData,
                        null,
                        2
                      )}
                    </code>
                  </pre>
                </TabPanelKycResponse>
              </Box>

              <Grid
                spacing={2}
                style={{
                  justifyContent: "end",
                  alignItems: "center",
                  marginBottom: "1rem",
                  paddingRight: "28px",
                }}
                container
              >
                <Grid item>
                  <Button
                    color="primary"
                    type="submit"
                    style={{
                      textTransform: "none",
                    }}
                    variant="contained"
                    contained
                    className={classes.actionButtonStyle}
                    onClick={() => {
                      setKycResponseModal(false);
                      props.getKycResponseFromAlloy(
                        {
                          providerId:
                            props.formData.providerDetailsById.Providers_Data[0]
                              ._id,
                          userId:
                            props.formData.providerDetailsById.Providers_Data[0]
                              ._id,
                        },
                        (data) => {
                          if (data.data) {
                            setKycResponseJson(data.data[0]);
                            // } else {
                            //   enqueueSnackbar(data.message, {
                            //     variant: "error",
                            //   });
                          }
                        },
                        () => {}
                      );
                    }}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </div>
        </Zoom>
      </Modal>

      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card
            style={{
              width: "50%",
              maxWidth: "40%",
              height: "auto",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                minInlineSize: "-webkit-fill-available",
                textAlign: "start",
                height: "auto",
                maxHeight: "70vh",
                display: "flex",
                overflow: "auto",
                flexWrap: "nowrap",
                marginTop: "10%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={closeImage}
                style={{ width: "10%", marginBottom: "3%" }}
              />
              <div className={classes.successText}>
                {!errorMessage && !kycValidationError ? (
                  <Typography
                    style={{ textAlign: "center" }}
                    color="error"
                    variant="subtitle2"
                    gutterBottom
                  >
                    Fields marked with an asterisk (*) are mandatory
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              {/* <div className={classes.successText}>-Email ID should be unique</div> */}
              {errorMessage && (
                <div
                  style={{ marginTop: "2%" }}
                  className={classes.successText}
                >
                  {/* {errorMessage} */}
                  {errorMessage &&
                    errorMessage.map((key) => (
                      <div className={classes.successText}>
                        -{` ${key.msg}`}{" "}
                      </div>
                    ))}
                </div>
              )}
              {!checkC && !saveDraftClick && (
                <div className={classes.successText}>
                  - Please click on 'eSignNow' button to sign the 'Terms and
                  Conditions' to process further.
                </div>
              )}
              {kycValidationError && (
                <div className={classes.successText}>
                  - Please complete the Checklists before submitting the app.
                </div>
              )}
              <br />
            </div>

            <CardActions style={{ margin: "5%" }}>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => handleErrorMessage()}
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </div>
      </Modal>
      {/* <Prompt
        when={formInEditMode}
        message="Are you sure you want to leave this page?"
      /> */}
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
            padding: "10px",
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Grid
            style={{
              display: "flex",
              position: "relative",
              flexWrap: "nowrap",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center",
            }}
            container
          >
            <Grid style={{ position: "absolute" }} item>
              <IconButton
                color="white"
                aria-label="Back"
                onClick={() => handleBack()}
                component="span"
                style={{ background: "white", padding: "6px" }}
              >
                <KeyboardBackspaceIcon fontSize="small" />
              </IconButton>
            </Grid>

            <Grid style={{ margin: "auto" }} item>
              <Typography
                style={{ margin: "auto" }}
                className={classes.tittleTextColor}
              >
                Business Information
              </Typography>
            </Grid>
            {checkKycDisabled() &&
              makeFormEditablePermission() &&
              values.status != 5 &&
              disabled && (
                <Grid style={{ display: "flex" }} item>
                  <IconButton
                    color="white"
                    onClick={makeFormEditable}
                    component="span"
                    style={{
                      background: "white",
                      padding: "6px",
                      margin: "10px",
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <Typography
                    style={{ margin: "auto" }}
                    className={classes.tittleTextColor}
                  >
                    Edit
                  </Typography>
                </Grid>
              )}
            {checkKycDisabled() &&
              values.status != 5 &&
              makeFormEditablePermission() &&
              !disabled && (
                <Grid style={{ display: "flex" }} item>
                  <IconButton
                    color="white"
                    onClick={disabledEditForm}
                    component="span"
                    style={{
                      background: "white",
                      padding: "6px",
                      margin: "10px",
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <Typography
                    style={{ margin: "auto" }}
                    className={classes.tittleTextColor}
                  >
                    Cancel
                  </Typography>
                </Grid>
              )}
            <EditCloseAlert />
            {createPermissionFlag && userType === "Internal" && (
              <Grid style={{ display: "flex" }} item>
                <IconButton
                  color="white"
                  aria-label="Back"
                  onClick={() => handleAddNew()}
                  component="span"
                  style={{
                    background: "white",
                    padding: "6px",
                    margin: "10px",
                  }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
                <Typography
                  style={{ margin: "auto" }}
                  className={classes.tittleTextColor}
                >
                  Add New
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            // padding: "4%",
          }}
        >
          <FormControl style={{ marginTop: "20px" }}>
            {/* {props.formData.formMode !== "Edit" && (
              <Grid container spacing={2} className={classes.statusContainer}>
                <Grid item lg={12} sm={12} xs={12}>
                  <Button
                    variant="contained"
                    className={classes.clearButton}
                    onClick={() => handleBack()}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={10} />
              </Grid>
            )} */}
            {props.formData.formMode === "Edit" && (
              <Grid
                container
                style={{ padding: "0px 25px" }}
                className={classes.statusContainer}
                justify="space-between"
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {`Agent Name: ${
                      props.formData.providerDetailsById &&
                      props.formData.providerDetailsById.Providers_Data[0]
                        .agentFullName
                    }`}
                  </div>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {!isEmpty(kycResponseJson) && kycResponseViewButton && (
                    <IconsTooltip title="KYC Response" placement="top">
                      <div className={classes.appSvgIcons}>
                        <KYCIcon onClick={() => setKycResponseModal(true)} />
                      </div>
                    </IconsTooltip>
                  )}

                  {/* additional info grid */}
                  {props.formData.statusList &&
                    values.status &&
                    editPermissionFlag &&
                    changeStatusPermissionFlag && (
                      <IconsTooltip
                        title="Additional Information"
                        placement="top"
                      >
                        <div className={classes.appSvgIcons}>
                          <InfoIcon
                            onClick={() => handleAddtionalInformation()}
                          />
                        </div>
                      </IconsTooltip>
                    )}
                  <IconsTooltip title="Notes" placement="top-end">
                    <div className={classes.appSvgIcons}>
                      <NotesIcon onClick={() => setOpenNotes(true)} />
                    </div>
                  </IconsTooltip>
                  <Modal
                    open={openNotes}
                    onClose={() => setOpenNotes(false)}
                    align="center"
                  >
                    <Notes
                      setOpenNotes={setOpenNotes}
                      openNotes={openNotes}
                      userId={userId}
                    />
                  </Modal>
                  <IconsTooltip title="View Pricing" placement="top">
                    <div
                      style={{ paddingTop: "4px" }}
                      className={classes.appSvgIcons}
                    >
                      <PricingIcon onClick={() => handlePricingTemplete()} />
                      {props.formData.providerDetailsById.Providers_Data &&
                        props.formData.providerDetailsById.Providers_Data[0]
                          .pricingTemplateView && (
                          <CheckCircleIcon
                            className={classes.appSvgIconsCheck}
                          />
                        )}
                    </div>
                  </IconsTooltip>

                  <IconsTooltip title="Activity History" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ActivityIcon onClick={() => activity()} />
                    </div>
                  </IconsTooltip>
                  <IconsTooltip title="Export Application" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon onClick={handlePrint} />
                    </div>
                  </IconsTooltip>
                  {!(
                    props.formData.statusList &&
                    values.status &&
                    editPermissionFlag &&
                    changeStatusPermissionFlag
                  ) && (
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Status:
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#fcba03",
                          marginLeft: "10px",
                        }}
                      >
                        {props.formData.statusList &&
                          props.formData.statusList.Provider_Status_Data.filter(
                            (key) => key.Status_Code === values.status
                          ).length > 0 &&
                          props.formData.statusList.Provider_Status_Data.filter(
                            (key) => key.Status_Code === values.status
                          )[0].Provider_Status}
                      </Typography>
                    </Grid>
                  )}
                  {/* kyc response grid */}
                  {props.formData.statusList &&
                    values.status &&
                    editPermissionFlag &&
                    changeStatusPermissionFlag && (
                      <Grid
                        container
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          background: "#2ba9921a",
                          borderRadius: "5px",
                          width: "unset",
                        }}
                        item
                      >
                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          item
                        >
                          <TextField
                            className={classes.root}
                            variant="outlined"
                            size="small"
                            disabled={changeStatus}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused",
                              },
                            }}
                            label="Status"
                            value={values.status}
                            onChange={(e) => {
                              onChange(e.target.value, "status");
                              setChangeStatusValue(true);
                            }}
                            name="status"
                            style={{ textAlign: "initial", width: "100%" }}
                            id="status"
                            select
                          >
                            {props.formData.statusList &&
                              props.formData.statusList.Provider_Status_Data.map(
                                (key) => (
                                  <MenuItem
                                    // disabled={
                                    //   key.Status_Code === 4 && !livePermissionFlag
                                    // }
                                    className={classes.dialogBoxDropDown}
                                    value={key.Status_Code}
                                  >
                                    {key.Provider_Status}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        </Grid>

                        <Grid item style={{ paddingRight: "8px" }}>
                          {!changeStatus && (
                            <Button
                              variant="contained"
                              className={classes.buttonStyle}
                              style={{ marginLeft: "0.5rem", width: "100%" }}
                              color="#696969"
                              onClick={() => saveStatus()}
                            >
                              Change Status
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    )}
                </div>
              </Grid>
            )}
            {disabled && (
              <Grid
                container
                spacing={2}
                style={{
                  color: "red",
                  fontWeight: "bold",
                  justifyContent: "center",
                }}
                className={classes.statusContainer}
                justify="space-between"
              >
                This application can not be edited as it is {appStatus()}.
              </Grid>
            )}
            <div ref={componentRef} text={text}>
              {text !== "old boring text" ? (
                <Grid>
                  <Grid
                    style={{
                      display: "flex",
                      marginBottom: "15px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "15px",
                      }}
                    >
                      <img style={{ width: "32%" }} src={image} alt="appName" />
                    </Box>
                    <Box style={{ textAlign: "end" }}>
                      <Typography>Merchant Application</Typography>
                      <Typography>{`Exported on: ${moment(newCurrentDate)
                        .tz("UTC")
                        .format("MM/DD/YYYY hh:mm:ss A")} UTC`}</Typography>
                      <Typography>
                        {`Exported by: ${exportedByFname} ${exportedByLname}, <${exportedBy}>`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              <Box className={classes.marginBox}>
                <Grid container className={classes.margin}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <BusinessCenterIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      error={validationError.legalBusiness}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title="As it appear on your income tax return"
                              placement="top"
                            >
                              <HelpOutlineOutlinedIcon
                                classes={{
                                  root: "HelpIcon",
                                  icon: "withHelpOutline",
                                }}
                                color="action"
                                fontSize="small"
                              />
                            </Tooltip>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize,
                        },
                        required: true,
                      }}
                      label="Legal Name of Business"
                      value={values.legalBusiness}
                      helperText={
                        validationError.legalBusiness &&
                        "Legal Name of Business is required."
                      }
                      onChange={(e) => {
                        (/^[A-Za-z0-9\d\.\-\/\&\,\ ]*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                          onChange(e.target.value, "legalBusiness");
                      }}
                      name="legalBusiness"
                      id="legalBusiness"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      // style={{ marginTop: "0px", marginBottom: "0px" }}
                      fontSize="small"
                    />
                    <TextField
                      select
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Type of Business"
                      error={validationError.businessType}
                      value={values.businessType}
                      onChange={(e) => onChange(e.target.value, "businessType")}
                      helperText={
                        validationError.businessType &&
                        "Business Type is required."
                      }
                      name="businessType"
                      id="businessType"
                    >
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Convenience Store"
                      >
                        Convenience Store
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Gas Station"
                      >
                        Gas Station
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="General Store"
                      >
                        General Store
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Liquor Store"
                      >
                        Liquor Store
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Tobacco Store"
                      >
                        Tobacco Store
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Truck Stop"
                      >
                        Truck Stop
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      // style={{ marginTop: "0px", marginBottom: "0px" }}
                      fontSize="small"
                    />
                    <TextField
                      select
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Ownership Type"
                      error={validationError.ownerShipType}
                      value={values.ownerShipType}
                      onChange={(e) =>
                        onChange(e.target.value, "ownerShipType")
                      }
                      helperText={
                        validationError.ownerShipType &&
                        "Business Ownership Type is required."
                      }
                      name="ownerShipType"
                      id="ownerShipType"
                    >
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Government"
                      >
                        Government
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="FinancialInstitution"
                      >
                        Financial Institution
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="LLC"
                      >
                        LLC
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="NonProfit"
                      >
                        Non Profit
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Partnership"
                      >
                        Partnership
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="PrivateCorporation"
                      >
                        Private Corporation
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="PublicCorporation"
                      >
                        Public Corporation
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="SECRegulatedCorporation"
                      >
                        SEC Regulated Corporation
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="SoleProprietorship"
                      >
                        Sole Proprietorship
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Trust"
                      >
                        Trust
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <DescriptionIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      error={validationError.businessTaxId}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      type="text"
                      label="Federal Tax ID"
                      value={values.businessTaxId}
                      onChange={(e) => {
                        (/^[0-9]\d*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                          onChange(e.target.value, "businessTaxId");
                      }}
                      helperText={
                        validationError.businessTaxId &&
                        "9 Digit Federal Tax ID is required."
                      }
                      name="businessTaxId"
                      id="businessTaxId"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <EventNoteIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <FormControl
                      className={classes.root}
                      style={{ fontSize: "unset" }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          disabled={disabled}
                          onChange={(e) => {
                            if (e === null) {
                              onChange(e, "businessStartDate");
                            } else {
                              onChange(
                                convertUTCDateToLocalDate(e),
                                "businessStartDate"
                              );
                            }
                          }}
                          value={
                            values.businessStartDate
                              ? values.businessStartDate
                              : null
                          }
                          label="Business Open Date"
                          id="businessStartDate"
                          max={new Date()}
                          format="MM/dd/yyyy"
                          name="businessStartDate"
                          style={{
                            "&.focused": {
                              color: theme.palette.primary.main,
                              fontSize: "14px",
                              fontStyle: "Helvetica, Arial,sans-serif",
                            },
                          }}
                          required
                          placeholder="MM/DD/YYYY"
                          maxDate={new Date()}
                          error={
                            validationError.businessStartDate ||
                            validationError.businessStartDateMessage
                          }
                          maxDateMessage="Business Start Date should not be a Future date."
                          keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                          KeyboardButtonProps={{
                            size: "small",
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused",
                            },
                            required: true,
                            endAdornment: (
                              <Tooltip title="cds_DateHelp" placement="top">
                                <IconButton edge="end" size="small">
                                  <HelpOutlineOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            ),
                          }}
                          helperText={
                            (validationError.businessStartDate &&
                              "Business Start Date is required.") ||
                            (validationError.businessStartDateMessage &&
                              "Future date is not allowed")
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid style={{ display: "flex" }} item lg={4} sm={6} xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <FormGroup
                        style={{ marginLeft: "7px" }}
                        aria-label="position"
                        row
                      >
                        <FormControlLabel
                          value="bottom"
                          style={{
                            marginTop: "9px",
                            color: "#757575",
                            fontSize: "15px !important",
                          }}
                          control={
                            <Checkbox
                              disabled={
                                !(
                                  (kycCheckListFlag ||
                                    ProviderStatus === "New Entry" ||
                                    ProviderStatus === "Sent to Merchant") &&
                                  ProviderStatus !== "Live" &&
                                  (editPermissionFlag || createPermissionFlag)
                                ) ||
                                declinedApp ||
                                disabled
                              }
                              color="primary"
                              checked={values.foreignEntityNonresident === true}
                              value={values.foreignEntityNonresident}
                              onChange={() => {
                                onChange(
                                  !values.foreignEntityNonresident,
                                  "foreignEntityNonresident"
                                );
                              }}
                            />
                          }
                          label="I certify that I am a foreign entity/ nonresident alien."
                        />
                      </FormGroup>
                      <FormHelperText style={{ marginTop: "-10px" }}>
                        (If checked, please attach IRS Form W-8.)
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      disabled={disabled}
                      error={validationError.businessName}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize,
                        },
                      }}
                      label="DBA Name"
                      value={
                        values.businessName === "" &&
                        values.legalBusiness === ""
                          ? values.legalBusiness
                          : dbaValue && values.legalBusiness !== ""
                          ? values.businessName
                          : values.businessName !== ""
                          ? values.businessName
                          : values.legalBusiness
                      }
                      onChange={(e) => {
                        // setDBAValue(true);
                        (/^[A-Za-z0-9\d\.\-\/\&\,\ ]*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                          onChange(e.target.value, "businessName");
                      }}
                      name="businessName"
                      id="businessName"
                      helperText={
                        validationError.businessName && "Provide Legal DBA Name"
                      }
                    />
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    <BusinessCenterIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title="As listed in the Attached Addendums. A signed Addendum must be completed and approved for each location."
                              placement="top"
                            >
                              <HelpOutlineOutlinedIcon
                                classes={{
                                  root: "HelpIcon",
                                  icon: "withHelpOutline",
                                }}
                                color="action"
                                fontSize="small"
                              />
                            </Tooltip>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Location Addresses"
                      className={classes.root}
                      error={validationError.businessAddress}
                      helperText={
                        validationError.businessAddress &&
                        "Business Address is required."
                      }
                      value={values.businessAddress}
                      onChange={(e) =>
                        onChange(e.target.value, "businessAddress")
                      }
                      name="businessAddress"
                      id="businessAddress"
                    />
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    <ContactMailIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Mailing Address"
                      helperText="(if different from location address)"
                      value={values.mailingAddress}
                      error={validationError.mailingAddress}
                      onChange={(e) =>
                        onChange(e.target.value, "mailingAddress")
                      }
                      name="mailingAddress"
                      id="mailingAddress"
                    />
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    <AttachMoneyRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      helperText={
                        validationError.cardVolumeMonth
                          ? "Average Monthly Volume must be greater than High Ticket"
                          : "(VS/MS/DSVR/AMEX)"
                      }
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Average Monthly Volume"
                      value={values.cardVolumeMonth}
                      error={validationError.cardVolumeMonth}
                      onChange={(e) =>
                        (/^[0-9]\d*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                        onChange(e.target.value, "cardVolumeMonth")
                      }
                      name="cardVolumeMonth"
                      id="cardVolumeMonth"
                      type="text"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <AttachMoneyRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      helperText={
                        validationError.averageTicketSize
                          ? "Average ticket amount must be less than High Ticket"
                          : "(VS/MS/DSVR/AMEX)"
                      }
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Average Ticket Amount"
                      value={values.averageTicketSize}
                      error={validationError.averageTicketSize}
                      onChange={(e) =>
                        (/^[0-9]\d*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                        onChange(e.target.value, "averageTicketSize")
                      }
                      name="averageTicketSize"
                      id="averageTicketSize"
                      type="text"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <AttachMoneyRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      helperText={
                        validationError.highTicket
                          ? "High Ticket Amount be greater than Average Ticket Amount"
                          : "(VS/MS/DSVR/AMEX)"
                      }
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="High Ticket Amount"
                      value={values.highTicket}
                      error={validationError.highTicket}
                      onChange={(e) =>
                        (/^[0-9]\d*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                        onChange(e.target.value, "highTicket")
                      }
                      name="highTicket"
                      id="highTicket"
                      type="text"
                    />
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    <PermContactCalendarIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Master Contact Name"
                      error={validationError.masterContactName}
                      value={values.masterContactName}
                      onChange={(e) =>
                        onChange(e.target.value, "masterContactName")
                      }
                      name="masterContactName"
                      id="masterContactName"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <StayCurrentPortraitIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        inputProps: { min: 0, max: 10 },
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Phone"
                      error={validationError.businessPhoneNumber}
                      value={formatPhoneNumber(values.businessPhoneNumber)}
                      onChange={(e) => {
                        onChange(
                          formatPhoneNumber(e.target.value),
                          "businessPhoneNumber"
                        );
                      }}
                      name="businessPhoneNumber"
                      id="businessPhoneNumber"
                      helperText={
                        validationError.businessPhoneNumber &&
                        "Enter a Valid Phone Number with 10 Digits Only"
                      }
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <PrintIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      disabled={disabled}
                      className={classes.root}
                      InputLabelProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Fax"
                      error={validationError.fax}
                      value={values.fax}
                      onChange={(e) => onChange(e.target.value, "fax")}
                      name="fax"
                      id="fax"
                      // type="number"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <StayCurrentPortraitIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                        readOnly:
                          values.businessEmail && !disabledValue ? true : false,
                        className:
                          values.businessEmail && !disabledValue
                            ? "Mui-disabled"
                            : "",
                      }}
                      label="Email Address"
                      error={validationError.businessEmail}
                      value={values.businessEmail}
                      onChange={(e) => {
                        setDisabledValue(true);
                        onChange(e.target.value, "businessEmail");
                      }}
                      name="businessEmail"
                      id="businessEmail"
                      helperText={
                        validationError.businessEmail &&
                        "Enter a Valid Email Address"
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ paddingBottom: "10px", paddingTop: "10px" }}
                  className={classes.margin}
                >
                  <Grid item lg={6} sm={6} xs={12}>
                    <DataUsageIcon
                      className={classes.inputBox}
                      style={{ marginTop: "20px", marginBottom: "0px" }}
                      fontSize="small"
                    />
                    <FormControl
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      error={validationError.transmitCardholderOption}
                      disabled={disabled}
                      component="fieldset"
                    >
                      <FormLabel
                        style={{ marginTop: "23px", fontSize: "14px" }}
                        component="span"
                      >
                        Do you use any third party to store, process or transmit
                        cardholder data?
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.transmitCardholderOption === true}
                              onChange={(e) =>
                                onChange(true, "transmitCardholderOption")
                              }
                              name="yes"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                values.transmitCardholderOption === false
                              }
                              onChange={(e) =>
                                onChange(false, "transmitCardholderOption")
                              }
                              name="no"
                            />
                          }
                          label="No"
                        />
                      </FormGroup>
                      <FormHelperText>
                        {validationError.transmitCardholderOption}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {values.transmitCardholderOption && (
                    <Grid item lg={6} sm={6} xs={12}>
                      <PermIdentityIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <TextField
                        disabled={
                          !(
                            (kycCheckListFlag ||
                              ProviderStatus === "New Entry" ||
                              ProviderStatus === "Sent to Merchant") &&
                            ProviderStatus !== "Live" &&
                            (editPermissionFlag || createPermissionFlag)
                          ) || declinedApp
                        }
                        required
                        style={{
                          width: windowWidthLG ? "87%" : "80%",
                          "&.focused": {
                            color: theme.palette.primary.main,
                            fontSize: "14px",
                            fontStyle: "Helvetica, Arial,sans-serif",
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                title="Name/Address of the third party that you use to store, process or transmit cardholder data."
                                placement="top"
                              >
                                <HelpOutlineOutlinedIcon
                                  classes={{
                                    root: "HelpIcon",
                                    icon: "withHelpOutline",
                                  }}
                                  color="action"
                                  fontSize="small"
                                />
                              </Tooltip>
                            </InputAdornment>
                          ),
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                        }}
                        label="If Yes, give name/address"
                        // helperText="Do you use any third party to store, process or transmit cardholder data"
                        value={values.transmitCardholderName}
                        onChange={(e) =>
                          onChange(e.target.value, "transmitCardholderName")
                        }
                        name="transmitCardholderName"
                        id="transmitCardholderName"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container className={classes.margin}>
                  <Grid item lg={3} sm={6} xs={3}>
                    <PublicIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Country"
                      value={values.businessCountry}
                      error={validationError.businessCountry}
                      onChange={(e) =>
                        onChange(e.target.value, "businessCountry")
                      }
                      name="businessCountry"
                      id="businessCountry"
                      select
                    >
                      {props.formData.countryTypeList &&
                        props.formData.countryTypeList.Country_Data.map(
                          (key) => (
                            <MenuItem
                              className={classes.dialogBoxDropDown}
                              value={key.Country_Code}
                            >
                              {key.Country_Name}
                            </MenuItem>
                          )
                        )}
                    </TextField>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={3}>
                    <NearMeRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    {values.businessCountry === "US" ? (
                      <TextField
                        disabled={disabled}
                        required
                        className={classes.root}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        label="State"
                        value={values.businessState}
                        error={validationError.businessState}
                        onChange={(e) =>
                          onChange(e.target.value, "businessState")
                        }
                        helperText={
                          validationError.businessState &&
                          "Business State is required."
                        }
                        name="businessState"
                        id="businessState"
                        select={stateInput}
                      >
                        {stateInput &&
                          locationCity
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((key) => (
                              <MenuItem
                                className={classes.dialogBoxDropDown}
                                value={key.value}
                              >
                                {key.name}
                              </MenuItem>
                            ))}
                      </TextField>
                    ) : (
                      <TextField
                        disabled={disabled}
                        required
                        className={classes.root}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        label="State"
                        error={validationError.businessState}
                        value={values.businessState}
                        onChange={(e) =>
                          onChange(e.target.value, "businessState")
                        }
                        helperText={
                          validationError.businessState &&
                          "Business State is required."
                        }
                        name="businessState"
                        id="businessState"
                      />
                    )}
                  </Grid>
                  <Grid item lg={3} sm={6} xs={3}>
                    <LocationCityRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="City"
                      error={validationError.businessCity}
                      value={values.businessCity}
                      onChange={(e) => onChange(e.target.value, "businessCity")}
                      helperText={
                        validationError.businessCity &&
                        "Business City is required."
                      }
                      name="businessCity"
                      id="businessCity"
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={3}>
                    <RoomIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      disabled={disabled}
                      required
                      style={{
                        width: windowWidthLG ? "74%" : "80%",
                        "&.focused": {
                          color: theme.palette.primary.main,
                          fontSize: "14px",
                          fontStyle: "Helvetica, Arial,sans-serif",
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Postal Code"
                      error={validationError.businessPostalCode}
                      value={values.businessPostalCode}
                      onChange={(e) =>
                        (/^[0-9]\d*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                        onChange(e.target.value, "businessPostalCode")
                      }
                      helperText={
                        validationError.businessPostalCode &&
                        "Business Postal Code is required."
                      }
                      name="businessPostalCode"
                      id="businessPostalCode"
                      type="text"
                    />
                  </Grid>

                  {props.formData.mcclist &&
                    props.formData.mcclist.MCC_Data && (
                      <Grid
                        item
                        style={{ display: "flex" }}
                        lg={4}
                        sm={6}
                        xs={12}
                      >
                        <AccountBalanceIcon
                          className={classes.inputBox}
                          fontSize="small"
                        />

                        <Autocomplete
                          name="USID"
                          id="USID"
                          style={{
                            fontSize: "14px",
                            width: windowWidthLG ? "90%" : "80%",
                            "&.focused": {
                              color: theme.palette.primary.main,
                              fontSize: "14px",
                              fontStyle: "Helvetica, Arial,sans-serif",
                            },
                          }}
                          label="Type(s) of goods or services sold"
                          value={
                            (mccList || []).find(
                              (option) =>
                                option.MCC_Code === parseInt(values.USID)
                            ) || {}
                          }
                          autoHighlight
                          fullWidth
                          onChange={(_, value, action) => {
                            if (action === "clear") {
                              onChange("", "USID");
                            } else {
                              value && onChange(value.MCC_Code, "USID");
                            }
                          }}
                          options={mccList || []}
                          getOptionLabel={(option) => option.MCC_Name || ""}
                          renderInput={(params) => (
                            <TextField
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused",
                                },
                              }}
                              required
                              InputProps={{
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused",
                                },
                                required: true,
                              }}
                              error={validationError.USID}
                              helperText={
                                validationError.USID &&
                                "Business MCC is required."
                              }
                              {...params}
                              label="MCC"
                            />
                          )}
                          renderOption={(option, { inputValue }) => {
                            const matches = match(option.MCC_Name, inputValue, {
                              insideWords: true,
                            });
                            const parts = parse(option.MCC_Name, matches);

                            return (
                              <div>
                                {parts.map((part, index) => (
                                  <span
                                    style={{
                                      background: part.highlight
                                        ? "#f3ff0070"
                                        : "",
                                      fontWeight: part.highlight ? 400 : "",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </div>
                            );
                          }}
                          disabled={disabled}
                        />
                      </Grid>
                    )}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xl: "center",
                        lg: "center",
                        md: "flex-start",
                      },
                      alignItems: "center",
                      alignContent: "center",
                    }}
                    lg={4}
                    sm={6}
                    xs={12}
                  >
                    <TrendingUpIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <FormControl
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      error={validationError.seasonalSales}
                      disabled={disabled}
                      component="fieldset"
                    >
                      <FormGroup
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          alignItems: "center",
                          flexWrap: "nowrap",
                        }}
                        row
                      >
                        <FormLabel
                          style={{ marginTop: "10px", fontSize: "14px" }}
                          component="span"
                        >
                          Seasonal Sales
                        </FormLabel>
                        <FormControlLabel
                          style={{ marginTop: "10px", marginLeft: "10px" }}
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.seasonalSales === true}
                              onChange={(e) => onChange(true, "seasonalSales")}
                              name="yes"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          style={{ marginTop: "10px", marginLeft: "10px" }}
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.seasonalSales === false}
                              onChange={(e) => onChange(false, "seasonalSales")}
                              name="no"
                            />
                          }
                          label="No"
                        />
                      </FormGroup>
                      <FormHelperText>
                        {validationError.seasonalSales}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <WebIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      disabled={disabled}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Website Url"
                      error={validationError.businessWebsite}
                      value={values.businessWebsite}
                      onChange={(e) =>
                        onChange(e.target.value, "businessWebsite")
                      }
                      name="businessWebsite"
                      id="businessWebsite"
                      helperText={
                        validationError.businessWebsite &&
                        "please enter a valid web url"
                      }
                    />
                  </Grid>

                  <Grid item lg={12} sm={12} xs={12}>
                    <CommentIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      multiline
                      style={{
                        marginTop: "20px",
                        width: windowWidthLG ? "94%" : "80%",
                        "&.focused": {
                          color: theme.palette.primary.main,
                          fontSize: "14px",
                          fontStyle: "Helvetica, Arial,sans-serif",
                        },
                      }}
                      rows={3}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Comments"
                      value={values.comments}
                      error={validationError.comments}
                      onChange={(e) => onChange(e.target.value, "comments")}
                      name="comments"
                      id="comments"
                    />
                  </Grid>
                </Grid>
                {/* {values.incorportionDocumentDetails.File_Name === "" ? (
                (kycCheckListFlag ||
                  ProviderStatus === "New Entry" ||
                  ProviderStatus === "Sent to Merchant") &&
                ProviderStatus !== "Live" &&
                (editPermissionFlag || createPermissionFlag) && (
                  <Grid
                    container
                    style={{ flexDirection: windowWidth ? "column" : "row" }}
                    className={
                      !validationError.incorportionDocument
                        ? classes.uploadDocumentBox
                        : classes.uploadDocumentBoxRequired
                    }
                  >
                    <Grid item xs={8} style={{ margin: "10px" }}>
                      <Typography
                        style={{ marginLeft: "10px" }}
                        variant="subtitle1"
                      >
                        Incorporation document (If you are a business, you must
                        provide the incorporation document in order to get
                        verified) Maximum file size: 25MB (format: PDF, JPG,
                        PNG)
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <label htmlFor="upload-incropraton">
                        <input
                          style={{ display: "none" }}
                          id="upload-incropraton"
                          name="upload-incropraton"
                          type="file"
                          disabled={declinedApp}
                          accept="image/*, application/pdf"
                          display="none"
                          onChange={(e) =>
                            handleUploadOnChange(e.target.files[0], "main")
                          }
                        />

                        <Button
                          style={{
                            fontSize: windowWidth
                              ? "12px"
                              : smallDevice
                              ? "7px"
                              : "14px",
                            textAlign: "center"
                          }}
                          disabled={declinedApp}
                          className={
                            !validationError.incorportionDocument
                              ? classes.buttonStyle
                              : classes.buttonStyleError
                          }
                          variant="contained"
                          component="span"
                        >
                          Upload Incorporation Document
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                )
              ) : (
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "5%" }}
                        align="center"
                      >
                        Document Type
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "5%" }}
                        align="center"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "10%" }}
                        align="center"
                      >
                        File Type
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "10%" }}
                        align="center"
                      >
                        Upload Time (UTC)
                      </TableCell>
                      {(ProviderStatus === "New Entry" ||
                        ProviderStatus === "Sent to Merchant" ||
                        (kycCheckListFlag &&
                          editPermissionFlag &&
                          ProviderStatus !== "Live")) && (
                        <TableCell
                          className={classes.cellTextHeaderOffer}
                          style={{ width: "5%" }}
                          align="center"
                        >
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">Incorporation</TableCell>
                      <TableCell align="center">
                        <a
                          href={values.incorportionDocumentDetails.File_Path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          {values.incorportionDocumentDetails.File_Name}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        {values.incorportionDocumentDetails.File_Type}
                      </TableCell>
                      <TableCell align="center">
                        {moment(
                          values.incorportionDocumentDetails.Upload_Date_Time
                        )
                          .tz("UTC")
                          .format("MM/DD/YYYY hh:mm:ss A")}
                      </TableCell>
                      {(ProviderStatus === "New Entry" ||
                        ProviderStatus === "Sent to Merchant" ||
                        (kycCheckListFlag &&
                          editPermissionFlag &&
                          ProviderStatus !== "Live")) && (
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleDeleteUpload("main")}
                            disabled={declinedApp}
                            color="secondary"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              )} */}
              </Box>
              <Box
                style={{
                  alignItems: "center",
                  marginTop: "30px",
                }}
                className={classes.tittleColor}
                height={40}
              >
                <Typography className={classes.tittleTextColor}>
                  Owner Information
                </Typography>
              </Box>
              {values.ownerArray.owners &&
                values.ownerArray.owners.map((key, i) => (
                  <OwnerInformation
                    handleOwnerChange={(data, type, e) =>
                      ownerOnchange(i, data, type, e)
                    }
                    handleDeleteUpload={(docType) =>
                      handleDeleteUpload("owner", i, docType)
                    }
                    handleUploadOnChange={(data, type) =>
                      handleUploadOnChange(data, "owner", type, i)
                    }
                    index={i}
                    validationError={validationError.ownerArray.owners[i]}
                    data={key}
                    ProviderStatus={ProviderStatus}
                    kycCheckListFlag={kycCheckListFlag}
                    editPermissionFlag={editPermissionFlag}
                    createPermissionFlag={createPermissionFlag}
                    add={() => addOwner()}
                    remove={() => setOwnerDeleteModal(i)}
                    disabled={disabled}
                  />
                ))}
              <Box
                style={{
                  alignItems: "center",
                  margin: "2rem 0",
                }}
                className={classes.tittleColor}
                height={40}
              >
                <Typography className={classes.tittleTextColor}>
                  Contact Information
                </Typography>
              </Box>
              {values.contactArray &&
                values.contactArray.map((key, i) => (
                  <ContactInformation
                    handleContactChange={(data, type) =>
                      contactOnchange(i, data, type)
                    }
                    index={i}
                    validationError={validationError.contactArray[i]}
                    data={key}
                    ProviderStatus={ProviderStatus}
                    kycCheckListFlag={kycCheckListFlag}
                    editPermissionFlag={editPermissionFlag}
                    createPermissionFlag={createPermissionFlag}
                    add={() => addContact()}
                    remove={() => deleteContact(i)}
                    disabled={disabled}
                  />
                ))}
              <Box
                style={{
                  alignItems: "center",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
                className={classes.tittleColor}
                height={40}
              >
                <Typography className={classes.tittleTextColor}>
                  Settlement Account Information
                </Typography>
              </Box>
              <Box className={classes.marginBox}>
                <Grid container className={classes.margin}>
                  <Grid item lg={3} sm={3} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      className={classes.quaterInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Name on Bank Account"
                      value={values.legalBusiness}
                      error={validationError.accountName}
                      onChange={(e) => onChange(e.target.value, "accountName")}
                      name="accountName"
                      id="accountName"
                      helperText={
                        validationError.accountName &&
                        "Provide Legal Business Name"
                      }
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      className={classes.quaterInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Routing Number"
                      error={validationError.routingNumber}
                      value={values.routingNumber}
                      onChange={(e) =>
                        (/^[0-9]\d*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                        onChange(e.target.value, "routingNumber")
                      }
                      helperText={
                        validationError.routingNumber &&
                        "(9 Digit Routing Number is Required."
                      }
                      name="routingNumber"
                      id="routingNumber"
                      type="text"
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      className={classes.quaterInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Account Number"
                      error={validationError.accountNumber}
                      helperText={
                        validationError.accountNumber &&
                        "Max 17 Digit Account Number is required."
                      }
                      value={values.accountNumber}
                      onChange={(e) =>
                        (/^[0-9]\d*$/g.test(e.target.value) ||
                          e.target.value == "") &&
                        onChange(e.target.value, "accountNumber")
                      }
                      name="accountNumber"
                      id="accountNumber"
                      type="text"
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={disabled}
                      required
                      className={classes.quaterInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      error={validationError.accountType}
                      helperText={
                        validationError.accountType &&
                        "Account type is required."
                      }
                      label="Account Type"
                      value={values.accountType}
                      onChange={(e) => onChange(e.target.value, "accountType")}
                      name="accountType"
                      id="accountType"
                      select
                    >
                      {props.formData.accountTypeList &&
                        props.formData.accountTypeList.Account_Type_Data.sort(
                          (a, b) =>
                            a.Account_Type_Name > b.Account_Type_Name ? 1 : -1
                        ).map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.Account_Type_Name}
                          >
                            {key.Account_Type_Name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </Grid>
                {values.bankDocumentsDetails.File_Name === "" ? (
                  <Grid
                    container
                    style={{ flexDirection: !windowWidth ? "column" : "row" }}
                    className={
                      !validationError.incorportionDocument
                        ? classes.uploadDocumentBox
                        : classes.uploadDocumentBoxRequired
                    }
                  >
                    <Grid item xs={7}>
                      <Typography
                        variant="subtitle1"
                        style={{ marginLeft: "10px" }}
                      >
                        Bank Document (Void Check, Bank Letter) * (required)
                        Maximum file size: 25MB (Format: PDF, JPG, PNG)
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={windowWidth ? 5 : 12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: windowWidth ? "row" : "column",
                          alignItems: "center",
                          justifyContent: "f,lex-start",
                          width: "100%",
                        }}
                      >
                        <TextField
                          className={classes.rootDocumentSelect}
                          style={{ width: "50%" }}
                          disabled={declinedApp}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused",
                            },
                            required: true,
                          }}
                          required
                          label="Document type"
                          value={values.bankDocumentType}
                          onChange={(e) => {
                            setBankDocFileType(true);
                            onChange(e.target.value, "bankDocumentType");
                          }}
                          name="bankDocumentType"
                          id="bankDocumentType"
                          select
                          error={validationError.bankDocumentType}
                          helperText={
                            values.bankDocumentType == "" || !bankDocFileType
                              ? "Select Document Type"
                              : ""
                          }
                          SelectProps={{
                            MenuProps: { disableScrollLock: true },
                          }}
                        >
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value="Bank Letter"
                          >
                            Bank Letter
                          </MenuItem>
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value="Void Check"
                          >
                            Void Check
                          </MenuItem>
                        </TextField>
                        <label
                          htmlFor="upload-bankDocument"
                          style={{ margin: windowWidth ? "0px" : "10px" }}
                        >
                          <input
                            style={{ display: "none" }}
                            id="upload-bankDocument"
                            type={bankDocFileType ? "file" : "button"}
                            accept="image/*, application/pdf"
                            disabled={declinedApp}
                            name="upload-bankDocument"
                            onClick={() => {
                              if (values.bankDocumentType) {
                                if (!bankDocFileType) {
                                  setBankDocFileType(true);
                                }
                              } else {
                                setBankDocFileType(false);
                              }
                            }}
                            onChange={(e) => {
                              if (values.bankDocumentType) {
                                handleUploadOnChange(
                                  e.target.files[0],
                                  "bankDocuments"
                                );
                              }
                            }}
                            display="none"
                          />
                          <Button
                            component="span"
                            variant={
                              !bankDocFileType ? "outlined" : "contained"
                            }
                            disabled={!bankDocFileType || props.declinedApp}
                          >
                            Upload bank document
                          </Button>
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell
                          className={classes.cellTextHeaderOffer}
                          style={{ width: "5%" }}
                          align="center"
                        >
                          Document Type
                        </TableCell>
                        <TableCell
                          className={classes.cellTextHeaderOffer}
                          style={{ width: "5%" }}
                          align="center"
                        >
                          Document Type Name
                        </TableCell>
                        <TableCell
                          className={classes.cellTextHeaderOffer}
                          style={{ width: "5%" }}
                          align="center"
                        >
                          Name
                        </TableCell>
                        <TableCell
                          className={classes.cellTextHeaderOffer}
                          style={{ width: "10%" }}
                          align="center"
                        >
                          File Type
                        </TableCell>
                        <TableCell
                          className={classes.cellTextHeaderOffer}
                          style={{ width: "10%" }}
                          align="center"
                        >
                          Upload Time (UTC)
                        </TableCell>
                        {(ProviderStatus === "New Entry" ||
                          ProviderStatus === "Sent to Merchant" ||
                          (kycCheckListFlag &&
                            editPermissionFlag &&
                            ProviderStatus !== "Live")) && (
                          <TableCell
                            className={classes.cellTextHeaderOffer}
                            style={{ width: "5%" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">Bank Document</TableCell>
                        <TableCell align="center">
                          {values.bankDocumentType}
                        </TableCell>
                        <TableCell align="center">
                          <a
                            href={values.bankDocumentsDetails.File_Path}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {values.bankDocumentsDetails.File_Name}
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          {values.bankDocumentsDetails.File_Type}
                        </TableCell>
                        <TableCell align="center">
                          {moment(values.bankDocumentsDetails.Upload_Date_Time)
                            .tz("UTC")
                            .format("MM/DD/YYYY hh:mm:ss A")}
                        </TableCell>
                        {(ProviderStatus === "New Entry" ||
                          ProviderStatus === "Sent to Merchant" ||
                          (kycCheckListFlag &&
                            editPermissionFlag &&
                            ProviderStatus !== "Live")) && (
                          <TableCell align="center">
                            <IconButton
                              onClick={() =>
                                handleDeleteUpload("bankDocuments")
                              }
                              color="secondary"
                              disabled={declinedApp || disabled}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </Box>
              <Box style={{ marginTop: "20px" }} height={20}>
                <Typography style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Terms and Conditions:
                </Typography>
              </Box>
              <Grid spacing={2} container className={classes.margin}>
                <Grid
                  style={{ marginTop: "10px" }}
                  item
                  container
                  xs={11}
                  sm={11}
                  md={11}
                  lg={6}
                  xl={6}
                >
                  <Grid display="flex" item xs={12} sm={6}>
                    <MenuBookRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      disabled={
                        preData?.termAndConditions?.docStatus == "Signed" ||
                        checkC ||
                        declinedApp
                      }
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Title"
                      error={validationError.titleTermsCondition}
                      value={values.titleTermsCondition}
                      onChange={(e) =>
                        onChange(e.target.value, "titleTermsCondition")
                      }
                      helperText={
                        validationError.titleTermsCondition &&
                        "Title is Required."
                      }
                      name="titleTermsCondition"
                      id="titleTermsCondition"
                    />
                  </Grid>
                  <Grid display="flex" item xs={12} sm={6}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      required
                      disabled={
                        preData?.termAndConditions?.docStatus == "Signed" ||
                        checkC ||
                        declinedApp
                      }
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Name"
                      error={validationError.nameTermsCondition}
                      value={values.nameTermsCondition}
                      onChange={(e) =>
                        onChange(e.target.value, "nameTermsCondition")
                      }
                      helperText={
                        validationError.nameTermsCondition &&
                        "Name is Required."
                      }
                      name="nameTermsCondition"
                      id="nameTermsCondition"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={11}
                  sm={11}
                  md={11}
                  lg={6}
                  xl={6}
                  style={{
                    alignItems: "center",
                  }}
                >
                  {text == "old boring text"
                    ? (preData?.termAndConditions?.docStatus == "Signed" ||
                        checkC) && (
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          onClick={() => handleESign()}
                        >
                          Download Document
                        </Button>
                      )
                    : ""}
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.margin}
                style={{ padding: "0 1rem" }}
              >
                <DocAlert />
                <Typography
                  style={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      preData?.termAndConditions?.docStatus == "Signed" ||
                      checkC
                    }
                    disabled
                    name="checkedC"
                    size="small"
                    style={{ marginRight: "5px" }}
                    color="primary"
                  />
                  By clicking on{" "}
                  <Button
                    variant="contained"
                    disabled={
                      isEmpty(values.nameTermsCondition) ||
                      isEmpty(values.titleTermsCondition) ||
                      preData?.termAndConditions?.docStatus == "Signed" ||
                      checkC
                    }
                    className={classes.buttonStyle}
                    onClick={() => handleESign()}
                    style={{
                      textTransform: "none",
                    }}
                  >
                    eSign Now
                  </Button>{" "}
                  I am agreeing to T/C and submitting my application for
                  underwriting.
                </Typography>
              </Grid>
            </div>
            {loading && <AppLoader loader={loading} />}
            {!loading && (
              <SignOnModal
                isDocModalOpen={isDocModalOpen}
                closeDocModal={closeDocModal}
                docLink={docLink}
              />
            )}
            <Grid
              container
              style={{ justifyContent: "center", margin: "20px 0" }}
              className={classes.margin}
            >
              {!disableSaveDraftButton(props, values) && (
                <Grid
                  container
                  spacing={2}
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    justifyContent: "inherit",
                    flexWrap: smallDevice ? "wrap" : "nowrap",
                  }}
                >
                  {/* <Grid item sm={2} xs={windowWidth ? 3 : 10}>
                    <Button
                      className={classes.clearButton}
                      variant="contained"
                      fullWidth
                      color="#696969"
                      onClick={() => handleBack()}
                    >
                      Back
                    </Button>
                  </Grid> */}
                  {text == "old boring text"
                    ? (preData?.termAndConditions?.docStatus !== "Signed" ||
                        !checkC) && (
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          id="saveDraftBusinessForm"
                          onClick={() => onFormSubbmit()}
                          disabled={
                            (ProviderStatus !== "New Entry" &&
                              ProviderStatus !== "Sent to Merchant") ||
                            declinedApp
                          }
                        >
                          Save Draft
                        </Button>
                      )
                    : ""}
                  {text == "old boring text" ? (
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      onClick={() => onFormPreviewSubmit()}
                    >
                      {!disableSaveDraftButton(props, values)
                        ? "Final Submit"
                        : "Update"}
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              )}
              {!disabled && checkKycDisabled() && values.status != 5 && (
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  onClick={() => setUpdateDescriptionModal(true)}
                >
                  Update
                </Button>
              )}
            </Grid>
          </FormControl>
        </div>
      </Card>
      {props.formData.formMode === "Edit" &&
        !declinedApp &&
        !sentToMerchantApp &&
        values.status !== 1 &&
        kycCheckListFlag &&
        props.formData.kycQuestionData &&
        props.formData.questionData && (
          <Grid
            container
            className={classes.fab}
            spacing={2}
            justifyContent="center"
          >
            <Grid item>
              <Button
                fab
                variant="fab"
                color="primary"
                aria-label="add"
                className={classes.verticalButtonStyle}
                onClick={() => setCheckListModal(true)}
                size="large"
                style={{
                  backgroundColor:
                    props.formData.questionData.confirmButton == "Approve" &&
                    "#07b707",
                }}
              >
                <Typography className={classes.kycButtonText}>
                  Checklist
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                fab
                variant="fab"
                color="primary"
                aria-label="add"
                className={classes.verticalButtonStyle}
                onClick={() => setKycListModal(true)}
                size="large"
                style={{
                  backgroundColor:
                    props.formData.kycQuestionData.confirmButton == "Approve" &&
                    "#07b707",
                }}
              >
                <Typography className={classes.kycButtonText}>
                  KYC - Checklist
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )}

      <Slide direction="left" in={kycListModal} mountOnEnter unmountOnExit>
        <Grid
          container
          style={{ flexWrap: "nowrap" }}
          className={classes.fabCheckList}
        >
          <Grid
            container
            style={{
              flexWrap: "nowrap",
              alignItems: "center",
              display: "flex",
            }}
            className={classes.tittleColorCheck}
            justifyContent="space-between"
            height={50}
          >
            <Typography className={classes.tittleTextColorList}>
              KYC-Checklist Review
            </Typography>
            <IconButton
              edge="end"
              style={{ margin: "15px" }}
              size="small"
              onClick={() => handleCheckListModalClose("kyc")}
            >
              <CloseIcon style={{ color: "white" }} fontSize="small" />
            </IconButton>
          </Grid>
          {!commentSection ? (
            <>
              <Grid
                style={{
                  display: "flex",
                  margin: "1rem 0",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                container
              >
                <Grid item xs={8}>
                  <Autocomplete
                    id="selectOwner"
                    options={[...values.ownerArray.owners]}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.Owner_First_Name} ${option.Owner_Last_Name}`
                        : ""
                    }
                    fullWidth
                    value={ownerSelect || values.ownerArray.owners[0]}
                    onChange={(event, newValue) => {
                      setOwnerSelect(newValue);
                    }}
                    disabled={checkKycFormDisabled()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Owner"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    helperText="Please select a Owner"
                  />
                </Grid>
              </Grid>
              {/* <Grid
                container
                style={{ flexWrap: "nowrap" }}
                className={classes.margin}
              >
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#ff4747" }}>
                    Pending
                  </Typography>
                  <Typography align="center" style={{ color: "#ff4747" }}>
                    {kycCheckListStatus.pending}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#f5d16e" }}>
                    In Progress
                  </Typography>
                  <Typography align="center" style={{ color: "#f5d16e" }}>
                    {kycCheckListStatus.progress}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#0abf28" }}>
                    Completed
                  </Typography>
                  <Typography align="center" style={{ color: "#0abf28" }}>
                    {kycCheckListStatus.completed}
                  </Typography>
                </Grid>
              </Grid> */}
              {props.formData.kycQuestionData &&
                props.formData.kycQuestionData.data &&
                props.formData.kycQuestionData.data.map((key, i) => (
                  <Grid
                    container
                    style={{ flexWrap: "nowrap", marginTop: "3%" }}
                  >
                    <Accordion
                      style={{ width: "inherit", margin: "5px" }}
                      elevation={1}
                      expanded={
                        accordian.index.length > 0
                          ? accordian.index.filter((v) => v === i).length > 0
                          : false
                      }
                      onChange={() => handleAccordian(i)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography
                            className={classes.fabQuesHeader}
                            color="primary"
                          >
                            {key.header}
                          </Typography>
                          <Typography
                            className={
                              key.headerStatus === "Pending"
                                ? classes.checkListStatusPending
                                : key.headerStatus === "Completed"
                                ? classes.checkListStatusComplete
                                : classes.checkListStatusInProgress
                            }
                          >
                            {key.headerStatus}
                          </Typography>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {key.list.map((item, index) => (
                          <>
                            <Grid
                              container
                              direction="column"
                              className={desiredAnsBgColor(item)}
                            >
                              <Typography className={classes.fabQues}>
                                {`${index + 1}. ${item.qusestion}`}
                                {item.isMandatory && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                                {/* <FiberManualRecordIcon
                                  className={desiredAnsBgColor(item)}
                                /> */}
                              </Typography>
                              <Grid
                                item
                                justifyContent="space-between"
                                style={{
                                  display: "flex",
                                  paddingLeft: "20px",
                                }}
                              >
                                <RadioGroup
                                  row
                                  aria-label="position"
                                  name="position"
                                  defaultValue="top"
                                >
                                  {item.options.map((keyItem) => (
                                    <FormControlLabel
                                      value={keyItem.option}
                                      style={{
                                        color: "black",
                                        fontSize: "14px",
                                      }}
                                      control={
                                        <Radio
                                          color="primary"
                                          checked={keyItem.optionSelected}
                                        />
                                      }
                                      onClick={() =>
                                        !checkKycFormDisabled() &&
                                        handleQuestionStatus(
                                          item,
                                          keyItem._id,
                                          "KYC_Checklist",
                                          { i, index },
                                          {},
                                          item.subQues,
                                          keyItem.option === "Yes"
                                        )
                                      }
                                      label={keyItem.option}
                                      disabled={checkKycFormDisabled()}
                                    />
                                  ))}
                                </RadioGroup>
                                <IconButton
                                  onClick={() =>
                                    handleCommentSection(item, "KYCChecklist")
                                  }
                                >
                                  <ChatBubbleOutlineIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                            {!isEmpty(item.subQues) &&
                              item.options[0].optionSelected && (
                                <Formik
                                  onSubmit={(v) =>
                                    commonCallForKyc(
                                      v,
                                      item,
                                      item.options[0]._id,
                                      "KYC_Checklist",
                                      { i, index }
                                    )
                                  }
                                  enableReinitialize
                                  initialValues={{
                                    quesId: item._id,
                                    parentIndex: i,
                                    currentIndex: index,
                                  }}
                                  // validationSchema={Validations.kycSubQues}
                                >
                                  {({
                                    values,
                                    setFieldValue,
                                    errors,
                                    touched,
                                  }) => (
                                    <Form>
                                      <Grid spacing={2} container>
                                        {item.subQues.map((subQues) => {
                                          if (
                                            subQues.value &&
                                            !!!values[subQues.fieldKey] &&
                                            values[subQues.fieldKey] !== ""
                                          ) {
                                            setFieldValue(
                                              subQues.fieldKey,
                                              subQues.value
                                            );
                                          }
                                          return (
                                            <Grid
                                              key={subQues.subQusId}
                                              md={6}
                                              lg={6}
                                              xl={6}
                                              sm={12}
                                              xs={12}
                                              item
                                            >
                                              {subQues.fieldType === "Text" && (
                                                <Field
                                                  as={TextField}
                                                  fullWidth
                                                  // error={
                                                  //   errors[subQues.fieldKey] &&
                                                  //   touched[subQues.fieldKey]
                                                  // }
                                                  // helperText={
                                                  //   errors[subQues.fieldKey] &&
                                                  //   touched[subQues.fieldKey]
                                                  // }
                                                  required
                                                  value={
                                                    values[subQues.fieldKey] ===
                                                    undefined
                                                      ? subQues.value
                                                      : values[subQues.fieldKey]
                                                  }
                                                  size="small"
                                                  className={classes.kycInput}
                                                  InputLabelProps={{
                                                    classes: {
                                                      root: classes.activeInputLableColor,
                                                      focused: "focused",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    classes: {
                                                      root: classes.activeInputColor,
                                                      focused: "focused",
                                                    },
                                                  }}
                                                  label={subQues.labelName}
                                                  name={subQues.fieldKey}
                                                  id={subQues.fieldKey}
                                                />
                                              )}

                                              {subQues.fieldType === "Date" &&
                                                subQues.fieldKey ===
                                                  "issueDate" && (
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <KeyboardDatePicker
                                                      as={TextField}
                                                      autoOk
                                                      fullWidth
                                                      className={
                                                        classes.kycInput
                                                      }
                                                      InputProps={{
                                                        classes: {
                                                          root: classes.activeInputColor,

                                                          focused: "focused",
                                                        },
                                                      }}
                                                      required
                                                      value={
                                                        values[
                                                          subQues.fieldKey
                                                        ] || subQues.value
                                                          ? values[
                                                              subQues.fieldKey
                                                            ] || subQues.value
                                                          : null
                                                      }
                                                      onChange={(value) =>
                                                        setFieldValue(
                                                          subQues.fieldKey,
                                                          value
                                                        )
                                                      }
                                                      views={[
                                                        "year",
                                                        "month",
                                                        "date",
                                                      ]}
                                                      label={subQues.labelName}
                                                      name={subQues.fieldKey}
                                                      id={subQues.fieldKey}
                                                      format="MM/dd/yyyy"
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                )}

                                              {subQues.fieldType === "Date" &&
                                                subQues.fieldKey ===
                                                  "dateExpires" && (
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <KeyboardDatePicker
                                                      as={TextField}
                                                      autoOk
                                                      fullWidth
                                                      className={
                                                        classes.kycInput
                                                      }
                                                      InputProps={{
                                                        color: "secondary",
                                                        classes: {
                                                          root: classes.activeInputColor,

                                                          focused: "focused",
                                                        },
                                                        classes: {
                                                          root: classes.activeInputColor,
                                                          input: classes.resize,
                                                          focused: "focused",
                                                        },
                                                      }}
                                                      minDate={values.issueDate}
                                                      required
                                                      value={
                                                        values[
                                                          subQues.fieldKey
                                                        ] || subQues.value
                                                          ? values[
                                                              subQues.fieldKey
                                                            ] || subQues.value
                                                          : null
                                                      }
                                                      onChange={(value) =>
                                                        setFieldValue(
                                                          subQues.fieldKey,
                                                          value
                                                        )
                                                      }
                                                      views={[
                                                        "year",
                                                        "month",
                                                        "date",
                                                      ]}
                                                      label={subQues.labelName}
                                                      name={subQues.fieldKey}
                                                      id={subQues.fieldKey}
                                                      format="MM/dd/yyyy"
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                )}

                                              {subQues.fieldType === "Date" &&
                                                subQues.fieldKey !==
                                                  "issueDate" &&
                                                subQues.fieldKey !==
                                                  "dateExpires" && (
                                                  <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}
                                                  >
                                                    <KeyboardDatePicker
                                                      as={TextField}
                                                      autoOk
                                                      fullWidth
                                                      required
                                                      InputProps={{
                                                        classes: {
                                                          root: classes.activeInputColor,

                                                          focused: "focused",
                                                        },
                                                      }}
                                                      className={
                                                        classes.kycInput
                                                      }
                                                      value={
                                                        values[
                                                          subQues.fieldKey
                                                        ] || subQues.value
                                                          ? values[
                                                              subQues.fieldKey
                                                            ] || subQues.value
                                                          : null
                                                      }
                                                      onChange={(value) =>
                                                        setFieldValue(
                                                          subQues.fieldKey,
                                                          value
                                                        )
                                                      }
                                                      views={[
                                                        "year",
                                                        "month",
                                                        "date",
                                                      ]}
                                                      label={subQues.labelName}
                                                      name={subQues.fieldKey}
                                                      id={subQues.fieldKey}
                                                      format="MM/dd/yyyy"
                                                    />
                                                  </MuiPickersUtilsProvider>
                                                )}
                                              {subQues.fieldType ===
                                                "Dropdown" &&
                                                subQues.fieldKey ===
                                                  "country" && (
                                                  <Autocomplete
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    className={classes.kycInput}
                                                    error={
                                                      errors[
                                                        subQues.fieldKey
                                                      ] &&
                                                      touched[subQues.fieldKey]
                                                    }
                                                    helperText={
                                                      errors[
                                                        subQues.fieldKey
                                                      ] &&
                                                      touched[subQues.fieldKey]
                                                    }
                                                    InputLabelProps={{
                                                      classes: {
                                                        root: classes.activeInputLableColor,
                                                        focused: "focused",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      classes: {
                                                        root: classes.activeInputColor,

                                                        focused: "focused",
                                                      },
                                                    }}
                                                    label="Country"
                                                    name="country"
                                                    id="country"
                                                    value={
                                                      (
                                                        props.formData
                                                          ?.countryTypeList
                                                          ?.Country_Data || []
                                                      ).find(
                                                        (option) =>
                                                          option.Country_Code ===
                                                          (values.country ||
                                                            subQues.value)
                                                      ) || {}
                                                    }
                                                    onChange={(_, value) => {
                                                      value &&
                                                        setFieldValue(
                                                          "country",
                                                          value.Country_Code
                                                        );
                                                    }}
                                                    options={
                                                      props.formData
                                                        .countryTypeList &&
                                                      props.formData
                                                        .countryTypeList
                                                        .Country_Data
                                                    }
                                                    classes={{
                                                      option: classes.option,
                                                    }}
                                                    autoHighlight
                                                    getOptionLabel={(option) =>
                                                      option.Country_Name || ""
                                                    }
                                                    renderOption={(option) => (
                                                      <>
                                                        <span
                                                          className={`country-flag ${option.Country_Code.toLowerCase().substring(
                                                            0,
                                                            2
                                                          )}`}
                                                        />
                                                        &nbsp;
                                                        <span className="country-name">
                                                          {option.Country_Name}
                                                        </span>
                                                        &nbsp;
                                                        <span className="dial-code">{`(${option.Country_Code})`}</span>
                                                      </>
                                                    )}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        label="Choose a country"
                                                        inputProps={{
                                                          ...params.inputProps,
                                                          autoComplete:
                                                            "new-password", // disable autocomplete and autofill
                                                        }}
                                                      />
                                                    )}
                                                  />
                                                )}
                                              {subQues.fieldType ===
                                                "Dropdown" &&
                                                subQues.fieldKey ===
                                                  "state" && (
                                                  <Field
                                                    as={TextField}
                                                    fullWidth
                                                    required
                                                    InputProps={{
                                                      classes: {
                                                        root: classes.activeInputColor,

                                                        focused: "focused",
                                                      },
                                                    }}
                                                    className={classes.kycInput}
                                                    error={
                                                      errors[
                                                        subQues.fieldKey
                                                      ] &&
                                                      touched[subQues.fieldKey]
                                                    }
                                                    helperText={
                                                      errors[
                                                        subQues.fieldKey
                                                      ] &&
                                                      touched[subQues.fieldKey]
                                                    }
                                                    size="small"
                                                    value={
                                                      values.state === undefined
                                                        ? subQues.value
                                                        : values.state
                                                    }
                                                    select={
                                                      (values.country ||
                                                        subQues.values) === "US"
                                                    }
                                                    InputLabelProps={{
                                                      classes: {
                                                        root: classes.activeInputLableColor,
                                                        focused: "focused",
                                                      },
                                                    }}
                                                    label="State"
                                                    name="state"
                                                    id="state"
                                                  >
                                                    {locationCity.map((key) => (
                                                      <MenuItem
                                                        className={
                                                          classes.dialogBoxDropDown
                                                        }
                                                        value={key.value}
                                                      >
                                                        {key.name}
                                                      </MenuItem>
                                                    ))}
                                                  </Field>
                                                )}
                                              {subQues.fieldType ===
                                                "Dropdown" &&
                                                subQues.fieldKey !== "state" &&
                                                subQues.fieldKey !==
                                                  "country" && (
                                                  <Field
                                                    as={TextField}
                                                    fullWidth
                                                    required
                                                    InputProps={{
                                                      classes: {
                                                        root: classes.activeInputColor,

                                                        focused: "focused",
                                                      },
                                                    }}
                                                    className={classes.kycInput}
                                                    error={
                                                      errors[
                                                        subQues.fieldKey
                                                      ] &&
                                                      touched[subQues.fieldKey]
                                                    }
                                                    helperText={
                                                      errors[
                                                        subQues.fieldKey
                                                      ] &&
                                                      touched[subQues.fieldKey]
                                                    }
                                                    size="small"
                                                    value={
                                                      values[
                                                        subQues.fieldKey
                                                      ] === undefined
                                                        ? subQues.value
                                                        : values[
                                                            subQues.fieldKey
                                                          ]
                                                    }
                                                    select
                                                    InputLabelProps={{
                                                      classes: {
                                                        root: classes.activeInputLableColor,
                                                        focused: "focused",
                                                      },
                                                    }}
                                                    label={subQues.labelName}
                                                    name={subQues.fieldKey}
                                                    id={subQues.fieldKey}
                                                  >
                                                    {[].map((key) => (
                                                      <MenuItem
                                                        className={
                                                          classes.dialogBoxDropDown
                                                        }
                                                        value={key.value}
                                                      >
                                                        {key.name}
                                                      </MenuItem>
                                                    ))}
                                                  </Field>
                                                )}
                                            </Grid>
                                          );
                                        })}
                                      </Grid>
                                      <Grid
                                        style={{
                                          marginTop: "20px",
                                          marginBottom: "20px",
                                        }}
                                        justifyContent="flex-end"
                                        container
                                      >
                                        <Grid item>
                                          <Button
                                            variant="contained"
                                            type="submit"
                                          >
                                            Save
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Form>
                                  )}
                                </Formik>
                              )}
                          </>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              <Grid
                container
                style={{
                  justifyContent: "flex-start",
                  marginTop: "10px",
                  padding: "1%",
                }}
              >
                {(props.formData.kycQuestionData.confirmButton === "Reject" ||
                  props.formData.kycQuestionData.confirmButton === "" ||
                  !props.formData.kycQuestionData.confirmButton) && (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: windowWidthLG ? "flex-start" : "center",
                    }}
                    item
                    sm={4}
                    xs={10}
                  >
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      color="#696969"
                      startIcon={<CheckCircleIcon />}
                      onClick={() => handleApproveClick("KYC")}
                      size="large"
                      disabled={checkKycFormDisabled()}
                    >
                      <Typography nowrap>Approve</Typography>
                    </Button>
                  </Grid>
                )}

                {/* {(props.formData.kycQuestionData.confirmButton === "Approve" ||
                  props.formData.kycQuestionData.confirmButton === "" ||
                  !props.formData.kycQuestionData.confirmButton) && (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: windowWidthLG ? "flex-start" : "center",
                    }}
                    item
                    sm={4}
                    xs={10}
                  >
                    <Button
                      variant="contained"
                      className={classes.clearButton}
                      color="#696969"
                      onClick={() => {
                        setDeclineModal(true);
                        setCheckListModalType("KYC");
                      }}
                      size="large"
                      startIcon={<CancelIcon />}
                    >
                      <Typography nowrap>Reject</Typography>
                    </Button>
                  </Grid>
                )} */}
                {props.formData.kycQuestionData.confirmButton && (
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: windowWidthLG ? "flex-end" : "center",
                      alignItems: "center",
                      width: "100%",
                      paddingRight: "8px",
                    }}
                  >
                    <Typography nowrap>
                      {props.formData.kycQuestionData.confirmButton} by{" "}
                      <u>{props.formData.kycQuestionData.updatedBy}</u> on{" "}
                      {props.formData.kycQuestionData.updatedOn}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                justifyContent="space-between"
                height={50}
              >
                <Typography className={classes.tittleTextColorAtteched}>
                  {`Checklist items: ${
                    selectedQuestion && selectedQuestion.qusestion
                  }`}
                </Typography>

                <IconButton onClick={() => setCommentSection(false)}>
                  <ArrowBackIcon fontSize="small" style={{ color: "#000" }} />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Comments" {...a11yProps(0)} />
                    <Tab label="Attachments" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  style={{ width: "100%" }}
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <TextareaAutosize
                      onChange={(e) => setTextAreaText(e.target.value)}
                      value={textAreaText}
                      style={{ width: "100%" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Add comment here"
                      disabled={checkKycFormDisabled()}
                    />
                    <Grid container>
                      <Grid container item xs justify="flex-end">
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          color="#696969"
                          style={{ margin: "10px" }}
                          size="sm"
                          onClick={() => sendComment()}
                          disabled={checkKycFormDisabled()}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanelAttchment
                    value={value}
                    index={1}
                    dir={theme.direction}
                  >
                    <Grid container>
                      <Grid container item xs justify="flex-end">
                        <label htmlFor="upload-photo">
                          <input
                            style={{ display: "none" }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            display="none"
                            onChange={(e) =>
                              handleUploadAttechmentOnChange(e.target.files[0])
                            }
                            disabled={checkKycFormDisabled()}
                          />

                          <Button
                            color="primary"
                            variant="contained"
                            component="span"
                            disabled={checkKycFormDisabled()}
                          >
                            New
                          </Button>
                        </label>
                      </Grid>
                    </Grid>
                    {fileHandleAttechment.files.length > 0 && (
                      <>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                          justifyContent="flex-start"
                          height={50}
                        >
                          <Typography
                            className={classes.tittleTextColorAtteched}
                          >
                            Attechments
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                          justifyContent="flex-start"
                          height={50}
                        >
                          {fileHandleAttechment.files.map((item) => (
                            <Typography className={classes.attechmentSection}>
                              {item.File_Name}
                            </Typography>
                          ))}
                        </Grid>
                      </>
                    )}
                  </TabPanelAttchment>
                </SwipeableViews>
              </Grid>
            </>
          )}
        </Grid>
      </Slide>

      <Slide direction="left" in={checkListModal} mountOnEnter unmountOnExit>
        <Grid
          container
          style={{ flexWrap: "nowrap" }}
          className={classes.fabCheckList}
        >
          <Grid
            container
            style={{
              flexWrap: "nowrap",
              alignItems: "center",
              display: "flex",
            }}
            className={classes.tittleColorCheck}
            justifyContent="space-between"
            height={50}
          >
            <Typography className={classes.tittleTextColorList}>
              Checklist-MPA Review
            </Typography>
            <IconButton
              edge="end"
              style={{ margin: "15px" }}
              size="small"
              onClick={() => handleCheckListModalClose("mpa")}
            >
              <CloseIcon style={{ color: "white" }} fontSize="small" />
            </IconButton>
          </Grid>
          {!commentSection ? (
            <>
              {/* <Grid
                container
                style={{ flexWrap: "nowrap" }}
                className={classes.margin}
              >
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#ff4747" }}>
                    Pending
                  </Typography>
                  <Typography align="center" style={{ color: "#ff4747" }}>
                    {mpaCheckListStatus.pending}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#f5d16e" }}>
                    In Progress
                  </Typography>
                  <Typography align="center" style={{ color: "#f5d16e" }}>
                    {mpaCheckListStatus.progress}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#0abf28" }}>
                    Completed
                  </Typography>
                  <Typography align="center" style={{ color: "#0abf28" }}>
                    {mpaCheckListStatus.completed}
                  </Typography>
                </Grid>
              </Grid> */}
              {props.formData.questionData &&
                props.formData.questionData.data &&
                props.formData.questionData.data.map((key, i) => (
                  <Grid
                    container
                    style={{ flexWrap: "nowrap", marginTop: "3%" }}
                  >
                    <Accordion
                      style={{ width: "inherit" }}
                      expanded={
                        accordian.index.length > 0
                          ? accordian.index.filter((v) => v === i).length > 0
                          : false
                      }
                      onChange={() => handleAccordian(i)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography
                            color="primary"
                            className={classes.fabQuesHeader}
                          >
                            {key.header}
                          </Typography>
                          <Typography
                            className={
                              key.headerStatus === "Pending"
                                ? classes.checkListStatusPending
                                : key.headerStatus === "Completed"
                                ? classes.checkListStatusComplete
                                : classes.checkListStatusInProgress
                            }
                          >
                            {key.headerStatus}
                          </Typography>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {key.list.map((item, index) => (
                          <>
                            <Grid
                              container
                              direction="column"
                              className={desiredAnsBgColor(item)}
                            >
                              <Typography className={classes.fabQues}>
                                {`${index + 1}. ${item.qusestion}`}
                                {item.isMandatory && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                                {/* <FiberManualRecordIcon
                                  className={desiredAnsBgColor(item)}
                                /> */}
                              </Typography>
                              <Grid
                                item
                                justifyContent="space-between"
                                style={{
                                  display: "flex",
                                  paddingLeft: "20px",
                                }}
                              >
                                <RadioGroup
                                  row
                                  aria-label="position"
                                  name="position"
                                  defaultValue="top"
                                >
                                  {item.options.map((keyItem, j) => (
                                    <FormControlLabel
                                      value={keyItem.option}
                                      control={
                                        <Radio
                                          color="primary"
                                          checked={keyItem.optionSelected}
                                        />
                                      }
                                      onClick={() =>
                                        !checkKycFormDisabled() &&
                                        handleQuestionStatus(
                                          item,
                                          keyItem._id,
                                          "MPA_Checklist",
                                          i
                                        )
                                      }
                                      label={keyItem.option}
                                      disabled={checkKycFormDisabled()}
                                    />
                                  ))}
                                </RadioGroup>
                                <IconButton
                                  onClick={() =>
                                    handleCommentSection(item, "MPAChecklist")
                                  }
                                >
                                  <ChatBubbleOutlineIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              <Grid
                container
                style={{
                  justifyContent: "flex-start",
                  marginTop: "10px",
                  padding: "1%",
                }}
              >
                {(props.formData.questionData.confirmButton === "Reject" ||
                  props.formData.questionData.confirmButton === "" ||
                  !props.formData.questionData.confirmButton) && (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: windowWidthLG ? "flex-start" : "center",
                    }}
                    item
                    sm={4}
                    xs={10}
                  >
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      color="#696969"
                      startIcon={<CheckCircleIcon />}
                      onClick={() => handleApproveClick("MPA")}
                      size="large"
                      disabled={checkKycFormDisabled()}
                    >
                      <Typography>Approve</Typography>
                    </Button>
                  </Grid>
                )}

                {/* {(props.formData.questionData.confirmButton === "Approve" ||
                  props.formData.questionData.confirmButton === "" ||
                  !props.formData.questionData.confirmButton) && (
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: windowWidthLG ? "flex-start" : "center"
                    }}
                    item
                    sm={4}
                    xs={10}
                  >
                    <Button
                      variant="contained"
                      className={classes.clearButton}
                      color="#696969"
                      onClick={() => {
                        setDeclineModal(true);
                        setCheckListModalType("MPA");
                      }}
                      startIcon={<CancelIcon />}
                      size="large"
                    >
                      <Typography>Reject</Typography>
                    </Button>
                  </Grid>
                )} */}
                {props.formData.questionData.confirmButton && (
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: windowWidthLG ? "flex-end" : "center",
                      alignItems: "center",
                      width: "100%",
                      paddingRight: "8px",
                    }}
                  >
                    <Typography>
                      {props.formData.questionData.confirmButton} by{" "}
                      <u>{props.formData.questionData.updatedBy}</u> on{" "}
                      {props.formData.questionData.updatedOn}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                justifyContent="space-between"
                height={50}
              >
                <Typography className={classes.tittleTextColorAtteched}>
                  {`Checklist items: ${
                    selectedQuestion && selectedQuestion.qusestion
                  }`}
                </Typography>

                <IconButton onClick={() => setCommentSection(false)}>
                  <ArrowBackIcon fontSize="small" style={{ color: "#000" }} />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Comments" {...a11yProps(0)} />
                    <Tab label="Attachments" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  style={{ width: "100%" }}
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <TextareaAutosize
                      onChange={(e) => setTextAreaText(e.target.value)}
                      value={textAreaText}
                      style={{ width: "100%" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Add comment here"
                      disabled={checkKycFormDisabled()}
                    />
                    <Grid container>
                      <Grid container item xs justify="flex-end">
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          color="#696969"
                          style={{ margin: "10px" }}
                          size="sm"
                          onClick={() => sendComment()}
                          disabled={checkKycFormDisabled()}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanelAttchment
                    value={value}
                    index={1}
                    dir={theme.direction}
                  >
                    <Grid container>
                      <Grid container item xs justify="flex-end">
                        <label htmlFor="upload-photo">
                          <input
                            style={{ display: "none" }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            display="none"
                            onChange={(e) =>
                              handleUploadAttechmentOnChange(e.target.files[0])
                            }
                            disabled={checkKycFormDisabled()}
                          />

                          <Button
                            color="primary"
                            variant="contained"
                            component="span"
                            disabled={checkKycFormDisabled()}
                          >
                            New
                          </Button>
                        </label>
                      </Grid>
                    </Grid>
                    {fileHandleAttechment.files.length > 0 && (
                      <>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                          justifyContent="flex-start"
                          height={50}
                        >
                          <Typography
                            className={classes.tittleTextColorAtteched}
                          >
                            Attechments
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                          justifyContent="flex-start"
                          height={50}
                        >
                          {fileHandleAttechment.files.map((item) => (
                            <Typography className={classes.attechmentSection}>
                              {item.File_Name}
                            </Typography>
                          ))}
                        </Grid>
                      </>
                    )}
                  </TabPanelAttchment>
                </SwipeableViews>
              </Grid>
            </>
          )}
        </Grid>
      </Slide>

      <Modal open={approveModal} onClose={() => setApproveModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCardApprove}>
            <div className={classes.cardDivApprove}>
              {!kycValidationError ? (
                <>
                  <ErrorOutlineIcon style={{ fontSize: 60, fill: "#f5d16e" }} />
                  <p style={{ textAlign: "center" }}>
                    <strong style={{ fontSize: "14px", color: "primary" }}>
                      Are you sure you want to approve the checklist?
                    </strong>
                  </p>
                </>
              ) : (
                <div
                  style={{
                    backgroundColor: "#f2d47d36",
                    padding: "1rem",
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <ErrorOutlineIcon style={{ fontSize: 60, fill: "#f5d16e" }} />
                  <strong>
                    This application cannot be processed further until required
                    checks are completed. — check it out!
                  </strong>
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                {!kycValidationError && (
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    style={{ margin: "10px" }}
                    size="sm"
                    onClick={() => approveYesClick()}
                  >
                    Yes
                  </Button>
                )}
                <Button
                  variant="contained"
                  className={classes.clearButton}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => {
                    setApproveModal(false);
                    setCheckListModalType("");
                    setKycValidationError(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={declineModal} onClose={() => setDeclineModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCardApprove}>
            <div className={classes.cardDivApprove}>
              <ErrorOutlineIcon style={{ fontSize: 60, fill: "#f5d16e" }} />
              <p>
                <strong style={{ fontSize: "14px", color: "primary" }}>
                  Are you sure you want to decline the checklist?
                </strong>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => declineYesClick()}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  className={classes.clearButton}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => {
                    setDeclineModal(false);
                    setCheckListModalType("");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal
        open={ownerDeleteModal !== false}
        onClose={() => setOwnerDeleteModal(false)}
      >
        <div className={classes.modalDiv}>
          <Card
            className={
              windowWidth ? classes.modalCard : classes.mobileModalCard
            }
          >
            <div
              style={{ display: "inline-table" }}
              className={classes.cardDiv1}
            >
              <img src={closeImage} width="75px" />

              <div
                style={{
                  textAlign: "center",
                  padding: "4%",
                  color: "gray",
                  fontSize: "14px",
                  whiteSpace: "normal",
                }}
              >
                Are you sure to remove?
              </div>
              <br />
              <Grid spacing={2} container>
                <Grid xs={4} item>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    size="sm"
                    onClick={() => deleteOwner()}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid xs={1} item />
                <Grid xs={4} item>
                  <Button
                    variant="contained"
                    className={classes.clearButton}
                    color="#696969"
                    size="sm"
                    onClick={() => setOwnerDeleteModal(false)}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </Modal>
      {props.formData.providerDetailsById &&
        props.formData.providerDetailsById.Providers_Data[0] && (
          <MerchantVersion componentRef={itemRef} style={{ display: "none" }} />
        )}
    </div>
  );
}

BusinessForm.propTypes = {
  provideRegistration: PropTypes.func,
  formData: PropTypes.object,
  getMccList: PropTypes.func,
  getCountryTypeList: PropTypes.func,
  accountTypeList: PropTypes.func,
  ownerTypeList: PropTypes.func,
  updateRegistration: PropTypes.func,
  getProviderStatus: PropTypes.func,
  mainData: PropTypes.object,
  history: PropTypes.object,
  getProviderActivityById: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  getProviderParameterCall: PropTypes.func,
  deleteIncroptration: PropTypes.func,
  deleteAddressProof: PropTypes.func,
  deleteIdentityProof: PropTypes.func,
  getQuestionData: PropTypes.func,
  saveQuestionCall: PropTypes.func,
  getKycQuestionData: PropTypes.func,
  statusPreameter: PropTypes.func,
  statusPreameterKyc: PropTypes.func,
  statusApproveMpa: PropTypes.func,
  statusApproveKyc: PropTypes.func,
  setResetWuestion: PropTypes.func,
  mpaChecklistComment: PropTypes.func,
  kycChecklistAttechment: PropTypes.func,
  mpaChecklistAttechment: PropTypes.func,
  changeProviderFormMode: PropTypes.func,
  pricingTempleteCall: PropTypes.func,
  setPricingTempleteEmpty: PropTypes.func,
  getKycResponseFromAlloy: PropTypes.func,
  postKycSubQuestions: PropTypes.func,
  getKycResponseUserList: PropTypes.func,
  saveQuestionCallAction: PropTypes.func,
  postKycQuestionsCall: PropTypes.func,
  getProviderDetailsById: PropTypes.func,
  clearRegData: PropTypes.func,
  deleteBankDocumentApiCall: PropTypes.func,
  deleteOwnerApiCall: PropTypes.func,
  getSignNowCall: PropTypes.func,
  roles: PropTypes.object,
  getDocStatus: PropTypes.func,
  app: PropTypes.object,
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app,
  roles: app.roles,
  app: app.app,
});

export default connect(mapStateToProps, {
  provideRegistration,
  getKycResponseFromAlloy,
  clearRegData,
  getMccList,
  getKycQuestionData,
  getCountryTypeList,
  accountTypeList,
  ownerTypeList,
  updateRegistration,
  getProviderStatus,
  getProviderActivityById,
  changeSingleStatus,
  getProviderParameterCall,
  deleteIncroptration,
  deleteAddressProof,
  deleteIdentityProof,
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  saveQuestionCall,
  setResetWuestion,
  postKycSubQuestions,
  mpaChecklistComment,
  kycChecklistAttechment,
  changeProviderFormMode,
  pricingTempleteCall,
  setPricingTempleteEmpty,
  mpaChecklistAttechment,
  getQuestionData,
  deleteOwnerApiCall,
  getKycResponseUserList,
  postKycQuestionsCall,
  saveQuestionCallAction,
  getProviderDetailsById,
  deleteBankDocumentApiCall,
  getSignNowCall,
  getDocStatus,
})(BusinessForm);
