import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import isEmpty from "lodash/isEmpty";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  getRoleDetailsById,
  changeRoleFormMode,
  getLandingPageList,
} from "../../Redux/Actions/RoleRegistration";
import {
  exportToCSV,
  actionPermission,
  permissionCheck,
  exportToXLS,
} from "../../Utils/commonUtils";

const useStyles = makeStyles(styles);

function LongMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [userOptions, setUserOptions] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    if (type === "edit") {
      props.getRoleDetailsById(props.details);
      props.getLandingPageList(props.profileCode);
      props.changeRoleFormMode("Edit");
    }
    setAnchorEl(null);
  };

  const associateclientForm = () => {
    props.openassociateModel();
    props.setStatusPid();
    handleClose();
  };

  const openStatusForm = () => {
    props.openForm();
    props.setStatusPid();
    handleClose();
  };

  React.useEffect(() => {
    if (props.mainData.roles.roleTab && !userOptions) {
      const filterSetUp =
        props.mainData.roles.roleTab.Roles_Data.Master_Permission.filter(
          (key) => key.Cat_Code === 2
        );
      const filterPageMenu =
        filterSetUp.length > 0 &&
        filterSetUp[0].Page_Menu.filter((key) => key.Page_Code === 3);
      const filterOption =
        filterPageMenu &&
        filterPageMenu[0]?.Options?.filter((key) => key?.value === permissionCheck.option.changeStatus);
      setUserOptions(filterOption[0]);
    }
  }, []);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {actionPermission(
          permissionCheck.Category.setup,
          permissionCheck.Page.roles,
          permissionCheck.option.view
        ) && (
          <MenuItem className={classes.dialogBoxDropDown} onClick={handleClose}>
            View
          </MenuItem>
        )}
        {props.editable && (
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => handleClose("edit")}
          >
            Edit
          </MenuItem>
        )}
        {actionPermission(
          permissionCheck.Category.setup,
          permissionCheck.Page.roles,
          permissionCheck.option.changeStatus
        ) && (
          <MenuItem
            disabled={isEmpty(userOptions) ? true : !userOptions.permission}
            className={classes.dialogBoxDropDown}
            onClick={openStatusForm}
          >
            Change Status
          </MenuItem>
        )}

        {localStorage.getItem("clientId") ===
          process.env.REACT_APP_ACCESS_SERVICE_PROVIDER_ID && (
          <>
            {actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.roles,
              permissionCheck.option.associatedUsers
            ) && (
              <MenuItem
                className={classes.dialogBoxDropDown}
                onClick={() => associateclientForm()}
              >
                Associated Users
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  details: PropTypes.string,
  getRoleDetailsById: PropTypes.func,
  changeRoleFormMode: PropTypes.func,
  openForm: PropTypes.func,
  setStatusPid: PropTypes.func,
  getLandingPageList: PropTypes.func,
  editable: PropTypes.bool,
  mainData: PropTypes.object,
  openassociateModel: PropTypes.func,
  roles: PropTypes.object,
};

const mapStateToProps = (app) => ({
  formData: app.roles,
  mainData: app,
  roles: app.roles,
});

export default connect(mapStateToProps, {
  getRoleDetailsById,
  changeRoleFormMode,
  getLandingPageList,
})(LongMenu);
