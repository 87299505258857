import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { actionPermission } from "../../Utils/commonUtils";
import { checkFormDisabled } from "../../views/UserProfile/SetRoleBase";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  inputSelect: {
    fontSize: "80%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
  },
  inputLableSelect: {
    fontSize: "80%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark,
  },
  menuItem: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.contrastText,
  },
  listItemText: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

function MultipleSelect(props) {
  const classes = useStyles();
  const [personName, setPersonName] = React.useState(
    props.values ? props.values : []
  );
  const [preValue, setPreValue] = React.useState(false);
  const [checkBox, setCheckBox] = React.useState(false);
  const names = !props.data.Options ? props.data : props.data.Options;
  const res = (array) => {
    if (array.includes("View") || array.includes("view")) {
      return true;
    } else {
      return false;
    }
  };
  if (
    !preValue &&
    !personName.length &&
    (!props.data.Options ? props.data : props.data.Options)
  ) {
    const value = [];
    if (props.data.Options) {
      props.data.Options.forEach((v) => {
        if (v.permission) {
          value.push(v.name);

          // value.push({value: v.value, name:v.name});
        }
      });
      setPersonName(value);
      setPreValue(!preValue);
    } else if (props.lable && props.values) {
      setPersonName(props.values);
      setPreValue(!preValue);
    } else if (props.roles) {
      setPersonName(props.values);
    }
  }

  const handleChange = (event) => {
    if (!res(event.target.value)) {
      setPersonName([]);
      props.onChangeSelect([]);
      props.handleSwicth(props.data)
    } else if (
      event.target.value.length == 1 &&
      res(event.target.value)
    ) {
      setPersonName(event.target.value);
      props.onChangeSelect(event.target.value);
      const value = [];
      if (props.data.Options) {
        props.data.Options.forEach((v) => {
          if (v.permission) {
            value.push(v.name);
          }
        });
        setPersonName(value);
        props.onChangeSelect(value);
      }
    } else {
      setPersonName(event.target.value);
      props.onChangeSelect(event.target.value);
    }
  };

  const disabled =
    props.formData.formMode === "Edit"
      ? checkFormDisabled(props) ||
        !actionPermission( 2, 3, 5)
      : !actionPermission( 2, 3, 5);

  return (
    <div style={{ width: `${props.lable ? "72%" : "100%"}` }}>
      <FormControl className={classes.formControl}>
        <InputLabel
          className={classes.inputLableSelect}
          id="demo-mutiple-checkbox-label"
        >
          {`${!props.data.Options ? "Providers Accessible" : "Status"}`}
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input className={classes.inputSelect} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {props.data.Options &&
            names.map((v) => (
              <MenuItem
                value={v.name}
                disabled={disabled}
                className={classes.menuItem}
                style={{ background: "#fff" }}
              >
                <Checkbox
                  // onClick={handelClickBox}
                  checked={personName.indexOf(v.name) > -1}
                />
                <ListItemText
                  className={classes.listItemText}
                  primary={v.name}
                />
              </MenuItem>
            ))}
          {!props.data.Options &&
            names.map((v) => (
              <MenuItem
                value={v.Porvider_Id}
                className={classes.menuItem}
                style={{ background: "#fff" }}
              >
                <Checkbox
                  checked={personName.indexOf(v.Porvider_Id) > -1}
                  color="secondary"
                />
                <ListItemText
                  className={classes.listItemText}
                  primary={v.ProviderData}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

MultipleSelect.propTypes = {
  data: PropTypes.any,
  onChangeSelect: PropTypes.func,
  values: PropTypes.array,
  lable: PropTypes.bool,
  roles: PropTypes.bool,
  formData: PropTypes.object,
  handleSwicth : PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.users,
  userRole: app.userRole,
  roles: app.roles,
  
});

export default connect(mapStateToProps, {
 
})(MultipleSelect);

