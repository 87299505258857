/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
import React from "react";
// @material-ui/core components
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
// import InputLabel from "@material-ui/core/InputLabel";
import isEmpty from "lodash/isEmpty";
// core components
import TextField from "@material-ui/core/TextField";
// import FormControl from "@material-ui/core/FormControl";
// import MenuItem from "@material-ui/core/MenuItem";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { Typography, Checkbox, MenuItem } from "@material-ui/core";
import closeImage from "assets/img/Close_Image.png";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { Field, Form, Formik } from "formik";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import Modal from "@material-ui/core/Modal";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { locationCity } from "../../constant/locationConstant";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import moment from "moment";
import gif from "../../assets/img/success.gif";
import { callOnBoarding } from "../../Redux/Actions/OnBordingActions";
import Validations from "../../Utils/Validations";
import {
  sendMailAgain,
  getPricingTemplete,
  getApplicationType,
  getProccesorList
} from "../../actions/MerchantSignUp";
import {
  pricingTempleteCallInvite,
  setPricingTempleteEmpty,
  getInviteEquipmentData
} from "../../Redux/Actions/ProvideRegistration";
import EquipmentInfoTree from "../AddtionalFields/EquipmentInfo";
import { actionPermission } from "../../Utils/commonUtils";
import { Container } from "@material-ui/core";
import AppLoader from "components/AppLoader/Index";

const useStyles = makeStyles(styles);

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    display: "none"
  }
}))((props) => <TreeItem {...props} />);

function OnBording(props) {
  const theme = useTheme();
  const [formMode, setFormMode] = React.useState(true);
  const [showSelectedItem, setShowSelectedItem] = React.useState(true);
  const [data, setData] = React.useState(false);
  const [equipmentData, setEquipmentData] = React.useState(false);
  const [pricingData, setPricingData] = React.useState(false);
  const [serviceListExpanded, setServiceListExpanded] = React.useState(false);
  const [equipmentListExpanded, setEquipmentListExpanded] =
    React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const windowWidth = useMediaQuery(theme.breakpoints.up("sm"));
  const [selectedEquipmentCode, setSelectedEquipmentCode] = React.useState([]);
  const [seletctQuantity, setQuantity] = React.useState([]);
  const [pricingTemplateList, setPricingTemplateList] = React.useState([]);
  const [proccssorList, setProccssorList] = React.useState([]);
  const [pricingTemplateValue, setPricingTemplateValue] = React.useState("");
  const [applicationTypeList, setApplicationTypeList] = React.useState([]);
  const [applicationTypeID, setApplicationTypeID] = React.useState("");
  const [bla, setBla] = React.useState({
    processId: "",
    applicationType: "",
    templateId: ""
  });
  const [preOnBoradData, setPreOnBoradData] = React.useState(
    props.mainData.onBoarding.data
  );
  const [initialCallLoading, setInitialCallLoading] = React.useState(true);
  const initialValues = {
    Profile_Code: "Merchant",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Email: "",
    Password: "Temp@1234",
    Role_ID: "6311bd99a224d13a8fad2704",
    Address1: "Testst",
    Address2: "",
    City: "AL",
    Mobile: "9874586321",
    Phone_No: "",
    Country_Code: "US",
    State_Code: "AR",
    Zip: "00124",
    Time_Zone_Code: "AKST",
    Accessible_Provider: ["H001"],
    ClientID: "H001",
    CreateByClientId: "H001",
    ClientId: "H001",
    legalName: "",
    dbaName: "",
    formType: "MerchantSetup",
    applicationType: "",
    Created_By: localStorage.getItem("userId")
  };

  const [successModal, setSuccessModal] = React.useState(false);
  const classes = useStyles();
  const [tempValues, setTempValues] = React.useState({});

  const handelValidation = (valueEquiment) => {
    let validate = true;
    valueEquiment.forEach((key) => {
      key.equipmentItem.forEach((item) => {
        item.equipmentType.forEach((innerItem) => {
          if (innerItem.selected && innerItem.activeCheckbox) {
            if (
              innerItem.unitPrice === "" ||
              parseFloat(innerItem.Max_Unit_Price.replace(/,/g, "")) <
                parseFloat(innerItem.unitPrice.replace(/,/g, "")) ||
              parseFloat(innerItem.Min_Unit_Price.replace(/,/g, "")) >
                parseFloat(innerItem.unitPrice.replace(/,/g, ""))
            ) {
              validate = false;
            }
          }
        });
      });
    });
    return validate;
  };

  const handleOnChangeProccess = (data, type) => {
    const allValue = { ...bla };
    allValue[type] = data;
    if (type === "processId") {
      allValue.applicationType = "";
      allValue.templateId = "";
      props.setPricingTempleteEmpty();
    }
    if (type === "applicationType") {
      allValue.templateId = "";
      props.setPricingTempleteEmpty();
    }
    setBla({ ...allValue });
  };

  const handleSubmit = (values) => {
    setTempValues(values);
    const addCreatedBy = { ...values };
    if (isEmpty(addCreatedBy.dbaName)) {
      addCreatedBy.dbaName = addCreatedBy.legalName;
    }
    addCreatedBy.Created_By = localStorage.getItem("userId");
    if (handelValidation(equipmentData)) {
      addCreatedBy.merchantTemplate = {
        ...pricingData,
        Pricing: data,
        Equipment: equipmentData
      };
      props.callOnBoarding({ ...addCreatedBy });
    } else {
      enqueueSnackbar("Please add valid value in unit price", {
        variant: "error"
      });
    }
  };

  const handleModal = () => {
    setSuccessModal(false);
    if (props.mainData.onBoarding.data.status) {
      props.history.push("/admin/Merchant");
    }
  };

  React.useEffect(() => {
    if (
      data !== props.mainData.provideRegistration.pricingTemplete &&
      props.mainData.provideRegistration.pricingTemplete.data
    ) {
      setData(props.mainData.provideRegistration.pricingTemplete.data.Pricing);
      setPricingData(props.mainData.provideRegistration.pricingTemplete.data);
    }
    if (
      data.length > 0 &&
      !props.mainData.provideRegistration.pricingTemplete
    ) {
      setData(false);
      setPricingData(false);
    }
  }, [props.mainData.provideRegistration.pricingTemplete]);

  React.useEffect(() => {
    if (
      equipmentData !== props.mainData.provideRegistration.Equipment &&
      props.mainData.provideRegistration.Equipment
    ) {
      props.mainData.provideRegistration.Equipment.data.forEach(
        (key, index) => {
          if (key.activeCheckbox) {
            const allEquimentItem = key.equipmentItem.flatMap((itemActive, i) =>
              itemActive.activeCheckbox ? i : []
            );
            key.equipmentItem.forEach((innerkey, innerIndex) => {
              if (innerkey.activeCheckbox) {
                if (key.successorEnable) {
                  const allIndex = innerkey.equipmentType.flatMap(
                    (typeItem, i) => (typeItem.activeCheckbox ? i : [])
                  );
                  key.equipmentItem[allEquimentItem[0]].selected = true;
                  key.equipmentItem[allEquimentItem[0]].disbaledCheckBox = true;
                  innerkey.equipmentType.forEach((typeKey, typeIndex) => {
                    if (
                      allIndex.filter((newIndex) => newIndex === typeIndex)
                        .length > 0
                    ) {
                      key.equipmentItem[allEquimentItem[0]].equipmentType[
                        typeIndex
                      ].selected = true;
                      if (
                        key.equipmentItem[allEquimentItem[0]].equipmentType[
                          typeIndex
                        ].qtyTo ===
                        key.equipmentItem[allEquimentItem[0]].equipmentType[
                          typeIndex
                        ].qtyFrom
                      ) {
                        if (key.equipmentItem[allEquimentItem[1]]) {
                          key.equipmentItem[
                            allEquimentItem[1]
                          ].disbaledCheckBox = true;
                        }
                      }
                    }
                  });
                } else {
                  const allIndex = innerkey.equipmentType.flatMap(
                    (typeItem, i) => (typeItem.activeCheckbox ? i : [])
                  );
                  innerkey.selected = true;
                  innerkey.equipmentType[allIndex[0]].selected = true;
                  innerkey.disbaledCheckBox = true;
                }
              }
            });
          }
        }
      );
      setEquipmentData(props.mainData.provideRegistration.Equipment.data);
    }
    if (
      equipmentData.length > 0 &&
      !props.mainData.provideRegistration.Equipment
    ) {
      setEquipmentData(false);
    }
  }, [props.mainData.provideRegistration.Equipment]);

  React.useEffect(() => {
    props.getPricingTemplete(
      (data) => {
        if (data.status) {
          setPricingTemplateList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    props.getApplicationType(
      (data) => {
        if (data.status) {
          setApplicationTypeList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
    props.getProccesorList(
      (data) => {
        if (data.status) {
          setProccssorList(data.data);
          setInitialCallLoading(false)
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  }, []);

  const resendMailApiCall = () => {
    props.sendMailAgain(
      { email: tempValues.Email },
      (data) => {
        if (data.status) {
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
        handleModal();
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
        handleModal();
      }
    );
  };
  React.useEffect(
    () => () => {
      props.setPricingTempleteEmpty();
    },
    []
  );

  const handleFocus = (data) => {
    setTimeout(() => document.getElementById(data).focus(), 50);
  };

  React.useEffect(() => {
    if (props.mainData.onBoarding.data !== preOnBoradData) {
      if (props.mainData.onBoarding.data) {
        setSuccessModal(true);
        
      }
      setPreOnBoradData(props.mainData.onBoarding.data);
      
    }
    
  }, [props]);

  React.useEffect(() => {
    if (bla.processId !== "" && bla.templateId !== "") {
      setInitialCallLoading(false);
      const data = {
        templateId: bla.templateId,
        processorId: bla.processId
      };
      props.pricingTempleteCallInvite(data);
      props.getInviteEquipmentData(data);
    } else {
      
      setPricingData(false);
      setData(false);
      setEquipmentData(false);
    }
    
  }, [bla]);

  const showIempMin = (data) => {
    const returnArray = [];
    const ticker = data.perItemTicker * 10000;
    const to = data.perItemTo * 10000;
    const from = data.perItemFrom * 10000;
    if (data.perItemTicker !== 0) {
      if (data.perItemTicker % 1 !== 0) {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      } else {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      }
    }
    if (ticker === 0 && to === 0 && from === 0) {
      returnArray.push("0");
    }
    if (
      returnArray.filter((key) => Number(key) === Number(data.perItemRate))
        .length === 0
    ) {
      returnArray.unshift("Custom");
    }
    return returnArray;
  };

  const onPricingChange = (value, type) => {
    const updatedPricingData = { ...pricingData };
    if (type === "Notes") {
      updatedPricingData.Notes = value;
    }
    if (type === "TermLength") {
      updatedPricingData.TermLength = value;
    }
    setPricingData({ ...updatedPricingData });
  };

  const onChange = (value, type, mainIndex, itemIndex) => {
    const updatedDate = [...data];
    if (type !== "check") {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = value;
    } else {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = 0;
      updatedDate[mainIndex].categoryItem[itemIndex].activeCheckbox = value;
    }
    if (type === "text") {
      handleFocus(updatedDate[mainIndex].categoryItem[itemIndex].itemName);
    }
    setData([...updatedDate]);
  };
  // React.useEffect(() => {
  //   if (!isEmpty(props.mainData.onBoarding.data)) {
  //     setInitialCallLoading(false);
  //   } else {
  //     setInitialCallLoading(true);
  //   }
  // }, [props]);

  // active pricing template
  const activeTemplate =
    pricingTemplateList &&
    pricingTemplateList.filter((key) => key.status === "Active");

  return (
    <div>
       {initialCallLoading ? (
          <AppLoader loader={initialCallLoading} />
      ):(<>
      {actionPermission(2, 6, 1) && (
        <>
      <Modal open={successModal} onClose={() => handleModal(false)}>
        <div className={classes.modalDiv}>
          <Card
            className={
              windowWidth ? classes.modalCard : classes.mobileModalCard
            }
          >
            <div
              style={{ display: "inline-table" }}
              className={classes.cardDiv1}
            >
              <img
                src={
                  props.mainData.onBoarding.data &&
                  props.mainData.onBoarding.data.status
                    ? gif
                    : closeImage
                }
                width="75px"
              />

              <div
                style={{
                  textAlign: "center",
                  padding: "4%",
                  color: "gray",
                  fontSize: "14px",
                  whiteSpace: "normal"
                }}
              >
                {props.mainData.onBoarding.data &&
                props.mainData.onBoarding.data.status
                  ? props.mainData.onBoarding.data.message
                  : props.mainData.onBoarding.data.message}
              </div>
              <br />
              {props.mainData.onBoarding.data.status === false &&
              props.mainData.onBoarding.data.message ===
                "Signup invitation has been already sent to this email id. Do you want to send invitation again?" ? (
                <Grid justifyContent="center" spacing={2} container>
                  <Grid xs={4} item>
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      color="#696969"
                      size="sm"
                      onClick={resendMailApiCall}
                    >
                      Yes
                    </Button>
                  </Grid>
                  <Grid xs={4} item>
                    <Button
                      variant="contained"
                      className={classes.clearButton}
                      color="#696969"
                      size="sm"
                      onClick={() => handleModal()}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  size="sm"
                  onClick={() => handleModal()}
                >
                  Ok
                </Button>
              )}
            </div>
          </Card>
        </div>
      </Modal>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center"
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Typography className={classes.tittleTextColor}>
            Merchant Setup
          </Typography>
        </Box>
        <Formik
          // innerRef={values => { formik.current = values; }}
          validationSchema={Validations.merchantSignUp}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>
              <Box style={{ paddingTop: "20px" }} className={classes.marginBox}>
                <Grid container>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      helperText={
                        errors.First_Name && touched.First_Name
                          ? errors.First_Name
                          : ""
                      }
                      error={errors.First_Name && touched.First_Name}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="First Name"
                      name="First_Name"
                      id="First_Name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Last Name"
                      name="Last_Name"
                      helperText={
                        errors.Last_Name && touched.Last_Name
                          ? errors.Last_Name
                          : ""
                      }
                      error={errors.Last_Name && touched.Last_Name}
                      id="Last_Name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <EmailIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Email"
                      name="Email"
                      helperText={
                        errors.Email && touched.Email ? errors.Email : ""
                      }
                      error={errors.Email && touched.Email}
                      id="Email"
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <EventNoteIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <Field
                      as={TextField}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Legal Name"
                      name="legalName"
                      id="legalName"
                      helperText={
                        errors.legalName && touched.legalName
                          ? errors.legalName
                          : ""
                      }
                      error={errors.legalName && touched.legalName}
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <EventNoteIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <Field
                      as={TextField}
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="DBA Name"
                      name="dbaName"
                      id="dbaName"
                      helperText={
                        errors.dbaName && touched.dbaName ? errors.dbaName : ""
                      }
                      error={errors.dbaName && touched.dbaName}
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      select
                      required
                      onChange={(e) => {
                        setFieldValue("processId", e.target.value);
                        handleOnChangeProccess(e.target.value, "processId");
                      }}
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Processor"
                      name="processId"
                      id="processId"
                    >
                      {proccssorList.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      select
                      required
                      disabled={bla.processId === ""}
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("applicationType", e.target.value);
                        handleOnChangeProccess(
                          e.target.value,
                          "applicationType"
                        );
                        setApplicationTypeID(e.target.value);
                      }}
                      label="Application Type,"
                      name="applicationType"
                      id="applicationType"
                    >
                      {applicationTypeList.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    style={{ display: "flex", alignItems: "center" }}
                    item
                    lg={4}
                    sm={4}
                    xs={12}
                  >
                    <EmailIcon className={classes.inputBox} fontSize="small" />
                    <Autocomplete
                      options={
                        activeTemplate.sort((a, b) =>
                          a.name.toString().localeCompare(b.name.toString())
                        ) || []
                      }
                      getOptionLabel={(option) => (option ? option.name : "")}
                      required
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      disabled={
                        bla.processId === "" || bla.applicationType === ""
                      }
                      onChange={(e, value) => {
                        setFieldValue("pricingTemplate", value._id);
                        setPricingTemplateValue(value._id);
                        handleOnChangeProccess(value._id, "templateId");
                      }}
                      label="Pricing Template"
                      name="pricingTemplate"
                      autoHighlight
                      // value={
                      //   (activeTemplate || []).find(
                      //     (option) => option._id === values.pricingTemplate
                      //   ) || ""
                      // }
                      id="pricingTemplate"
                      renderInput={(params) => (
                        <TextField {...params} label="Pricing Template" />
                      )}
                    />
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center"
                    // padding: "4%",
                  }}
                >
                  {pricingData &&
                    pricingData.Pricing &&
                    pricingData.Pricing.length > 0 && (
                      <Box style={{ margin: "15px 30px" }}>
                        <Box
                          style={{
                            alignItems: "center"
                          }}
                          height={40}
                        >
                          <Typography className={classes.userTitleTextColor}>
                            Pricing Template:
                            {pricingTemplateList.map((key) =>
                              key._id === pricingTemplateValue ? key.name : ""
                            )}
                          </Typography>
                        </Box>
                        <Box className={classes.marginBox}>
                          <Grid container>
                            <Grid
                              item
                              lg={3}
                              md={3}
                              sm={3}
                              xs={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px"
                              }}
                            >
                              <span style={{ fontSize: "15px" }}>
                                Term Length (months)
                              </span>
                              <TextField
                                style={{
                                  width: "70px",
                                  padding: "0px 5px",
                                  margin: "0px"
                                }}
                                rows={1}
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={pricingData.TermLength}
                                onChange={(e) =>
                                  onPricingChange(e.target.value, "TermLength")
                                }
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused"
                                  }
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resize,
                                    focused: "focused"
                                  }
                                }}
                                name="TermLength"
                                id="TermLength"
                              />
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              xs={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                                marginLeft: "auto",
                                float: "right"
                              }}
                            >
                              <span style={{ fontSize: "15px" }}>
                                Description
                              </span>
                              <Field
                                as={TextField}
                                style={{
                                  padding: "0px 5px",
                                  margin: "0px"
                                }}
                                multiline
                                rows={2}
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={pricingData.Notes}
                                onChange={(e) =>
                                  onPricingChange(e.target.value, "Notes")
                                }
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused"
                                  }
                                }}
                                InputProps={{
                                  classes: {
                                    root: classes.activeInputColor,
                                    input: classes.resize,
                                    focused: "focused"
                                  }
                                }}
                                name="Notes"
                                id="Notes"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  {data && data.length > 0 && (
                    <div
                      style={{
                        border: "1px solid lightgrey",
                        margin: "0px 20px"
                      }}
                    >
                      <TreeView>
                        <StyledTreeItem
                          nodeId="1"
                          label={
                            <div className={classes.lableTextColor}>
                              <span>Services and Fees</span>
                              {serviceListExpanded && <ExpandMoreIcon />}
                              {!serviceListExpanded && <ChevronRightIcon />}
                            </div>
                          }
                          onClick={() =>
                            setServiceListExpanded(!serviceListExpanded)
                          }
                        >
                          {data &&
                            data
                              .sort((a, b) =>
                                a.categoryName > b.categoryName ? 1 : -1
                              )
                              .map((key, mainIndex) => (
                                <>
                                  <Box
                                    style={{
                                      alignItems: "center",
                                      marginTop: "10px"
                                    }}
                                    key={Math.random()}
                                    className={classes.usertittleBGColor}
                                    height={40}
                                  >
                                    <Typography
                                      className={classes.userTitleTextColor}
                                    >
                                      {key.categoryName}
                                    </Typography>
                                  </Box>
                                  <Box className={classes.marginBox}>
                                    <Grid container className={classes.margin}>
                                      {key.categoryItem
                                        .sort((a, b) =>
                                          a.itemName > b.itemName ? 1 : -1
                                        )
                                        .map((item, index) => (
                                          <>
                                            <Grid
                                              key={Math.random()}
                                              item
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                padding: "15px"
                                              }}
                                              lg={6}
                                              sm={6}
                                              xs={12}
                                            >
                                              <div style={{ minWidth: "5%" }}>
                                                <Checkbox
                                                  checked={item.activeCheckbox}
                                                  onChange={(e) =>
                                                    onChange(
                                                      e.target.checked,
                                                      "check",
                                                      mainIndex,
                                                      index
                                                    )
                                                  }
                                                  inputProps={{
                                                    "aria-label":
                                                      "select all desserts"
                                                  }}
                                                  color="primary"
                                                />
                                              </div>
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  lineHeight: "20px",
                                                  maxWidth: "25%",
                                                  minWidth: "25%",
                                                  padding: "1%"
                                                }}
                                              >
                                                {item.itemName}
                                              </span>
                                              <div
                                                style={{
                                                  maxWidth: "25%",
                                                  minWidth: "25%",
                                                  padding: "1%"
                                                }}
                                              >
                                                <TextField
                                                  className={
                                                    classes.quaterInputPricing
                                                  }
                                                  style={{
                                                    minWidth: "75%",
                                                    fontSize: "14px"
                                                  }}
                                                  key={Math.random()}
                                                  disabled={
                                                    !item.activeCheckbox
                                                  }
                                                  InputLabelProps={{
                                                    classes: {
                                                      root: classes.activeInputLableColor,
                                                      focused: "focused"
                                                    }
                                                  }}
                                                  InputProps={{
                                                    classes: {
                                                      root: classes.activeInputColor,
                                                      input:
                                                        classes.resizePrice,
                                                      focused: "focused"
                                                    }
                                                  }}
                                                  defaultValue="Custom"
                                                  value={
                                                    item.perItemTicker % 1 !== 0
                                                      ? Number(item.perItemRate)
                                                          .toFixed(4)
                                                          .toString()
                                                      : item.perItemRate
                                                  }
                                                  onChange={(e) =>
                                                    onChange(
                                                      e.target.value,
                                                      item,
                                                      mainIndex,
                                                      index
                                                    )
                                                  }
                                                  name={Math.random()
                                                    .toString(36)
                                                    .substr(2, 5)}
                                                  id={Math.random()
                                                    .toString(36)
                                                    .substr(2, 5)}
                                                  select
                                                >
                                                  {showIempMin(
                                                    item,
                                                    index,
                                                    key.categoryItem.length
                                                  ).length > 0 &&
                                                    showIempMin(
                                                      item,
                                                      index,
                                                      key.categoryItem.length
                                                    ).map((number) => (
                                                      <MenuItem
                                                        key={Math.random()}
                                                        className={
                                                          classes.dialogBoxDropDown
                                                        }
                                                        value={
                                                          number === "Custom"
                                                            ? item.perItemTicker %
                                                                1 !==
                                                              0
                                                              ? Number(
                                                                  item.perItemRate
                                                                )
                                                                  .toFixed(4)
                                                                  .toString()
                                                              : item.perItemRate
                                                            : number
                                                        }
                                                      >
                                                        {number}
                                                      </MenuItem>
                                                    ))}
                                                </TextField>
                                              </div>
                                              <div
                                                style={{
                                                  maxWidth: "25%",
                                                  minWidth: "25%",
                                                  padding: "1%",
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  alignItems: "center"
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    lineHeight: "20px",
                                                    maxWidth: "25%",
                                                    minWidth: "25%",
                                                    padding: "1%",
                                                    textAlign: "end"
                                                  }}
                                                >
                                                  {item.rateTypeSing === "$"
                                                    ? item.rateTypeSing
                                                    : ""}
                                                </span>
                                                <TextField
                                                  className={
                                                    classes.quaterInputPricing
                                                  }
                                                  key={Math.random()}
                                                  InputLabelProps={{
                                                    classes: {
                                                      root: classes.activeInputLableColor,
                                                      focused: "focused"
                                                    }
                                                  }}
                                                  size="small"
                                                  InputProps={{
                                                    classes: {
                                                      root: classes.activeInputColor,
                                                      input:
                                                        classes.resizePrice,
                                                      focused: "focused"
                                                    }
                                                  }}
                                                  variant="outlined"
                                                  style={{
                                                    backgroundColor: "#f9f7f7",
                                                    minWidth: "75%"
                                                  }}
                                                  value={item.perItemRate}
                                                  disabled={
                                                    !item.activeCheckbox
                                                  }
                                                  onChange={(e) =>
                                                    onChange(
                                                      e.target.value,
                                                      "text",
                                                      mainIndex,
                                                      index
                                                    )
                                                  }
                                                  name={item.itemName}
                                                  id={item.itemName}
                                                />
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    lineHeight: "20px",
                                                    maxWidth: "25%",
                                                    minWidth: "25%",
                                                    padding: "1%"
                                                  }}
                                                >
                                                  {item.rateTypeSing !== "$"
                                                    ? item.rateTypeSing
                                                    : ""}
                                                </span>
                                              </div>
                                            </Grid>
                                          </>
                                        ))}
                                    </Grid>
                                  </Box>
                                </>
                              ))}
                        </StyledTreeItem>
                        {equipmentData && equipmentData.length > 0 && (
                          <StyledTreeItem
                            nodeId="2"
                            label={
                              <div className={classes.lableTextColor}>
                                <span>Equipment Fees/Charges</span>
                                {equipmentListExpanded && <ExpandMoreIcon />}
                                {!equipmentListExpanded && <ChevronRightIcon />}
                              </div>
                            }
                            // label="Services and Fees"
                            onClick={() =>
                              setEquipmentListExpanded(!equipmentListExpanded)
                            }
                          >
                            <Box style={{ margin: "15px 30px" }}>
                              <EquipmentInfoTree
                                setEquipmentData={setEquipmentData}
                                equipmentData={equipmentData}
                                selectedEquipmentCode={selectedEquipmentCode}
                                seletctQuantity={seletctQuantity}
                                setSelectedEquipmentCode={(data) =>
                                  setSelectedEquipmentCode(data)
                                }
                                setQuantity={setQuantity}
                                formMode={formMode}
                                showSelectedItem={showSelectedItem}
                              />
                            </Box>
                          </StyledTreeItem>
                        )}
                      </TreeView>
                    </div>
                  )}
                </div>
                <Grid
                  container
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "space-evenly",
                    marginTop: "30px",
                    marginBottom: "20px"
                  }}
                >
                  <Grid item sm={3} xs={8}>
                    <Button
                      className={classes.buttonStyle}
                      variant="contained"
                      disabled={!isEmpty(errors)}
                      color="#696969"
                      type="submit"
                    >
                      Submit and Send
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
      </>
       )}
      {!actionPermission(2, 6, 1) && (
        <p  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop:"25%"
        }}>Access denied.</p>
      )}
      </>
      )}
    </div>
  );
}

OnBording.propTypes = {
  mainData: PropTypes.object,
  // history: PropTypes.object,
  callOnBoarding: PropTypes.func,
  history: PropTypes.object,
  sendMailAgain: PropTypes.func,
  getPricingTemplete: PropTypes.func,
  getApplicationType: PropTypes.func,
  getProccesorList: PropTypes.func,
  pricingTempleteCallInvite: PropTypes.func,
  getInviteEquipmentData: PropTypes.func,
  setPricingTempleteEmpty: PropTypes.func
};

const mapStateToProps = (app) => ({
  mainData: app
});
export default connect(mapStateToProps, {
  callOnBoarding,
  sendMailAgain,
  getPricingTemplete,
  getApplicationType,
  getProccesorList,
  pricingTempleteCallInvite,
  getInviteEquipmentData,
  setPricingTempleteEmpty
})(OnBording);
