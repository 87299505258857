import React from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { actionPermission,permissionCheck } from "../../../../Utils/commonUtils";
import { connect } from "react-redux";

const payoutActions = ({ addPayout, setAddPayout, props }) => (
  <div>
    <Box style={{ marginTop: "20px" }}>
      <Grid
        spacing={2}
        style={{ justifyContent: "flex-end" }}
        className="custflex-dr-aic"
        container
      >
        <Grid item>
          {actionPermission(
            permissionCheck.Category.residual,
            permissionCheck.Page.AgentPayout,
            permissionCheck.option.add
          ) && (
            <Button
              onClick={() => {
                setAddPayout(!addPayout);
              }}
              startIcon={<AddCircleIcon />}
              style={{
                backgroundColor: "#2ba992",
                color: "white",
                textTransform: "capitalize",
                padding: "8px 24px",
              }}
            >
              New Profile
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  </div>
);

payoutActions.propTypes = {
  addPayout: PropTypes.bool,
  setAddPayout: PropTypes.func,
  props: PropTypes.func,
};

const mapStateToProps = (app) => ({
  users: app,
  props: app,
  formData: app.users,
});

export default connect(mapStateToProps, {})(payoutActions);
