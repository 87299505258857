/* eslint-disable no-nested-ternary */
import React from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Field, Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import {
  Typography,
  Checkbox,
  MenuItem,
  FormLabel,
  FormGroup
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useSnackbar } from "notistack";
import {
  alpha,
  makeStyles,
  withStyles,
  useTheme,
  lighten
} from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import { connect } from "react-redux";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useParams, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PersonIcon from "@material-ui/icons/Person";
import isEmpty from "lodash/isEmpty";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import PropTypes from "prop-types";
import { getInviteEquipmentData } from "Redux/Actions/ProvideRegistration";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import styles from "../../assets/jss/material-dashboard-react/components/businessForm";
import Validations from "../../Utils/Validations";
import Card from "../../components/Card/Card";
import {
  getApplicationType,
  getProccesorList,
  getPricingTemplete
} from "../../actions/MerchantSignUp";
import {
  createPricingTemplate,
  updatePricingTemplate,
  templateFieldsDetailsCall
} from "../../actions/Operations";
import PricingEquimentInfo from "./PricingEquimentInfo";
import {
  showCheckList,
  changeStatusPermission,
  checkFormDisabled,
  disbaleAndHide,
  disableSaveDraftButton
} from "../../views/UserProfile/SetRoleBase";
import { actionPermission } from "../../Utils/commonUtils";

const useStyles = makeStyles(styles);

const newUseStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
});

const initialFormData = {
  name: "",
  processorId: "",
  applicationId: "",
  startDate: null,
  termLengthDays: "",
  status: "Inactive",
  notes: ""
};
const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    display: "none"
  },
  group: {
    marginLeft: "0px"
  }
}))((props) => <TreeItem {...props} />);
function PricingTemplateAddEdit(props) {
  const classes = useStyles();
  const newClasses = newUseStyles();
  const theme = useTheme();
  const history = useHistory();
  const { tempId } = useParams();
  const { copyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [editDetails, setEditDetails] = React.useState({});
  // const windowWidth = useMediaQuery(theme.breakpoints.up("sm"));
  const [initialValues, setInitialValues] = React.useState({
    ...initialFormData
  });
  const [showItemsButton, setShowItemsButton] = React.useState(
    tempId || copyId
  );
  const [templateData, setTemplateData] = React.useState([]);
  const [proccssorList, setProccssorList] = React.useState([]);
  const [equipmentData, setEquipmentData] = React.useState([]);
  const [applicationTypeList, setApplicationTypeList] = React.useState([]);
  const [itemsValidation, setItemsValidation] = React.useState(true);
  const [bla, setBla] = React.useState({
    processorId: "",
    applicationId: "",
    templateId: ""
  });
  const [serviceListExpanded, setServiceListExpanded] = React.useState(false);
  const [equipmentListExpanded, setEquipmentListExpanded] =
    React.useState(false);

  const handleFocus = (data) => {
    setTimeout(() => document.getElementById(data).focus(), 50);
  };

  const handleBack = () => {
    history.push("/admin/PricingTemplate");
  };

  const handleOnChangeProccess = (data, type) => {
    const allValue = { ...bla };
    allValue[type] = data;
    if (type === "processorId") {
      allValue.applicationId = "";
      setShowItemsButton(true);
    }
    if (type === "applicationId") {
      setShowItemsButton(true);
    }
    setBla({ ...allValue });
  };

  const onSubmitItemValidationCheck = (categories) => {
    const check = [];
    categories.map((category) => {
      category.categoryItem.map((item) => {
        if (item.activeCheckbox) {
          check.push(true);
        } else {
          check.push(false);
        }
      });
    });
    const flag = check.some((x) => x);
    setItemsValidation(flag);
    return flag;
  };

  const equimentDataValidation = () => {
    let flag = true;
    equipmentData.forEach((key) => {
      key.equipmentItem.forEach((item) => {
        item.equipmentType.forEach((innerItem) => {
          if (innerItem.activeCheckbox) {
            if (
              parseFloat(innerItem.Max_Unit_Price.replace(/,/g, "")) <
              parseFloat(innerItem.unitPrice.replace(/,/g, ""))
            ) {
              flag = false;
            }
            if (
              innerItem.unitPrice === "" ||
              parseFloat(innerItem.Min_Unit_Price.replace(/,/g, "")) >
                parseFloat(innerItem.unitPrice.replace(/,/g, ""))
            ) {
              flag = false;
            }
          }
        });
      });
    });
    return flag;
  };

  const handleSubmit = (values, { setFieldError }) => {
    if (
      onSubmitItemValidationCheck(templateData) &&
      equimentDataValidation(equipmentData)
    ) {
      const payload = {
        name: values.name,
        startDate: values.startDate,
        termLengthDays: values.termLengthDays,
        active: values.status,
        notes: values.notes,
        pricingItem: templateData,
        Equipment: equipmentData
      };
      if (tempId) {
        props.updatePricingTemplate(
          {
            processorId: values.processorId,
            applicationId: values.applicationId,
            templateId: tempId
          },
          { ...payload, updatedBy: localStorage.getItem("userId") },
          (data) => {
            if (data.status) {
              enqueueSnackbar("Pricing template updated successfully.", {
                variant: "success"
              });
              handleBack();
            } else {
              enqueueSnackbar(`${data.message}`, {
                variant: "error"
              });
            }
          },
          (err) => {
            enqueueSnackbar(`${err.message}`, {
              variant: "error"
            });
          }
        );
      } else {
        props.createPricingTemplate(
          {
            processorId: values.processorId,
            applicationId: values.applicationId
          },
          { ...payload, createdBy: localStorage.getItem("userId") },
          (data) => {
            if (data.status) {
              enqueueSnackbar("Pricing template created successfully.", {
                variant: "success"
              });
              handleBack();
            } else {
              enqueueSnackbar(`${data.message}`, {
                variant: "error"
              });
            }
          },
          (err) => {
            if (!isEmpty(err.errors)) {
              err.errors.map((x) => {
                if (x.location === "body") {
                  setFieldError(x.param, x.msg);
                } else {
                  enqueueSnackbar(x.msg, {
                    variant: "error"
                  });
                }
              });
              enqueueSnackbar(`${err.message}`, {
                variant: "error"
              });
            }
          }
        );
      }
    } else if (!equimentDataValidation(equipmentData)) {
      enqueueSnackbar("Please add valid value in unit price", {
        variant: "error"
      });
    } else {
      enqueueSnackbar("Please select at least one pricing item.", {
        variant: "error"
      });
    }
  };

  const showIempMin = (data) => {
    const returnArray = [];
    const ticker = data.perItemTicker * 10000;
    const to = data.perItemTo * 10000;
    const from = data.perItemFrom * 10000;
    if (data.perItemTicker !== 0) {
      if (data.perItemTicker % 1 !== 0) {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      } else {
        for (let i = from; i <= to; i += ticker) {
          if (i > 0) {
            const finalValue = i / 10000;
            returnArray.push(
              data.perItemTicker % 1 !== 0 ? finalValue.toFixed(4) : finalValue
            );
          } else {
            break;
          }
        }
      }
    }
    if (ticker === 0 && to === 0 && from === 0) {
      returnArray.push("0");
    }
    if (
      returnArray.filter((key) => Number(key) === Number(data.perItemRate))
        .length === 0
    ) {
      returnArray.unshift("Custom");
    }
    return returnArray;
  };

  const onChange = (value, type, mainIndex, itemIndex) => {
    if (!itemsValidation) {
      setItemsValidation(true);
    }
    const updatedDate = [...templateData];
    if (type !== "check") {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = value;
    } else {
      updatedDate[mainIndex].categoryItem[itemIndex].perItemRate = 0;
      updatedDate[mainIndex].categoryItem[itemIndex].activeCheckbox = value;
    }
    if (type === "text") {
      handleFocus(updatedDate[mainIndex].categoryItem[itemIndex].itemName);
    }
    setTemplateData([...updatedDate]);
  };

  const onEquipmentChange = (value, type, mainIndex, itemIndex, subIndex) => {
    const updatedData = [...equipmentData];
    if (type !== "check") {
      updatedData[mainIndex].equipmentItem[itemIndex].equipmentType[
        subIndex
      ].unitPrice = value;
    } else {
      updatedData[mainIndex].equipmentItem[itemIndex].equipmentType[
        subIndex
      ].activeCheckbox = value;
    }
    updatedData[mainIndex].equipmentItem[itemIndex].activeCheckbox = true;
    updatedData[mainIndex].activeCheckbox = true;
    setEquipmentData([...updatedData]);
  };

  const setTemplateInitialData = () => {
    const applicationType = applicationTypeList.find(
      (list) => list.name === editDetails.applicationType
    );
    const proccssorType = proccssorList.find(
      (list) => list.name === editDetails.processor
    );

    setInitialValues({
      pricingtemplateId: editDetails?._id,
      name: editDetails?.name,
      processorId: proccssorType?._id,
      applicationId: applicationType?._id,
      startDate: editDetails?.startDate,
      termLengthDays: editDetails?.termLengthDays,
      status: editDetails?.status,
      notes: editDetails?.notes
    });
  };

  const onShowItemClick = () => {
    getPricingTempleteFields({
      processorId: bla.processorId,
      applicationId: bla.applicationId
    });
    const data = {
      processorId: bla.processorId,
      getAllOrGetOnlySelected: "pricing"
    };
    props.getInviteEquipmentData(data);
    setShowItemsButton(true);
  };

  React.useEffect(() => {
    if (
      equipmentData !== props.mainData.provideRegistration.Equipment &&
      props.mainData.provideRegistration.Equipment
    ) {
      setEquipmentData(props.mainData.provideRegistration.Equipment.data);
    }
  }, [props.mainData.provideRegistration.Equipment]);

  const getPricingTempleteFields = (data) => {
    props.templateFieldsDetailsCall(
      {
        ...data
      },
      (data) => {
        if (data.status) {
          setTemplateData([...data.data]);
          setShowItemsButton(true);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
          setShowItemsButton(false);
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
        setShowItemsButton(false);
      }
    );
  };

  const getApplicationList = () => {
    props.getApplicationType(
      (data) => {
        if (data.status) {
          setApplicationTypeList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getProccesorListApi = () => {
    props.getProccesorList(
      (data) => {
        if (data.status) {
          setProccssorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getTemplateDetails = () => {
    props.getPricingTemplete(
      (data) => {
        if (data.status) {
          if (tempId) {
            setEditDetails({ ...data.data[0] });
          } else {
            setEditDetails({
              ...data.data[0],
              name: `Copy of ${data.data[0].name}`
            });
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      // { pricingTemplateId: tempId }
      { pricingTemplateId: tempId ? tempId : copyId }

    );
  };

  React.useEffect(() => {
    if (tempId || copyId) {
      getTemplateDetails();
    }
    getApplicationList();
    getProccesorListApi();
  }, []);

  React.useEffect(() => {
    if (
      !isEmpty(initialValues) &&
      (tempId || copyId) &&
      initialValues.processorId
    ) {
      getPricingTempleteFields({
        processorId: initialValues.processorId,
        applicationId: initialValues.applicationId,
        templateId: tempId || copyId || ""
      });
      props.getInviteEquipmentData({
        templateId: tempId || copyId,
        processorId: initialValues.processorId,
        getAllOrGetOnlySelected: "pricing"
      });
    }
  }, [initialValues]);

  React.useEffect(() => {
    if (
      !isEmpty(applicationTypeList) &&
      !isEmpty(proccssorList) &&
      !isEmpty(editDetails)
    ) {
      setTemplateInitialData();
    }
  }, [applicationTypeList, proccssorList, editDetails]);

  const disabled =
  props.formData.formMode === "Edit"
    ? checkFormDisabled(props, initialFormData) ||
      !actionPermission( 2, 7, 3)
    : !actionPermission(2, 7, 3);

  return (
    <div>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
            padding: "10px"
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Grid
            style={{
              display: "flex",
              position: "relative",
              flexWrap: "nowrap",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center"
            }}
            container
          >
            <Grid style={{ position: "absolute" }} item>
              <IconButton
                color="white"
                aria-label="Back"
                onClick={() => handleBack()}
                component="span"
                style={{ background: "white", padding: "6px" }}
              >
                <KeyboardBackspaceIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid style={{ margin: "auto" }} item>
              <Typography className={classes.tittleTextColor}>
                {tempId ? "Edit " : "Add "}
                {"- Pricing Template"}
                {tempId ? ` (${editDetails.name})` : ""}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Formik
          // innerRef={values => { formik.current = values; }}
          validationSchema={Validations.pricingTemplateAddEdit}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={{ ...initialValues }}
        >
          {({ errors, touched, setFieldValue, values, dirty }) => (
            <Form>
              <Box style={{ paddingTop: "20px" }} className={classes.marginBox}>
                <Grid spacing={2} container>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      disabled={disabled}
                      className={classes.root}
                      helperText={
                        errors.name && touched.name ? errors.name : ""
                      }
                      error={errors.name && touched.name}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Template Name"
                      name="name"
                      id="name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      select
                      required
                      disabled={tempId}
                      value={values.processorId}
                      onChange={(e) => {
                        setFieldValue("processorId", e.target.value);
                        handleOnChangeProccess(e.target.value, "processorId");
                      }}
                      fullWidth
                      helperText={
                        errors.processorId && touched.processorId
                          ? errors.processorId
                          : ""
                      }
                      error={errors.processorId && touched.processorId}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Processor"
                      name="processorId"
                      id="processorId"
                    >
                      {proccssorList.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </Field>
                    {/* <ErrorMessage name='processorId'></ErrorMessage> */}
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      select
                      helperText={
                        errors.applicationId && touched.applicationId
                          ? errors.applicationId
                          : ""
                      }
                      error={errors.applicationId && touched.applicationId}
                      value={values.applicationId}
                      required
                      disabled={tempId}
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      onChange={(e) => {
                        setFieldValue("applicationId", e.target.value);
                        handleOnChangeProccess(e.target.value, "applicationId");
                      }}
                      label="Application Type,"
                      name="applicationId"
                      id="applicationId"
                    >
                      {applicationTypeList.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key._id}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <DateRangeIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <FormControl
                      className={classes.root}
                      style={{ fontSize: "unset" }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Field
                          as={KeyboardDatePicker}
                          minDateMessage="Past date are not allowed"
                          clearable
                          disablePast
                          animateYearScrolling
                          disabled={tempId}
                          onChange={(e) => {
                            if (e === null) {
                              setFieldValue("startDate", e);
                            } else {
                              setFieldValue("startDate", e);
                            }
                          }}
                          label="Start Date"
                          id="startDate"
                          invalidDateMessage="Please enter a valid start date"
                          value={values.startDate}
                          helperText={
                            errors.startDate && touched.startDate
                              ? "Please enter a valid start date"
                              : ""
                          }
                          error={errors.startDate && touched.startDate}
                          format="MM/dd/yyyy"
                          name="startDate"
                          style={{
                            "&.focused": {
                              color: theme.palette.primary.main,
                              fontSize: "14px",
                              fontStyle: "Helvetica, Arial,sans-serif"
                            }
                          }}
                          required
                          placeholder="MM/DD/YYYY"
                          keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                          KeyboardButtonProps={{
                            size: "small"
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            },
                            required: true
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <DateRangeIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <Field
                      as={TextField}
                      name="termLengthDays"
                      id="termLengthDays"
                      required
                      disabled={disabled}
                      type="number"
                      className={classes.root}
                      helperText={
                        errors.termLengthDays && touched.termLengthDays
                          ? errors.termLengthDays
                          : ""
                      }
                      error={errors.termLengthDays && touched.termLengthDays}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Term Length Days"
                    />
                  </Grid>
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                    item
                    lg={4}
                    sm={4}
                    xs={12}
                  >
                    <div style={{ marginRight: "20px", marginLeft: "13px" }}>
                      <FormLabel>Status:</FormLabel>
                    </div>
                    <FormGroup color="primary" row>
                      <FormControlLabel
                        label="Active"
                        disabled={disabled}
                        control={
                          <Checkbox
                            color="primary"
                           
                            checked={values.status === "Active"}
                            onChange={() => {
                              setFieldValue("status", "Active");
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Inactive"
                        disabled={disabled}
                        control={
                          <Checkbox
                            color="primary"
                            checked={values.status === "Inactive"}
                            onChange={() => {
                              setFieldValue("status", "Inactive");
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <SpeakerNotesIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <Field
                      as={TextField}
                      name="notes"
                      id="notes"
                      multiline
                      rows={2}
                      fullWidth
                      disabled={disabled}
                      type="text"
                      className={classes.rootNotes}
                      helperText={
                        errors.notes && touched.notes ? errors.notes : ""
                      }
                      error={errors.notes && touched.notes}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          // input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Notes"
                    />
                  </Grid>
                </Grid>
                {templateData && templateData.length > 0 && (
                  <TreeView style={{ marginTop: "15px" }}>
                    <StyledTreeItem
                      nodeId="1"
                      label={
                        <div className={classes.lableTextColor}>
                          <span>Services and Fees</span>
                          {serviceListExpanded && <ExpandMoreIcon />}
                          {!serviceListExpanded && <ChevronRightIcon />}
                        </div>
                      }
                      onClick={() =>
                        setServiceListExpanded(!serviceListExpanded)
                      }
                    >
                      {showItemsButton &&
                        templateData
                          .sort((a, b) =>
                            a.categoryName > b.categoryName ? 1 : -1
                          )
                          .map((key, mainIndex) => (
                            <>
                              <Box
                                style={{
                                  alignItems: "center",
                                  marginTop: "10px"
                                }}
                                key={Math.random()}
                                className={classes.tittleColor}
                                height={40}
                              >
                                <Typography
                                  className={classes.tittleTextColor}
                                  style={{ color: "white" }}
                                >
                                  {key.categoryName}
                                </Typography>
                              </Box>
                              <Box className={classes.marginBox}>
                                <Grid container className={classes.margin}>
                                  {key.categoryItem
                                    .sort((a, b) =>
                                      a.itemName > b.itemName ? 1 : -1
                                    )
                                    .map((item, index) => (
                                      <>
                                        <Grid
                                          key={Math.random()}
                                          item
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "20px"
                                          }}
                                          lg={6}
                                          sm={6}
                                          xs={12}
                                        >
                                          <div style={{ minWidth: "5%" }}>
                                            <Checkbox
                                              checked={item.activeCheckbox}
                                              onChange={(e) =>
                                                onChange(
                                                  e.target.checked,
                                                  "check",
                                                  mainIndex,
                                                  index
                                                )
                                              }
                                              inputProps={{
                                                "aria-label":
                                                  "select all desserts"
                                              }}
                                              color="primary"
                                            />
                                          </div>
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              lineHeight: "20px",
                                              maxWidth: "25%",
                                              minWidth: "25%",
                                              padding: "1%"
                                            }}
                                          >
                                            {item.itemName}
                                          </span>
                                          <div
                                            style={{
                                              maxWidth: "25%",
                                              minWidth: "25%",
                                              padding: "1%"
                                            }}
                                          >
                                            <TextField
                                              className={
                                                classes.quaterInputPricing
                                              }
                                              key={Math.random()}
                                              disabled={!item.activeCheckbox}
                                              InputLabelProps={{
                                                classes: {
                                                  root: classes.activeInputLableColor,
                                                  focused: "focused"
                                                }
                                              }}
                                              InputProps={{
                                                classes: {
                                                  root: classes.activeInputColor,
                                                  input: classes.resizePrice,
                                                  focused: "focused"
                                                }
                                              }}
                                              defaultValue="Custom"
                                              value={
                                                item.perItemTicker % 1 !== 0
                                                  ? Number(item.perItemRate)
                                                      .toFixed(4)
                                                      .toString()
                                                  : item.perItemRate
                                              }
                                              onChange={(e) =>
                                                onChange(
                                                  e.target.value,
                                                  item,
                                                  mainIndex,
                                                  index
                                                )
                                              }
                                              name={Math.random()
                                                .toString(36)
                                                .substr(2, 5)}
                                              id={Math.random()
                                                .toString(36)
                                                .substr(2, 5)}
                                              select
                                            >
                                              {showIempMin(
                                                item,
                                                index,
                                                key.categoryItem.length
                                              ).length > 0 &&
                                                showIempMin(
                                                  item,
                                                  index,
                                                  key.categoryItem.length
                                                ).map((number) => (
                                                  <MenuItem
                                                    key={Math.random()}
                                                    className={
                                                      classes.dialogBoxDropDown
                                                    }
                                                    value={
                                                      number === "Custom"
                                                        ? item.perItemTicker %
                                                            1 !==
                                                          0
                                                          ? Number(
                                                              item.perItemRate
                                                            )
                                                              .toFixed(4)
                                                              .toString()
                                                          : item.perItemRate
                                                        : number
                                                    }
                                                  >
                                                    {number}
                                                  </MenuItem>
                                                ))}
                                            </TextField>
                                          </div>
                                          <div
                                            style={{
                                              maxWidth: "25%",
                                              minWidth: "25%",
                                              padding: "1%",
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: "14px",
                                                lineHeight: "20px",
                                                maxWidth: "25%",
                                                minWidth: "25%",
                                                padding: "1%",
                                                textAlign: "end"
                                              }}
                                            >
                                              {item.rateTypeSing === "$"
                                                ? item.rateTypeSing
                                                : ""}
                                            </span>
                                            <TextField
                                              // className={
                                              //   classes.quaterInputPricing
                                              // }
                                              type="number"
                                              onClick={(event) =>
                                                event.target.select()
                                              }
                                              className={newClasses.input}
                                              key={Math.random()}
                                              InputLabelProps={{
                                                classes: {
                                                  root: classes.activeInputLableColor,
                                                  focused: "focused"
                                                }
                                              }}
                                              InputProps={{
                                                classes: {
                                                  root: classes.activeInputColor,
                                                  input: classes.resizePrice,
                                                  focused: "focused"
                                                }
                                              }}
                                              variant="outlined"
                                              style={{
                                                backgroundColor: "#f9f7f7",
                                                minWidth: "60%",
                                              }}
                                              inputProps={{
                                                style: {
                                                  padding: 9
                                                }
                                             }}
                                              value={item.perItemRate}
                                              disabled={!item.activeCheckbox}
                                              onChange={(e) =>
                                                onChange(
                                                  e.target.value,
                                                  "text",
                                                  mainIndex,
                                                  index
                                                )
                                              }
                                              name={item.itemName}
                                              id={item.itemName}
                                            />
                                            <span
                                              style={{
                                                fontSize: "14px",
                                                lineHeight: "20px",
                                                maxWidth: "25%",
                                                minWidth: "25%",
                                                padding: "1%"
                                              }}
                                            >
                                              {item.rateTypeSing !== "$"
                                                ? item.rateTypeSing
                                                : ""}
                                            </span>
                                          </div>
                                        </Grid>
                                      </>
                                    ))}
                                </Grid>
                              </Box>
                            </>
                          ))}
                    </StyledTreeItem>
                    {equipmentData && equipmentData.length > 0 && (
                      <StyledTreeItem
                        nodeId="2"
                        label={
                          <div className={classes.lableTextColor}>
                            <span>Equipment Fees/Charges</span>
                            {equipmentListExpanded && <ExpandMoreIcon />}
                            {!equipmentListExpanded && <ChevronRightIcon />}
                          </div>
                        }
                        onClick={() =>
                          setEquipmentListExpanded(!equipmentListExpanded)
                        }
                      >
                        <Box style={{ margin: "15px 30px" }}>
                          <PricingEquimentInfo
                            onChange={onEquipmentChange}
                            equipmentData={equipmentData}
                          />
                        </Box>
                      </StyledTreeItem>
                    )}
                  </TreeView>
                )}
                <Grid
                  container
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "space-evenly",
                    marginTop: "30px",
                    display: "flex",
                    marginBottom: "20px",
                    flexDirection: "column",
                    alignContent: "center"
                  }}
                >
                  <Grid item sm={3} xs={8}>
                    {!isEmpty(templateData) && showItemsButton ? (
                      <>
                      
                      {actionPermission(2, 7, 3) && (
                      <Button
                        className={classes.buttonStyle}
                        variant="contained"
                        fullWidth
                        disabled={!isEmpty(errors) || !itemsValidation}
                        color="#696969"
                        type="submit"
                      >
                        {tempId ? "Update" : "Submit"}
                      </Button>
                      )}
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={!isEmpty(errors) || !dirty}
                        onClick={onShowItemClick}
                      >
                        Show Items
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}
const mapStateToProps = (app) => ({
  mainData: app,
  roles: app.roles,
  formData: app.provideRegistration,
});

PricingTemplateAddEdit.propTypes = {
  getApplicationType: PropTypes.func,
  getProccesorList: PropTypes.func,
  templateFieldsDetailsCall: PropTypes.func,
  getPricingTemplete: PropTypes.func,
  mainData: PropTypes.object,
  createPricingTemplate: PropTypes.func,
  updatePricingTemplate: PropTypes.func,
  getInviteEquipmentData: PropTypes.func,
  roles: PropTypes.object,
  formData: PropTypes.object,
};
PricingTemplateAddEdit.defaultProps = {
  getApplicationType: () => {},
  getProccesorList: () => {},
  templateFieldsDetailsCall: () => {},
  getPricingTemplete: () => {},
  createPricingTemplate: () => {},
  updatePricingTemplate: () => {}
};
export default connect(mapStateToProps, {
  getApplicationType,
  getPricingTemplete,
  getProccesorList,
  templateFieldsDetailsCall,
  createPricingTemplate,
  updatePricingTemplate,
  getInviteEquipmentData
})(PricingTemplateAddEdit);
