import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";
import { TablePageData } from "../../utils/constants";
import {
  getAdjustmentList,
  getAdjustmentDetails,
} from "../../actions/adjustment.actions";
import { fetchProcessorList } from "../../actions/pricingSchedule.action";
import { fetchAgentList } from "../../actions/agentPayout.action";
import { exportToCSV } from "../../utils/common";
import { fetchMappedMerchantsListAll } from "../../actions/merchantMapping.action";
import SearchComponent from "../../components/AdjustmentSearch/SearchFields";
import SearchedChips from "../../components/AdjustmentSearch/SearchChips";
import FilterFields from "./filterFields";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { exportToPDF } from "modules/reports/utils/common";
import { exportNumberToDashFormat } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ExportIcon } from "../../../../assets/svgIcons/ExportIcon.svg";
import { ReactComponent as AddIconSvg } from "../../../../assets/svgIcons/AddIconSvg.svg";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const headCellsItems = [
  {
    id: "ProcessorID",
    label: "Processor",
    isSort: true,
    sortProperty: "ProcessorID",
    actionsCellStyle: "left",
  },
  {
    id: "AgentName",
    label: "Agent",
    isSort: true,
    sortProperty: "AgentName",
    actionsCellStyle: "left",
  },
  {
    id: "MID",
    label: "Merchant",
    isSort: true,
    sortProperty: "MID",
    actionsCellStyle: "left",
  },
  {
    id: "Adjustment_Name",
    label: "Adjustment Name",
    isSort: true,
    sortProperty: "Adjustment_Name",
    actionsCellStyle: "left",
  },
  {
    id: "StartDate",
    label: "Start Date",
    isSort: true,
    sortProperty: "StartDate",
    actionsCellStyle: "left",
  },
  {
    id: "EndDate",
    label: "End Date",
    isSort: true,
    sortProperty: "EndDate",
    actionsCellStyle: "left",
  },
  {
    id: "Amount",
    label: "Amount (USD)",
    isSort: true,
    sortProperty: "Amount",
    actionsCellStyle: "left",
  },
  {
    id: "Status",
    label: "Status",
    isSort: true,
    sortProperty: "Status",
    actionsCellStyle: "left",
  },
  {
    id: "LastPay",
    label: "Last Paid",
    isSort: false,
    sortProperty: "LastPay",
    actionsCellStyle: "center",
  },
];

const labelName = {
  ProcessorID: "Processor",
  ProfileID: "Agent",
  MID: "DBA Name",
  Status: "Status",
  FromMonth: "From Month",
  ToMonth: "To Month",
  Adj_ID: "Adjustment Name",
};
function Adjustment({
  getAdjustmentList,
  fetchProcessorList,
  fetchAgentList,
  getAdjustmentDetails,
  fetchMappedMerchantsListAll,
  users,
}) {
  const searchInitialData = {
    ProcessorID: "",
    MID: null,
    ProfileID: "",
    FromMonth: "",
    ToMonth: "",
    Adj_ID: "",
    Status: "",
  };

  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dataParameter = "Adj_ID";
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [order, setOrder] = React.useState("desc");
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [orderBy, setOrderBy] = React.useState("StartDate");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [adjustmentList, setAdjustmentList] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState("");
  const [processorList, setProcessorList] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData,
  });
  const [chipValue, setChipValue] = React.useState({
    ...searchInitialData,
  });
  const [agentsList, setAgentsList] = React.useState([]);
  const [searchBar, setSearchBar] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [adjustmentNameList, setAdjustmentNameList] = React.useState([]);
  const [merchantList, setMerchantList] = React.useState([]);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const getAdjustmentListFun = function (searchValues) {
    getAdjustmentList(
      { ...searchValues },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const { totalCount } = records;
          setAdjustmentList(content);
          setTotalCount(totalCount);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < totalCount ? page * rowsPerPage : totalCount,
            total: totalCount,
          });
        } else {
          enqueueSnackbar(records.message, {
            variant: "error",
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error",
        });
      }
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorName : id;
    }
  };
  const processorName = React.useMemo(
    () => findProcessorName(searchValues.ProcessorID),
    [searchValues.ProcessorID, processorList]
  );

  const findAgentName = (id) => {
    if (!isEmpty(agentsList)) {
      const name = agentsList.find((x) => x.UserID == id);
      return name ? name.AgentName : id;
    }
  };

  const agent_Name = React.useMemo(
    () => findAgentName(searchValues.ProfileID),
    [searchValues.ProfileID, agentsList]
  );

  const findAdjustmentName = (id) => {
    if (!isEmpty(adjustmentNameList)) {
      const name = adjustmentNameList.find((x) => x.Adj_ID == id);
      return name ? name.Adjustment_Name : id;
    }
  };

  const adjustment_Name = React.useMemo(
    () => findAdjustmentName(searchValues.Adj_ID),
    [searchValues.Adj_ID, adjustmentNameList]
  );

  const findMerchantName = (id) => {
    if (!isEmpty(merchantList)) {
      const name = merchantList.find((x) => x.MID == id);
      return name ? name.DBAName : id;
    }
  };

  const merchant_Name = React.useMemo(
    () => findMerchantName(searchValues.MID),
    [searchValues.MID, merchantList]
  );

  const handleAddEdit = (type = "", row) => {
    if (type === "edit") {
      history.push(`Adjustment/add?adjId=${row.Adj_ID}`);
    }
  };

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleDeleteChip = (chip) => {
    let temp = "";
    if (chipValue) {
      temp = { ...searchValues };
      temp[chip] = "";
      setSearchValues({ ...temp });
      setChipValue({
        ...temp,
      });
    }
    getAdjustmentListFun({
      ...temp,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page,
    });
  };

  const getFilteredAdjustments = (value) => {
    const finalSearchData = {
      ...value,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page,
    };
    setChipValue({
      ...value,
    });
    getAdjustmentListFun(finalSearchData);
  };

  const handleSubmitSearch = () => {
    handleSearchClose();
    getFilteredAdjustments(searchValues);
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    getAdjustmentListFun({
      ...searchInitialData,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page,
    });
    handleSearchClose();
  };

  const getAllAdjustmentNameList = () => {
    getAdjustmentDetails(
      (data) => {
        if (data.status) {
          setAdjustmentNameList(data.data);
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      }
    );
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      }
    );
  };
  const getAgentList = (agentName = "") => {
    fetchAgentList(
      (data) => {
        if (data.status || data.status === "success") {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: "919863231596669598",
                AgentName: "No record found",
              },
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      },
      { search: agentName }
    );
  };

  const getPayoutList = function () {
    fetchMappedMerchantsListAll(
      (records) => {
        if (records.status == "success") {
          setMerchantList(records.data);
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error",
        });
      }
    );
  };

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues,
    };
    getAdjustmentList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => ({
              Id: row.Adj_ID,
              Name: row.Adjustment_Name,
              "Start Date": `${row.StartMonth}/${row.StartYear}`,
              "Created Date": row.CreatedOn,
              "Updated Date": row.UpdatedOn,
              Amount: exportNumberToDashFormat(row.Amount),
              "Processor Name": row.ProcessorName,
              "Profile Linked": row.Profile_Linked,
              Description: row.Description,
              Status: row.PS_Status,
              "Split Type": row.Split_Type,
              "Created By": row.CreatedBy_Name,
              "Updated By": row.UpdatedBy_Name,
            }));
            exportToCSV(
              mappedLogs,
              "Adjustments List",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error",
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error",
        });
      }
    );
  };

  const exportToPDFList = () => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues,
    };
    getAdjustmentList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => [
              row.Adj_ID,
              row.Adjustment_Name,
              `${row.StartMonth}/${row.StartYear}`,
              row.CreatedOn,
              row.UpdatedOn,
              exportNumberToDashFormat(row.Amount),
              row.ProcessorName,
              row.Profile_Linked,
              row.Description,
              row.PS_Status,
              row.Split_Type,
              row.CreatedBy_Name,
              row.UpdatedBy_Name,
            ]);
            const headersName = [
              "Id",
              "Name",
              "Start Date",
              "Created Date",
              "Updated Date",
              "Amount",
              "Processor Name",
              "Profile Linked",
              "Description",
              "Status",
              "Split Type",
              "Created By",
              "Updated By",
            ];
            exportToPDF(
              headersName,
              mappedLogs,
              "Adjustments List",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              processorName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error",
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error",
        });
      }
    );
  };

  React.useEffect(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
    if (isEmpty(adjustmentNameList)) {
      getAllAdjustmentNameList();
    }
    if (isEmpty(agentsList)) {
      getAgentList();
    }
    getPayoutList();
  }, []);

  React.useEffect(() => {
    getAdjustmentListFun({
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page,
    });
  }, [orderBy, order, page, rowsPerPage]);

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div
                className={classes.searchWrapper}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    alignContent: "center",
                    flexWrap: "nowrap",
                    flexDirection: "row",
                    minWidth: "600px",
                  }}
                >
                  <SearchComponent
                    searchBar={searchBar}
                    handleSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    width="100%"
                    Fields={() => {
                      return (
                        <FilterFields
                          handleSearchClose={() => handleSearchClose()}
                          setSearchValues={setSearchValues}
                          searchValues={searchValues}
                          agentsList={agentsList}
                          processorList={processorList}
                          adjustmentNameList={adjustmentNameList}
                          handleSubmitSearch={handleSubmitSearch}
                          handleClearSearch={handleClearSearch}
                          merchantList={merchantList}
                          getAdjustmentListFun={getAdjustmentListFun}
                          // getFilteredAdjustments={getFilteredAdjustments}
                        />
                      );
                    }}
                  />
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>

                  <Popper
                    open={openImportFileModal}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    style={{
                      zIndex: "10",
                    }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              // autoFocusItem={openImportFileModal}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                              <MenuItem
                                onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <IconsTooltip title="Add" placement="top">
                    <div className={classes.appSvgIcons}>
                      <AddIconSvg
                        onClick={() => {
                          history.push("Adjustment/add");
                        }}
                      />
                    </div>
                  </IconsTooltip>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            {/* <Grid
              item
              className={classes.margin}
              style={{ display: "flex" }}
            ></Grid> */}
            <Grid item className={classes.expirtItemGrid}>
              <SearchComponent
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => {
                  return (
                    <FilterFields
                      handleSearchClose={() => handleSearchClose()}
                      setSearchValues={setSearchValues}
                      searchValues={searchValues}
                      agentsList={agentsList}
                      processorList={processorList}
                      adjustmentNameList={adjustmentNameList}
                      handleSubmitSearch={handleSubmitSearch}
                      handleClearSearch={handleClearSearch}
                      merchantList={merchantList}
                      getAdjustmentListFun={getAdjustmentListFun}
                    />
                  );
                }}
              />
              <CoustomButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </CoustomButton>
              {/* <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu}>As Pdf</MenuItem>
                <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
                <MenuItem
                  onClick={() => {
                    exportListData();
                    handleCloseMenu();
                  }}
                >
                  As Xlsx
                </MenuItem>
              </Menu> */}
              <CoustomButton
                aria-controls="simple-menu"
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
                onClick={() => {
                  history.push("Adjustment/add");
                }}
              >
                <AddIcon />
                New
              </CoustomButton>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <SearchedChips
            handleDeleteChip={handleDeleteChip}
            searchValues={chipValue}
            labelName={labelName}
            agentName={agent_Name}
            processorName={processorName}
            adjustment_Name={adjustment_Name}
            merchant_Name={merchant_Name}
          />
        </Grid>
        <CustomTable
          page={page}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          data={adjustmentList}
          pageDetails={pageDetails}
          headCells={headCellsItems}
          dataParameter={dataParameter}
          isCallInitialization={initialCall}
          selected={[]}
          setSelected={() => {}}
          isSelection={false}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
        >
          {adjustmentList.map((row) => {
            return (
              <TableRow
                key={row.Adj_ID}
                className={classes.cellHeadSign}
                onClick={() => handleAddEdit("edit", row)}
              >
                <TableCell className={classes.cellText}>
                  {row.ProcessorName}
                </TableCell>
                <TableCell className={classes.cellText}>
                  {row.AgentName}
                </TableCell>
                <TableCell className={classes.cellText}>
                  {row.DBAName}
                </TableCell>
                <TableCell className={classes.cellText}>
                  {row.Adjustment_Name}
                </TableCell>
                <TableCell className={classes.cellText}>
                  {/* {row.StartDate ? row.StartDate : "N/A"} */}
                  {row.StartDate
                    ? moment(row.StartDate)
                        .format("MMM-YYYY")
                        .toLocaleUpperCase()
                    : "N/A"}
                </TableCell>
                <TableCell className={classes.cellText}>
                  {row.Forever == 1
                    ? "Forever"
                    : row.EndDate
                    ? moment(row.EndDate).format("MMM-YYYY").toLocaleUpperCase()
                    : "N/A"}
                </TableCell>
                <TableCell className={classes.cellText}>{row.Amount}</TableCell>
                <TableCell className={classes.cellText}>
                  {row.Status == 1 ? "Active" : row.Status == 0 && "Inactive"}
                </TableCell>
                <TableCell className={classes.cellText}>
                  {row.LastPay}
                </TableCell>
              </TableRow>
            );
          })}
        </CustomTable>
      </TableContainer>
    </>
  );
}
Adjustment.propTypes = {
  getAdjustmentList: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  getAdjustmentDetails: PropTypes.func,
  fetchAgentList: PropTypes.func,
  fetchMappedMerchantsListAll: PropTypes.func,
  users: PropTypes.object,
};

Adjustment.defaultProps = {
  getAdjustmentList: () => {},
  fetchProcessorList: () => {},
  fetchAgentList: () => {},
  getAdjustmentDetails: () => {},
  fetchMappedMerchantsListAll: () => {},
};

const mapStateToProps = (app) => ({
  users: app,
});

export default connect(mapStateToProps, {
  getAdjustmentDetails,
  getAdjustmentList,
  fetchProcessorList,
  fetchAgentList,
  fetchMappedMerchantsListAll,
})(Adjustment);
