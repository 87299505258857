import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Grid, InputLabel, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import Select from "@material-ui/core/Select";
import { Field, Form, Formik, ErrorMessage } from "formik";
import {
  createTheme,
  ThemeProvider,
  makeStyles
} from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import Card from "components/Card/Card.js";
import Payout_Calculation_Listing from "./listing";
import SearchFields from "./SearchFields";
import { fetchProcessorList } from "../../../actions/pricingSchedule.action";
import { fetchAgentList } from "../../../actions/agentPayout.action";
import {
  payout_calculation_function,
  payout_migration_function,
  payout_calculation_detail
} from "../../../actions/PayoutCalculation.action";
import { fetchProfileRepCodeList } from "../../../actions/merchantMapping.action";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  viewCalculationPermission,
  runCalculationPermission
} from "../permission";

import ProgressBar from "@ramonak/react-progress-bar";
import CalculationTable from "./calculationTable";

const useStyles = makeStyles(styles);

const initialDetails = {
  processorId: "",
  // Month: "",
  // Year: "",
  payoutDate: "",
  calculationType: "",
  midIds: ""
};

const searchInitialDetails = {
  processorId: "",
  agentName: "",
  ProfileID: ""
};

const PayoutCalculation = ({
  fetchProcessorList,
  fetchAgentList,
  payout_calculation_function,
  payout_migration_function,
  payout_calculation_detail,
  fetchProfileRepCodeList,
  roles
}) => {
  const formikRef = React.useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [payoutDetails, setPayoutDetails] = React.useState([]);
  const [agentsList, setAgentsList] = React.useState([]);
  const [processorList, setProcessorList] = React.useState([]);
  const [repCodeList, setRepCodeList] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    ...initialDetails
  });
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialDetails
  });
  const [progressBarValue, setProgressBarValue] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [barItemValueRes, setBarItemValueRes] = React.useState([]);
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const getPayoutDetails = () => {
    payout_calculation_detail(
      (res) => {
        if (res.status == "success") {
          setPayoutDetails(res.data);
        }
      },
      (err) => {}
    );
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        }
      },
      (err) => {
        enqueueSnackbar("An error occurred.", {
          variant: "error"
        });
      }
    );
  };
  const getAgentProfileRepCodeList = (payload = {}) => {
    fetchProfileRepCodeList(
      {
        userId: "",
        processorId: !isEmpty(payload)
          ? payload.processorId || ""
          : searchValues.processorId || ""
      },
      (data) => {
        if (data.status == "success") {
          setRepCodeList(data.data);
        }
      },
      (err) => {}
    );
  };

  React.useEffect(() => {
    if (searchValues.processorId) {
      getAgentProfileRepCodeList();
    }
  }, [searchValues.processorId]);

  const getAgentList = (agentName = "") => {
    fetchAgentList(
      (data) => {
        if (data.status || data.status === "success") {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: "919863231596669598",
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        }
      },
      (err) => {},
      { search: agentName }
    );
  };

  const ErrorDialog = () => {
    const isAlertClose = () => {
      setErrorAlertOpen(false);
      setErrorMsg(false);
    };

    React.useEffect(() => {
      if (isEmpty(payoutDetails)) {
        getPayoutDetails();
      }
      if (isEmpty(processorList)) {
        getProcessorList();
      }
      if (isEmpty(agentsList)) {
        getAgentList();
      }
    }, []);

    return (
      <Dialog
        open={errorAlertOpen}
        onClose={isAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ textAlign: "center" }}>
          <ErrorOutlineIcon
            style={{ color: "orange", width: "100px", height: "100px" }}
          />
          <DialogContentText id="alert-dialog-description">
            {errorMsg
              ? errorMsg
              : "This selected Month/Year calculation is already freezed by Admin. You can't recalculate it. Please contact to Administrator."}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={isAlertClose} color="primary" variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const progressBar = () => {
    const startTime = new Date().getTime();
    const interval = setInterval(function () {
      if (new Date().getTime() - startTime > 5010) {
        clearInterval(interval);
        return enqueueSnackbar("Calculation successfully completed.", {
          variant: "success"
        });
      }
      //do whatever here..
      if (progressBarValue < 1) {
        setProgressBarValue((t) => t + 10);
      }
    }, 500);
  };

  const runCalculation = (values) => {
    const payload = {
      processorId: values.processorId,
      payoutDate: moment(values.payoutDate).format("YYYY-MM-DD"),
      calculationType: +values.calculationType,
      midIds: ""
    };
    payout_calculation_function(
      payload,
      (res) => {
        setBarItemValueRes(res);
        if (res.status && res.status !== "error") {
          enqueueSnackbar("Calculation has been initiated", {
            variant: "success"
          });
          progressBar();
        } else if (res.status && res.status == "error") {
          setErrorMsg(res.message);
          setErrorAlertOpen(true);
        }
      },
      (err) => {
        setErrorMsg(err.message);
        setErrorAlertOpen(true);
      }
    );
  };
  // React.useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (progress < 1) {
  //       setProgressBarValue(t => t + 1);
  //     }
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  //   // setInterval(() => setProgressBarValue(Math.floor(Math.random() * 100) + 1), 1000);
  // }, []);
  return (
    <div>
      <ErrorDialog />
      <Grid container style={{ padding: "2rem", paddingTop: "1.5rem" }}>
        <Grid xs={12} sm={12} md={6} lg={6}>
        <Typography variant="h1"> Select the processor and the month to run the calculation: </Typography>
          {roles &&
            runCalculationPermission(roles.Roles_Data.Master_Permission) && (
              <Formik
                enableReinitialize
                innerRef={(r) => {
                  formikRef.current = r;
                }}
                onSubmit={runCalculation}
                initialValues={{ ...initialValues }}
                // validationSchema={Validations.addNewAdjustment}
              >
                {({ values, setFieldValue, resetForm, errors, touched }) => (
                  <>
                    <Form style={{ width: "100%", marginTop: "2rem" }}>
                      <Grid container>
                        <Grid xs={6} sm={4} md={4} lg={4} container item>
                          <Field
                            as={TextField}
                            // required
                            // error={touched.processorId && errors.processorId}
                            style={{ width: "87%" }}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused"
                              }
                            }}
                            onChange={(e) => {
                              setFieldValue("processorId", e.target.value);
                            }}
                            label="Processor"
                            name="processorId"
                            id="processorId"
                            select
                            // helperText={
                            //   touched.processorId && errors.processorId
                            //     ? errors.processorId
                            //     : ""
                            // }
                          >
                            {processorList.map((processor) => (
                              <MenuItem value={processor.ProcessorID}>
                                {processor.ProcessorName}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid xs={6} sm={4} md={4} lg={4} container item>
                          {/* <FormControl fullWidth> */}
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Field
                              as={KeyboardDatePicker}
                              style={{ display: "grid", width: "87%" }}
                              views={["month", "year"]}
                              id="payoutDate"
                              name="payoutDate"
                              // error={errors.payoutDate && touched.payoutDate}
                              minDateMessage=""
                              invalidDateMessage=""
                              maxDateMessage=""
                              inputVariant="standard"
                              format="MM/yyyy"
                              placeholder="MM/YYYY"
                              onChange={(value) => {
                                setFieldValue("payoutDate", value);
                              }}
                              KeyboardButtonProps={{
                                size: "small"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          {/* </FormControl> */}
                        </Grid>
                        <Grid xs={6} sm={4} md={4} lg={4} container item>
                          <Field
                            as={TextField}
                            // required
                            // error={touched.ProcessorID && errors.ProcessorID}
                            style={{ width: "87%" }}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused"
                              }
                            }}
                            onChange={(e) => {
                              setFieldValue("calculationType", e.target.value);
                            }}
                            label="Calculation Type"
                            name="calculationType"
                            id="calculationType"
                            select
                            // helperText={
                            //   touched.ProcessorID && errors.ProcessorID
                            //     ? errors.ProcessorID
                            //     : ""
                            // }
                          >
                            <MenuItem value="0">Full</MenuItem>
                            <MenuItem value="1">Partial</MenuItem>
                          </Field>
                        </Grid>
                        {values.calculationType == 1 && (
                          <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            container
                            style={{ marginTop: "1rem" }}
                          >
                            <SearchFields
                              agentsList={agentsList}
                              processorList={processorList}
                              repCodeList={repCodeList}
                              searchValues={searchValues}
                              setSearchValues={setSearchValues}
                            />
                            <Payout_Calculation_Listing />
                          </Grid>
                        )}
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          container
                          item
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            justifyContent: "flex-end",
                            marginTop: "1rem"
                          }}
                          spacing={3}
                        >
                          <Box mr={2}>
                            <Button
                              onClick={() => {
                                resetForm();
                              }}
                              variant="contained"
                              className={classes.resetButtonStyle}
                              disabled={
                                values.processorId == "" &&
                                values.payoutDate == "" &&
                                values.calculationType == ""
                              }
                              style={{ width: "80px" }}
                            >
                              Clear
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              type="submit"
                              variant="contained"
                              className={classes.buttonStyle}
                              disabled={
                                values.processorId == "" ||
                                values.payoutDate == "" ||
                                values.calculationType == ""
                              }
                              style={{ width: "80px" }}
                            >
                              Run
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box
                        style={{
                          paddingLeft: "220px",
                          paddingRight: "220px",
                          paddingTop: "50px"
                        }}
                      >
                        {barItemValueRes.status == "success" ? (
                          <ProgressBar
                            width="100%"
                            height="30%"
                            labelAlignment="center"
                            className={classes.progress}
                            style={{ width: "100%" }}
                            completed={progressBarValue}
                            bgColor="#6ad058"
                            baseBgColor="#adadad"
                            labelColor="#f5f5f5"
                            animateOnRender={true}
                            maxCompleted={100}
                            customLabel={`${progressBarValue}%`}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Form>
                  </>
                )}
              </Formik>
            )}
        </Grid>
        {payoutDetails && (
          <Grid
            xs={12}
            sm={12}
            md={5}
            lg={5}
            justifyContent="end"
            style={{ marginLeft: "auto" }}
          >
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0.5rem"
              }}
            >
              Calculation Status Log
            </div>
            <CalculationTable payoutDetails={payoutDetails} />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

PayoutCalculation.propTypes = {
  fetchProcessorList: PropTypes.func,
  fetchAgentList: PropTypes.func,
  payout_calculation_function: PropTypes.func,
  payout_migration_function: PropTypes.func,
  payout_calculation_detail: PropTypes.func,
  fetchProfileRepCodeList: PropTypes.func
};

PayoutCalculation.defaultProps = {
  fetchProcessorList: () => {},
  fetchAgentList: () => {},
  payout_calculation_function: () => {},
  payout_migration_function: () => {},
  payout_calculation_detail: () => {},
  fetchProfileRepCodeList: () => {}
};

const mapStateToProps = (app) => ({
  roles: app.roles.roleTab
});

export default connect(mapStateToProps, {
  fetchProcessorList,
  fetchAgentList,
  payout_calculation_function,
  payout_migration_function,
  payout_calculation_detail,
  fetchProfileRepCodeList
})(PayoutCalculation);
