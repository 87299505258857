import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import isEmpty from "lodash/isEmpty";
import { useLocation } from "react-router-dom";
import moment from "moment";

export const exportToCSVPricing = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  processorName
) => {
  var result = [];
  const fromMonth = moment(chipValue.fromMonth).format("MM/DD/YYYY");
  const toMonth = moment(chipValue.toMonth).format("MM/DD/YYYY");
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      if (some == "Processor") {
        result.push(`${some}: ${processorName}`);
      } else if (some == "From Month") {
        result.push(`${some} : ${fromMonth} `);
      } else if (some == "To Month") {
        result.push(`${some} : ${toMonth} `);
      } else if (some == "Status") {
        result.push(` ${some}: ${another == 1 ? "Active" : " Inactive"}`);
      } else {
        result.push(` ${some}: ${another}`);
      }
    }
  }
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A6" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `${moment(newCurrentDate)
          .tz("UTC")
          .format("MM/DD/YYYY hh:mm:ss A")} UTC`
      ]
    ],
    { origin: "B2" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A6:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}6`
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }
  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map((width) => ({ wch: width }));
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    fileName +
      `${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}` +
      fileExtension
  );
};

export const exportToPSHistory = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  psName,
  agent_Name
) => {
  var result = [];
  const fromMonth = moment(chipValue.fromMonth).format("MM/DD/YYYY");
  const toMonth = moment(chipValue.toMonth).format("MM/DD/YYYY");
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      if (some == "Pricing schedule") {
        result.push(`${some}: ${psName}`);
      } else if (some == "Updated By") {
        result.push(`${some}: ${agent_Name}`);
      } else if (some == "From") {
        result.push(`${some} : ${fromMonth} `);
      } else if (some == "To") {
        result.push(`${some} : ${toMonth} `);
      }
    }
  }
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A6" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `${moment(newCurrentDate)
          .tz("UTC")
          .format("MM/DD/YYYY hh:mm:ss A")} UTC`
      ]
    ],
    { origin: "B2" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A6:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}6`
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }
  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map((width) => ({ wch: width }));
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    fileName +
      `${moment(newCurrentDate).tz("UTC").format("MM-DD-YYYY hh:mm:ss A")}` +
      fileExtension
  );
};

const checkURL = (val) => {
  // const location = useLocation();
  if (window.location.pathname.indexOf(val) > -1) {
    return true;
  } else return false;
};

export const exportToCSV = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  processorName
) => {
  var result = [];
  const fromMonth = moment(chipValue.fromMonth).format("MM/DD/YYYY");
  const toMonth = moment(chipValue.toMonth).format("MM/DD/YYYY");
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      if (some == "Processor") {
        result.push(`${some}: ${processorName}`);
      } else if (some == "From Month") {
        result.push(`${some} : ${fromMonth} `);
      } else if (some == "To Month") {
        result.push(`${some} : ${toMonth} `);
      } else if (some == "Status" && checkURL("PayoutApproval")) {
        result.push(
          ` ${some}: ${
            another == 1
              ? "Pending"
              : another == 2
              ? "Under Review"
              : another == 3
              ? "Approved"
              : "Declined"
          }`
        );
      } else if (some == "Status" && checkURL("MerchantMapping")) {
        result.push(`${some}: ${another == 1 ? "Mapped" : "Un-Mapped"}`);
      } else if (some == "Status" && checkURL("Adjustment")) {
        result.push(` ${some}: ${another == 1 ? "Active" : "Inactive"}`);
      } else {
        result.push(` ${some}: ${another}`);
      }
    }
  }
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A6" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `${moment(newCurrentDate)
          .tz("UTC")
          .format("MM/DD/YYYY hh:mm:ss A")} UTC`
      ]
    ],
    { origin: "B2" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A6:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}6`
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }
  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map((width) => ({ wch: width }));
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    fileName +
      `${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}` +
      fileExtension
  );
};

export const exportToCSVWithPayout = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  processorName
) => {
  var result = [];
  const fromMonth = moment(chipValue.fromMonth).format("MM/DD/YYYY");
  const toMonth = moment(chipValue.toMonth).format("MM/DD/YYYY");
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      if (some == "Processor") {
        result.push(`${some}: ${processorName}`);
      } else if (some == "From Month") {
        result.push(`${some} : ${fromMonth} `);
      } else if (some == "To Month") {
        result.push(`${some} : ${toMonth} `);
      } else if (some == "Status" && checkURL("PayoutApproval")) {
        result.push(
          ` ${some}: ${
            another == 1
              ? "Pending"
              : another == 2
              ? "Under Review"
              : another == 3
              ? "Approved"
              : "Declined"
          }`
        );
      } else {
        result.push(` ${some}: ${another}`);
      }
    }
  }
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A6" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `${moment(newCurrentDate)
          .tz("UTC")
          .format("MM/DD/YYYY hh:mm:ss A")} UTC`
      ]
    ],
    { origin: "B2" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A6:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}6`
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }
  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map((width) => ({ wch: width }));
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    fileName +
      `${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}` +
      fileExtension
  );
};

export const calculationExportToCSV = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  processorName
) => {
  var result = [];
  const fromMonth = moment(chipValue.fromMonth).format("MM/DD/YYYY");
  const toMonth = moment(chipValue.toMonth).format("MM/DD/YYYY");
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      if (some == "Processor") {
        result.push(`${some}: ${processorName}`);
      } else if (some == "From Month") {
        result.push(`${some} : ${fromMonth} `);
      } else if (some == "To Month") {
        result.push(`${some} : ${toMonth} `);
      } else {
        result.push(` ${some}: ${another}`);
      }
    }
  }
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A6" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `${moment(newCurrentDate)
          .tz("UTC")
          .format("MM/DD/YYYY hh:mm:ss A")} UTC`
      ]
    ],
    { origin: "B2" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A6:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}6`
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }

  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map((width) => ({ wch: width }));

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    fileName +
      `${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}` +
      fileExtension
  );
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getQueryParams = function (data) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};

export const handleClickTable = function (
  event,
  name,
  processor,
  selected,
  selectedProcessorList,
  setSelected
) {
  const selectedIndex = selected.indexOf(name);
  const processorIndex = selectedProcessorList.indexOf(processor);
  let newSelected = [];
  let processorArr = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
    processorArr = processorArr.concat(selectedProcessorList, processor);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
    processorArr = processorArr.concat(selectedProcessorList.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
    processorArr = processorArr.concat(selectedProcessorList.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
    processorArr = processorArr.concat(
      selectedProcessorList.slice(0, processorIndex),
      selectedProcessorList.slice(processorIndex + 1)
    );
  }
  setSelected(newSelected, processorArr);
};

export const accountingNumberformat = (
  number,
  maxdecimal = 2,
  mindecimal = 2
) => {
  const intNumber = typeof number === "number" ? number : parseFloat(number);
  const result = new Intl.NumberFormat("en", {
    maximumFractionDigits: maxdecimal,
    minimumFractionDigits: mindecimal
  }).format(intNumber);
  return result;
};

export const handleRowDataCheck = function (
  event,
  name,
  agent,
  selected,
  agentsList,
  setSelected
) {
  const selectedIndex = selected.indexOf(name);
  const agentIndex = agentsList.indexOf(agent);
  let newSelected = [];
  let agentsArr = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
    agentsArr = agentsArr.concat(agentsList, agent);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
    agentsArr = agentsArr.concat(agentsList.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
    agentsArr = agentsArr.concat(agentsList.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
    agentsArr = agentsArr.concat(
      agentsList.slice(0, agentIndex),
      agentsList.slice(agentIndex + 1)
    );
  }
  setSelected(newSelected, agentsArr);
};
