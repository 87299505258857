import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { payout_calculation_history } from "../../../actions/PayoutCalculation.action";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import isEmpty from "lodash/isEmpty";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { TablePageData } from "../../../utils/constants";
import tableStyle from "../../../components/CustomizeTable/style";
import CustomTable from "../../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../../components/CustomButtons/Button";
import { exportToCSV, getQueryParams } from "../../../utils/common";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import noDataImage from "../../../../../assets/img/noDataImage.png";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ExportIcon } from "../../../../../assets/svgIcons/ExportIcon.svg";
import get from "lodash/get";
import { exportToPDF } from "modules/reports/utils/common";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  actionPermission,
  permissionCheck,
} from "../../../../../Utils/commonUtils";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const headCellsItems = [
  {
    id: "ProcessorName",
    label: "Processor",
    isSort: true,
    sortProperty: "ProcessorName",
    actionsCellStyle: "center",
  },
  {
    id: "ResidualMonth",
    label: "Residual Month",
    isSort: true,
    sortProperty: "ResidualMonth",
    actionsCellStyle: "center",
  },
  {
    id: "Type",
    label: "Calculation Type",
    isSort: true,
    sortProperty: "Type",
    actionsCellStyle: "center",
  },
  {
    id: "StartDate",
    label: "Start Date",
    isSort: true,
    sortProperty: "StartDate",
    actionsCellStyle: "center",
  },
  {
    id: "EndDate",
    label: "End Date",
    isSort: true,
    sortProperty: "EndDate",
    actionsCellStyle: "center",
  },
  {
    id: "Status",
    label: "Status",
    isSort: true,
    sortProperty: "Status",
    actionsCellStyle: "center",
  },
  {
    id: "No_Of_Merch",
    label: "Merchants",
    isSort: false,
    sortProperty: "No_Of_Merch",
    actionsCellStyle: "center",
  },
  {
    id: "StartedBy_Name",
    label: "Initiated By",
    isSort: false,
    sortProperty: "StartedBy_Name",
    actionsCellStyle: "center",
  },
];
const labelName = {
  ProcessorID: "Processor",
  fromMonth: "From Month",
  toMonth: "To Month",
};

const CalculationHistory = ({ payout_calculation_history, users }) => {
  const searchInitialData = {
    ProcessorID: "",
    fromMonth: "",
    toMonth: "",
  };
  const CALCULATION_ID = getQueryParams("calcId");
  const URL_FLAG = CALCULATION_ID;
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [order, setOrder] = React.useState("desc");
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [orderBy, setOrderBy] = React.useState("UpdatedOn");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [calculationHistoryList, setCalculationHistoryList] = React.useState(
    []
  );
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData,
  });
  const [chipValue, setChipValue] = React.useState([]);
  const anchorRef = React.useRef(null);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const calculationHistoryListCall = function (searchValues) {
    const payload = {
      logID: URL_FLAG,
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page,
    };
    payout_calculation_history(
      payload,
      (res) => {
        if (res.status == "success") {
          setCalculationHistoryList(res);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(res.totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < res.totalCount
                ? page * rowsPerPage
                : res.totalCount,
            total: res.totalCount,
          });
        }
      },
      (err) => {
        enqueueSnackbar("An error occurred.", {
          variant: "error",
        });
      }
    );
  };

  React.useEffect(() => {
    calculationHistoryListCall();
  }, [orderBy, order, page, rowsPerPage]);

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const filterData = {
      logID: URL_FLAG,
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: page,
    };
    filterData.userId = localStorage.getItem("userId") || "";
    payout_calculation_history(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => ({
              "Processor Name": row.ProcessorName,
              "Residual Month": row.ResidualMonth,
              "Calculation Type": row.Type,
              "Start Date": moment
                .utc(row.StartDate)
                .format("MM/DD/YYYY HH:mm:ss"),
              "End Date": moment.utc(row.EndDate).format("MM/DD/YYYY HH:mm:ss"),
              Status: row.Status,
              Merchants: row.No_Of_Merch,
              "Stated By": row.StartedBy_Name,
            }));
            exportToCSV(
              mappedLogs,
              "Calculation History List",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error",
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error",
        });
      }
    );
  };

  const exportToPDFList = () => {
    const filterData = {
      logID: URL_FLAG,
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: page,
    };
    filterData.userId = localStorage.getItem("userId") || "";
    payout_calculation_history(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => [
              row.ProcessorName,
              row.ResidualMonth,
              row.Type,
              row.StartDate,
              row.EndDate,
              row.Status,
              row.No_Of_Merch,
              row.StartedBy_Name,
            ]);
            const headersName = [
              "Processor Name",
              "Residual Month",
              "Calculation Type",
              "Start Date",
              "End Date",
              "Status",
              "Merchants",
              "Stated By",
            ];
            exportToPDF(
              headersName,
              mappedLogs,
              "Calculation Summary List",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error",
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error",
        });
      }
    );
  };

  return (
    <TableContainer
      className={classes.TableContainer}
      component={Paper}
      style={{ borderRadius: "unset" }}
    >
      <Grid
        item
        style={{
          margin: "1rem",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <CoustomButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.clearButton}
          onClick={() => history.goBack()}
        >
          Back
        </CoustomButton>
        {actionPermission(
          permissionCheck.Category.reports,
          permissionCheck.Page.CalculationHistory,
          permissionCheck.option.export
        ) && (
          <IconsTooltip title="Export" placement="top">
            <div className={classes.appSvgIcons}>
              <ExportIcon
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleToggle}
                ref={anchorRef}
              />
            </div>
          </IconsTooltip>
        )}
        <Popper
          open={openImportFileModal}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{
            zIndex: "10",
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    // autoFocusItem={openImportFileModal}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={exportListData}
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Export to Excel
                    </MenuItem>
                    <MenuItem
                      onClick={exportToPDFList}
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Export to PDF
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
      <CustomTable
        page={page}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        data={!isEmpty(calculationHistoryList) && calculationHistoryList.data}
        pageDetails={pageDetails}
        headCells={headCellsItems}
        isCallInitialization={initialCall}
        selected={[]}
        setSelected={() => {}}
        isSelection={false}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={
          !isEmpty(calculationHistoryList) && calculationHistoryList.totalCount
        }
      >
        {!isEmpty(calculationHistoryList) ? (
          calculationHistoryList.data.map((row) => {
            return (
              <TableRow className={classes.cellHeadSign} key={row.ID}>
                <TableCell className={classes.cellText} align="center">
                  {row.ProcessorName}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.ResidualMonth}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Type}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {moment.utc(row.StartDate).format("MM/DD/YYYY HH:mm:ss")}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {moment.utc(row.EndDate).format("MM/DD/YYYY HH:mm:ss")}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.Status}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.No_Of_Merch}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.StartedBy_Name}
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell className="no-data" colSpan={12}>
              <img src={noDataImage} alt="" className={classes.noDataImage} />
            </TableCell>
          </TableRow>
        )}
      </CustomTable>
    </TableContainer>
  );
};

CalculationHistory.propTypes = {
  payout_calculation_history: PropTypes.func,
  users: PropTypes.object,
};

CalculationHistory.defaultProps = {
  payout_calculation_history: () => {},
};

const mapStateToProps = (app) => ({
  users: app,
});

export default connect(mapStateToProps, {
  payout_calculation_history,
})(CalculationHistory);
