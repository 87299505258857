/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import CoustomButton from "components/CustomButtons/Button.js";
import Menu from "@material-ui/core/Menu";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Button from "components/CustomButtons/Button.js";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card.js";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";
import isEmpty from "lodash/isEmpty";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/ProviderAcitivityHistoryChip";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { getProviderActivityById } from "../../Redux/Actions/ProvideRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { parseISO } from "date-fns";
import { getQueryParams } from "Utils/commonUtils";
import ChipInput from "material-ui-chip-input";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import { exportToXLS } from "../../Utils/commonUtils";

const useStyles = makeStyles(tableStyle);

function createData(
  DBA_Name,
  E_mail,
  Activity_Type,
  Sub_Merchant_ID,
  Updated_By,
  Updated_On,
  Description,
  Message,
  Version_Pdf
) {
  return {
    DBA_Name,
    E_mail,
    Activity_Type,
    Sub_Merchant_ID,
    Updated_By,
    Updated_On,
    Description,
    Message,
    Version_Pdf
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function descendingComparator(a, b, orderBy) {
  if (orderBy == "Updated_On") {
    if (moment(b[orderBy]) < moment(a[orderBy])) {
      return -1;
    }
    if (moment(b[orderBy]) > moment(a[orderBy])) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Activity_Type",
    numeric: false,
    disablePadding: false,
    label: "Activity-Type",
    headClassName: "Activity_Type"
  },
  {
    id: "Description",
    numeric: true,
    disablePadding: false,
    label: "Description",
    headClassName: "Description"
  },
  {
    id: "Updated_By",
    numeric: true,
    disablePadding: false,
    label: "Updated By",
    headClassName: "Updated_By"
  },
  {
    id: "Updated_On",
    numeric: false,
    disablePadding: false,
    label: "Updated On",
    headClassName: "Updated_On"
  },
  {
    id: "Message",
    numeric: true,
    disablePadding: false,
    label: "Status",
    headClassName: "Message"
  },
  {
    id: "Version_Pdf",
    numeric: false,
    disablePadding: false,
    label: "",
    headClassName: "Version_Pdf"
  }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const ProviderActivity = (props) => {
  const searchInitialData = {
    Name: "",
    searchAgentId: "",
    From: null,
    To: null
  };
  const classes = useStyles();
  const PID = getQueryParams("mid");
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Updated_On");
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [initialCall, setInitialCall] = React.useState(false);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [showFullText, setShowFullText] = React.useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBack = () => {
    if (props.tableData.formMode === "Edit") {
      props.history.push(`/${props.userDetails.layout}/Merchant/app`);
    } else {
      props.history.push(`/${props.userDetails.layout}/Merchant`);
    }
  };

  const getFilteredData = function (searchValues) {
    const finalSearchData = {
      ...searchValues,
      providerId:
        getQueryParams("mid") ||
        (props.tableData.providerDetailsById &&
          props.tableData.providerDetailsById.Providers_Data[0]._id)
    };
    props.getProviderActivityById(finalSearchData);
  };

  React.useEffect(() => {
    if (props.tableData.providerDetailsById) {
      getFilteredData(searchValues);
    }
    if (
      !props.tableData.providerActivityById &&
      !props.tableData.providerDetailsById
    ) {
      handleBack();
    }
  }, []);

  const handleSubmitSearch = (values) => {
    const chipArray = [];
    for (const property in values) {
      if (!isEmpty(values[property])) {
        chipArray.push(
          `${property === "searchAgentId" ? "Updated By" : property}: ${
            property === "searchAgentId"
              ? props.agentData.agentList.data.filter(
                  (item) => item.User_Id === values[property]
                )[0].Name
              : values[property]
          }`
        );
      }
    }
    setSearchValues(values);
    setChipValue(chipArray);
    getFilteredData(values);
    handleClose();
  };

  const exportListData = () => {
    if (props.tableData.providerActivityById.data) {
      const mappedLogs = props.tableData.providerActivityById.data.map(
        (row) => ({
          "DBA Name": row.DBA_Name,
          "E-mail": row.Business_EMail,
          "Activity Type": row.Activity_Type,
          Description: row.Description,
          "Updated By": row.Updated_By,
          "Updated Date": moment(row.Updated_On).format("YYYY-MM-DD")
        })
      );
      exportToXLS(mappedLogs, "Provider activity history");
    }
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue([]);
    getFilteredData({
      ...searchInitialData
    });
    handleClose();
  };

  const handleDeleteChip = (chip) => {
    setPage(0);
    if (chipValue.length) {
      setChipValue(chipValue.filter((v) => v !== chip));
      const requestData = { ...searchValues };
      const fillterPreChip = chipValue.filter((v) => v !== chip);
      const filterRemoveChip = chipValue.filter((v) => v === chip);
      if (fillterPreChip.length !== 0) {
        fillterPreChip.forEach((v) => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "Name") {
            requestData.Name = splitSearchName[1].trim();
          }
          if (splitSearchName[0] === "From") {
            requestData.From = splitSearchName[1].trim();
          }
          if (splitSearchName[0] === "To") {
            requestData.To = splitSearchName[1].trim();
          }
          if (splitSearchName[0] === "Updated By") {
            const filterAgentName = props.agentList.data.filter(
              (key) => key.Name.trim() === splitSearchName[1].trim()
            );
            requestData.searchAgentId = filterAgentName[0].User_Id;
          }
        });
      }
      if (filterRemoveChip.length !== 0) {
        filterRemoveChip.forEach((v) => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "Name") {
            requestData.Name = "";
          }
          if (splitSearchName[0] === "Updated By") {
            requestData.searchAgentId = "";
          }
          if (splitSearchName[0] === "From") {
            requestData.From = null;
          }
          if (splitSearchName[0] === "To") {
            requestData.To = null;
          }
        });
      }
      getFilteredData({
        ...requestData
      });
      setSearchValues(requestData);
    } else {
      const requestData = { ...searchValues };
      requestData.Name = "";
      requestData.searchAgentId = "";
      requestData.From = null;
      requestData.To = null;
      getFilteredData({
        ...requestData
      });
      setChipValue(chipValue);
      setSearchValues(requestData);
    }
    if (chipValue.length === 0) {
      const requestData = { ...searchValues };
      requestData.Name = "";
      requestData.searchAgentId = "";
      requestData.From = null;
      requestData.To = null;
      getFilteredData({
        ...requestData
      });
      setSearchValues(requestData);
      setChipValue([]);
    }
  };

  let rows = [];
  if (props.tableData.providerActivityById) {
    props.tableData.providerActivityById.data.forEach((v, i) => {
      const DBA_Name = v.DBA_Name;
      const E_mail = v.Business_EMail;
      const Activity_Type = v.Activity_Type;
      const Sub_Merchant_ID = v.Activity_id;
      const Updated_By = v.Updated_By;
      const Updated_On = v.Updated_On;
      const Description = v.Description;
      const Message = v.Message;
      const Version_Pdf = v.Version_Pdf;
      rows.push(
        createData(
          DBA_Name,
          E_mail,
          Activity_Type,
          Sub_Merchant_ID,
          Updated_By,
          Updated_On,
          Description,
          Message,
          Version_Pdf
        )
      );
    });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    if (props.tableData.providerActivityById?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  // Sort the entire dataset before pagination
  const sortedAndPaginatedData = stableSort(
    rows,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const RowWithDescription = ({ row }) => {
    const [showFullTextMap, setShowFullTextMap] = React.useState({});
    const toggleShowFullText = (id) => {
      setShowFullTextMap((prevState) => ({
        ...prevState,
        [id]: !prevState[id]
      }));
    };
    const showFullText = showFullTextMap[row.Sub_Merchant_ID] || false;

    const descriptionToShow = showFullText
      ? row.Description
      : `${row.Description.slice(0, 75)}...`;

    return (
      <>
        {row.Description && (
          <div>
            {descriptionToShow}
            {row.Description.length > 75 && (
              <span
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginLeft: showFullText ? "8px" : "unset"
                }}
                onClick={() => toggleShowFullText(row.Sub_Merchant_ID)}
              >
                {showFullText ? "Show less" : "Show More"}
              </span>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <>
            <Grid
              container
              className={classes.searchContainer}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "0px"
              }}
            >
              <Button
                className={classes.clearButton}
                onClick={() => handleBack()}
              >
                Back
              </Button>
              <Typography>
                <span style={{ fontWeight: "bold" }}>DBA Name: </span>{" "}
                {`${props.tableData.providerActivityById.DBA_Name}`}
              </Typography>
              <Typography>
                <span style={{ fontWeight: "bold" }}>Email: </span>{" "}
                {`${props.tableData.providerActivityById.Business_EMail}`}
              </Typography>
            </Grid>
            <Grid item className={classes.margin}>
              <div
                className={classes.searchWrapper}
                style={{ paddingBottom: "1%" }}
              >
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ width: "35%" }}>
                    <ChipInput
                      value={chipValue.length > 0 ? chipValue : []}
                      classes={{ chipContainer: classes.chipContainer }}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex" }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Search"
                      onDelete={(chip, index) => {
                        handleDeleteChip(chip, index);
                      }}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        setSearchValues={setSearchValues}
                        searchValues={searchValues}
                        handleSubmitSearch={handleSubmitSearch}
                        handleClearSearch={handleClearSearch}
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <Button
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </Button>
                <ExportIcon
                  onClick={exportListData}
                  className={classes.appSvgIcons}
                />
              </div>
            </Grid>
          </>
        )}
        {!windowWidth && (
          <>
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: "0 1rem" }}
            >
              <Button
                className={classes.clearButton}
                onClick={() => handleBack()}
              >
                Back
              </Button>
            </Grid>
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin} style={{ display: "flex" }}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ width: "85%" }}>
                    <ChipInput
                      value={chipValue.length > 0 ? chipValue : []}
                      classes={{ chipContainer: classes.chipContainer }}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex" }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Search"
                      onDelete={(chip, index) => {
                        handleDeleteChip(chip, index);
                      }}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        setSearchValues={setSearchValues}
                        searchValues={searchValues}
                        handleSubmitSearch={handleSubmitSearch}
                        handleClearSearch={handleClearSearch}
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <Button
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </Button>
                <ExportIcon
                  onClick={exportListData}
                  className={classes.appSvgIcons}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows && rows.length}
          />
          <TableBody>
            {rows && !isEmpty(rows) ? (
              sortedAndPaginatedData.map((row, index) => (
                <TableRow>
                  <TableCell className={classes.cellText} align="left">
                    {row.Activity_Type}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Description && <RowWithDescription row={row} />}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Updated_By}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Updated_On}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Message}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.Version_Pdf ? (
                      <a
                        href={row.Version_Pdf}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AttachFileIcon className={classes.cellHeadSign} />
                      </a>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: -1
                    }
                  ]}
                  colSpan={12}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } }
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ProviderActivity.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  userDetails: PropTypes.object,
  getProviderActivityById: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  agentData: app.users
});
export default connect(mapStateToProps, {
  getProviderActivityById
})(ProviderActivity);
