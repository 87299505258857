import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TodayIcon from "@material-ui/icons/Today";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "../../../../assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);

function SimpleDialog({
  setSearchValues,
  handleSubmitSearch,
  handleClearSearch,
  searchValues,
  agentsList,
  processorList,
  repCodeList,
  getAgentList
}) {
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    allState[type] = value;
    if (type === "fromMonth") {
      allState.fromMonth = moment(value).startOf("month").format("YYYY-MM-DD");
    }
    if (type === "toMonth") {
      allState.toMonth = moment(value).endOf("month").format("YYYY-MM-DD");
    }
    setSearchValues({ ...allState });
  };
  const dateNow = new Date();
  const maxDate = new Date().setMonth(dateNow.getMonth() - 1);
  const minDate = new Date().setMonth(dateNow.getMonth() - 12);

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "3%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Grid style={{ width: "100%" }} item xs={12}>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Search By"
            value={searchValues.searchBy}
            onChange={(e) => onChange(e.target.value, "searchBy")}
            name="searchBy"
            style={{ width: "100%" }}
            fullWidth
            id="searchBy"
            select
          >
            <MenuItem className={classes.dialogBoxDropDown} value="PayoutMonth">
              Payout Month
            </MenuItem>
            <MenuItem
              className={classes.dialogBoxDropDown}
              value="ResidualMonth"
            >
              Residual Month
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Processor"
            value={searchValues.processor}
            onChange={(e) => onChange(e.target.value, "processor")}
            name="processor"
            id="processor"
            select
            fullWidth
          >
            {processorList.map((processor) => (
              <MenuItem
                className={classes.dialogBoxDropDown}
                value={processor.ProcessorID}
              >
                {processor.ProcessorName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid style={{ width: "100%" }} item xs={12}>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            autofocus
            label="DBA Name"
            value={searchValues.dbaName}
            autoFocus={searchValues.dbaName != "" && true}
            onChange={(e) => onChange(e.target.value, "dbaName")}
            name="dbaName"
            style={{ width: "100%" }}
            fullWidth
            id="dbaName"
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <Autocomplete
            name="agentId"
            id="agentId"
            style={{
              fontSize: "14px",
              "&.focused": {
                // color: theme.palette.primary.main,
                fontSize: "14px",
                fontStyle: "Helvetica, Arial,sans-serif"
              }
            }}
            label="Type(s) of goods or services sold"
            value={
              (agentsList || []).find(
                (option) => option.UserID === parseInt(searchValues.agentId)
              ) || ""
            }
            autoHighlight
            fullWidth
            // onInputChange={(e, newInputValue) => {
            //   if (newInputValue.length >= 3) {
            //     getAgentList(newInputValue);
            //   }
            // }}
            getOptionDisabled={(option) => option.UserID === 919863231596669598}
            onChange={(_, value, action) => {
              if (action === "clear") {
                onChange("", "agentId");
              } else {
                value && onChange(value.UserID, "agentId");
              }
            }}
            selectOnFocus
            options={agentsList || []}
            getOptionLabel={(option) => option.AgentName || ""}
            renderInput={(params) => (
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                required
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  },
                  required: true
                }}
                {...params}
                label="Agent Name"
              />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.AgentName, inputValue, {
                insideWords: true
              });
              const parts = parse(option.AgentName, matches);

              return (
                <div>
                  {parts.map((part, index) => (
                    <span
                      style={{
                        background: part.highlight ? "#f3ff0070" : "",
                        fontWeight: part.highlight ? 400 : "",
                        fontSize: "14px"
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "2%" }}>
        <Grid item xs>
          <InputLabel id="label-fromMonth">
            From Month
            <span className="spn-required">*</span>
          </InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              fullWidth
              views={["month", "year"]}
              id="fromMonth"
              name="fromMonth"
              labelId="label-fromMonth"
              allowKeyboardControl={false}
              maxDate={searchValues.toMonth || maxDate}
              minDate={minDate}
              minDateMessage=""
              invalidDateMessage=""
              maxDateMessage="Future dates are not allowed."
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {}}>
                      <TodayIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              format="MM/yyyy"
              placeholder="MM/YYYY"
              defaultValue={null}
              value={
                searchValues.fromMonth
                  ? moment(searchValues.fromMonth).format("MM-DD-YYYY")
                  : null
              }
              onChange={(value) => {
                let dateObj = value;
                if (dateObj && dateObj.getMonth()) {
                  const month = dateObj.getMonth() + 1; // months from 1-12
                  const day = dateObj.getDate();
                  const year = dateObj.getFullYear();
                  dateObj = `${year}/${month}/${day}`;
                }
                onChange(dateObj, "fromMonth");
              }}
              KeyboardButtonProps={{
                "aria-label": "start month"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs>
          <InputLabel id="label-toMonth">
            To Month
            <span className="spn-required">*</span>
          </InputLabel>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              fullWidth
              labelId="label-toMonth"
              views={["month", "year"]}
              id="toMonth"
              name="toMonth"
              allowKeyboardControl={false}
              maxDate={moment(new Date()).endOf("month")}
              minDate={moment(searchValues.fromMonth).format("MM-DD-YYYY")}
              minDateMessage=""
              invalidDateMessage=""
              maxDateMessage="Future dates are not allowed."
              defaultValue={null}
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {}}>
                      <TodayIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              format="MM/yyyy"
              placeholder="MM/YYYY"
              value={
                searchValues.toMonth
                  ? moment(searchValues.toMonth).format("MM-DD-YYYY")
                  : null
              }
              onChange={(value) => {
                let dateObj = value;
                if (dateObj && dateObj.getMonth()) {
                  const month = dateObj.getMonth() + 1; // months from 1-12
                  const day = dateObj.getDate();
                  const year = dateObj.getFullYear();
                  dateObj = `${year}/${month}/${day}`;
                }
                onChange(dateObj, "toMonth");
              }}
              KeyboardButtonProps={{
                "aria-label": "start month"
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      {searchValues.agentId && repCodeList && (
        <Grid container style={{ marginBottom: "3%" }}>
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              label="Rep Code"
              value={searchValues.repCode}
              onChange={(e) => onChange(e.target.value, "repCode")}
              name="repCode"
              id="repCode"
              select
              fullWidth
            >
              {repCodeList.map((repCode) => (
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={repCode.RepCode}
                >
                  {repCode.RepCode}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        className={classes.margin}
        style={{ justifyContent: "end", marginTop: "5%" }}
      >
        <Button
          cancel
          variant="contained"
          className={classes.clearButton}
          onClick={() => handleClearSearch()}
        >
          clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          onClick={() => handleSubmitSearch()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  setSearchValues: PropTypes.func,
  searchValues: PropTypes.object,
  agentsList: PropTypes.array,
  processorList: PropTypes.array,
  getAgentList: PropTypes.array,
  repCodeList: PropTypes.array,
  handleSubmitSearch: PropTypes.func,
  handleClearSearch: PropTypes.func
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(SimpleDialog);
