import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import tableStyle from "../../modules/payout/components/CustomizeTable/style";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Card from "components/Card/Card";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Template from "assets/doc/User_Import_Template.xlsx";
import PSNewItem from "assets/doc/NewResidual_Items_Import_Template.xlsx";
import PSUpdate from "assets/doc/Update_PS_Import_Template.xlsx";
import PSTemplate from "assets/doc/NewPS_Import_Template.xlsx";
import { useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ImportIconSvg } from "../../assets/svgIcons/ImportIconSvg.svg";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px"
  },
  tooltipPlacementTop: {
    margin: "4px 0"
  }
}))(Tooltip);

const MenuItemTheme = createTheme({
  overrides: {
    MuiMenuItem: {
      root: {
        fontSize: "14px !important"
      }
    }
  }
});

function ImportButton(props) {
  const useStyles = makeStyles(tableStyle);
  const useModalStyles = makeStyles(styles);
  const classes = useStyles();
  const modalClasses = useModalStyles();
  const theme = useTheme();
  const location = useLocation();
  const mediumWidth = useMediaQuery(theme.breakpoints.up("md"));
  const smallWidth = useMediaQuery(theme.breakpoints.up("sm"));
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const [psImportType, setPSImportType] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openImportFileModal);
  React.useEffect(() => {
    if (prevOpen.current === true && openImportFileModal === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = openImportFileModal;
  }, [openImportFileModal]);

  const pageURL = (val) => {
    if (location.pathname.indexOf(val) > -1) {
      return true;
    } else return false;
  };

  const handleFormSubmit = () => {
    if (pageURL("Users")) {
      props.handleSubmit();
    } else if (pageURL("PricingSchedule")) {
      props.handlePSSubmit(psImportType);
    }
  };

  let title = "";
  if (pageURL("Users")) {
    title = props.title;
  } else if (pageURL("PricingSchedule")) {
    if (psImportType == 1) {
      title = "Import New Items";
    } else if (psImportType == 2) {
      title = "Import New PS";
    } else if (psImportType == 3) {
      title = "Update PS";
    }
  }

  return (
    <>
      <IconsTooltip title="Import" placement="top">
        <div className={classes.appSvgIcons}>
          <ImportIconSvg
            ref={anchorRef}
            aria-controls={openImportFileModal ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          />
        </div>
      </IconsTooltip>
      <Popper
        open={openImportFileModal}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{
          zIndex: "10"
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper>
              <ThemeProvider theme={MenuItemTheme}>
                <ClickAwayListener onClickAway={handleClose}>
                  {pageURL("Users") ? (
                    <MenuList
                      autoFocusItem={openImportFileModal}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleClose}>
                        <a
                          href={Template}
                          without
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Download Template
                        </a>
                      </MenuItem>
                      <MenuItem onClick={props.handleModalOpen}>
                        Import
                      </MenuItem>
                    </MenuList>
                  ) : pageURL("PricingSchedule") ? (
                    <MenuList
                      autoFocusItem={openImportFileModal}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {/* <MenuItem onClick={handleClose}>
                      <a
                        href={PSNewItem}
                        without
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Download Template - New Items
                      </a>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setPSImportType(1);
                        props.handleModalOpen();
                      }}
                    >
                      Import - New Items
                    </MenuItem> */}
                      <MenuItem onClick={handleClose}>
                        <a
                          href={PSTemplate}
                          without
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Download Template - New PS
                        </a>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPSImportType(2);
                          props.handleModalOpen();
                        }}
                      >
                        Import - New PS
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <a
                          href={PSUpdate}
                          without
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Download Template - Update PS
                        </a>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setPSImportType(3);
                          props.handleModalOpen();
                        }}
                      >
                        Import - Update PS
                      </MenuItem>
                    </MenuList>
                  ) : (
                    ""
                  )}
                </ClickAwayListener>
              </ThemeProvider>
            </Paper>
          </Grow>
        )}
      </Popper>
      {/* upload modal */}
      <Modal
        open={props.isModalOpen}
        onClose={props.handleModalClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div
          className={modalClasses.modalDiv}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
          }}
        >
          <Card
            className={modalClasses.modalCard}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem 30px",
              width:
                mediumWidth && smallWidth
                  ? "30%"
                  : !mediumWidth && smallWidth
                  ? ""
                  : !mediumWidth && !smallWidth && "55%"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
              }}
            >
              <h1 style={{ fontWeight: "700", textDecoration: "underline" }}>
                {title}
              </h1>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "24px",
                  margin: "8px 0",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  color: "#5a5a5a"
                }}
              >
                Upload File
                <br /> <i style={{ fontSize: "12px" }}>( Only xls / xlsx )</i>
              </p>
              <FormControl style={{ width: "80%" }}>
                <Input
                  disabled
                  startAdornment={
                    <InputAdornment position="start">
                      <input
                        accept=".xlsx, .xls, .csv"
                        id="fileUpload"
                        type="file"
                        name="importFile"
                        // multiple
                        onChange={props.handleCapture}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div style={{ margin: "8% 0", display: "flex", gap: "1rem" }}>
                {props.selectedFile && (
                  <Button
                    className={classes.ExportButtonStyle}
                    onClick={() => handleFormSubmit()}
                  >
                    Upload
                  </Button>
                )}
                <Button
                  className={classes.closeButton}
                  onClick={() => props.handleModalClose()}
                >
                  Close
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
      {/* error modal */}
      <Modal open={props.errorModal} onClose={() => props.setErrorModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard} style={{ height: "auto" }}>
            <Grid constainer className={classes.margin}>
              <Grid item style={{ alignItems: "center" }}>
                <div className={classes.cardDiv} style={{ height: "unset" }}>
                  <div align="center">
                    <CloseOutlinedIcon
                      style={{
                        color: "red",
                        fontSize: "5rem",
                        borderRadius: "50%",
                        border: "6px solid red"
                      }}
                    />
                  </div>
                  <div
                    className={classes.successText}
                    style={{
                      whiteSpace: "initial",
                      textAlign: "center",
                      margin: "8px 0"
                    }}
                  >
                    {props.errorMsg
                      ? props.errorMsg
                      : "Wrong format. Choose either .xls or .xlsx"}
                    <br />
                  </div>
                  <div align="center" style={{ paddingTop: "10px" }}>
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      size="sm"
                      onClick={() => props.setErrorModal(false)}
                      align="center"
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Modal>
      {/* success modal */}
      <Modal
        open={props.successModal}
        onClose={() => props.setSuccessModal(false)}
      >
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard} style={{ height: "auto" }}>
            <Grid constainer className={classes.margin}>
              <Grid item style={{ alignItems: "center" }}>
                <div className={classes.cardDiv} style={{ height: "unset" }}>
                  <div align="center">
                    <CheckOutlinedIcon
                      style={{
                        color: "#2ba992",
                        fontSize: "5rem",
                        borderRadius: "50%",
                        border: "6px solid #2ba992"
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontWeight: "500",
                      fontSize: "22px",
                      margin: "8px 0",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column"
                    }}
                  >
                    Successfully Imported File
                  </p>
                  <div
                    className={classes.successText}
                    style={{
                      whiteSpace: "initial",
                      textAlign: "center",
                      margin: "8px 0"
                    }}
                  >
                    {props.successData && props.successData.message}
                    <br />
                    <span
                      style={{ color: "#0093c9", cursor: "pointer" }}
                      onClick={props.downloadImportedData}
                    >
                      Click here
                    </span>
                  </div>
                  <div align="center" style={{ paddingTop: "10px" }}>
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      size="sm"
                      onClick={() => {
                        props.setSuccessModal(false);
                        props.handleModalClose();
                      }}
                      align="center"
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Modal>
    </>
  );
}
ImportButton.propTypes = {
  handleModalClose: PropTypes.func,
  handleModalOpen: PropTypes.func,
  downloadImportedData: PropTypes.func,
  setSuccessModal: PropTypes.func,
  setErrorModal: PropTypes.func,
  successData: PropTypes.object,
  successModal: PropTypes.bool,
  errorMsg: PropTypes.string,
  errorModal: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  handleCapture: PropTypes.func,
  handleSubmit: PropTypes.func,
  template: PropTypes.any,
  selectedFile: PropTypes.any,
  handlePSSubmit: PropTypes.func
};

const mapStateToProps = (app) => ({
  mainData: app
});

export default connect(mapStateToProps, {})(ImportButton);
