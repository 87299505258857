import { get, post } from "../api_interceptor/httpClient";
import toggleLoaderAction from "../../../Redux/Actions/loader";
import {
  GET_PRICING_SCHEDULE_LIST,
  GET_PAYOUT_RATE_TYPE_LIST,
  GET_PAYOUT_PROCESSOR_LIST,
  GET_PRICING_SCHEDULE_INFO,
  GET_PS_VERSION_LIST
} from "../api_interceptor/urls";

export const fetchPricingScheduleList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PRICING_SCHEDULE_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchRateList =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PAYOUT_RATE_TYPE_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchProcessorList =
  (successCallback, failureCallback, payload) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PAYOUT_PROCESSOR_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchPricingScheduleDetailsByID =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PRICING_SCHEDULE_INFO, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const newUpdatePricingSchedule =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await post(GET_PRICING_SCHEDULE_INFO, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };

export const fetchPSVersionList =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    dispatch(toggleLoaderAction(true));
    try {
      const data = await get(GET_PS_VERSION_LIST, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
    dispatch(toggleLoaderAction(false));
  };
