/* eslint-disable no-nested-ternary */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
// import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
// core components
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import WebIcon from "@material-ui/icons/Web";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import CommentIcon from "@material-ui/icons/Comment";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "components/Card/Card";
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DateFnsUtils from "@date-io/date-fns";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import PrintIcon from "@material-ui/icons/Print";
import PublicIcon from "@material-ui/icons/Public";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DescriptionIcon from "@material-ui/icons/Description";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import RoomIcon from "@material-ui/icons/Room";
import Table from "@material-ui/core/Table";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { locationCity } from "../../constant/locationConstant";
import OwnerInformation from "./OwnerInformation";
import ContactInformation from "./ContactInformation";
import {
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  mpaChecklistComment,
  kycChecklistAttechment,
  mpaChecklistAttechment,
  deleteBankDocumentApiCall,
  deleteOwnerApiCall
} from "../../actions/Operations";
import {
  provideRegistration,
  deleteIncroptration,
  deleteAddressProof,
  deleteIdentityProof,
  getMccList,
  getQuestionData,
  getKycQuestionData,
  getCountryTypeList,
  accountTypeList,
  ownerTypeList,
  updateRegistration,
  pricingTempleteCall,
  setPricingTempleteEmpty,
  getProviderStatus,
  changeSingleStatus,
  saveQuestionCall,
  changeProviderFormMode,
  setResetWuestion,
  saveQuestionCallAction,
  getProviderParameterCall,
  getProviderDetailsById,
  getKycResponseUserList,
  clearRegData
  // getSignNowCall
} from "../../Redux/Actions/ProvideRegistration";
import {
  getKycResponseFromAlloy,
  postKycQuestionsCall,
  postKycSubQuestions
} from "../../actions/Operations";
import { getSignNowCall, getDocStatus } from "../../actions/signNowAction";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(styles);

let kycCheckListFlag = false;
let editPermissionFlag = false;
let createPermissionFlag = false;

function MerchantVersion(props) {
  const { componentRef } = props;
  const [ownerDeleteModal, setOwnerDeleteModal] = React.useState(false);
  const theme = useTheme();
  const [dbaValue, setDBAValue] = React.useState(false);
  const classes = useStyles();
  const [data, setData] = React.useState(false);
  const [mccList, setMccList] = React.useState([]);
  const [stateInput, setStateInput] = React.useState(true);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthLG = useMediaQuery(theme.breakpoints.up("lg"));
  var image =
    props.app.defaultTheme && props.app.defaultTheme.data[0].Logo_Image;

  const [values, handleInput] = React.useState({
    businessName: "",
    legalBusiness: "",
    businessType: "",
    businessWebsite: "",
    businessStartDate: "",
    docAdd: false,
    businessDiscrition: "",
    ownerShipType: "",
    businessPhoneNumber: "",
    businessEmail: "",
    businessTaxId: "",
    businessStock: "",
    businessAddress: "",
    businessSuite: "",
    businessCity: "",
    businessState: "",
    USID: "",
    businessPostalCode: "",
    businessInterNational: "",
    deleteCoperation: false,
    businessCountry: "US",
    accountNumber: "",
    routingNumber: "",
    accountName: "",
    accountType: "",
    cardPresent: "",
    masterContactName: "",
    foreignEntityNonresident: false,
    fax: "",
    mailingAddress: "",
    transmitCardholderOption: null,
    transmitCardholderName: "",
    seasonalSales: false,
    comments: "",
    locationAddress: "",
    ownerArray: {
      owners: [
        {
          Owner_First_Name: "",
          Owner_Last_Name: "",
          Owner_Last4_SSN: "",
          ownerPhotoIdType: "",
          ownerAddressProofType: "",
          Owner_DOB: "",
          Owner_Ownership_Percentage: "",
          Owner_Title: "",
          Owner_Phone: "",
          Owner_Email: "",
          Owner_Middle_Name: "",
          Owner_Type: "",
          Owner_Postal_Code_Ext: "",
          Owner_Street_Address2: "",
          Owner_Street_Address: "",
          Owner_Suite: "",
          Owner_City: "",
          Owner_State_Code: "",
          Owner_Postal_Code: "",
          Owner_International_Province: "",
          Owner_Country_Code: "US",
          Owner_Timezone_Code: "IST",
          ownerAddressProof: false,
          ownerPhotoId: "",
          deleteOwnerPhotoId: false,
          deleteAddressProofId: false,
          ownerAddressProofDetails: {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: "",
            Doc_Type: ""
          },
          ownerPhotoIdDetails: {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: "",
            Doc_Type: ""
          }
        }
      ]
    },
    contactArray: [
      {
        type: "",
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        phoneExt: "",
        faxNumber: "",
        email: ""
        // zip: "",
      }
    ],
    incorportionDocumentDetails: {
      File_Name: "",
      File_Type: "",
      Upload_Date_Time: ""
    },
    cardNotPresent: "",
    accountNumber: "",
    routingNumber: "",
    accountType: "",
    eCommers: "",
    cardVolumeMonth: "",
    averageTicketSize: "",
    highTicket: "",
    ussidTypeInput: "",
    eCheckVolumeMonth: "",
    incorportionDocument: "",
    incroprationDocDelete: false,
    bankDocumentDelete: false,
    bankDocumentType: "",
    bankDocuments: "",
    bankDocumentsDetails: {
      File_Name: "",
      File_Type: "",
      File_Path: "",
      Upload_Date_Time: ""
    },
    eCheckAverage: "",
    eCheckHigh: "",
    status: "",
    checkedB: "",
    checkedC: "",
    editable: false,
    titleTermsCondition: "",
    nameTermsCondition: ""
  });

  const [validationError, setVlidationError] = React.useState({
    businessName: false,
    legalBusiness: false,
    businessStartDate: false,
    businessStartDateMessage: false,
    businessType: false,
    businessWebsite: false,
    businessDiscrition: false,
    ownerShipType: false,
    businessPhoneNumber: false,
    businessEmail: false,
    businessTaxId: false,
    businessStock: false,
    businessAddress: false,
    businessSuite: false,
    businessCity: false,
    businessState: false,
    USID: false,
    incorportionDocument: false,
    businessPostalCode: false,
    businessInterNational: false,
    businessCountry: false,
    accountNumber: false,
    routingNumber: false,
    accountType: false,
    bankDocuments: false,
    cardPresent: false,
    masterContactName: false,
    foreignEntityNonresident: false,
    fax: false,
    mailingAddress: false,
    transmitCardholderOption: false,
    transmitCardholderName: false,
    seasonalSales: false,
    comments: false,
    locationAddress: false,
    ownerArray: {
      owners: [
        {
          Owner_First_Name: false,
          Owner_Last_Name: false,
          Owner_Last4_SSN: false,
          Owner_DOB: false,
          Owner_Ownership_Percentage: false,
          Owner_Title: false,
          Owner_Phone: false,
          Owner_Email: false,
          Owner_Middle_Name: false,
          Owner_Type: false,
          Owner_Postal_Code_Ext: false,
          Owner_Street_Address: false,
          Owner_Street_Address2: false,
          Owner_Suite: false,
          Owner_City: false,
          Owner_State_Code: false,
          Owner_Postal_Code: false,
          Owner_International_Province: false,
          Owner_Country_Code: false,
          Owner_Timezone_Code: false,
          ownerAddressProof: false,
          ownerPhotoId: false,
          ownerAddressProofDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          },
          ownerPhotoIdDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          }
        }
      ]
    },
    contactArray: [
      {
        type: false,
        title: false,
        firstName: false,
        middleName: false,
        lastName: false,
        phoneNumber: false,
        phoneExt: false,
        faxNumber: false,
        email: false
      }
    ],
    cardNotPresent: false,
    eCommers: false,
    cardVolumeMonth: false,
    averageTicketSize: false,
    highTicket: false,
    ussidTypeInput: false,
    eCheckVolumeMonth: false,
    eCheckAverage: false,
    // eCheckHigh: false,
    status: "",
    checkedB: false,
    checkedC: false,
    Owner_First_Name: false,
    titleTermsCondition: false,
    nameTermsCondition: false
  });

  React.useEffect(() => {
    if (!props.formData.mcclist) {
      props.getMccList();
    }
    if (!props.formData.accountTypeList) {
      props.accountTypeList();
    }
    if (!props.formData.ownerShipTypeList) {
      props.ownerTypeList();
    }
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
    props.getProviderStatus();
  }, []);

  React.useEffect(() => {
    if (props.formData.mcclist.MCC_Data) {
      const sortedData = props.formData.mcclist.MCC_Data.map((option) => ({
        MCC_Code: option.MCC_Code,
        MCC_Name: `[${option.MCC_Code}] - ${option.MCC_Name}`
      }));
      setMccList([...sortedData]);
    }
  }, [props.formData.mcclist]);

  function formatPhoneNumber(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r.length > 6) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 3) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  function convertformat(date) {
    if (!!date) {
      let newDate = date.split("T")[0];

      newDate = newDate.split("-");
      let month = newDate.splice(1, 1);
      newDate.push(month);
      newDate = newDate.reverse("").join("/");
      return newDate;
    }
  }

  function formatSocialSecurity(v) {
    var r = v.replace(/\D/g, "");
    if (r.length > 9) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    } else if (r.length > 4) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  if (!data && props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    const allState = { ...values };
    const statusId = {
      statusCode: preData.Current_Status
    };
    props.getProviderStatus(statusId);
    allState.status = preData.Current_Status;
    allState.editable = preData.Edit_Status;
    allState.businessName = preData.Business_Name;
    allState.businessType = preData.businessType;
    allState.legalBusiness = preData.Legal_Name;
    allState.businessStartDate =
      preData.Business_Start_Date &&
      preData.Business_Start_Date !== "Invalid date"
        ? convertformat(preData.Business_Start_Date)
        : null;
    allState.businessDiscrition = preData.Business_Description;
    allState.ownerShipType = preData.Ownership_Type;
    allState.businessPhoneNumber = preData.Business_Phone
      ? formatPhoneNumber(preData.Business_Phone)
      : "";
    allState.businessEmail = preData.Business_EMail;
    allState.businessWebsite = preData.Website;
    allState.businessTaxId = preData.Fedral_Tax_ID;
    allState.businessStock = preData.Stock_Symbol;
    allState.businessAddress = preData.Business_Address;
    allState.businessSuite = preData.Suite;
    allState.businessCity = preData.City;
    allState.businessState = preData.State_Code;
    allState.USID = preData.MCC;
    allState.businessPostalCode = preData.Postal_Code;
    allState.businessInterNational = preData.International_Province;
    allState.businessCountry = preData.Country_Code
      ? preData.Country_Code
      : "US";
    allState.accountNumber = preData.Sattlement_Account?.Account_Number;
    allState.routingNumber = preData.Sattlement_Account?.Routing_Number;
    allState.accountName = preData.Sattlement_Account?.Name_On_Account;
    allState.accountType = preData.Sattlement_Account?.Account_Type;
    allState.cardPresent = preData.Processing_Method?.Card_Swiped;
    allState.cardNotPresent = preData.Processing_Method?.Card_Not_Present;
    allState.eCommers = preData.Processing_Method?.ECommerce;
    allState.cardVolumeMonth = preData.Processing_Method?.Monthly_Card_Volume;
    allState.averageTicketSize = preData.Processing_Method?.Average_Ticket_Size;
    allState.highTicket = preData.Processing_Method?.High_Ticket;
    allState.ussidTypeInput = "";
    allState.eCheckVolumeMonth = preData.Processing_Method?.Monthly_ACH_Volume;
    allState.eCheckAverage = preData.Processing_Method?.Average_ACH;
    allState.eCheckHigh = preData.Processing_Method?.High_ACH;
    allState.masterContactName = preData.masterContactName;
    allState.foreignEntityNonresident = preData.foreignEntityNonresident;
    allState.fax = preData.fax;
    allState.mailingAddress = preData.mailingAddress;
    allState.transmitCardholderOption = preData.transmitCardholderOption;
    allState.transmitCardholderName = preData.transmitCardholderName;
    allState.seasonalSales = preData.seasonalSales;
    allState.comments = preData.comments;
    allState.locationAddress = preData.locationAddress;
    allState.titleTermsCondition = preData.titleTermsCondition;
    allState.nameTermsCondition = preData.nameTermsCondition;
    allState.incorportionDocumentDetails =
      preData.incorportionDocument === ""
        ? {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: ""
          }
        : preData.incorportionDocument;
    allState.bankDocumentsDetails =
      preData.bankDocument === ""
        ? {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: ""
          }
        : preData.bankDocument;
    allState.bankDocuments = preData.bankDocument
      ? preData.bankDocument.File_Name
      : "";
    allState.docAdd = preData.incorportionDocument !== "";
    allState.bankDocumentType =
      preData.bankDocument === "" ? "" : preData.bankDocumentType;
    allState.incorportionDocument = preData.incorportionDocument
      ? preData.incorportionDocument.File_Name
      : "";
    allState.checkedC = preData.termAndConditions.docStatus;
    const ownerData = preData.Owner_Information;
    const newContact = [
      {
        type: "",
        title: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        phoneExt: "",
        faxNumber: "",
        email: ""
      }
    ];
    const contactData = isEmpty(preData.contactArray)
      ? newContact
      : preData.contactArray;
    const preDataArray = [];
    const preDataArrayContact = [];
    const validationAddToOwner = { ...validationError };
    contactData.forEach((key, i) => {
      if (i > 0) {
        validationAddToOwner.contactArray.push({
          type: false,
          title: false,
          firstName: false,
          middleName: false,
          lastName: false,
          phoneNumber: false,
          phoneExt: false,
          faxNumber: false,
          email: false
        });
      }
      preDataArrayContact.push({ ...key });
    });
    ownerData.forEach((key, i) => {
      if (i > 0) {
        validationAddToOwner.ownerArray.owners.push({
          Owner_First_Name: false,
          Owner_Last_Name: false,
          Owner_Last4_SSN: false,
          Owner_DOB: false,
          Owner_Ownership_Percentage: false,
          Owner_Title: false,
          Owner_Phone: false,
          Owner_Email: false,
          Owner_Middle_Name: false,
          Owner_Type: false,
          Owner_Postal_Code_Ext: false,
          Owner_Street_Address2: false,
          Owner_Street_Address: false,
          Owner_Suite: false,
          Owner_City: false,
          Owner_State_Code: false,
          Owner_Postal_Code: false,
          Owner_International_Province: false,
          Owner_Country_Code: false,
          Owner_Timezone_Code: false,
          ownerAddressProof: false,
          ownerPhotoId: false,
          ownerAddressProofDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          },
          ownerPhotoIdDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          }
        });
      }
      const obj = { ...key };
      obj.ownerAddressProofDetails =
        key.ownerAddressProof === ""
          ? {
              Doc_Type: "",
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          : {
              Doc_Type: key.ownerAddressProof.Doc_Type,
              File_Type: key.ownerAddressProof.File_Type,
              File_Name: key.ownerAddressProof.File_Name,
              File_Path: key.ownerAddressProof.File_Path,
              Upload_Date_Time: key.ownerAddressProof.Upload_Date_Time
            };
      obj.ownerAddressProofType =
        key.ownerAddressProof === "" ? "" : key.ownerAddressProof.Doc_Type;
      obj.ownerPhotoIdType =
        key.ownerPhotoId === "" ? "" : key.ownerPhotoId.Doc_Type;
      obj.ownerPhotoIdDetails =
        key.ownerPhotoId === ""
          ? {
              Doc_Type: "",
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          : {
              Doc_Type: key.ownerPhotoId.Doc_Type,
              File_Type: key.ownerPhotoId.File_Type,
              File_Name: key.ownerPhotoId.File_Name,
              File_Path: key.ownerPhotoId.File_Path,
              Upload_Date_Time: key.ownerPhotoId.Upload_Date_Time
            };
      obj.ownerAddressProof = key.ownerAddressProof.File_Path;
      obj.ownerPhotoId = key.ownerPhotoId.File_Path;
      obj.Owner_Country_Code = key.Owner_Country_Code
        ? key.Owner_Country_Code
        : "US";
      obj.Owner_DOB = convertformat(key.Owner_DOB);
      obj.Owner_Phone = key.Owner_Phone
        ? formatPhoneNumber(key.Owner_Phone)
        : "";
      preDataArray.push(obj);
    });
    allState.ownerArray.owners = preDataArray;
    allState.contactArray = [...preDataArrayContact];
    handleInput(allState);
    setData(true);
  }

  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;

  const exportedBy =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;

  const ProviderStatus =
    props.formData.formMode === "Create"
      ? "New Entry"
      : props.formData.statusList &&
        props.formData.statusList.Provider_Status_Data.filter(
          (key) =>
            key.Status_Code ===
            (props.formData.formMode === "Create"
              ? 1
              : props.formData.providerDetailsById &&
                props.formData.providerDetailsById?.Providers_Data[0]
                  ?.Current_Status)
        ).length > 0 &&
        props.formData.statusList.Provider_Status_Data.filter(
          (key) =>
            key.Status_Code ===
            (props.formData.formMode === "Create"
              ? 1
              : props.formData.providerDetailsById &&
                props.formData.providerDetailsById?.Providers_Data[0]
                  ?.Current_Status)
        )[0].Provider_Status;

  const ownerOnchange = (index, data, type, e) => {};
  const handleDeleteUpload = (type, index, docType) => {};
  const handleUploadOnChange = (files, type, ownerType, index) => {};
  const contactOnchange = (index, data, type) => {};
  const addContact = () => {};
  const deleteContact = (index) => {};
  const addOwner = () => {};

  return (
    <div style={{ paddingBottom: "40px", display: "none" }}>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center"
            // padding: "4%",
          }}
        >
          <FormControl style={{ marginTop: "20px" }}>
            <div ref={componentRef}>
              <Grid
                style={{
                  display: "flex",
                  margin: "2rem 0.5px",
                  justifyContent: "space-between"
                }}
              >
                <img
                  style={{ width: "25%", height: "2rem", paddingLeft: "15px" }}
                  src={image}
                  alt="appName"
                />
                <Box style={{ textAlign: "end" }}>
                  <Typography style={{ fontSize: "12px" }}>
                    Merchant Previous Version
                  </Typography>
                  <Typography
                    style={{ fontSize: "12px" }}
                  >{`Updated on: ${moment(newCurrentDate)
                    .tz("UTC")
                    .format("MM/DD/YYYY hh:mm:ss A")} UTC`}</Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    {`Updated by: ${exportedByFname} ${exportedByLname}, <${exportedBy}>`}
                  </Typography>
                </Box>
              </Grid>

              <Box className={classes.marginBox}>
                <Grid container className={classes.margin}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <BusinessCenterIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title="As it appear on your income tax return"
                              placement="top"
                            >
                              <HelpOutlineOutlinedIcon
                                classes={{
                                  root: "HelpIcon",
                                  icon: "withHelpOutline"
                                }}
                                color="action"
                                fontSize="small"
                              />
                            </Tooltip>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        },
                        required: true
                      }}
                      label="Legal Name of Business"
                      value={values.legalBusiness}
                      name="legalBusiness"
                      id="legalBusiness"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      // style={{ marginTop: "0px", marginBottom: "0px" }}
                      fontSize="small"
                    />
                    <TextField
                      select
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Type of Business"
                      value={values.businessType}
                      name="businessType"
                      id="businessType"
                    >
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Convenience Store"
                      >
                        Convenience Store
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Gas Station"
                      >
                        Gas Station
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="General Store"
                      >
                        General Store
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Liquor Store"
                      >
                        Liquor Store
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Tobacco Store"
                      >
                        Tobacco Store
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Truck Stop"
                      >
                        Truck Stop
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      // style={{ marginTop: "0px", marginBottom: "0px" }}
                      fontSize="small"
                    />
                    <TextField
                      select
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Ownership Type"
                      value={values.ownerShipType}
                      name="ownerShipType"
                      id="ownerShipType"
                    >
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Government"
                      >
                        Government
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="FinancialInstitution"
                      >
                        Financial Institution
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="LLC"
                      >
                        LLC
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="NonProfit"
                      >
                        Non Profit
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Partnership"
                      >
                        Partnership
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="PrivateCorporation"
                      >
                        Private Corporation
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="PublicCorporation"
                      >
                        Public Corporation
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="SECRegulatedCorporation"
                      >
                        SEC Regulated Corporation
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="SoleProprietorship"
                      >
                        Sole Proprietorship
                      </MenuItem>
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="Trust"
                      >
                        Trust
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <DescriptionIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      type="text"
                      label="Federal Tax ID"
                      value={values.businessTaxId}
                      name="businessTaxId"
                      id="businessTaxId"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <EventNoteIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <FormControl
                      className={classes.root}
                      style={{ fontSize: "unset" }}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          value={
                            values.businessStartDate
                              ? values.businessStartDate
                              : null
                          }
                          label="Business Open Date"
                          id="businessStartDate"
                          max={new Date()}
                          format="MM/dd/yyyy"
                          name="businessStartDate"
                          style={{
                            "&.focused": {
                              color: theme.palette.primary.main,
                              fontSize: "14px",
                              fontStyle: "Helvetica, Arial,sans-serif"
                            }
                          }}
                          required
                          placeholder="MM/DD/YYYY"
                          maxDate={new Date()}
                          maxDateMessage="Business Start Date should not be a Future date."
                          keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                          KeyboardButtonProps={{
                            size: "small"
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            },
                            required: true,
                            endAdornment: (
                              <Tooltip title="cds_DateHelp" placement="top">
                                <IconButton edge="end" size="small">
                                  <HelpOutlineOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid style={{ display: "flex" }} item lg={4} sm={6} xs={12}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <FormGroup
                        style={{ marginLeft: "7px" }}
                        aria-label="position"
                        row
                      >
                        <FormControlLabel
                          value="bottom"
                          style={{
                            marginTop: "8px",
                            color: "#757575",
                            fontSize: "14px !important"
                          }}
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.foreignEntityNonresident === true}
                              value={values.foreignEntityNonresident}
                              disabled
                            />
                          }
                          label="I certify that I am a foreign entity/ nonresident alien."
                        />
                      </FormGroup>
                      <FormHelperText style={{ marginTop: "-10px" }}>
                        (If checked, please attach IRS Form W-8.)
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        }
                      }}
                      label="DBA Name"
                      value={
                        values.businessName === "" &&
                        values.legalBusiness === ""
                          ? values.legalBusiness
                          : dbaValue && values.legalBusiness !== ""
                          ? values.businessName
                          : values.businessName !== ""
                          ? values.businessName
                          : values.legalBusiness
                      }
                      name="businessName"
                      id="businessName"
                    />
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    <BusinessCenterIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title="As listed in the Attached Addendums. A signed Addendum must be completed and approved for each location."
                              placement="top"
                            >
                              <HelpOutlineOutlinedIcon
                                classes={{
                                  root: "HelpIcon",
                                  icon: "withHelpOutline"
                                }}
                                color="action"
                                fontSize="small"
                              />
                            </Tooltip>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Location Addresses"
                      className={classes.root}
                      value={values.businessAddress}
                      name="businessAddress"
                      id="businessAddress"
                    />
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    <ContactMailIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Mailing Address"
                      value={values.mailingAddress}
                      name="mailingAddress"
                      id="mailingAddress"
                    />
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    <AttachMoneyRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Average Monthly Volume"
                      value={values.cardVolumeMonth}
                      name="cardVolumeMonth"
                      id="cardVolumeMonth"
                      type="text"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <AttachMoneyRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Average Ticket Amount"
                      value={values.averageTicketSize}
                      name="averageTicketSize"
                      id="averageTicketSize"
                      type="text"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <AttachMoneyRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="High Ticket Amount"
                      value={values.highTicket}
                      name="highTicket"
                      id="highTicket"
                      type="text"
                    />
                  </Grid>

                  <Grid item lg={4} sm={6} xs={12}>
                    <PermContactCalendarIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Master Contact Name"
                      value={values.masterContactName}
                      name="masterContactName"
                      id="masterContactName"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <StayCurrentPortraitIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        inputProps: { min: 0, max: 10 },
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Phone"
                      value={formatPhoneNumber(values.businessPhoneNumber)}
                      name="businessPhoneNumber"
                      id="businessPhoneNumber"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <PrintIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      className={classes.root}
                      InputLabelProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Fax"
                      value={values.fax}
                      name="fax"
                      id="fax"
                      // type="number"
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <StayCurrentPortraitIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Email Address"
                      value={values.businessEmail}
                      name="businessEmail"
                      id="businessEmail"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ paddingBottom: "10px", paddingTop: "10px" }}
                  className={classes.margin}
                >
                  <Grid item lg={6} sm={6} xs={12}>
                    <DataUsageIcon
                      className={classes.inputBox}
                      style={{ marginTop: "20px", marginBottom: "0px" }}
                      fontSize="small"
                    />
                    <FormControl
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      component="fieldset"
                    >
                      <FormLabel
                        style={{ marginTop: "23px", fontSize: "14px" }}
                        component="span"
                      >
                        Do you use any third party to store, process or transmit
                        cardholder data?
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.transmitCardholderOption === true}
                              name="yes"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                values.transmitCardholderOption === false
                              }
                              name="no"
                            />
                          }
                          label="No"
                        />
                      </FormGroup>
                      <FormHelperText>
                        {validationError.transmitCardholderOption}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {values.transmitCardholderOption && (
                    <Grid item lg={6} sm={6} xs={12}>
                      <PermIdentityIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <TextField
                        required
                        style={{
                          width: windowWidthLG ? "87%" : "80%",
                          "&.focused": {
                            color: theme.palette.primary.main,
                            fontSize: "14px",
                            fontStyle: "Helvetica, Arial,sans-serif"
                          }
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                title="Name/Address of the third party that you use to store, process or transmit cardholder data."
                                placement="top"
                              >
                                <HelpOutlineOutlinedIcon
                                  classes={{
                                    root: "HelpIcon",
                                    icon: "withHelpOutline"
                                  }}
                                  color="action"
                                  fontSize="small"
                                />
                              </Tooltip>
                            </InputAdornment>
                          ),
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          }
                        }}
                        label="If Yes, give name/address"
                        value={values.transmitCardholderName}
                        name="transmitCardholderName"
                        id="transmitCardholderName"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container className={classes.margin}>
                  <Grid item lg={3} sm={6} xs={12}>
                    <PublicIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      required
                      style={{
                        width: windowWidthLG ? "74%" : "80%"
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Country"
                      value={values.businessCountry}
                      name="businessCountry"
                      id="businessCountry"
                      select
                    >
                      {props.formData.countryTypeList &&
                        props.formData.countryTypeList.Country_Data.map(
                          (key) => (
                            <MenuItem
                              className={classes.dialogBoxDropDown}
                              value={key.Country_Code}
                            >
                              {key.Country_Name}
                            </MenuItem>
                          )
                        )}
                    </TextField>
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <NearMeRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    {values.businessCountry === "US" ? (
                      <TextField
                        required
                        style={{
                          width: windowWidthLG ? "74%" : "80%"
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          },
                          required: true
                        }}
                        label="State"
                        value={values.businessState}
                        name="businessState"
                        id="businessState"
                      >
                        {stateInput &&
                          locationCity
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((key) => (
                              <MenuItem
                                className={classes.dialogBoxDropDown}
                                value={key.value}
                              >
                                {key.name}
                              </MenuItem>
                            ))}
                      </TextField>
                    ) : (
                      <TextField
                        required
                        style={{
                          width: windowWidthLG ? "74%" : "80%"
                        }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          },
                          required: true
                        }}
                        label="State"
                        value={values.businessState}
                        name="businessState"
                        id="businessState"
                      />
                    )}
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <LocationCityRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      style={{
                        width: windowWidthLG ? "74%" : "80%"
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="City"
                      value={values.businessCity}
                      name="businessCity"
                      id="businessCity"
                    />
                  </Grid>
                  <Grid item lg={3} sm={6} xs={12}>
                    <RoomIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      required
                      style={{
                        width: windowWidthLG ? "74%" : "80%"
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Postal Code"
                      value={values.businessPostalCode}
                      name="businessPostalCode"
                      id="businessPostalCode"
                      type="text"
                    />
                  </Grid>

                  {props.formData.mcclist &&
                    props.formData.mcclist.MCC_Data && (
                      <Grid
                        item
                        style={{ display: "flex" }}
                        lg={4}
                        sm={6}
                        xs={12}
                      >
                        <AccountBalanceIcon
                          className={classes.inputBox}
                          fontSize="small"
                        />

                        <Autocomplete
                          name="USID"
                          id="USID"
                          style={{
                            fontSize: "14px",
                            width: windowWidthLG ? "90%" : "80%",
                            "&.focused": {
                              color: theme.palette.primary.main,
                              fontSize: "14px",
                              fontStyle: "Helvetica, Arial,sans-serif"
                            }
                          }}
                          label="Type(s) of goods or services sold"
                          value={
                            (mccList || []).find(
                              (option) =>
                                option.MCC_Code === parseInt(values.USID)
                            ) || {}
                          }
                          autoHighlight
                          fullWidth
                          options={mccList || []}
                          getOptionLabel={(option) => option.MCC_Name || ""}
                          renderInput={(params) => (
                            <TextField
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused"
                                }
                              }}
                              required
                              InputProps={{
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused"
                                },
                                required: true
                              }}
                              {...params}
                              label="MCC"
                            />
                          )}
                          renderOption={(option, { inputValue }) => {
                            const matches = match(option.MCC_Name, inputValue, {
                              insideWords: true
                            });
                            const parts = parse(option.MCC_Name, matches);

                            return (
                              <div>
                                {parts.map((part, index) => (
                                  <span
                                    style={{
                                      background: part.highlight
                                        ? "#f3ff0070"
                                        : "",
                                      fontWeight: part.highlight ? 400 : "",
                                      fontSize: "14px"
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </div>
                            );
                          }}
                        />
                      </Grid>
                    )}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xl: "center",
                        lg: "center",
                        md: "flex-start"
                      },
                      alignItems: "center",
                      alignContent: "center"
                    }}
                    lg={4}
                    sm={6}
                    xs={12}
                  >
                    <TrendingUpIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <FormControl
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      component="fieldset"
                    >
                      <FormGroup
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          alignItems: "center",
                          flexWrap: "nowrap"
                        }}
                        row
                      >
                        <FormLabel
                          style={{ marginTop: "10px", fontSize: "14px" }}
                          component="span"
                        >
                          Seasonal Sales
                        </FormLabel>
                        <FormControlLabel
                          style={{ marginTop: "10px", paddingLeft: "8px" }}
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.seasonalSales === true}
                              name="yes"
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          style={{ marginTop: "10px", paddingLeft: "8px" }}
                          control={
                            <Checkbox
                              color="primary"
                              checked={values.seasonalSales === false}
                              name="no"
                            />
                          }
                          label="No"
                        />
                      </FormGroup>
                      <FormHelperText>
                        {validationError.seasonalSales}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <WebIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Website Url"
                      value={values.businessWebsite}
                      name="businessWebsite"
                      id="businessWebsite"
                    />
                  </Grid>

                  <Grid item lg={12} sm={12} xs={12}>
                    <CommentIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      multiline
                      style={{
                        marginTop: "20px",
                        width: windowWidthLG ? "94%" : "80%",
                        "&.focused": {
                          color: theme.palette.primary.main,
                          fontSize: "14px",
                          fontStyle: "Helvetica, Arial,sans-serif"
                        }
                      }}
                      rows={2}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Comments"
                      value={values.comments}
                      name="comments"
                      id="comments"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                style={{
                  alignItems: "center",
                  marginTop: "30px"
                }}
                className={classes.tittleColor}
                height={40}
              >
                <Typography className={classes.tittleTextColor}>
                  Owner Information
                </Typography>
              </Box>
              {values.ownerArray.owners &&
                values.ownerArray.owners.map((key, i) => (
                  <OwnerInformation
                    handleOwnerChange={(data, type, e) =>
                      ownerOnchange(i, data, type, e)
                    }
                    handleDeleteUpload={(docType) =>
                      handleDeleteUpload("owner", i, docType)
                    }
                    handleUploadOnChange={(data, type) =>
                      handleUploadOnChange(data, "owner", type, i)
                    }
                    index={i}
                    validationError={validationError.ownerArray.owners[i]}
                    data={key}
                    ProviderStatus={ProviderStatus}
                    kycCheckListFlag={kycCheckListFlag}
                    editPermissionFlag={editPermissionFlag}
                    createPermissionFlag={createPermissionFlag}
                    add={() => addOwner()}
                    remove={() => setOwnerDeleteModal(i)}
                    disabled={false}
                  />
                ))}
              <Box
                style={{
                  alignItems: "center",
                  marginTop: "30px",
                  marginBottom: "30px"
                }}
                className={classes.tittleColor}
                height={40}
              >
                <Typography className={classes.tittleTextColor}>
                  Contact Information
                </Typography>
              </Box>
              {values.contactArray &&
                values.contactArray.map((key, i) => (
                  <ContactInformation
                    handleContactChange={(data, type) =>
                      contactOnchange(i, data, type)
                    }
                    index={i}
                    validationError={validationError.contactArray[i]}
                    data={key}
                    ProviderStatus={ProviderStatus}
                    kycCheckListFlag={kycCheckListFlag}
                    editPermissionFlag={editPermissionFlag}
                    createPermissionFlag={createPermissionFlag}
                    add={() => addContact()}
                    remove={() => deleteContact(i)}
                    disabled={false}
                  />
                ))}
              <Box
                style={{
                  alignItems: "center",
                  marginTop: "30px",
                  marginBottom: "30px"
                }}
                className={classes.tittleColor}
                height={40}
              >
                <Typography className={classes.tittleTextColor}>
                  Settlement Account Information
                </Typography>
              </Box>
              <Box className={classes.marginBox}>
                <Grid container className={classes.margin}>
                  <Grid item lg={3} sm={3} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      className={classes.quaterInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Name on Bank Account"
                      value={values.legalBusiness}
                      name="accountName"
                      id="accountName"
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.quaterInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Routing Number"
                      value={values.routingNumber}
                      name="routingNumber"
                      id="routingNumber"
                      type="text"
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.quaterInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      label="Account Number"
                      value={values.accountNumber}
                      name="accountNumber"
                      id="accountNumber"
                      type="text"
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xs={12}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.quaterInput}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Account Type"
                      value={values.accountType}
                      name="accountType"
                      id="accountType"
                      select
                    >
                      {props.formData.accountTypeList &&
                        props.formData.accountTypeList.Account_Type_Data.sort(
                          (a, b) =>
                            a.Account_Type_Name > b.Account_Type_Name ? 1 : -1
                        ).map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.Account_Type_Name}
                          >
                            {key.Account_Type_Name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "5%" }}
                        align="center"
                      >
                        Document Type
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "5%" }}
                        align="center"
                      >
                        Document Type Name
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "5%" }}
                        align="center"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "10%" }}
                        align="center"
                      >
                        File Type
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "10%" }}
                        align="center"
                      >
                        Upload Time (UTC)
                      </TableCell>
                      {(ProviderStatus === "New Entry" ||
                        ProviderStatus === "Sent to Merchant" ||
                        (kycCheckListFlag &&
                          editPermissionFlag &&
                          ProviderStatus !== "Live")) && (
                        <TableCell
                          className={classes.cellTextHeaderOffer}
                          style={{ width: "5%" }}
                          align="center"
                        >
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">Bank Document</TableCell>
                      <TableCell align="center">
                        {values.bankDocumentType}
                      </TableCell>
                      <TableCell align="center">
                        <a
                          href={values.bankDocumentsDetails.File_Path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {values.bankDocumentsDetails.File_Name}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        {values.bankDocumentsDetails.File_Type}
                      </TableCell>
                      <TableCell align="center">
                        {moment(values.bankDocumentsDetails.Upload_Date_Time)
                          .tz("UTC")
                          .format("MM/DD/YYYY hh:mm:ss A")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
              <Box style={{ marginTop: "20px" }} height={20}>
                <Typography style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Terms and Conditions:
                </Typography>
              </Box>
              <Grid spacing={2} container className={classes.margin}>
                <Grid
                  style={{ marginTop: "10px" }}
                  item
                  container
                  xs={11}
                  sm={11}
                  md={11}
                  lg={6}
                  xl={6}
                >
                  <Grid display="flex" item xs={12} sm={6}>
                    <MenuBookRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Title"
                      value={values.titleTermsCondition}
                      name="titleTermsCondition"
                      id="titleTermsCondition"
                    />
                  </Grid>
                  <Grid display="flex" item xs={12} sm={6}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <TextField
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Name"
                      value={values.nameTermsCondition}
                      name="nameTermsCondition"
                      id="nameTermsCondition"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.margin}
                style={{ padding: "0 1rem" }}
              >
                <Typography
                  style={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    width: "100%"
                  }}
                >
                  <Checkbox
                    className={classes.checkBox}
                    checked={values.checkedC == "Signed"}
                    name="checkedC"
                    size="small"
                    style={{ marginRight: "5px" }}
                    color="primary"
                  />
                  By clicking on{" "}
                  <Button variant="contained" className={classes.buttonStyle}>
                    eSign Now
                  </Button>{" "}
                  I am agreeing to T/C and submitting my application for
                  underwriting.
                </Typography>
              </Grid>
            </div>
          </FormControl>
        </div>
      </Card>
    </div>
  );
}

MerchantVersion.propTypes = {
  provideRegistration: PropTypes.func,
  formData: PropTypes.object,
  getMccList: PropTypes.func,
  getCountryTypeList: PropTypes.func,
  accountTypeList: PropTypes.func,
  ownerTypeList: PropTypes.func,
  updateRegistration: PropTypes.func,
  getProviderStatus: PropTypes.func,
  mainData: PropTypes.object,
  history: PropTypes.object,
  changeSingleStatus: PropTypes.func,
  getProviderParameterCall: PropTypes.func,
  deleteIncroptration: PropTypes.func,
  deleteAddressProof: PropTypes.func,
  deleteIdentityProof: PropTypes.func,
  getQuestionData: PropTypes.func,
  saveQuestionCall: PropTypes.func,
  getKycQuestionData: PropTypes.func,
  statusPreameter: PropTypes.func,
  statusPreameterKyc: PropTypes.func,
  statusApproveMpa: PropTypes.func,
  statusApproveKyc: PropTypes.func,
  setResetWuestion: PropTypes.func,
  mpaChecklistComment: PropTypes.func,
  kycChecklistAttechment: PropTypes.func,
  mpaChecklistAttechment: PropTypes.func,
  changeProviderFormMode: PropTypes.func,
  pricingTempleteCall: PropTypes.func,
  setPricingTempleteEmpty: PropTypes.func,
  getKycResponseFromAlloy: PropTypes.func,
  postKycSubQuestions: PropTypes.func,
  getKycResponseUserList: PropTypes.func,
  saveQuestionCallAction: PropTypes.func,
  postKycQuestionsCall: PropTypes.func,
  getProviderDetailsById: PropTypes.func,
  clearRegData: PropTypes.func,
  deleteBankDocumentApiCall: PropTypes.func,
  deleteOwnerApiCall: PropTypes.func,
  getSignNowCall: PropTypes.func,
  roles: PropTypes.object,
  getDocStatus: PropTypes.func,
  app: PropTypes.object,
  text: PropTypes.any,
  componentRef: PropTypes.any
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app,
  roles: app.roles,
  app: app.app
});

export default connect(mapStateToProps, {
  provideRegistration,
  getKycResponseFromAlloy,
  clearRegData,
  getMccList,
  getKycQuestionData,
  getCountryTypeList,
  accountTypeList,
  ownerTypeList,
  updateRegistration,
  getProviderStatus,
  changeSingleStatus,
  getProviderParameterCall,
  deleteIncroptration,
  deleteAddressProof,
  deleteIdentityProof,
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  saveQuestionCall,
  setResetWuestion,
  postKycSubQuestions,
  mpaChecklistComment,
  kycChecklistAttechment,
  changeProviderFormMode,
  pricingTempleteCall,
  setPricingTempleteEmpty,
  mpaChecklistAttechment,
  getQuestionData,
  deleteOwnerApiCall,
  getKycResponseUserList,
  postKycQuestionsCall,
  saveQuestionCallAction,
  getProviderDetailsById,
  deleteBankDocumentApiCall,
  getSignNowCall,
  getDocStatus
})(MerchantVersion);
