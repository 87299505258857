import React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import { ThemeProvider, createTheme, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { commonStyle } from "../../assets/style/js/commonStyle";
import TodayIcon from "@material-ui/icons/Today";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import tableStyle from "../../../../assets/jss/material-dashboard-react/components/reportTables";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles(tableStyle);

const UpdateDBA = ({
  open,
  onClose,
  updateDbaNameCall,
  selectedMerchant,
  setUpdateDbaName,
}) => {
  const oldTheme = useTheme();
  const cmClasses = commonStyle();
  const classes = useStyles();
  const theme = createTheme({
    ...oldTheme,
    overrides: {
      MuiAutocomplete: {
        inputRoot: {
          padding: "0px",
        },
        input: {
          padding: "0px",
        },
      },
      MuiOutlinedInput: {
        root: {
          background: "#F9FAFF 0% 0% no-repeat",
          borderRadius: "6px",
          height: "44px",
        },
      },
    },
  });

  const onChange = (value, type) => {
    const allState = { ...selectedMerchant };
    allState[type] = value;
    setUpdateDbaName(allState);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="sasdasd"
      aria-describedby="simpleasdasdasdan"
    >
      <Zoom in={open} style={{ transitionDelay: open ? "250ms" : "0ms" }}>
        <Fade in={open}>
          <Paper
            className={classes.dialogBoxBorder}
            style={{
              width: "510px",
            }}
          >
            <div className={classes.statusModalHeader}>
              <div className={classes.statusHeadingText}>Update DBA</div>
              <ClearIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => onClose(false)}
              />
            </div>
            <Formik
              enableReinitialize
              // eslint-disable-next-line react/jsx-no-bind
              onSubmit={updateDbaNameCall}
              initialValues={selectedMerchant}
            >
              {({ values, setFieldValue }) => (
                <Form
                  style={{
                    padding: theme.spacing(2, 2, 3),
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    className={classes.margin}
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h1" style={{ padding: "10px" }}>
                        MID:
                      </Typography>
                      {"    "}
                      <Typography
                        component="p"
                        variant="body1"
                        style={{ padding: "10px",paddingLeft:"60px" }}
                      >
                        {selectedMerchant.MID}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h1" style={{ padding: "10px" }}>
                        Processor:
                      </Typography>
                      {"    "}
                      <Typography
                        component="p"
                        variant="body1"
                        style={{ padding: "10px",paddingLeft:"13px" }}
                      >
                        {selectedMerchant.ProcessorName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h1" style={{ padding: "10px" }}>
                        Start From:
                      </Typography>
                      {"    "}
                      <Typography
                        component="p"
                        variant="body1"
                        style={{ padding: "10px" }}
                      >
                        {selectedMerchant.Start_Date}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h1" style={{ padding: "10px" }}>
                        Merchant:
                      </Typography>
                      {"    "}
                      <Typography
                        component="p"
                        variant="body1"
                        style={{ paddingLeft: "19px", width: "298px" }}
                      >
                        <TextField
                          key="DBAName"
                          className={cmClasses.rootDialog}
                          InputLabelProps={{
                            classes: {
                              root: cmClasses.activeInputLableColor,
                              focused: "focused",
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: cmClasses.activeInputColor,
                              input: cmClasses.resize,
                              focused: "focused",
                            },
                          }}
                          // label="DBA Name"
                          value={values.DBAName}
                          onChange={(e) => onChange(e.target.value, "DBAName")}
                          name="DBAName"
                          style={{ width: "100%" }}
                          fullWidth
                          id="DBAName"
                          autoFocus={selectedMerchant.DBAName != "" && true}
                        />
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    spacing={2}
                    style={{
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                    container
                  >
                    <Grid item>
                      <Button
                        color="primary"
                        type="submit"
                        style={{
                          textTransform: "none",
                        }}
                        variant="contained"
                        contained
                        className={classes.actionButtonStyle}
                        onClick={() => onClose(false)}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Fade>
      </Zoom>
    </Modal>
  );
};

UpdateDBA.propTypes = {
  agentsList: PropTypes.array,
  profilesList: PropTypes.array,
  processorList: PropTypes.array,
  selectedList: PropTypes.array,
  selectedMerchant: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  updateDbaNameCall: PropTypes.func,
  getAgentProfileRepCodeList: PropTypes.func,
};

UpdateDBA.defaultProps = {
  profilesList: [],
  agentsList: [],
  processorList: [],
  open: true,
  onClose: () => {},
  updateDbaNameCall: () => {},
  getAgentProfileRepCodeList: PropTypes.func,
};
export default UpdateDBA;
