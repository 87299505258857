import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import {
  actionPermission,
  exportToXLS,
  permissionCheck,
} from "../../Utils/commonUtils";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import EditIcon from "@material-ui/icons/Edit";
import CoustomButton from "components/CustomButtons/Button";
import Chip from "@material-ui/core/Chip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import ChipInput from "material-ui-chip-input";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import AddEditGroup from "../RoleManager/AddEditGroup";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Groups";
import { exportToCSV } from "../../Utils/commonUtils";
import {
  groupsListApi,
  groupsTypeListApi,
  groupsListSearchApi,
} from "../../actions/TableData";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { getUserDetailsById } from "../../Redux/Actions/UserRegistration";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { exportToPDF } from "modules/reports/utils/common";
import { exportNumberToDashFormat } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import { ReactComponent as AddIconSvg } from "../../assets/svgIcons/AddIconSvg.svg";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const useStyles = makeStyles(tableStyle);

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "groupName",
    className: "groupName",
    numeric: false,
    disablePadding: true,
    label: "Group Name",
  },
  {
    id: "status",
    className: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "groupType",
    className: "groupType",
    numeric: false,
    disablePadding: true,
    label: "Group Type",
  },
  {
    id: "numberOfUsers",
    className: "numberOfUsers",
    numeric: false,
    disablePadding: true,
    label: "Number Of Users",
  },
  {
    id: "numberOfMid",
    className: "numberOfMid",
    numeric: false,
    disablePadding: true,
    label: "Number Of MID's",
  },
  {
    id: "createdAt",
    className: "createdAt",
    numeric: true,
    disablePadding: true,
    label: "Created Date",
  },
  {
    id: "updatedAt",
    className: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Updated Date",
  },
  // {
  //   id: "actions",
  //   className: "actions",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Actions",
  // },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function GroupDataList(props) {
  const classes = useStyles();

  const [order, setOrder] = React.useState("asc");
  const [initialCall, setInitialCall] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [searchValues, setSearchValues] = React.useState({
    groupName: "",
    groupType: "",
    apiType: "search",
    groupStatus: "Active",
  });
  const [groupTypeList, setGroupTypeList] = React.useState([]);
  const [addEditForm, setAddEditForm] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [groupList, setGroupList] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [rolePermission, setRolePermission] = React.useState(
    props.tableData.roleDetailsById
  );
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleClosed = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = groupList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const closeAddEditForm = () => {
    setAddEditForm(false);
    setDetails({});
    if (
      searchValues.groupName === "" &&
      searchValues.groupStatus === "" &&
      searchValues.groupType === ""
    ) {
      getGroupListData();
    } else {
      groupSearchCall(searchValues);
    }
    callGroupTypeListApi();
  };

  const onClickEdit = (row) => {
    setDetails(row);
    setAddEditForm(true);
  };

  const handleEdit = (type, row) => {
    if (type === "edit") {
      onClickEdit(row);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callGroupTypeListApi = () => {
    props.groupsTypeListApi((data) => {
      setGroupTypeList(data.State_Data);
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const changeChipsValue = (values) => {
    const temp = [];
    if (values.groupName) {
      temp.push(`Group Name: ${values.groupName}`);
    }

    if (values.groupStatus) {
      temp.push(`Status: ${values.groupStatus}`);
    }
    if (values.groupType) {
      temp.push(
        `Group Type: ${
          values.groupType == 1
            ? "Internal"
            : values.groupType == 2
            ? "External"
            : "All"
        }`
      );
    } else {
      temp.push(`Group Type: ${values.groupType}`);
    }
    setChipValue(temp);
  };

  const groupSearchCall = (values) => {
    props.groupsListSearchApi(values, (data) => {
      changeChipsValue(values);
      setGroupList(data.data);
    });
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      const newSearchValue = { ...searchValues };
      if (chipName === "Group Name") {
        newSearchValue.groupName = "";
      }
      if (chipName === "Group Type") {
        newSearchValue.groupType = "";
      }
      if (chipName === "Status") {
        newSearchValue.groupStatus = "";
      }
      setSearchValues({ ...newSearchValue });
      groupSearchCall(newSearchValue);
    }
  };

  const exportedBy =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    props.mainData.users.userDetailsById &&
    props.mainData.users.userDetailsById?.Users_Data[0].LName;
  const exportListData = () => {
    if (groupList) {
      const mappedLogs = groupList.map((row) => ({
        "Group name": row.groupName,
        Status: row.groupStatus,
        "Group Type": row.groupType,
        "Number of Users": exportNumberToDashFormat(row.groupUser.length),
        "Total Mid": exportNumberToDashFormat(row.totalMid),
        "Created Date": row.createdDate,
      }));
      exportToCSV(
        mappedLogs,
        "Group List",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname
      );
    }
  };

  const exportToPDFList = () => {
    if (groupList) {
      const mappedLogs = groupList.map((row) => [
        row.groupName,
        row.groupStatus,
        row.groupType,
        exportNumberToDashFormat(row.groupUser.length),
        exportNumberToDashFormat(row.totalMid),
        row.createdDate,
      ]);
      const headersName = [
        "Group name",
        "Status",
        "Group Type",
        "Number of Users",
        "Total Mid",
        "Created Date",
      ];
      exportToPDF(
        headersName,
        mappedLogs,
        "Group List",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname
      );
    }
  };

  const getGroupListData = () => {
    props.groupsListApi((data) => {
      setGroupList(data.data);
      setInitialCall(true);
    });
  };

  React.useEffect(() => {
    if (rolePermission !== props.tableData.roleDetailsById) {
      if (props.tableData.roleDetailsById.status) {
        setRolePermission(props.tableData.roleDetailsById);
      }
    }
  }, [props]);

  React.useEffect(() => {
    let userid = localStorage.getItem("userId");
    if (isEmpty(groupList)) {
      getGroupListData();
      callGroupTypeListApi();
    }
    props.getUserDetailsById(userid);
  }, []);

  return (
    <>
      {addEditForm && (
        <AddEditGroup
          getGroupListData={getGroupListData}
          details={details}
          addEditForm={addEditForm}
          goBack={closeAddEditForm}
        />
      )}
      {!addEditForm && (
        <>
          {windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin}>
                <div className={classes.searchWrapper}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => handleClose()}
                  >
                    <Box style={{ width: "35%" }}>
                      <ChipInput
                        value={chipValue.length > 0 ? chipValue : []}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        classes={{ chipContainer: classes.chipContainer }}
                        style={{ display: "flex" }}
                        InputProps={{
                          // autoComplete: "off",
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                        }}
                        fullWidth
                        label="Search"
                        // value={chipValue.length > 0 ? chipValue : []}
                        onClick={() => handleClickOpen()}
                        name="status"
                        id="status"
                        onDelete={(chip, index) =>
                          handleDeleteChip(chip, index)
                        }
                      />
                      <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={classes.overLayBox}
                      >
                        <SimpleDialog
                          close={() => handleClose()}
                          groupTypeList={groupTypeList}
                          searchValues={searchValues}
                          setSearchValues={setSearchValues}
                          searchChipData={groupSearchCall}
                        />
                      </Collapse>
                    </Box>
                  </ClickAwayListener>
                  <CoustomButton
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    onClick={() => {}}
                  >
                    <Search />
                  </CoustomButton>
                  {actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.groups,
                    permissionCheck.option.export
                  ) &&
                    (actionPermission(
                      permissionCheck.Category.setup,
                      permissionCheck.Page.groups,
                      permissionCheck.option.exportToExcel
                    ) ||
                      actionPermission(
                        permissionCheck.Category.setup,
                        permissionCheck.Page.groups,
                        permissionCheck.option.exportToPdf
                      )) && (
                      <IconsTooltip title="Export" placement="top">
                        <div className={classes.appSvgIcons}>
                          <ExportIcon
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleToggle}
                            ref={anchorRef}
                          />
                        </div>
                      </IconsTooltip>
                    )}
                  <Popper
                    open={openImportFileModal}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    style={{
                      zIndex: "10",
                    }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClosed}>
                            <MenuList
                              // autoFocusItem={openImportFileModal}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              {actionPermission(
                                permissionCheck.Category.setup,
                                permissionCheck.Page.groups,
                                permissionCheck.option.exportToExcel
                              ) && (
                                <MenuItem
                                  onClick={exportListData}
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  Export to Excel
                                </MenuItem>
                              )}
                              {actionPermission(
                                permissionCheck.Category.setup,
                                permissionCheck.Page.groups,
                                permissionCheck.option.exportToPdf
                              ) && (
                                <MenuItem
                                  onClick={exportToPDFList}
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  Export to PDF
                                </MenuItem>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  {actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.groups,
                    permissionCheck.option.add
                  ) && (
                    <IconsTooltip title="Add" placement="top">
                      <div className={classes.appSvgIcons}>
                        <AddIconSvg
                          onClick={() => {
                            setAddEditForm(true);
                          }}
                        />
                      </div>
                    </IconsTooltip>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
          {!windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin} style={{ display: "flex" }}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ width: "85%" }}>
                    <ChipInput
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      classes={{ chipContainer: classes.chipContainer }}
                      style={{ display: "flex" }}
                      InputProps={{
                        // autoComplete: "off",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      value={chipValue.length > 0 ? chipValue : []}
                      fullWidth
                      label="Search"
                      onClick={() => handleClickOpen()}
                      name="status"
                      id="status"
                      onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        close={() => handleClose()}
                        groupTypeList={groupTypeList}
                        searchValues={searchValues}
                        setSearchValues={setSearchValues}
                        searchChipData={groupSearchCall}
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <CoustomButton
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </CoustomButton>
              </Grid>
              <Grid item className={classes.expirtItemGrid}>
                {actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.groups,
                  permissionCheck.option.export
                ) &&
                  (actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.groups,
                    permissionCheck.option.exportToExcel
                  ) ||
                    actionPermission(
                      permissionCheck.Category.setup,
                      permissionCheck.Page.groups,
                      permissionCheck.option.exportToPdf
                    )) && (
                    <IconsTooltip title="Export" placement="top">
                      <div className={classes.appSvgIcons}>
                        <ExportIcon
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={handleToggle}
                          ref={anchorRef}
                        />
                      </div>
                    </IconsTooltip>
                  )}
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClosed}>
                          <MenuList
                            // autoFocusItem={openImportFileModal}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.groups,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.groups,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                {actionPermission(
                  permissionCheck.Category.setup,
                  permissionCheck.Page.groups,
                  permissionCheck.option.add
                ) && (
                  <IconsTooltip title="Add" placement="top">
                    <div className={classes.appSvgIcons}>
                      <AddIconSvg
                        onClick={() => {
                          setAddEditForm(true);
                        }}
                      />
                    </div>
                  </IconsTooltip>
                )}
              </Grid>
            </Grid>
          )}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={groupList.length}
            />
            <TableBody>
              {(!isEmpty(groupList) && initialCall) ||
              (isEmpty(groupList) && !initialCall) ? (
                stableSort(groupList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row._id}
                      hover
                      className={classes.cellHeadSign}
                    >
                      <TableCell
                        className={classes.cellText}
                        align="center"
                        onClick={() => handleEdit("edit", row)}
                      >
                        <b>{row.groupName}</b>
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.groupStatus}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.groupType}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.groupUser.length}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.totalMid}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {moment(row.createdDate).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.updatedDate || "N/A"}
                      </TableCell>
                      {actionPermission(
                        permissionCheck.Category.setup,
                        permissionCheck.Page.groups,
                        permissionCheck.option.edit
                      ) && (
                        <TableCell>
                          <IconButton
                            color="primary"
                            aria-label="edit group"
                            component="span"
                            onClick={() => onClickEdit(row)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {groupList.length ? (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: 999999 },
                    ]}
                    colSpan={12}
                    count={groupList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    // SelectProps={{
                    //   inputProps: { placeholder: "Show" },
                    //   native: true,
                    // }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            ) : (
              ""
            )}
          </Table>
        </>
      )}
    </>
  );
}

GroupDataList.propTypes = {
  groupsListApi: PropTypes.func,
  tableData: PropTypes.object,
  groupsTypeListApi: PropTypes.func,
  groupsListSearchApi: PropTypes.func,
  mainData: PropTypes.object,
  getUserDetailsById: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.users,
  userDetails: app.app,
  mainData: app,
});
export default connect(mapStateToProps, {
  groupsListApi,
  groupsTypeListApi,
  groupsListSearchApi,
  getUserDetailsById,
})(GroupDataList);
