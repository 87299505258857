import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import AgentSearchChip from "./AgentSearchChip";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const classes = useStyles();
  const {
    setSearchValues,
    handleSubmitSearch,
    handleClearSearch,
    searchValues
  } = props;

  const handleClose = () => {
    handleClearSearch();
  };

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    if (type == "From") {
      allState[type] = moment(value).format("L");
    } else if (type == "To") {
      allState[type] = moment(value).format("L");
    } else {
      allState[type] = value;
    }
    setSearchValues(allState);
  };

  const onFormSubmit = () => {
    const allState = { ...searchValues };
    handleSubmitSearch(allState);
  };
  const userType = localStorage.getItem("userType");
  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            autoComplete="off"
            label="Type / Description"
            value={searchValues.Name}
            onChange={(e) => onChange(e.target.value, "Name")}
            name="Name"
            id="Name"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      {userType === "Internal" && (
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <AgentSearchChip
            onChange={(value, type) => onChange(value, type)}
            values={searchValues}
            label="Updated By"
          />
        </Grid>
      )}
      <Grid spacing={2} container>
        <Grid item xs={6} lg={6}>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                // views={["month", "year"]}
                id="From"
                name="From"
                maxDate={new Date()}
                minDateMessage=""
                invalidDateMessage=""
                maxDateMessage=""
                inputVariant="standard"
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                value={moment(searchValues.From).format("YYYY-MM-DD")}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                onChange={(value) => {
                  onChange(value, "From");
                }}
                KeyboardButtonProps={{
                  size: "small"
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={6}>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                // views={["month", "year"]}
                id="To"
                name="To"
                maxDate={moment(new Date()).endOf("month")}
                minDate={searchValues.From}
                minDateMessage=""
                invalidDateMessage=""
                maxDateMessage=""
                inputVariant="standard"
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYYY"
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                value={moment(searchValues.To).format("YYYY-MM-DD")}
                onChange={(value) => {
                  onChange(value, "To");
                }}
                KeyboardButtonProps={{
                  size: "small"
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.margin}
        style={{ justifyContent: "end", marginTop: "1rem" }}
      >
        <Button
          cancel
          variant="contained"
          className={classes.clearButton}
          onClick={() => handleClose()}
        >
          clear
        </Button>
        <Button
          variant="contained"
          className={classes.buttonStyle}
          onClick={() => onFormSubmit()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  handleSubmitSearch: PropTypes.func,
  setSearchValues: PropTypes.func,
  handleClearSearch: PropTypes.func,
  tableData: PropTypes.object,
  searchValues: PropTypes.object
};

const mapStateToProps = (app) => ({
  formData: app.users,
  tableData: app.users,
  userDetails: app.app
});

export default connect(mapStateToProps, {})(SimpleDialog);
