import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}));

function ChipsArray({
  searchValues,
  labelName,
  handleDeleteChip,
  processorName,
  psName,
  agentName
}) {
  const classes = useStyles();
  return (
    <Paper elevation={0} component="ul" className={classes.root}>
      {Object.entries(searchValues).map((data) => {
        let icon;
        if (!data[1] || data[0] === "fromMonth") {
          return;
        }
        if (data[0] === "toMonth") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`Update period : ${moment(searchValues.fromMonth).format(
                  "MMM-YYYY"
                )} - ${moment(searchValues.toMonth).format("MMM-YYYY")}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        if (data[0] === "processorId") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${processorName}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        if (data[0] === "Status") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${
                  data[1] == 1 ? "Active" : "Inactive"
                }`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        if (data[0] === "psId") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${psName}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        if (data[0] === "userId") {
          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={`${labelName[data[0]]} : ${agentName}`}
                onDelete={() => handleDeleteChip(data[0])}
                className={classes.chip}
              />
            </li>
          );
        }
        return (
          <li key={data.key}>
            <Chip
              icon={icon}
              label={`${labelName[data[0]]} : ${data[1]}`}
              onDelete={() => handleDeleteChip(data[0])}
              className={classes.chip}
            />
          </li>
        );
      })}
    </Paper>
  );
}

ChipsArray.propTypes = {
  searchValues: PropTypes.object,
  labelName: PropTypes.object,
  handleDeleteChip: PropTypes.func,
  agentName: PropTypes.any,
  processorName: PropTypes.any,
  adjustment_Name: PropTypes.any,
  merchant_Name: PropTypes.any,
  psName: PropTypes.any
};
export default ChipsArray;
