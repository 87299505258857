/* eslint-disable no-restricted-syntax */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons

import Email from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import LockOutlined from "@material-ui/icons/Lock";
import jwt from "jsonwebtoken";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CoreButton from "@material-ui/core/Button";
import { connect } from "react-redux";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import closeImage from "assets/img/Close_Image.png";

import {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
} from "../../Redux/Actions/LoginActions";
import styles from "../../assets/jss/material-dashboard-react/views/LoginPage.js";
import { checkLayoutName } from "../../Uintls/gloables";

const useStyles = makeStyles(styles);
const Login = (props) => {
  const [validationModal, setValidationModal] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = React.useState(
    props.userData.data
  );

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const [validationError, setVlidationError] = React.useState({
    Email: false,
    password: false,
  });

  const checkValidation = () => {
    // var dataapierror = JSON.stringify(props.formData.data.status);
    const error = { ...validationError };
    let validate = true;
    for (const key in values) {
      if (values[key] === "" && (key === "Email" || key === "password")) {
        error[key] = true;
        validate = false;
      } else {
        error[key] = false;
      }
    }

    setVlidationError(error);
    return validate;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const [values, setValues] = React.useState({
    Email: "",
    password: "",
    userType: "PT",
  });

  const onChange = (type, value) => {
    const data = { ...values };
    data[type] = value;
    setValues(data);
  };

  const onFormSubbmit = () => {
    localStorage.setItem("email", values.Email.toLowerCase());
    if (checkValidation()) {
      props.loginRequest(values);
    } else {
      setValidationModal(true);
    }
  };

  React.useEffect(() => {
    if (props.userData !== false) {
      if (props.userData && props.userData.status) {
        jwt.verify(
          props.userData.token,
          "Support@Vaminfosyshserus",
          (err, decoded) => {
            if (decoded !== undefined) {
              // const idealPath = JSON.parse(localStorage.getItem("idealPath"));
              const idealPath = "";
              let LandingPage = "/";
              let MainCat = "";
              if (idealPath) {
                LandingPage = idealPath.landingPageName;
              } else {
                LandingPage = decoded.Role_Permission_Data.LandingPage;
                MainCat =
                  decoded.Role_Permission_Data.Main_Category_LandingPage;
              }
              props.setAppBarName(
                MainCat ? `${MainCat} / ${LandingPage}` : LandingPage
              );
              const layoutName = checkLayoutName(
                decoded.Role_Permission_Data.Profile_Code
              );
              props.setLayoutName(layoutName);
              if (idealPath) {
                props.prop.history.push(idealPath.path);
              } else {
                props.prop.history.push(
                  `/${layoutName}/${LandingPage.replace(/\s/g, "")}`
                );
              }
              localStorage.setItem("menu", JSON.stringify(decoded));
              localStorage.setItem("clientId", decoded.ClientID);
              localStorage.setItem("userId", decoded.UserID);
              localStorage.setItem("userProfileImage", decoded.userProfileImage);
              localStorage.setItem(
                "sendApiClientId",
                JSON.stringify([decoded.ClientID])
              );
              props.setUserRole(decoded);
              props.state();
              localStorage.clear();
            }
          }
        );
      } else {
        setErrorMessage(props.userData.message);
        setValidationModal(true);
      }
    }
  }, [submissionSuccess, props]);

  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem xs>
          <div className={classes.root}>
            <img style={{ width: "157px" }} src={require("../../assets/img/imgpsh_fullsize_anim.png")} />
            <Card className={classes.card1}>
              <form className={classes.form}>
                <p
                  className={classes.divider}
                  style={{ fontSize: "18px", color: "gray" }}
                >
                  Log in to your account{" "}
                </p>
                <CardBody>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      color: "secondary",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      value: values.Email,
                      onChange: (e) => onChange("Email", e.target.value),
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="pass"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      color: "secondary",
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutlined className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      value: values.password,
                      onChange: (e) => onChange("password", e.target.value),
                    }}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className={classes.patientLogin}>
                      <CoreButton
                        color="primary"
                        className={classes.textColor}
                        onClick={() => {
                          props.prop.history.push("/");
                        }}
                      >
                        Administrative Login
                      </CoreButton>
                    </div>
                    <div className={classes.forgetPassword}>
                      <CoreButton
                        color="primary"
                        className={classes.textColor}
                        onClick={() => {
                          props.prop.history.push("/resetPassword");
                        }}
                      >
                        Forgot password?
                      </CoreButton>
                    </div>
                  </div>
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button
                    simple
                    type="submit"
                    color="primary"
                    size="lg"
                    style={{
                      backgroundColor: "#0f0877",
                      borderRadius: "30px",
                      color: "#fff",
                      fontWeight: "bold",
                      width: "14rem",
                    }}
                    onClick={() => onFormSubbmit()}
                  >
                    Login
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv}>
              <img src={closeImage} width="30%" />
              <p>
                <strong style={{ fontSize: "22px", color: "primary" }}>
                  {`${
                    validationError.Email || validationError.password
                      ? "Invalid Credentials"
                      : ""
                  }`}
                </strong>
              </p>
              <p>
                <strong style={{ color: "primary" }}>{errorMessage}</strong>
              </p>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => setValidationModal(false)}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

Login.propTypes = {
  loginRequest: PropTypes.func,
  userData: PropTypes.object,
  setUserRole: PropTypes.func,
  prop: PropTypes.any,
  state: PropTypes.any,
  setAppBarName: PropTypes.func,
  setLayoutName: PropTypes.func,
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails,
});
export default connect(mapStateToProps, {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
})(Login);
