/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
// creates a beautiful scrollbar
/*
// code clean up @20230421
import "perfect-scrollbar/css/perfect-scrollbar.css";
*/
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar";
import Sidebar from "components/Sidebar/Sidebar";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DashboardPage from "views/Dashboard/Dashboard";
import BusinessForm from "views/UserProfile/BuainessInformation";
import Roles from "views/Reports/Roles";
import GenrateOffer from "components/GenrateOffer/index";
import PaymentGenrateOffer from "views/PaymentOffer/index";
import ActivePatient from "views/Reports/ActivePatient";
import DailyDeposite from "views/Reports/DailyDeposit";
import MoveToCollection from "views/Reports/MoveToCollection";
import Defaults from "views/Reports/Defaults";
import TotalOutstanding from "views/Reports/TotalOutstanding";
import BadDebts from "views/Reports/BadDebts";
import AppLoader from "components/AppLoader/Index";
/*
// code clean up @20230421
import { useBeforeunload } from "react-beforeunload";
*/

import CptCode from "views/Reports/CptCode";
import UserLogin from "views/Login/UserLogin";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ResetPassword from "views/Login/NewLogin/ResetPassword";
import BusinessFormOpen from "views/PricingTemplete/BuainessInformation";
import SessionOut from "views/Login/SessionOut";
import MemberRegistration from "views/Reports/memberRegistration";
import ProviderReport from "views/Reports/Provider";
import RoleManager from "views/RoleManager/Index";
import MyProfile from "views/Profile/Profile";
import ProviderActivity from "views/Reports/ProviderActivityHistory";
import ProviderParameter from "views/ProviderParameter/Index";
import EmailGenrateOffer from "views/PaymentOffer/EmailFinalGenrateOffer";
import FinalGenrateOffer from "views/PaymentOffer/FinalGenrateOffer";
import PatientPaymentDashboard from "views/Patient/PatientPaymentDetails";
import Payments from "views/Payment/PaymentOffer";
import PatientRegister from "views/Patient/PatientRegister";
import OfferDetails from "views/OfferDetails/Index";
import OfferPayment from "views/Offerpayment/NewOfferPayment";
import OfferPrintPatient from "views/OfferPrintPatient/Index";
import PaymentOtp from "views/Payment/PaymentOtp";
import AddPatientForm from "views/Patient/AddPatient";
import OnBording from "views/OnBoarding/Index";
import logo from "../assets/img/7OHMVp.jpg";
import bgImage from "../assets/img/sidebar-2.jpg";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";
import NewLogin from "../views/Login/NewLogin/NewLogin";
import {
  getPatientDetailsEmail,
  getPatientInfoEmail,
  checkResetPasswordAuthCall,
  setUserIdResetPaassword,
  emptyPatientSelect,
} from "../Redux/Actions/genrateOfferPatient";
import PdfComponent from "../components/Pdf/index";
import {
  setAppBarName,
  setUserRole,
  setLayoutName,
  clientThemeCall,
  clientPortalConfig,
  getUserLeftMenuCall,
} from "../Redux/Actions/LoginActions";
import {
  getUserList,
  changeUserFormMode,
  getUserListProvider,
  setOroviderListEmpty,
  getUserDetailsById,
  setUserSearchEmpty,
} from "../Redux/Actions/UserRegistration";
import {
  getProviderList,
  getProviderDetailsById,
  changeProviderFormMode,
} from "../Redux/Actions/ProvideRegistration";
import { resetReducer } from "../Redux/Actions/appActions";
import { lastCalculationDate } from "../Redux/Actions/payoutAction";
import {
  changeRoleFormMode,
  rolePermisstionRequest,
} from "../Redux/Actions/RoleRegistration";
import Patients from "../views/Patient/Dashboard";
import { checkLayoutName } from "../Uintls/gloables";
import { logout } from "../Redux/Actions/LoginActions";
import OpenFunding from "../views/PatientReport/OpenFunding";
import PendingOffer from "../views/PatientReport/PendingOffers";
import AccountStatement from "../views/PatientReport/AccountStatement";
import AdminSettings from "../views/PanelConfig/ProvidersSettings";
import Products from "../views/Transactions/Products";
import OrderCart from "../views/Transactions/OrderCart";
import NewPayments from "../views/Transactions/Payments";
import MobilePosCheckout from "../views/Transactions/MobilePosCheckout";
import AddtionalInformation from "../views/AddtionalFields/AddionalInformation";
import PricingTemplateListing from "../views/Reports/PricingTemplate";
import PricingTemplateListingAddEdit from "../views/PricingTemplete/PricingTemplateAddEdit";
import PricingSchedule from "../modules/payout/pages/pricing_schedule/listing";
import PSActivityHistory from "../modules/payout/pages/pricing_schedule/PSActivityHistory";
import PricingScheduleAddEdit from "../modules/payout/pages/pricing_schedule/index";
import AgentPayout from "../modules/payout/pages/agent_payout/index";
import NewPayoutSummaryReport from "../modules/reports/pages/NewPayoutSummary/index";
import PayoutApproval from "../modules/payout/pages/PayoutApproval/index";
import AdjustmentDetailsReport from "../modules/reports/pages/Adjustment Details/index";
import NewPayoutDetailsReport from "../modules/reports/pages/NewPayoutDetails/index";
import MerchantMapping from "../modules/payout/pages/merchant_mapping/index";
import MappingHistory from "../modules/payout/pages/merchant_mapping/mappingHistory";
import Adjustment from "../modules/payout/pages/adjustment/index";
import AdjustmentAddEdit from "../modules/payout/pages/adjustment/AddjustmentAddEdit";
import MyHierarchy from "../views/Setup/myHierarchy";
import PayoutManager from "../modules/payout/pages/payout_calculation/index";
import IncomeExpenseDetail from "../modules/reports/pages/IncomeExpenseDetail/index";
import ImportLog from "../modules/reports/pages/ImportLog/index";
import CalculationSummary from "../modules/payout/pages/payout_calculation/calculation_summary/calculation_summary";
import CalculationHistory from "../modules/payout/pages/payout_calculation/CalculationHistory/index";
import { isEmpty } from "lodash";
import RiskManagement from "views/RiskManagement/RiskManagement";

const switchRoutes = (value, rest) => (
  <>
    <Switch>
      <Route exact path="/" component={NewLogin} />
      <Route exact path="/login" component={NewLogin} />
      <Route
        exact
        path="/resetPassword"
        component={() => <ResetPassword state={value} prop={rest} />}
      />
      <Route
        exact
        path="/sessionOut"
        component={() => <SessionOut state={value} prop={rest} />}
      />
      <Route path="/admin/email/offer" component={EmailGenrateOffer} />
      <Route path="/admin/print/offer" component={FinalGenrateOffer} />
      <Route path="/admin/email/register" component={PatientRegister} />
      <Route path="/admin/paymentotp" component={PaymentOtp} />
      <Route path="/admin/PricingSchedule" exact component={PricingSchedule} />
      <Route path="/admin/AgentPayout" exact component={AgentPayout} />
      <Route path="/admin/MerchantMapping" exact component={MerchantMapping} />
      <Route
        path={`/${rest.userDetails.layout}/PayoutCalculation`}
        exact
        component={PayoutManager}
      />
      <Route
        path={`/${rest.userDetails.layout}/CalculationSummary`}
        exact
        component={CalculationSummary}
      />
      <Route
        path={`/${rest.userDetails.layout}/CalculationHistory`}
        exact
        component={CalculationHistory}
      />
      <Route
        path={`/${rest.userDetails.layout}/Adjustment`}
        exact
        component={Adjustment}
      />
      <Route
        path={`/${rest.userDetails.layout}/Adjustment/add`}
        exact
        component={AdjustmentAddEdit}
      />
      <Route
        path={`/${rest.userDetails.layout}/Adjustment/:id`}
        exact
        component={AdjustmentAddEdit}
      />
      <Route
        path="/admin/PricingSchedule/add"
        exact
        component={PricingScheduleAddEdit}
      />
      <Route
        path="/admin/PricingSchedule/activity-history"
        exact
        component={PSActivityHistory}
      />

      <Route
        path="/admin/MerchantMapping/history"
        exact
        component={MappingHistory}
      />
      <Route
        path="/admin/PricingSchedule/edit/:id"
        exact
        component={PricingScheduleAddEdit}
      />
      <Route
        path="/admin/PayoutSummary"
        exact
        component={NewPayoutSummaryReport}
      />
      <Route path="/admin/PayoutApproval" exact component={PayoutApproval} />
      <Route
        path="/admin/AdjustmentDetails"
        exact
        component={AdjustmentDetailsReport}
      />
      <Route
        path="/admin/PayoutDetail"
        exact
        component={NewPayoutDetailsReport}
      />
      <Route
        path="/admin/IncomeExpenseDetail"
        exact
        component={IncomeExpenseDetail}
      />
      <Route path="/admin/ImportLogs" exact component={ImportLog} />
      <Route
        path="/tpo/payments"
        render={(props) => <Payments state={value} {...props} />}
      />
      <Route
        path="/patient/patientPaymentDetails"
        render={(props) => <PatientPaymentDashboard state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/Dashboard`}
        component={DashboardPage}
      />
      <Route
        path={`/${rest.userDetails.layout}/RiskManagement`}
        component={RiskManagement}
      />
      <Route
        path={`/${rest.userDetails.layout}/Merchant/app`}
        component={BusinessForm}
      />
      <Route
        path={`/${rest.userDetails.layout}/addpatient`}
        component={AddPatientForm}
      />
      <Route path={`/${rest.userDetails.layout}/Roles`} component={Roles} />
      <Route
        path={`/${rest.userDetails.layout}/MerchantInvite`}
        component={OnBording}
      />
      <Route
        path={`/${rest.userDetails.layout}/Offers`}
        render={(props) => <PaymentGenrateOffer state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/panelConfigurations`}
        render={(props) => <AdminSettings state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/OpenFunding`}
        render={(props) => <OpenFunding state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/PendingOffer`}
        render={(props) => <PendingOffer state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/AccountStatement`}
        render={(props) => <AccountStatement state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/pricingTemplete`}
        component={BusinessFormOpen}
      />
      <Route
        path={`/${rest.userDetails.layout}/offerDetails`}
        render={(props) => <OfferDetails state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/offerpayment`}
        render={(props) => <OfferPayment state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/printoffer`}
        render={(props) => <OfferPrintPatient state={value} {...props} />}
      />
      <Route
        path={`/${rest.userDetails.layout}/GenerateOffer`}
        component={GenrateOffer}
      />
      <Route
        path={`/${rest.userDetails.layout}/Transactions`}
        component={ActivePatient}
      />
      <Route
        path={`/${rest.userDetails.layout}/DailyDeposit`}
        component={DailyDeposite}
      />
      <Route
        path={`/${rest.userDetails.layout}/PricingTemplate`}
        exact
        component={PricingTemplateListing}
      />
      <Route
        path={`/${rest.userDetails.layout}/PricingTemplate/add`}
        exact
        component={PricingTemplateListingAddEdit}
      />
      <Route
        path={`/${rest.userDetails.layout}/PricingTemplate/edit/:tempId`}
        exact
        component={PricingTemplateListingAddEdit}
      />
      <Route
        path={`/${rest.userDetails.layout}/PricingTemplate/cloneTemplate/:copyId`}
        exact
        component={PricingTemplateListingAddEdit}
      />
      <Route
        path={`/${rest.userDetails.layout}/Collections`}
        component={MoveToCollection}
      />
      <Route
        path={`/${rest.userDetails.layout}/providerParameter`}
        component={ProviderParameter}
      />
      <Route
        path={`/${rest.userDetails.layout}/Defaults`}
        component={Defaults}
      />
      <Route
        path={`/${rest.userDetails.layout}/pdf`}
        component={PdfComponent}
      />
      <Route
        path={`/${rest.userDetails.layout}/TotalOutstanding`}
        component={TotalOutstanding}
      />
      <Route
        path={`/${rest.userDetails.layout}/BedDebts`}
        component={BadDebts}
      />
      <Route path={`/${rest.userDetails.layout}/CPTCode`} component={CptCode} />
      <Route
        path={`/${rest.userDetails.layout}/MemberRegister`}
        component={MemberRegistration}
      />
      <Route
        path={`/${rest.userDetails.layout}/Merchant`}
        component={ProviderReport}
      />
      <Route
        path={`/${rest.userDetails.layout}/Users`}
        component={RoleManager}
      />
      <Route
        path={`/${rest.userDetails.layout}/Profile`}
        component={MyProfile}
      />
      <Route
        path={`/${rest.userDetails.layout}/merchantactivity`}
        component={ProviderActivity}
      />
      <Route
        path={`/${rest.userDetails.layout}/addtionalInformation`}
        component={AddtionalInformation}
      />
      <Route
        path={`/${rest.userDetails.layout}/products`}
        component={Products}
      />
      <Route
        path={`/${rest.userDetails.layout}/Paynow`}
        component={OrderCart}
      />
      <Route
        path={`/${rest.userDetails.layout}/payments`}
        component={NewPayments}
      />
      <Route
        path={`/${rest.userDetails.layout}/MobilePosCheckout`}
        component={MobilePosCheckout}
      />
      <Route
        path={`/${rest.userDetails.layout}/MyHierarchy`}
        component={MyHierarchy}
      />
    </Switch>
  </>
);

function Admin({ ...rest }) {
  if (localStorage.getItem("sessionExpired") === "true") {
    localStorage.clear();
    rest.history.push("/sessionOut");
  }
  const useStyles = makeStyles(styles);
  const theme = useTheme();
  const location = useLocation();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [preUserByIdDetails, setPreUserByIdDetails] = React.useState(
    rest.userProvider.userDetailsById
  );
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const [state, setState] = React.useState(false);
  const [hoverDrawer, setOpenDrawerHover] = React.useState(false);
  // states and functions
  const [image] = React.useState(bgImage);
  const [callRolePermission, setCallRolePermission] = React.useState(false);
  const [color] = React.useState("blue");
  const [preWindowDetails, setPreWindowDetails] = React.useState(false);
  const [openDrawer, setOpen] = React.useState(false);
  const [preProviderListData, setPreProviderListData] = React.useState(
    rest.tableData.providerList
  );
  const [preDatas, setPreData] = React.useState(false);

  React.useEffect(() => {
    if (preProviderListData !== rest.tableData.providerList) {
      setPreProviderListData(rest.tableData.providerList);
      if (
        rest.tableData.providerList &&
        rest.tableData.providerList.Providers_Data
      ) {
        const providerListLength =
          rest.tableData.providerList.Providers_Data.length;
        if (providerListLength === 1) {
          const merchantId =
            rest.tableData.providerList.Providers_Data[0].Provider_id;
          rest.history.push(`/${rest.userDetails.layout}/Merchant/app`);
          rest.getProviderDetailsById(merchantId);
          rest.changeProviderFormMode("Edit");
        }
      }
    }
  }, []);

  React.useEffect(() => {
    if (preUserByIdDetails !== rest.userProvider.userDetailsById) {
      if (!rest.app.roles.roleTab) {
        if (
          rest.userProvider.userDetailsById &&
          rest.userProvider.userDetailsById
        ) {
          const data = {
            role_id: rest.userProvider.userDetailsById?.Users_Data[0]?.RoleID,
          };
          setCallRolePermission(true);
          rest.rolePermisstionRequest(data, "admin");
          setPreUserByIdDetails(rest.userProvider.userDetailsById);
        }
      }
    }
  }, [rest.userProvider, rest]);

  React.useEffect(() => {
    const AdminUrlPermission = localStorage.getItem("rolePermission")
    if (rest.roles.roleTab) {
      localStorage.setItem(
        "rolePermission",
        JSON.stringify(rest.roles)
      );
    }
  }, [rest.roles]);

  React.useEffect(() => {
    let withLocal = true;
    if (!preWindowDetails && window.location.pathname.match("Offers/email")) {
      const splitValue = window.location.pathname.split("/");
      setPreWindowDetails(true);
      setPreData(true);
      const data = {
        guid: splitValue[splitValue.length - 1],
      };
      rest.getPatientDetailsEmail(data);
      rest.history.push("/admin/email/offer");
    } else if (window.location.pathname.match("register")) {
      const splitValue = window.location.pathname.split("/");
      const data = {
        patientId: splitValue[splitValue.length - 1],
      };
      rest.getPatientInfoEmail(data);
      withLocal = false;
      rest.history.push("/admin/email/register");
    } else if (window.location.pathname.match("password")) {
      withLocal = false;
      if (window.location.pathname.match("user")) {
        const splitValue = window.location.pathname.split("/");
        rest.setUserIdResetPaassword(splitValue[splitValue.length - 1]);
        rest.history.push("/admin/email/register");
      } else {
        const splitValue = window.location.pathname.split("/");
        const data = {
          emailToken: splitValue[splitValue.length - 1],
        };
        withLocal = false;
        rest.checkResetPasswordAuthCall(data);
        rest.history.push("/admin/email/register");
      }
    } else if (
      !localStorage.length &&
      !window.location.pathname.match("register") &&
      !window.location.pathname.match("login") &&
      !window.location.pathname.match("newLogin")
    ) {
      rest.history.push("/");
    }
    if (!rest.userRole.userList && withLocal) {
      const preData = localStorage.getItem("menu");
      if (preData && !preDatas) {
        rest.setUserRole(JSON.parse(preData));
        rest.setLayoutName(
          checkLayoutName(JSON.parse(preData).Role_Permission_Data.Profile_Code)
        );
        const idealPath = "";
        let LandingPage = "/";
        let MainCat = "";
        if (idealPath) {
          LandingPage = idealPath.landingPageName;
        } else {
          LandingPage = JSON.parse(preData).Role_Permission_Data.LandingPage;
          MainCat =
            JSON.parse(preData).Role_Permission_Data.Main_Category_LandingPage;
        }
        rest.setAppBarName(
          MainCat ? `${MainCat} / ${LandingPage}` : LandingPage
        );
        const layoutName = checkLayoutName(
          JSON.parse(preData).Role_Permission_Data.Profile_Code
        );
        rest.clientThemeCall(JSON.parse(preData).ClientID);
        rest.clientPortalConfig(JSON.parse(preData).ClientID);
        rest.getUserLeftMenuCall();
        rest.lastCalculationDate();
        rest.getUserDetailsById(JSON.parse(preData).UserID);
        rest.setLayoutName(layoutName);
        const lastPath = localStorage.getItem("last");
        if (idealPath) {
          rest.history.push(idealPath.path);
        } else if (lastPath === "") {
          rest.history.push("/");
        } else if (lastPath === "/admin/user") {
          rest.history.push("/admin/Merchant");
        }
        setPreData(true);
      }
    }
  }, []);

  const routeState = () => {
    setState(!state);
  };

  if (window.performance.navigation.type === 1) {
    localStorage.setItem("last", window.location.pathname);
  } else {
  }

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/sessionOut" &&
    window.location.pathname !== "/Login1" &&
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/" &&
    window.location.pathname !== "/admin/email/offer" &&
    window.location.pathname !== "/admin/print/offer" &&
    window.location.pathname !== "/tpo/payments" &&
    window.location.pathname !== "/admin/email/register" &&
    window.location.pathname !== "/resetPassword" &&
    window.location.pathname !== "/newLogin"
  ) {
    if (!rest.app.app.mainAppLoading) {
      return (
        <div className={classes.wrapper}>
          {!rest.app.genrateOfferPatient.genrateOfferLoading && (
            <AppLoader
              loader={
                rest.app.genrateOfferPatient.loading ||
                rest.app.provideRegistration.loading ||
                rest.app.roles.loading ||
                rest.app.table.loading ||
                rest.app.userRole.loading ||
                rest.app.users.loading ||
                rest.loader
              }
            />
          )}
          {openDrawer && !windowWidth && (
            <Sidebar
              logoText="Vam Infosys"
              logo={logo}
              image={image}
              setState={() => setState(!state)}
              open={openDrawer}
              setopen={() => setOpen(false)}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              color={color}
              {...rest}
            />
          )}
          {windowWidth && (
            <Sidebar
              logoText="Vam Infosys"
              logo={logo}
              image={image}
              setState={() => setState(!state)}
              open={openDrawer}
              setopen={() => setOpen(false)}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              color={color}
              {...rest}
            />
          )}
          <div
            className={classNames({
              [classes.mainPanelHide]: !openDrawer && true,
              [classes.mainPanel]: openDrawer && true,
            })}
            ref={mainPanel}
          >
            <Navbar
              setopen={() => setOpen(!openDrawer)}
              setState={() => setState(!state)}
              openDrawer={openDrawer}
              hoverDrawer={hoverDrawer}
              setOpenDrawerHover={(value) => setOpenDrawerHover(value)}
              setCallRolePermission={() => setCallRolePermission(false)}
              color={color}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            <div className={classes.content}>
              <div className={classes.container}>
                {switchRoutes(routeState, { ...rest })}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <AppLoader loader />
      </div>
    );
  }
  const value = { ...rest };
  return (
    <div>
      <AppLoader
        loader={
          rest.app.provideRegistration.loading ||
          rest.app.roles.loading ||
          rest.app.table.loading ||
          rest.app.userRole.loading ||
          rest.app.users.loading
        }
      />
      {switchRoutes(routeState, value)}
    </div>
  );
}

Admin.propTypes = {
  userDetails: PropTypes.object,
  userRole: PropTypes.object,
  setAppBarName: PropTypes.func,
  getUserList: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  getUserListProvider: PropTypes.func,
  setLayoutName: PropTypes.func,
  setOroviderListEmpty: PropTypes.func,
  resetReducer: PropTypes.func,
  getPatientDetailsEmail: PropTypes.func,
  getPatientInfoEmail: PropTypes.func,
  genrateOffer: PropTypes.object,
  app: PropTypes.object,
  logout: PropTypes.func,
  checkResetPasswordAuthCall: PropTypes.func,
  setUserIdResetPaassword: PropTypes.func,
  clientThemeCall: PropTypes.func,
  clientPortalConfig: PropTypes.func,
  getUserLeftMenuCall: PropTypes.func,
  getProviderList: PropTypes.func,
  tableData: PropTypes.object,
  getProviderDetailsById: PropTypes.func,
  changeProviderFormMode: PropTypes.func,
  rolePermisstionRequest: PropTypes.func,
  getUserDetailsById: PropTypes.func,
  setUserSearchEmpty: PropTypes.func,
  loader: PropTypes.bool,
  lastCalculationDate: PropTypes.func,
  roles: PropTypes.object,
};

const mapStateToProps = (app) => ({
  userRole: app.userRole,
  userProvider: app.users,
  tableData: app.provideRegistration,
  app,
  userDetails: app.app,
  loader: app.loader,
  genrateOffer: app.genrateOfferPatient,
  payout: app.payout,
  roles: app.roles,
});

export default connect(mapStateToProps, {
  setAppBarName,
  logout,
  getProviderList,
  getProviderDetailsById,
  changeProviderFormMode,
  getUserList,
  changeUserFormMode,
  changeRoleFormMode,
  setUserRole,
  getUserListProvider,
  setLayoutName,
  setOroviderListEmpty,
  resetReducer,
  getPatientDetailsEmail,
  getPatientInfoEmail,
  checkResetPasswordAuthCall,
  emptyPatientSelect,
  setUserIdResetPaassword,
  clientThemeCall,
  clientPortalConfig,
  rolePermisstionRequest,
  getUserDetailsById,
  setUserSearchEmpty,
  getUserLeftMenuCall,
  lastCalculationDate,
})(Admin);
