import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: "700"
      },
      root: {
        padding: "10px",
        fontSize: "13px"
      }
    }
  }
});

export default function calculationTable(props) {
  const { payoutDetails } = props;

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={theme}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Event</TableCell>
              <TableCell align="center">Month</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Processor</TableCell>
              <TableCell align="right"> Initiated By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payoutDetails &&
              payoutDetails.map((row) => (
                <>
                  <TableRow key={row.ID}>
                    <TableCell align="left">Last Calculation</TableCell>
                    <TableCell align="center">{row.ResidualMonth}</TableCell>
                    <TableCell align="center">{row.StartDate}</TableCell>
                    <TableCell align="center">{row.ProcessorName}</TableCell>
                    <TableCell align="right">{row.StartedBy}</TableCell>
                  </TableRow>
                  <TableRow key={row.ID}>
                    <TableCell align="left">Last Frozen</TableCell>
                    <TableCell align="center">{row.FreezedMonthYear}</TableCell>
                    <TableCell align="center">{row.FreezedDateTime}</TableCell>
                    <TableCell align="center">{row.ProcessorName}</TableCell>
                    <TableCell align="right">{row.StartedBy}</TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}
