import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import {
  withStyles,
  makeStyles,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import isEmpty from "lodash/isEmpty";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { Field, Form, Formik } from "formik";
import Input from "@material-ui/core/Input";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Divider } from "@material-ui/core";
// import { getAgentProfileVersionList } from "../../actions/agentPayout.action";
import { getQueryParams } from "../../utils/common";
import tableStyle from "../../components/CustomizeTable/style";
import CoustomButton from "../../../../components/CustomButtons/Button";
import moment from "moment";
import { exportToCSV } from "../../utils/common";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import { TablePageData } from "../../utils/constants";
import noDataImage from "../../../../assets/img/noDataImage.png";
import { connect } from "react-redux";
import { fetchMerchantProfileHistory } from "../../actions/merchantMapping.action";
import SearchedChips from "../../components/MerchantSearchComp/SearchChips";
import SearchComponent from "../../components/MerchantSearchComp/SearchFields";
import FilterFields from "./merchantHistoryfilterFields";
import { fetchAgentList } from "../../actions/agentPayout.action";
import { fetchProcessorList } from "../../actions/pricingSchedule.action";
import { fetchProfileRepCodeList } from "../../actions/merchantMapping.action";
import get from "lodash/get";

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});
const useBtnStyles = makeStyles(tableStyle);
const theme = createTheme({
  overrides: {
    MuiTypography: {
      body: {
        fontSize: "14px"
      }
    }
  },
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff"
    }
  }
});

const headCellsItems = [
  {
    id: "ProcessorName",
    label: "Processor",
    isSort: true,
    sortProperty: "ProcessorName",
    actionsCellStyle: "center"
  },
  {
    id: "MID",
    label: "MID",
    isSort: true,
    sortProperty: "MID",
    actionsCellStyle: "center"
  },
  {
    id: "AgentName",
    label: "Agent Name",
    isSort: true,
    sortProperty: "AgentName",
    actionsCellStyle: "center"
  },
  {
    id: "RepCode",
    label: "RepCode",
    isSort: true,
    sortProperty: "RepCode",
    actionsCellStyle: "center"
  },
  {
    id: "StartDate",
    label: "From",
    isSort: true,
    sortProperty: "StartDate",
    actionsCellStyle: "center"
  },
  {
    id: "EndDate",
    label: "To",
    isSort: true,
    sortProperty: "EndDate",
    actionsCellStyle: "center"
  },
  {
    id: "CurrentMapping",
    label: "Current Mapping",
    isSort: true,
    sortProperty: "CurrentMapping",
    actionsCellStyle: "center"
  },
  {
    id: "UsedInLastCalc",
    label: "Last Calculation",
    isSort: true,
    sortProperty: "UsedInLastCalc",
    actionsCellStyle: "center"
  }
];

const labelName = {
  processorId: "Processor",
  profileId: "Rep Code",
  userId: "Agent Name",
  searchDbaMID: "DBA Name / MID"
};

const MappingHistory = ({
  fetchMerchantProfileHistory,
  fetchAgentList,
  fetchProcessorList,
  fetchProfileRepCodeList,
  users
}) => {
  const classes = useStyles();
  const btnClasses = useBtnStyles();
  const CALCULATION_ID = getQueryParams("mid");
  const URL_FLAG = CALCULATION_ID;
  const EditFlag = getQueryParams("mid");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [select, setSelected] = useState("");
  const [versionsList, setVersionsList] = useState([]);
  const history = useHistory();
  const [initialCall, setInitialCall] = React.useState(true);
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [orderBy, setOrderBy] = React.useState("StartDate");
  const [order, setOrder] = React.useState("desc");
  const [page, setPage] = React.useState(1);
  const [searchBar, setSearchBar] = React.useState(false);
  const [repCodeList, setRepCodeList] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [merchantMappingHistoryList, setMerchantMappingHistoryList] =
    React.useState([]);
  const [profiles, setProfiles] = React.useState([]);
  const [processorList, setProcessorList] = React.useState([]);
  const [agentsList, setAgentsList] = React.useState([]);
  const PROCESSOR_ID = getQueryParams("processorId");
  const PROFILE_ID = getQueryParams("profileId");
  const USER_ID = getQueryParams("userId");
  const searchInitialData = {
    processorId: PROCESSOR_ID || "",
    profileId: PROFILE_ID || "",
    userId: USER_ID || "",
    searchDbaMID: URL_FLAG || ""
  };
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [merchantList, setMerchantList] = React.useState([]);
  const [chipValue, setChipValue] = React.useState({ ...searchInitialData });

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleSubmitSearch = (values) => {
    setSearchValues(values);
    setChipValue(searchValues);
    handleSearchClose();
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    handleSearchClose();
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const findAgentName = (id) => {
    if (!isEmpty(agentsList)) {
      const name = agentsList.find((x) => x.UserID == id);
      return name ? name.AgentName.split("[")[0] : id;
    }
  };

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorShortName : id;
    }
  };

  const findProfileName = (id) => {
    if (!isEmpty(repCodeList)) {
      const name = repCodeList.find((x) => x.ProfileID == id);
      return name ? name.RepCode : id;
    }
  };

  const profileName = React.useMemo(
    () => findProfileName(searchValues.profileId),
    [searchValues.profileId, repCodeList]
  );

  const processorName = React.useMemo(
    () => findProcessorName(searchValues.processorId),
    [searchValues.processorId, processorList]
  );

  const agent_Name = React.useMemo(
    () => findAgentName(searchValues.userId),
    [searchValues.userId, agentsList]
  );

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getAgentList = (agentName = "") => {
    fetchAgentList(
      (data) => {
        if (data.status || data.status === "success") {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: 919863231596669598,
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      { search: agentName }
    );
  };

  const merchantMappingHistoryListCall = function () {
    const filterData = {
      searchDbaMID: URL_FLAG,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page,
      ...searchValues
    };

    fetchMerchantProfileHistory(
      { ...filterData },
      (res) => {
        if (res.status == "success") {
          setMerchantMappingHistoryList(res);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(res.totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < res.totalCount
                ? page * rowsPerPage
                : res.totalCount,
            total: res.totalCount
          });
        }
      },
      (err) => {
        enqueueSnackbar("An error occurred.", {
          variant: "error"
        });
      }
    );
  };

  const handleBack = () => {
    history.push(`/admin/MerchantMapping`);
  };

  React.useState(() => {
    merchantMappingHistoryListCall();
  }, []);

  React.useEffect(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
    if (isEmpty(agentsList)) {
      getAgentList();
    }
    // getMerchantsListAsync();
  }, []);

  const handleDeleteChip = (chip) => {
    if (chipValue) {
      const temp = { ...searchValues };

      temp[chip] = "";
      setSearchValues({ ...temp });
      setChipValue({ ...temp });
    }
  };

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = (props) => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    fetchMerchantProfileHistory(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => ({
              "Processor Name": row.ProcessorName,
              MID: row.MID,
              "Agent Name": row.AgentName,
              RepCode: row.RepCode,
              From: row.StartDate,
              To: row.EndDate,
              "Current Mapping": row.CurrentMapping,
              "Last Calculation": row.UsedInLastCalc
            }));
            exportToCSV(
              mappedLogs,
              "Merchant Mapping History List",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getAgentProfileRepCodeList = (payload = {}) => {
    fetchProfileRepCodeList(
      {
        userId: !isEmpty(payload)
          ? payload.userId || ""
          : searchValues.userId || "",
        processorId: !isEmpty(payload)
          ? payload.processorId || ""
          : searchValues.processorId || ""
      },
      (data) => {
        if (data.status) {
          if (isEmpty(payload)) {
            setRepCodeList(data.data);
          } else {
            setProfiles(data.data);
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  React.useEffect(() => {
    if (searchValues.processorId || searchValues.userId) {
      getAgentProfileRepCodeList();
    }
  }, [searchValues.userId, searchValues.processorId]);

  React.useEffect(() => {
    merchantMappingHistoryListCall();
  }, [orderBy, order, page, rowsPerPage]);

  React.useEffect(() => {
    const searchDbaMIDName = {
      ...searchValues
    };
    if (searchDbaMIDName.searchDbaMID == "") {
      history.push(`/admin/MerchantMapping/history`);
    }
    if (page === 1) {
      merchantMappingHistoryListCall();
    }
    setPage(1);
  }, [chipValue]);

  return (
    //   <Paper style={{ padding: "2rem" }}>
    //   <ThemeProvider theme={theme}>
    //     <Grid
    //       container
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-between",
    //         padding: "0px 1rem",
    //         marginTop: "1rem",
    //       }}
    //     >
    //       <Grid item>
    //         <Box>
    //           <Box
    //             style={{ display: "flex", alignItems: "center", gap: "1rem" }}
    //           >
    //             <Typography>
    //               <b style={{ fontWeight: "bold" }}>
    //                 {" "}
    //                 Agent Name / Repcode:{" "}
    //               </b>
    //             </Typography>
    //             <Typography>
    //               {/* {selectedUser.AgentName} / [{splitList.RepCode}] */}
    //             </Typography>
    //           </Box>
    //           <Box
    //             style={{ display: "flex", alignItems: "center", gap: "1rem" }}
    //           >
    //             <Typography>
    //               <b style={{ fontWeight: "bold" }}>Processor: </b>
    //             </Typography>
    //             {/* <Typography>{splitList.ProcessorName}</Typography> */}
    //           </Box>
    //           <Box
    //             style={{ display: "flex", alignItems: "center", gap: "1rem" }}
    //           >
    //             <Typography>
    //               <b style={{ fontWeight: "bold" }}>Last Mapping: </b>
    //             </Typography>
    //             {/* <Typography>{splitList.Period}</Typography> */}
    //           </Box>
    //         </Box>
    //       </Grid>
    //       <Grid item>
    //         <Button
    //           variant="contained"
    //           className={btnClasses.clearButton}
    //           onClick={handleBack}
    //         >
    //           Back
    //         </Button>
    //       </Grid>
    //     </Grid>
    //     <Grid item style={{ margin: "1.5rem 0px" }}>
    //       <Typography
    //         style={{
    //           display: "flex",
    //           justifyContent: "end",
    //           marginRight: "50px",
    //           color: "#2ba992",
    //         }}
    //       >
    //         {/* # of Records: {versionsList && versionsList.length} */}
    //       </Typography>
    //       <Divider />
    //     </Grid>
    //   </ThemeProvider>
    // </Paper>
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        <Grid
          container
          style={{ padding: "12px" }}
          className={classes.searchContainer}
        >
          <Grid
            item
            className={classes.margin}
            style={{
              width: "100%"
            }}
          >
            <div
              className={classes.searchWrapper}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "nowrap"
              }}
            >
              <div style={{ width: "50%" }}>
                <Button
                  variant="contained"
                  className={btnClasses.clearButton}
                  onClick={handleBack}
                >
                  Back
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  alignContent: "center",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  minWidth: "533px"
                }}
              >
                <SearchComponent
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  width="100%"
                  Fields={() => (
                    <FilterFields
                      handleSearchClose={() => handleSearchClose()}
                      searchValues={searchValues}
                      setSearchValues={setSearchValues}
                      agentsList={agentsList}
                      searchDbaMID={URL_FLAG}
                      processorList={processorList}
                      repCodeList={repCodeList}
                      getAgentList={getAgentList}
                      handleSubmitSearch={(data) => handleSubmitSearch(data)}
                      handleClearSearch={handleClearSearch}
                      allmerchantList={merchantList}
                    />
                  )}
                />
                <CoustomButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={exportListData}
                  variant="contained"
                  // color="primary"
                  className={classes.buttonStyle}
                >
                  Export
                </CoustomButton>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item>
          <SearchedChips
            handleDeleteChip={handleDeleteChip}
            searchValues={chipValue}
            labelName={labelName}
            agentName={agent_Name}
            processorName={processorName}
            profileName={profileName}
          />
        </Grid>
        <CustomTable
          page={page}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          data={
            !isEmpty(merchantMappingHistoryList) &&
            merchantMappingHistoryList.data
          }
          pageDetails={pageDetails}
          headCells={headCellsItems}
          isCallInitialization={initialCall}
          selected={[]}
          setSelected={() => {}}
          isSelection={false}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={
            !isEmpty(merchantMappingHistoryList) &&
            merchantMappingHistoryList.totalCount
          }
        >
          {!isEmpty(merchantMappingHistoryList) ? (
            merchantMappingHistoryList.data.map((row) => {
              return (
                <TableRow className={classes.cellHeadSign} key={row.ID}>
                  <TableCell className={classes.cellText} align="center">
                    {row.ProcessorName}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.MID}
                    <br />
                    {URL_FLAG && (
                      <span style={{ color: "#0093c9", fontSize: "12px" }}>
                        {row.DBAName}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.AgentName}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.RepCode}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.StartDate}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.EndDate}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.CurrentMapping}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.UsedInLastCalc}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell className="no-data" colSpan={12}>
                <img src={noDataImage} alt="" className={classes.noDataImage} />
              </TableCell>
            </TableRow>
          )}
        </CustomTable>
      </TableContainer>
    </>
  );
};

MappingHistory.propTypes = {
  selectedUser: PropTypes.object,
  listData: PropTypes.object,
  addSplitFlag: PropTypes.bool,
  setAddSplitFlag: PropTypes.bool,
  shareType: PropTypes.array,
  payeeList: PropTypes.array,
  getPayerList: PropTypes.func,
  getPayeeList: PropTypes.func,
  payerList: PropTypes.array,
  psList: PropTypes.array,
  getPricingScheduleList: PropTypes.func,
  onAddSplitProfile: PropTypes.func,
  onChangeAgentPayoutStatus: PropTypes.func,
  onRemoveAgentMultiSplit: PropTypes.func,
  editProfileID: PropTypes.any,
  handleBack: PropTypes.func,
  fetchAgentList: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  fetchProfileRepCodeList: PropTypes.func,
  users: PropTypes.object
};

MappingHistory.propTypes = {
  fetchMerchantProfileHistory: PropTypes.func
};

MappingHistory.defaultProps = {
  fetchMerchantProfileHistory: () => {},
  fetchAgentList: () => {},
  fetchProcessorList: () => {},
  fetchProfileRepCodeList: () => {}
};

const mapStateToProps = (app) => ({
  users: app,
  tableData: app.users
});

export default connect(mapStateToProps, {
  fetchMerchantProfileHistory,
  fetchAgentList,
  fetchProcessorList,
  fetchProfileRepCodeList
})(MappingHistory);
