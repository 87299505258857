import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TodayIcon from "@material-ui/icons/Today";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "../../../../assets/jss/material-dashboard-react/components/businessForm";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(styles);

function SimpleDialog({
  setSearchValues,
  handleSubmitSearch,
  handleClearSearch,
  searchValues,
  processorList
}) {
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    if (type == "fromMonth" || type == "toMonth") {
      allState[type] = moment(value).startOf("month").format("YYYY-MM-DD");
    } else {
      allState[type] = value;
    }
    setSearchValues({ ...allState });
  };

  const dateNow = new Date();
  const maxDate = new Date().setMonth(dateNow.getMonth() - 1);
  const minDate = new Date().setMonth(dateNow.getMonth() - 12);

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Processor"
            value={searchValues.processor}
            onChange={(e) => onChange(e.target.value, "processor")}
            name="processor"
            id="processor"
            fullWidth
            select
          >
            {processorList.map((res) => (
              <MenuItem
                className={classes.dialogBoxDropDown}
                value={res.ProcessorID}
              >
                {res.ProcessorName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="MID"
            value={searchValues.mid}
            autoFocus={searchValues.mid != ""}
            onChange={(e) => onChange(e.target.value, "mid")}
            name="mid"
            id="mid"
            fullWidth
          ></TextField>
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Status"
            value={searchValues.status}
            onChange={(e) => onChange(e.target.value, "status")}
            name="status"
            id="status"
            select
            fullWidth
          >
            <MenuItem className={classes.dialogBoxDropDown} value="5">
              All
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="3">
              Approved
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="4">
              Declined
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="1">
              Pending
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="2">
              Under Review
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={6} lg={6}>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                views={["month", "year"]}
                id="fromMonth"
                name="fromMonth"
                maxDate={searchValues.toMonth || new Date()}
                minDateMessage=""
                invalidDateMessage=""
                maxDateMessage=""
                inputVariant="standard"
                format="MM/yyyy"
                placeholder="MM/YYYY"
                label="Start Month"
                value={moment(searchValues.fromMonth).format("YYYY-MM-DD")}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                onChange={(value) => {
                  onChange(value, "fromMonth");
                }}
                KeyboardButtonProps={{
                  size: "small"
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={6}>
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                views={["month", "year"]}
                id="toMonth"
                name="toMonth"
                maxDate={moment(new Date()).endOf("month")}
                minDate={searchValues.fromMonth}
                minDateMessage=""
                invalidDateMessage=""
                maxDateMessage=""
                label="End Month"
                inputVariant="standard"
                format="MM/yyyy"
                placeholder="MM/YYYY"
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                value={moment(searchValues.toMonth).format("YYYY-MM-DD")}
                onChange={(value) => {
                  onChange(value, "toMonth");
                }}
                KeyboardButtonProps={{
                  size: "small"
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.margin}
        style={{ justifyContent: "end", marginTop: "5%" }}
      >
        <Button
          variant="contained"
          className={classes.clearButton}
          onClick={() => handleClearSearch()}
        >
          clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonStyle}
          onClick={() => handleSubmitSearch()}
        >
          Search
        </Button>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  setSearchValues: PropTypes.func,
  searchValues: PropTypes.object,
  handleSubmitSearch: PropTypes.func,
  handleClearSearch: PropTypes.func,
  processorList: PropTypes.array,
  roles: PropTypes.object,
};

const mapStateToProps = (app) => ({
  roles: app.roles,
});

export default connect(mapStateToProps, {})(SimpleDialog);
