import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import styles from "../../../../assets/jss/material-dashboard-react/components/businessForm";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const theme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      backgroundColor: "#2ba992",
      toolbar: {
        backgroundColor: "#3ab499"
      }
    }
  }
});

function SimpleDialog({
  setSearchValues,
  handleSubmitSearch,
  handleClearSearch,
  searchValues,
  handleSearchClose,
  pricingScheduleList,
  agentsList
}) {
  const classes = useStyles();
  const defaultProps = {
    options: pricingScheduleList
  };
  const onChange = (value, type) => {
    const allState = { ...searchValues };
    if (type == "fromMonth" || type == "toMonth") {
      allState[type] = moment(value).format("YYYY-MM");
    } else {
      allState[type] = value;
    }
    setSearchValues(allState);
  };

  const onFormSubmit = () => {
    const allState = { ...searchValues };
    handleSubmitSearch(allState);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid style={{ width: "100%" }} item xs={12}>
            <Autocomplete
              {...defaultProps}
              value={
                pricingScheduleList &&
                pricingScheduleList.find((item) =>
                  item.PS_ID == searchValues.psId ? searchValues.psId : ""
                )
              }
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              autoFocus={searchValues.psId != "" && true}
              onChange={(e, value) => {
                if (value) {
                  onChange(value.PS_ID, "psId");
                } else {
                  onChange("", "psId");
                }
              }}
              options={pricingScheduleList.sort((a, b) =>
                a.PS_Name.localeCompare(b.PS_Name)
              )}
              clearOnBlur
              clearOnEscape
              getOptionLabel={(option) => option.PS_Name}
              getOptionValue={(option) => option.PS_ID}
              name="psId"
              style={{ width: "100%" }}
              id="psId"
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ fontSize: "82%" }}
                  name="psId"
                  id="psId"
                  label="Pricing Schedule"
                />
              )}
            ></Autocomplete>
          </Grid>
          <Grid
            container
            className={classes.margin}
            style={{ marginBottom: "3%" }}
          >
            <Grid item xs>
              <Autocomplete
                name="userId"
                id="userId"
                style={{
                  fontSize: "14px",
                  "&.focused": {
                    // color: theme.palette.primary.main,
                    fontSize: "14px",
                    fontStyle: "Helvetica, Arial,sans-serif"
                  }
                }}
                value={
                  agentsList &&
                  agentsList.find((option) =>
                    option.UserID === searchValues.userId
                      ? searchValues.userId
                      : ""
                  )
                }
                autoHighlight
                fullWidth
                onChange={(e, value) => {
                  if (value) {
                    onChange(value.UserID, "userId");
                  } else {
                    onChange("", "userId");
                  }
                }}
                selectOnFocus
                options={agentsList || []}
                getOptionLabel={(option) => option.AgentName || ""}
                getOptionValue={(option) => option.UserID || ""}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    name="userId"
                    id="userId"
                    required
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      },
                      required: true
                    }}
                    {...params}
                    label="Updated By"
                  />
                )}
                renderOption={(option) => (
                  <>
                    {option.AgentName && (
                      <span
                        className={classes.dialogBoxDropDown}
                        style={{
                          width: "100%",
                          margin: "-5px -16px",
                          padding: "4px 16px"
                        }}
                      >
                        {option?.AgentName}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    views={["month", "year"]}
                    id="fromMonth"
                    name="fromMonth"
                    maxDate={new Date()}
                    minDateMessage=""
                    invalidDateMessage=""
                    maxDateMessage=""
                    inputVariant="standard"
                    format="MM/yyyy"
                    placeholder="MM/YYYY"
                    label="Start Month"
                    value={moment(searchValues.fromMonth).format("YYYY-MM-DD")}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    onChange={(value) => {
                      onChange(value, "fromMonth");
                    }}
                    KeyboardButtonProps={{
                      size: "small"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={6}>
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    views={["month", "year"]}
                    id="toMonth"
                    name="toMonth"
                    maxDate={moment(new Date()).endOf("month")}
                    minDate={searchValues.fromMonth}
                    minDateMessage=""
                    invalidDateMessage=""
                    maxDateMessage=""
                    label="End Month"
                    inputVariant="standard"
                    format="MM/yyyy"
                    placeholder="MM/YYYY"
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    value={moment(searchValues.toMonth).format("YYYY-MM-DD")}
                    onChange={(value) => {
                      onChange(value, "toMonth");
                    }}
                    KeyboardButtonProps={{
                      size: "small"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end", marginTop: "5%" }}
        >
          <Button
            cancel
            variant="contained"
            className={classes.clearButton}
            onClick={() => handleClearSearch()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={() => onFormSubmit()}
          >
            Search
          </Button>
        </Grid>
      </ThemeProvider>
    </>
  );
}

SimpleDialog.propTypes = {
  setSearchValues: PropTypes.func,
  searchValues: PropTypes.object,
  pricingScheduleList: PropTypes.array,
  handleSubmitSearch: PropTypes.func,
  handleClearSearch: PropTypes.func,
  handleSearchClose: PropTypes.func,
  agentsList: PropTypes.array
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(SimpleDialog);
