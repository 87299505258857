import React, { useEffect } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
// import Search from "@material-ui/icons/Search";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import IdleTimer from "react-idle-timer/dist/modern";
import Button from "components/CustomButtons/Button";
// @material-ui/icons
import PersonIcon from "@material-ui/icons/Person";
// core components
import { connect } from "react-redux";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle";
import MultipleSelect from "../MultiSelect/Index";
import SessionOutModal from "views/Login/SessionOutModal";
import { Box, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  getUserDetailsById,
  getUserProfileImage
} from "../../Redux/Actions/UserRegistration";

const useStyles = makeStyles(styles);

function AdminNavbarLinks(props) {
  const classes = useStyles();
  const MenuData = JSON.parse(localStorage.getItem("menu") || "{}");
  const [accessible, setAccessible] = React.useState(
    !localStorage.getItem("sendApiClientId")
      ? ""
      : JSON.parse(localStorage.getItem("sendApiClientId"))
  );
  const [openProfile, setOpenProfile] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [remaining, setRemainingTime] = React.useState(0);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const idleTimerRef = React.createRef();
  const idleTime = parseInt(process.env.REACT_APP_IDEAL_TIME_MINUTES);
  const timeOutLimit = 1000 * 60 * 15;

  const closeModal = () => {
    setOpenModal(false);
    if (idleTimerRef.current) {
      idleTimerRef.current.reset();
    }
  };

  const onActive = () => {
    setOpenModal(false);
    if (idleTimerRef.current) {
      idleTimerRef.current.resume();
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      onIdle();
    }, 1000);

    return () => clearInterval(interval);
  });

  const logoutFun = () => {
    if (window.location.pathname === "/admin/user") {
      document.getElementById("saveDraftBusinessForm").click();
    }
    props.setState();
    localStorage.setItem(
      "idealPath",
      JSON.stringify({
        path: window.location.pathname,
        landingPageName: props.app
      })
    );
    props.logout();
    localStorage.clear();
    setAccessible("");
    props.setOroviderListEmpty();
    props.history.push("/sessionOut");
    props.resetReducer();
  };
  const onIdle = () => {
    if (idleTimerRef.current) {
      const remainingTime = Math.ceil(
        idleTimerRef.current.getRemainingTime() / 1000
      );
      setRemainingTime(remainingTime);

      if (remainingTime > 0 && remainingTime <= 120) {
        setOpenModal(true);
      } else if (remainingTime > 120) {
        setOpenModal(false);
      } else if (remainingTime == 0) {
        logoutFun();
      }
    }
  };

  const profileTypeCheck =
    props.userRole.userList &&
    props.userRole.userList.Role_Permission_Data &&
    props.userRole.userList.Role_Permission_Data.Profile_Code;
  React.useEffect(() => {
    if (!props.userProvider.userProvider && !props.userProvider.loading) {
      props.getUserListProvider();
    } else if (
      accessible === "" &&
      props.userProvider.userProvider &&
      !props.userProvider.loading &&
      !localStorage.getItem("sendApiClientId")
    ) {
      const value = [];
      props.userProvider.userProvider.data.forEach((v) => {
        value.push(v.Porvider_Id);
      });
      localStorage.setItem("sendApiClientId", JSON.stringify(value));
      setAccessible(value);
    }
  }, [props]);
  const handleCloseProfile = () => {
    props.setState();
    props.logout();
    localStorage.clear();
    localStorage.clear();
    props.setCallRolePermission();
    setAccessible("");
    props.setOroviderListEmpty();
    props.resetReducer();
    props.history.push("/");
  };

  const openUserProfile = () => {
    props.history.push("Profile");
    setOpenProfile(null);
  };

  React.useEffect(() => {
    if (!props.userProvider.profileImage) {
      const userId = localStorage.getItem("userId");
      props.getUserProfileImage(userId);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end"
      }}
    >
      <Box
        onClick={handleClickProfile}
        style={{
          cursor: "pointer",
          display: "flex",
          borderRadius: "24px",
          backgroundColor: "#EFF4FF",
          padding: "8px 12px"
        }}
      >
        <Box style={{ padding: "8px", display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              fontSize: "16px",
              fontWight: "600",
              lineHeight: "22px",
              color: "#333333"
            }}
          >
            {MenuData?.Role_Permission_Data?.UserName}{" "}
          </Typography>
        </Box>
        <Box style={{ padding: "8px", display: "flex", alignItems: "center" }}>
          <KeyboardArrowDownIcon />
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {profileTypeCheck === "SA" && (
            <>
              {props.userProvider.profileImage &&
              props.userProvider.profileImage.Users_Data[0]
                .User_Profile_Image != "" ? (
                <img
                  alt="UserImage"
                  style={{
                    borderRadius: "50%",
                    height: "40px",
                    width: "40px"
                  }}
                  src={
                    props.userProvider.profileImage &&
                    props.userProvider.profileImage.Users_Data[0]
                      .User_Profile_Image.File_Path
                  }
                />
              ) : (
                <PersonIcon />
              )}
            </>
          )}
        </Box>
      </Box>

      <Poppers
        open={Boolean(openProfile)}
        anchorEl={openProfile}
        transition
        disablePortal
        placement="bottom-end"
        className={`${classNames({ [classes.popperClose]: !openProfile })} ${
          classes.popperNav
        }`}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="profile-menu-list-grow"
            style={{
              transformOrigin: "right"
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setOpenProfile(null);
                }}
              >
                <MenuList role="menu">
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    onClick={openUserProfile}
                  >
                    My Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      props.history.push("MyHierarchy");
                      setOpenProfile(null);
                    }}
                    className={classes.dialogBoxDropDown}
                  >
                    View Hierarchy
                  </MenuItem>
                  <MenuItem
                    onClick={handleCloseProfile}
                    className={classes.dialogBoxDropDown}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Poppers>
      <IdleTimer
        ref={idleTimerRef}
        startOnMount={true}
        element={document}
        onIdle={() => onIdle()}
        onAction={closeModal}
        debounce={500}
        timeout={timeOutLimit}
        onActive={onActive}
      />
      <SessionOutModal
        open={openModal}
        logout={onIdle}
        close={closeModal}
        remaining={remaining}
      />
    </div>
  );
}

AdminNavbarLinks.propTypes = {
  userProvider: PropTypes.object,
  history: PropTypes.object,
  getUserListProvider: PropTypes.func,
  userRole: PropTypes.object,
  setState: PropTypes.func,
  logout: PropTypes.func,
  setOroviderListEmpty: PropTypes.func,
  resetReducer: PropTypes.func,
  menuData: PropTypes.object,
  app: PropTypes.any,
  setCallRolePermission: PropTypes.func,
  getUserProfileImage: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app
});
export default connect(mapStateToProps, {
  getUserProfileImage
})(AdminNavbarLinks);
