import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import isEmpty from "lodash/isEmpty";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import styles from "../../../../assets/jss/material-dashboard-react/components/businessForm";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const theme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      backgroundColor: "#2ba992",
      toolbar: {
        backgroundColor: "#3ab499"
      }
    }
  }
});

function SimpleDialog({
  setSearchValues,
  handleSubmitSearch,
  handleClearSearch,
  searchValues,
  processorList,
  handleSearchClose
}) {
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...searchValues };
    allState[type] = value;
    setSearchValues(allState);
  };

  const onFormSubmit = () => {
    const allState = { ...searchValues };
    handleSubmitSearch(allState);
  };


  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid style={{ width: "100%" }} item xs={12}>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              label="Pricing Schdule"
              value={searchValues.pricingName}
              autoFocus={searchValues.pricingName != "" && true}
              onChange={(e) => onChange(e.target.value, "pricingName")}
              name="pricingName"
              style={{ width: "100%" }}
              fullWidth
              id="pricingName"
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              label="Processor"
              value={searchValues.processorId}
              onChange={(e) => onChange(e.target.value, "processorId")}
              name="processorId"
              id="processorId"
              select
              fullWidth
            >
              {processorList.map((processor) => (
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={processor.ProcessorID}
                >
                  {processor.ProcessorName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "1%" }}
        >
          <Grid style={{ width: "100%" }} item xs={12}>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              SelectProps={{
                MenuProps: { disablePortal: false }
              }}
              label="Status"
              value={searchValues.Status}
              onChange={(e) => onChange(e.target.value, "Status")}
              name="Status"
              style={{ width: "100%" }}
              fullWidth
              id="Status"
              select
            >
              <MenuItem className={classes.dialogBoxDropDown} value="1">
                Active
              </MenuItem>
              <MenuItem className={classes.dialogBoxDropDown} value="0">
                Inactive
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end", marginTop: "5%" }}
        >
          <Button
            cancel
            variant="contained"
            className={classes.clearButton}
            onClick={() => handleClearSearch()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={() => onFormSubmit()}
          >
            Search
          </Button>
        </Grid>
      </ThemeProvider>
    </>
  );
}

SimpleDialog.propTypes = {
  setSearchValues: PropTypes.func,
  merchantList: PropTypes.array,
  searchValues: PropTypes.object,
  agentsList: PropTypes.array,
  processorList: PropTypes.array,
  adjustmentNameList: PropTypes.array,
  handleSubmitSearch: PropTypes.func,
  handleClearSearch: PropTypes.func,
  getAdjustmentListFun: PropTypes.func,
  getFilteredAdjustments: PropTypes.func
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(SimpleDialog);
