import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import CoustomButton from "../../../../components/CustomButtons/Button";
import tableStyle from "../../components/CustomizeTable/style";
import { exportToCSVPricing } from "../../utils/common";
import { exportToXLS } from "../../../../Utils/commonUtils";
import { TablePageData } from "../../utils/constants";
import {
  fetchPricingScheduleList,
  fetchProcessorList
} from "../../actions/pricingSchedule.action";
import SearchComponent from "../../components/PricingScheduleSearch/SearchFields";
import SearchedChips from "../../components/PricingScheduleSearch/SearchChips";
import FilterFields from "./filterFields";
import ImportButton from "../../../../components/DialogBox/ImportFileDialog";
import {
  PSImportFile,
  PSNewItemsFile,
  PSUpdateFile
} from "../../../../actions/Operations";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Button from "@material-ui/core/Button";
import { exportToPDF } from "modules/reports/utils/common";
import { exportNumberToDashFormat } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ExportIcon } from "../../../../assets/svgIcons/ExportIcon.svg";
import { ReactComponent as AddIconSvg } from "../../../../assets/svgIcons/AddIconSvg.svg";
import {
  actionPermission,
  permissionCheck
} from "../../../../Utils/commonUtils";
import AppLoader from "components/AppLoader/Index";
import { ReactComponent as ActivityIcon } from "../../../../assets/svgIcons/activityHistoryIcon.svg";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px"
  },
  tooltipPlacementTop: {
    margin: "4px 0"
  }
}))(Tooltip);

const headCellsItems = [
  {
    id: "PS_Name",
    label: "Name",
    isSort: true,
    sortProperty: "PS_Name"
  },
  {
    id: "Processor",
    label: "Processor",
    isSort: true,
    sortProperty: "Processor"
  },
  {
    id: "St_Date",
    label: "Start Date",
    isSort: true,
    sortProperty: "St_Date"
  },
  {
    id: "Profile_Linked",
    label: "Users Linked",
    isSort: true,
    sortProperty: "Profile_Linked"
  },
  {
    id: "Profit_Percent",
    label: "Profit %",
    isSort: true,
    sortProperty: "Profit_Percent"
  },
  {
    id: "Loss_Percent",
    label: "Loss %",
    isSort: true,
    sortProperty: "Loss_Percent"
  },
  {
    id: "CreatedDt",
    label: "Creation Date",
    isSort: true,
    sortProperty: "CreatedDt"
  },
  {
    id: "Updated_Dt",
    label: "Updation Date",
    isSort: true,
    sortProperty: "Updated_Dt"
  },
  {
    id: "UpdatedBy_Name",
    label: "Updated By",
    isSort: true,
    sortProperty: "UpdatedBy_Name"
  },
  {
    id: "PS_Status",
    label: "Status",
    isSort: true,
    sortProperty: "PS_Status"
  }
  // {
  //   id: "action",
  //   label: "",
  //   isSort: false,
  //   sortProperty: "action"
  // }
];

const labelName = {
  processorId: "Processor",
  pricingName: "Pricing schedule",
  Status: "Status"
};

function PricingSchedule({
  fetchPricingScheduleList,
  fetchProcessorList,
  users,
  PSImportFile,
  PSNewItemsFile,
  PSUpdateFile
}) {
  const searchInitialData = {
    pricingName: "",
    processorId: "",
    Status: ""
  };
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dataParameter = "PS_ID";
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState("");
  const [initialCall, setInitialCall] = React.useState(true);
  const [order, setOrder] = React.useState("desc");
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [orderBy, setOrderBy] = React.useState("Updated_Dt");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pricingScheduleList, setPricingScheduleList] = React.useState([]);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [chipValue, setChipValue] = React.useState([]);
  const [processorList, setProcessorList] = React.useState([]);
  const [searchBar, setSearchBar] = React.useState(false);
  // import file modal
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [errorModal, setErrorModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [successData, setSuccessData] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [importType, setImportType] = React.useState(false);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [initialCallLoading, setInitialCallLoading] = React.useState(true);
  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const handleModalClose = (event) => {
    setIsModalOpen(false);
  };

  const handleModalOpen = (event) => {
    setIsModalOpen(true);
    setSelectedFile(null);
  };

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorName : id;
    }
  };
  const processorName = React.useMemo(
    () => findProcessorName(searchValues.processorId),
    [searchValues.processorId, processorList]
  );

  const handleCapture = ({ target }) => {
    var file = document.getElementById("fileUpload");
    if (/\.(xls|xlsx|csv)$/i.test(file.files[0].name) === false) {
      setErrorModal(true);
      setSelectedFile(null);
      setErrorMsg(false);
      setSuccessData(false);
    } else {
      setSelectedFile(target.files[0]);
      setErrorModal(false);
    }
  };

  const handleSubmit = (type) => {
    setImportType(type);
    const formData = new FormData();
    formData.append("importFile", selectedFile);
    if (type == 1) {
      // PSNewItemsFile(
      //   formData,
      //   (res) => {
      // handleModalClose();
      //     // if (res.data.status == true) {
      //     //   setSuccessModal(true);
      //     //   setSuccessData(res.data);
      //     // } else {
      //     //   setErrorModal(true);
      //     //   setErrorMsg(res.data.message);
      //     // }
      //   },
      //   (err) => {
      //  handleModalClose();
      //     setErrorModal(true);
      //     setErrorMsg(err.message);
      //   }
      // );
    } else if (type == 2) {
      PSImportFile(
        formData,
        (res) => {
          handleModalClose();
          if (res.data.status == true) {
            setSuccessModal(true);
            setSuccessData(res.data);
            getPricingScheduleList(searchValues);
          } else {
            setErrorModal(true);
            setErrorMsg(res.data.message);
          }
        },
        (err) => {
          handleModalClose();
          setErrorModal(true);
          setErrorMsg(err.message);
        }
      );
    } else if (type == 3) {
      PSUpdateFile(
        formData,
        (res) => {
          console.log("res", res);
          handleModalClose();
          if (res.data.status == true) {
            setSuccessModal(true);
            setSuccessData(res.data);
          } else {
            setErrorModal(true);
            setErrorMsg(res.data.message);
          }
        },
        (err) => {
          handleModalClose();
          setErrorModal(true);
          setErrorMsg(err.message);
        }
      );
    }
  };

  const downloadImportedData = () => {
    const content = get(successData, "data", []);
    let mappedData;
    if (!isEmpty(content)) {
      if (importType == 1) {
      } else if (importType == 2) {
        mappedData = content.map((row) => ({
          "Pricing Schedule": row.pricingSchedule,
          Processor: row.processor,
          Description: row.description,
          "Split Type": row.splitType,
          "Item Name": row.itemName,
          "Rate Type": row.rateType,
          Rate: row.rate,
          "Profit %": row.profit,
          "Loss %": row.loss,
          "Start Date": row.startDate,
          Message: row.message
        }));
      } else if (importType == 3) {
        mappedData = content.map((row) => ({
          "Pricing Schedule": row.pricingSchedule,
          "Item Name": row.itemName,
          "Rate Type": row.rateType,
          Rate: row.rate,
          "Profit %": row.profit,
          "Loss %": row.loss,
          Message: row.message
        }));
      }
      exportToXLS(mappedData, "Pricing Schedule Log Details");
    }
    setSuccessModal(false);
    handleModalClose();
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAddEdit = (type = "", row) => {
    if (type === "edit") {
      history.push(
        `PricingSchedule/add?psId=${row.PS_ID}&processorId=${row.ProcessorID}`
      );
    }
  };

  const showPsActivityHistory = () => {
    history.push("PricingSchedule/activity-history");
  };

  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    fetchPricingScheduleList(
      {
        sortColumn: orderBy,
        sortOrder: order,
        recordPerPage: "",
        pageNo: 1
      },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const mappedLogs = content.map((row) => ({
            Id: row.PS_ID,
            Name: row.PS_Name,
            "Start Date": row.St_Date,
            "Created Date": moment(row.CreatedDt).format("YYYY-MM-DD"),
            "Updated Date": moment(row.Updated_Dt).format("YYYY-MM-DD"),
            "Loss %": exportNumberToDashFormat(row.Loss_Percent),
            "Processor Name": row.ProcessorName,
            "Profile Linked": exportNumberToDashFormat(row.Profile_Linked),
            Description: row.Description,
            Status: row.PS_Status,
            "Split Type": row.Split_Type,
            "Created By": row.CreatedBy_Name,
            "Updated By": row.UpdatedBy_Name
          }));
          exportToCSVPricing(
            mappedLogs,
            "Pricing Schedule Details",
            chipValue,
            exportedBy,
            exportedByFname,
            exportedByLname,
            labelName,
            processorName
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const exportToPDFList = () => {
    fetchPricingScheduleList(
      {
        sortColumn: orderBy,
        sortOrder: order,
        recordPerPage: "",
        pageNo: 1
      },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const mappedLogs = content.map((row) => [
            row.PS_ID,
            row.PS_Name,
            row.St_Date,
            moment(row.CreatedDt).format("YYYY-MM-DD"),
            moment(row.Updated_Dt).format("YYYY-MM-DD"),
            exportNumberToDashFormat(row.Loss_Percent),
            row.ProcessorName,
            exportNumberToDashFormat(row.Profile_Linked),
            row.Description,
            row.PS_Status,
            row.Split_Type,
            row.CreatedBy_Name,
            row.UpdatedBy_Name
          ]);
          const headersName = [
            "Id",
            "Name",
            "Start Date",
            "Created Date",
            "Updated Date",
            "Loss %",
            "Processor Name",
            "Profile Linked",
            "Description",
            "Status",
            "Split Type",
            "Created By",
            "Updated By"
          ];
          exportToPDF(
            headersName,
            mappedLogs,
            "Pricing Schedule Details",
            chipValue,
            exportedBy,
            exportedByFname,
            exportedByLname,
            labelName,
            processorName
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const handleDeleteChip = (chip) => {
    let temp = "";
    if (chipValue) {
      temp = { ...searchValues };
      temp[chip] = "";
      setSearchValues({ ...temp });
      setChipValue({
        ...temp
      });
    }
    getPricingScheduleList({
      ...temp
    });
  };
  const getFilteredPS = (value) => {
    const finalSearchData = {
      ...value,
      pricingName: value.pricingName ? value.pricingName.trim() : ""
    };
    setChipValue({
      ...value
    });
    getPricingScheduleList(finalSearchData);
  };

  const getPricingScheduleList = function (searchValues) {
    fetchPricingScheduleList(
      {
        ...searchValues,
        sortColumn: orderBy,
        sortOrder: order,
        recordPerPage: rowsPerPage,
        pageNo: page
      },
      (records) => {
        setInitialCallLoading(false);
        if (records.status === "success") {
          const content = get(records, "data", []);
          const { totalCount } = records;
          setPricingScheduleList(content);
          setTotalCount(totalCount);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < totalCount ? page * rowsPerPage : totalCount,
            total: totalCount
          });
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  React.useEffect(() => {
    getPricingScheduleList({
      ...searchValues,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page
    });
  }, [orderBy, order, page, rowsPerPage]);

  React.useEffect(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
  }, []);
  const handleSearchOpen = () => {
    setSearchBar(true);
  };
  const handleSearchClose = () => {
    setSearchBar(false);
  };
  const handleSubmitSearch = (values) => {
    setSearchValues(values);
    setChipValue({ searchValues });
    getFilteredPS(searchValues);
    handleSearchClose();
  };
  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    getPricingScheduleList({
      ...searchInitialData,
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage,
      pageNo: page
    });
    handleSearchClose();
  };
  return (
    <>
      {initialCallLoading ? (
        <AppLoader loader={initialCallLoading} />
      ) : (
        <>
          {actionPermission(
            permissionCheck.Category.residual,
            permissionCheck.Page.PricingSchedule,
            permissionCheck.option.view
          ) && (
            <>
              <TableContainer
                className={classes.TableContainer}
                component={Paper}
              >
                {windowWidth && (
                  <Grid container className={classes.searchContainer}>
                    <Grid item className={classes.margin}>
                      <div
                        className={classes.searchWrapper}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          flexWrap: "nowrap",
                          justifyContent: "space-between"
                        }}
                      >
                        <IconsTooltip
                          title="Activity History"
                          placement="right"
                        >
                          <div className={classes.appSvgIcons}>
                            <ActivityIcon
                              onClick={() => showPsActivityHistory()}
                            />
                          </div>
                        </IconsTooltip>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            alignContent: "center",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            minWidth: "600px"
                          }}
                        >
                          <SearchComponent
                            searchBar={searchBar}
                            handleSearchClose={handleSearchClose}
                            handleSearchOpen={handleSearchOpen}
                            width="100%"
                            Fields={() => {
                              return (
                                <FilterFields
                                  handleSearchClose={() => handleSearchClose()}
                                  setSearchValues={setSearchValues}
                                  searchValues={searchValues}
                                  processorList={processorList}
                                  handleSubmitSearch={handleSubmitSearch}
                                  handleClearSearch={handleClearSearch}
                                />
                              );
                            }}
                          />
                          {actionPermission(
                            permissionCheck.Category.residual,
                            permissionCheck.Page.PricingSchedule,
                            permissionCheck.option.import
                          ) && (
                            <ImportButton
                              handleModalClose={handleModalClose}
                              handleModalOpen={handleModalOpen}
                              handleCapture={handleCapture}
                              handlePSSubmit={(type) => handleSubmit(type)}
                              downloadImportedData={downloadImportedData}
                              setSuccessModal={setSuccessModal}
                              successData={successData}
                              successModal={successModal}
                              errorMsg={errorMsg}
                              errorModal={errorModal}
                              setErrorModal={setErrorModal}
                              isModalOpen={isModalOpen}
                              title="Pricing Schedule Import"
                              selectedFile={selectedFile}
                            />
                          )}
                          {actionPermission(
                            permissionCheck.Category.residual,
                            permissionCheck.Page.PricingSchedule,
                            permissionCheck.option.export
                          ) &&
                            (actionPermission(
                              permissionCheck.Category.residual,
                              permissionCheck.Page.PricingSchedule,
                              permissionCheck.option.exportToExcel
                            ) ||
                              actionPermission(
                                permissionCheck.Category.residual,
                                permissionCheck.Page.PricingSchedule,
                                permissionCheck.option.exportToPdf
                              )) && (
                              <IconsTooltip title="Export" placement="top">
                                <div className={classes.appSvgIcons}>
                                  <ExportIcon
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    ref={anchorRef}
                                  />
                                </div>
                              </IconsTooltip>
                            )}
                          <Popper
                            open={openImportFileModal}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                            style={{
                              zIndex: "10"
                            }}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom-start"
                                      ? "left top"
                                      : "left bottom"
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      // autoFocusItem={openImportFileModal}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      {actionPermission(
                                        permissionCheck.Category.residual,
                                        permissionCheck.Page.PricingSchedule,
                                        permissionCheck.option.exportToExcel
                                      ) && (
                                        <MenuItem
                                          onClick={exportListData}
                                          style={{
                                            fontSize: "14px"
                                          }}
                                        >
                                          Export to Excel
                                        </MenuItem>
                                      )}
                                      {actionPermission(
                                        permissionCheck.Category.residual,
                                        permissionCheck.Page.PricingSchedule,
                                        permissionCheck.option.exportToPdf
                                      ) && (
                                        <MenuItem
                                          onClick={exportToPDFList}
                                          style={{
                                            fontSize: "14px"
                                          }}
                                        >
                                          Export to PDF
                                        </MenuItem>
                                      )}
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                          {actionPermission(
                            permissionCheck.Category.residual,
                            permissionCheck.Page.PricingSchedule,
                            permissionCheck.option.add
                          ) && (
                            <IconsTooltip title="Add" placement="top">
                              <div className={classes.appSvgIcons}>
                                <AddIconSvg
                                  onClick={() => {
                                    history.push("PricingSchedule/add");
                                  }}
                                />
                              </div>
                            </IconsTooltip>
                          )}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
                {!windowWidth && (
                  <Grid container className={classes.searchContainer}>
                    <Grid item className={classes.expirtItemGrid}>
                      <SearchComponent
                        searchBar={searchBar}
                        handleSearchClose={handleSearchClose}
                        handleSearchOpen={handleSearchOpen}
                        Fields={() => {
                          return (
                            <FilterFields
                              handleSearchClose={() => handleSearchClose()}
                              setSearchValues={setSearchValues}
                              searchValues={searchValues}
                              processorList={processorList}
                              handleSubmitSearch={handleSubmitSearch}
                              handleClearSearch={handleClearSearch}
                            />
                          );
                        }}
                      />
                      <Grid item className={classes.expirtItemGrid}>
                        <IconsTooltip
                          title="Activity History"
                          placement="right"
                        >
                          <div className={classes.appSvgIcons}>
                            <ActivityIcon
                              onClick={() => showPsActivityHistory()}
                            />
                          </div>
                        </IconsTooltip>
                        <ImportButton
                          handleModalClose={handleModalClose}
                          handleModalOpen={handleModalOpen}
                          handleCapture={handleCapture}
                          handlePSSubmit={(type) => handleSubmit(type)}
                          downloadImportedData={downloadImportedData}
                          setSuccessModal={setSuccessModal}
                          successData={successData}
                          successModal={successModal}
                          errorMsg={errorMsg}
                          errorModal={errorModal}
                          setErrorModal={setErrorModal}
                          isModalOpen={isModalOpen}
                          title="Pricing Schedule Import"
                          selectedFile={selectedFile}
                        />
                        {actionPermission(
                          permissionCheck.Category.residual,
                          permissionCheck.Page.PricingSchedule,
                          permissionCheck.option.export
                        ) &&
                          (actionPermission(
                            permissionCheck.Category.residual,
                            permissionCheck.Page.PricingSchedule,
                            permissionCheck.option.exportToExcel
                          ) ||
                            actionPermission(
                              permissionCheck.Category.residual,
                              permissionCheck.Page.PricingSchedule,
                              permissionCheck.option.exportToPdf
                            )) && (
                            <IconsTooltip title="Export" placement="top">
                              <div className={classes.appSvgIcons}>
                                <ExportIcon
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={handleToggle}
                                  ref={anchorRef}
                                />
                              </div>
                            </IconsTooltip>
                          )}
                        <Popper
                          open={openImportFileModal}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                          style={{
                            zIndex: "10"
                          }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom"
                              }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList
                                    // autoFocusItem={openImportFileModal}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                  >
                                    {actionPermission(
                                      permissionCheck.Category.residual,
                                      permissionCheck.Page.PricingSchedule,
                                      permissionCheck.option.exportToExcel
                                    ) && (
                                      <MenuItem
                                        onClick={exportListData}
                                        style={{
                                          fontSize: "14px"
                                        }}
                                      >
                                        Export to Excel
                                      </MenuItem>
                                    )}
                                    {actionPermission(
                                      permissionCheck.Category.residual,
                                      permissionCheck.Page.PricingSchedule,
                                      permissionCheck.option.exportToPdf
                                    ) && (
                                      <MenuItem
                                        onClick={exportToPDFList}
                                        style={{
                                          fontSize: "14px"
                                        }}
                                      >
                                        Export to PDF
                                      </MenuItem>
                                    )}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                        {actionPermission(
                          permissionCheck.Category.residual,
                          permissionCheck.Page.PricingSchedule,
                          permissionCheck.option.add
                        ) && (
                          <IconsTooltip title="Add" placement="top">
                            <div className={classes.appSvgIcons}>
                              <AddIconSvg
                                onClick={() => {
                                  history.push("PricingSchedule/add");
                                }}
                              />
                            </div>
                          </IconsTooltip>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <SearchedChips
                    handleDeleteChip={handleDeleteChip}
                    searchValues={chipValue}
                    labelName={labelName}
                    processorName={processorName}
                  />
                </Grid>
                <CustomTable
                  page={page}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  data={pricingScheduleList}
                  pageDetails={pageDetails}
                  headCells={headCellsItems}
                  dataParameter={dataParameter}
                  isCallInitialization={initialCall}
                  selected={[]}
                  setSelected={() => {}}
                  isSelection={false}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  totalCount={totalCount}
                >
                  {pricingScheduleList.map((row) => {
                    return (
                      <TableRow
                        key={row.PS_ID}
                        className={classes.cellHeadSign}
                      >
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {row.PS_Name}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {row.ProcessorName}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {moment(row.St_Date).format("MMM-YYYY")}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {row.Profile_Linked}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {row.Profit_Percent}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {row.Loss_Percent}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {moment(row.CreatedDt).format(
                            "MM/DD/YYYY hh:mm:ss a"
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {moment(row.Updated_Dt).format(
                            "MM/DD/YYYY hh:mm:ss a"
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {row.UpdatedBy_Name}
                        </TableCell>
                        <TableCell
                          className={classes.cellText}
                          onClick={() => handleAddEdit("edit", row)}
                        >
                          {row.PS_Status}
                        </TableCell>
                        {/* <TableCell
                  className={classes.cellText}
                  onClick={() => handleAddEdit("edit", row)}
                >
                  <IconButton color="primary" component="span">
                    <EditIcon />
                  </IconButton>
                </TableCell> */}
                      </TableRow>
                    );
                  })}
                </CustomTable>
              </TableContainer>
            </>
          )}
          {!actionPermission(
            permissionCheck.Category.residual,
            permissionCheck.Page.PricingSchedule,
            permissionCheck.option.view
          ) && (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "25%"
              }}
            >
              Access denied.
            </p>
          )}
        </>
      )}
    </>
  );
}
PricingSchedule.propTypes = {
  fetchPricingScheduleList: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  users: PropTypes.object,
  PSImportFile: PropTypes.func,
  PSNewItemsFile: PropTypes.func,
  PSUpdateFile: PropTypes.func
};

PricingSchedule.defaultProps = {
  fetchPricingScheduleList: () => {},
  fetchProcessorList: () => {},
  PSImportFile: () => {},
  PSNewItemsFile: () => {},
  PSUpdateFile: () => {}
};

const mapStateToProps = (app) => ({
  users: app
});

export default connect(mapStateToProps, {
  fetchPricingScheduleList,
  fetchProcessorList,
  PSImportFile,
  PSNewItemsFile,
  PSUpdateFile
})(PricingSchedule);
