import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { Typography, MenuItem } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import TextField from "@material-ui/core/TextField";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import noDataImage from "../../assets/img/noDataImage.png";
import Modal from "@material-ui/core/Modal";
import CoustomButton from "components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import { createNotes } from "../../actions/Operations";
import { getNotesList } from "../../Redux/Actions/ProvideRegistration";
import PublishIcon from "@material-ui/icons/Publish";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
import Validation from "../../Utils/Validations";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ChipInput from "material-ui-chip-input";
import Collapse from "@material-ui/core/Collapse";
import SimpleDialog from "../../components/DialogBox/notes";
import ClearIcon from "@material-ui/icons/Clear";
import { useLocation } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyle);

function createData(
  UserName,
  Type,
  Created_date,
  Description,
  Attachment,
  AttachedDoc
) {
  return {
    UserName,
    Type,
    Created_date,
    Description,
    Attachment,
    AttachedDoc,
  };
}

const headCells = [
  {
    id: "UserName",
    align: "left",
    numeric: false,
    disablePadding: true,
    label: "User",
  },
  {
    id: "Description",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "Attachment",
    align: "center",
    numeric: false,
    disablePadding: true,
    label: "Attachment",
  },
  {
    id: "Created_date",
    align: "center",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const Notes = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  let rows = [];
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openAddNotesModel, setopenAddNotesModel] = React.useState(false);
  const [preNotesList, setPreNotesList] = React.useState(
    props.mainData.notesList
  );
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Created_date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showMore, setShowMore] = React.useState(false);
  const [showMoreData, setShowMoreData] = React.useState(false);
  const [chipValue, setChipValue] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    type:
      location.pathname === "/admin/Merchant/app"
        ? "Merchant-App"
        : location.pathname === "/admin/Users"
        ? "User"
        : "",
    description: "",
    // documentAttachment: ""
    documentAttachment: {
      File_Name: "",
      File_Type: "",
      File_Path: "",
      Upload_Date_Time: "",
    },
  });

  const [searchVlaues, setSearchValues] = React.useState({
    globalSearchText: "",
    fromDate: "",
    toDate: "",
  });
  const [apiCallData, setApiCallData] = React.useState({
    userId: props.userId,
    type: initialValues.type,
    pageNo: page + 1,
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
  });

  function TablePaginationActions(props) {
    const classes = useTableStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  React.useEffect(() => {
    if (!props.mainData.notesList) {
      const data = {
        ...apiCallData,
        ...searchVlaues,
      };
      props.getNotesList(data);
    }
  }, []);

  React.useEffect(() => {
    const data = {
      ...apiCallData,
      ...searchVlaues,
    };
    props.getNotesList(data);
  }, [page, rowsPerPage, order, orderBy]);

  if (props.mainData.notesList) {
    props.mainData.notesList.data.forEach((v, i) => {
      const UserName = v.UserName;
      const Type = v.Type;
      const Created_date = moment
        .utc(v.Created_date)
        .format("MM/DD/YYYY HH:MM:ss");
      const Description = v.Description;
      const AttachedDoc = v?.Attachment_Document;
      const Attachment = `${process.env.REACT_APP_AWS_PATH}${process.env.REACT_APP_AWS_NOTE_DOCUMENTS}/${v?.Attachment_Document?.Internal_File_Name}`;
      rows.push(
        createData(
          UserName,
          Type,
          Created_date,
          Description,
          Attachment,
          AttachedDoc
        )
      );
    });
  }

  const closeNotesModal = () => {
    props.setOpenNotes(false);
    setPreNotesList(false);
  };

  const clearDocument = () => {
    let documentAttachment = {
      File_Name: "",
      File_Type: "",
      File_Path: "",
      Upload_Date_Time: "",
    };
    setInitialValues({
      ...initialValues,
      documentAttachment: documentAttachment,
    });
  };
  const cancelNotesModal = () => {
    setopenAddNotesModel(false);
    clearDocument();
  };
  const handleUploadOnChange = (files) => {
    if (files) {
      const userDocumentUpdate = { ...initialValues };
      const reader = new FileReader();
      reader.readAsDataURL(files);
      if (files.size / 1024 / 1024 < 6) {
        reader.onload = function () {
          userDocumentUpdate.documentAttachment.File_Path = reader.result;
          userDocumentUpdate.documentAttachment.File_Name = files.name;
          userDocumentUpdate.documentAttachment.File_Type = files.type;
          userDocumentUpdate.documentAttachment.Upload_Date_Time = new Date();
          setInitialValues({
            ...initialValues,
            documentAttachment: userDocumentUpdate.documentAttachment,
          });
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        enqueueSnackbar("Max file size can be upto 6 MB", {
          variant: "error",
        });
      }
    }
  };
  const onFormSubmit = (values) => {
    props.createNotes(
      {
        ...values,
        documentAttachment: values.documentAttachment.File_Path,
        userId: props.userId,
      },
      (res) => {
        if (res.status) {
          enqueueSnackbar(res.message, {
            variant: "success",
          });
          setopenAddNotesModel(false);
          const data = {
            ...apiCallData,
            ...searchVlaues,
          };
          props.getNotesList(data);
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
          });
        }
      }
    );
    setInitialValues({
      type:
        location.pathname === "/admin/Merchant/app"
          ? "Merchant-App"
          : location.pathname === "/admin/Users"
          ? "User"
          : "",
      description: "",
      documentAttachment: {
        File_Name: "",
        File_Type: "",
        File_Path: "",
        Upload_Date_Time: "",
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setApiCallData({
      ...apiCallData,
      pageNo: 1,
      limit: parseInt(event.target.value),
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setApiCallData({ ...apiCallData, pageNo: newPage + 1 });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setApiCallData({
      ...apiCallData,
      sortBy: property,
      orderBy: isAsc ? "desc" : "asc",
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getFilteredNotes = (value) => {
    setSearchValues(value);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        chipArray.push(
          `${
            property === "globalSearchText"
              ? "Search"
              : property === "fromDate"
              ? "From"
              : property === "toDate" && "To"
          }: ${value[property]}`
        );
      }
    }
    setChipValue(chipArray);
    props.getNotesList({ ...apiCallData, ...value });
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      setSearchValues(searchVlaues);
      const newSearchValue = { ...searchVlaues };
      if (chipName === "Search") {
        newSearchValue.globalSearchText = "";
      } else if (chipName === "From") {
        newSearchValue.fromDate = "";
      } else if (chipName === "To") {
        newSearchValue.toDate = "";
      }
      setSearchValues({ ...newSearchValue });
      props.getNotesList({ ...apiCallData, ...newSearchValue });
    }
  };

  function ShowMore() {
    const handleAlertClose = () => {
      setShowMore(false);
    };

    return (
      <Dialog
        open={showMore}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Notes - Description"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showMoreData}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAlertClose}
            className={classes.buttonStyle}
            color="#696969"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <Zoom
        in={props.setOpenNotes}
        style={{ transitionDelay: props.setOpenNotes ? "250ms" : "0ms" }}
      >
        <Box
          // className={tableClasses.notesdialog}
          component={Paper}
          style={{ width:"50%", borderTopLeftRadius: "16px", borderTopRightRadius: "16px",marginTop:"77px" }}
        >
          <div style={{ paddingRight: "0px", paddingLeft: "0px" }} className={tableClasses.statusModalHeader}>
            <div className={tableClasses.statusHeadingText}>View All Notes</div>
            <ClearIcon
              style={{ color: "white", cursor: "pointer" }}
              onClick={closeNotesModal}
            />
          </div>
          <Grid
            container
            className={tableClasses.searchContainer}
            style={{ paddingBottom: "8px" }}
          >
            <Grid style={{paddingLeft:"8px", paddingRight: "8px" }} item className={tableClasses.margin}>
              <div
                className={tableClasses.searchWrapper}
                style={{ justifyContent: "space-between" }}
              >
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box
                    style={{
                      minWidth: "40%",
                      // display: "flex",
                      // flexDirection: "column",
                      // alignItems: "start"
                    }}
                  >
                    <ChipInput
                      value={chipValue.length > 0 ? chipValue : []}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex", height: "45px" }}
                      classes={{ chipContainer: tableClasses.chipContainer }}
                      InputLabelProps={{
                        classes: {
                          root: tableClasses.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: tableClasses.activeInputColor,
                          input: tableClasses.resize,
                          focused: "focused",
                        },
                      }}
                      label="Search"
                      onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    />

                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={tableClasses.notesOverLayBox}
                    >
                      <SimpleDialog
                        close={() => handleClose()}
                        searchValue={searchVlaues}
                        seacrhChipData={(data) => getFilteredNotes(data)}
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <Grid item className={classes.margin}>
                  <CoustomButton
                    aria-controls="simple-menu"
                    variant="contained"
                    className={classes.ExportButtonStyle}
                    onClick={() => setopenAddNotesModel(true)}
                  >
                    Add Note
                  </CoustomButton>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* <Typography style={{ textAlign: "start", padding: "8px 0 2px 8px" }}>
          Type - {initialValues.type}
        </Typography> */}
          <TableContainer
            className={[classes.TableContainer, tableClasses.notestableScroll]}
            style={{ maxHeight: "58vh" }}
          >
            <Table
              className={tableClasses.notestable}
              aria-label="simple table"
            >
              <EnhancedTableHead
                classes={tableClasses}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {!isEmpty(rows) ? (
                  rows.map((row, index) => (
                    <TableRow
                      className={tableClasses.cellHeadSign}
                      key={row.Id}
                      style={{ cursor: "unset" }}
                    >
                      <TableCell
                        className={tableClasses.notesCellText}
                        align="left"
                      >
                        {row.UserName}
                      </TableCell>
                      {row.Description.length < 50 ? (
                        <TableCell
                          className={tableClasses.notesCellText}
                          align="center"
                        >
                          {row.Description}
                        </TableCell>
                      ) : (
                        <TableCell
                          className={tableClasses.notesCellText}
                          align="center"
                        >
                          {row.Description.substring(0, 50)}...
                          <span
                            style={{
                              color: "#4856c1",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowMore(true);
                              setShowMoreData(row.Description);
                            }}
                          >
                            more
                          </span>
                          {showMore && <ShowMore />}
                        </TableCell>
                      )}
                      <TableCell
                        className={tableClasses.notesCellText}
                        align="center"
                      >
                        {!isEmpty(row.AttachedDoc) && (
                          <Button
                            href={row.Attachment}
                            color="transparent"
                            target="_blank"
                            download
                            style={{
                              background: "lightgray",
                              padding: "8px",
                              borderRadius: "8px",
                              margin: "unset",
                            }}
                          >
                            <AttachFileIcon style={{ margin: "unset" }} />
                          </Button>
                        )}
                      </TableCell>
                      <TableCell
                        className={tableClasses.notesCellText}
                        align="center"
                      >
                        {row.Created_date}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="no-data" colSpan={12}>
                      <img
                        src={noDataImage}
                        alt=""
                        className={tableClasses.noDataImage}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {rows.length ? (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        {
                          label: "All",
                          value: props.mainData.notesList.data.length + 1,
                        },
                      ]}
                      colSpan={12}
                      count={props.mainData.notesList.totalDocs}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              ) : (
                ""
              )}
            </Table>
          </TableContainer>
        </Box>
      </Zoom>
      <Modal
        open={openAddNotesModel}
        onClose={() => setopenAddNotesModel(false)}
        align="center"
      >
        <Paper
          className={[tableClasses.notesdialog, tableClasses.notestableScroll]}
          style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
        >
          <Box className={classes.notestittleColor}>
            <Typography className={classes.tittleTextColor}>
              Add Notes
            </Typography>
          </Box>

          <Typography
            style={{
              textAlign: "end",
              color: "#4856c1",
              padding: "1rem",
              cursor: "pointer",
            }}
            onClick={() => setopenAddNotesModel(false)}
          >
            View All
          </Typography>
          <Formik
            innerRef={(p) => {
              formikRef.current = p;
            }}
            onSubmit={onFormSubmit}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Validation.addNotes}
          >
            {({ errors, setFieldValue, values, touched, handleSubmit }) => (
              <Form style={{ padding: "10px 40px" }}>
                <Grid item>
                  <Field
                    as={TextField}
                    style={{
                      marginTop: "20px",
                      width: "100%",
                      textAlign: "start",
                    }}
                    name="type"
                    id="type"
                    label="Notes Type"
                    disabled
                    required
                    error={touched.type && errors.type}
                    helperText={touched.type && errors.type ? errors.type : ""}
                  ></Field>
                </Grid>
                <Grid item>
                  <Field
                    as={TextField}
                    name="description"
                    id="description"
                    label="Description (Max Characters 2000)"
                    multiline
                    style={{
                      marginTop: "20px",
                      width: "100%",
                    }}
                    minRows={3}
                    required
                    error={touched.description && errors.description}
                    helperText={
                      touched.description && errors.description
                        ? errors.description
                        : ""
                    }
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    padding: "10px 0px",
                  }}
                >
                  <Field
                    as={TextField}
                    style={{
                      marginTop: "20px",
                      width: "100%",
                    }}
                    id="documentAttachment"
                    name="documentAttachment"
                    label="Upload Document (File Type: JPG, JPEG, PNG, PDF)"
                    value={initialValues.documentAttachment.File_Name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <label htmlFor="documentAttachment">
                            <input
                              style={{ display: "none" }}
                              id="documentAttachment"
                              name="documentAttachment"
                              type="file"
                              accept="image/*, application/pdf"
                              display="none"
                              onChange={(e) =>
                                handleUploadOnChange(e.target.files[0])
                              }
                            />
                            <Tooltip title="Upload File" placement="right">
                              <IconButton
                                component="span"
                                style={{ marginBottom: "15px" }}
                              >
                                <PublishIcon
                                  style={{ width: "30px", height: "30px" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </label>
                        </InputAdornment>
                      ),

                      endAdornment: (
                        <>
                          {initialValues.documentAttachment.File_Name && (
                            <InputAdornment position="end">
                              <Tooltip title="Clear" placement="top">
                                <IconButton
                                  component="span"
                                  style={{ marginBottom: "15px" }}
                                  onClick={() => clearDocument()}
                                >
                                  <ClearIcon
                                    style={{ width: "30px", height: "30px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    className={classes.clearButton}
                    onClick={() => cancelNotesModal()}
                  >
                    Cancel
                  </Button>
                  <Button
                    // className={classes.clearButton}
                    onClick={() => handleSubmit()}
                    className={classes.buttonStyle}
                    color="#696969"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Modal>
    </>
  );
};

Notes.propTypes = {
  createNotes: PropTypes.func,
  getNotesList: PropTypes.func,
  openNotes: PropTypes.func,
  setOpenNotes: PropTypes.func,
  userId: PropTypes.any,
};

const mapStateToProps = (app) => ({
  mainData: app.provideRegistration,
});
export default connect(mapStateToProps, { createNotes, getNotesList })(Notes);
