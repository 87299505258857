import React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { Field, Form, Formik } from "formik";
import { ThemeProvider, createTheme, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Fade from "@material-ui/core/Fade";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InputLabel,
  Typography
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { commonStyle } from "../../assets/style/js/commonStyle";
import TodayIcon from "@material-ui/icons/Today";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import tableStyle from "../../../../assets/jss/material-dashboard-react/components/reportTables";
import tableStyles from "../../components/CustomizeTable/style";
import Zoom from "@material-ui/core/Zoom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(tableStyle);

const AssignReassignModal = ({
  agentsList,
  processorList,
  open,
  onClose,
  profilesList,
  assignReassignCall,
  getAgentProfileRepCodeList,
  selectedList,
  selectedMerchant,
  successModal,
  setSuccessModal,
  downloadImportedData,
  successData
}) => {
  const oldTheme = useTheme();
  const classes = useStyles();
  const useModalStyles = makeStyles(tableStyles);
  const successClasses = useModalStyles();
  const theme = createTheme({
    ...oldTheme,
    overrides: {
      MuiAutocomplete: {
        inputRoot: {
          padding: "0px"
        },
        input: {
          padding: "0px"
        }
      },
      MuiOutlinedInput: {
        root: {
          background: "#F9FAFF 0% 0% no-repeat",
          borderRadius: "6px",
          height: "44px"
        }
      }
    }
  });

  const selectedProcessor =
    !isEmpty(selectedMerchant) &&
    processorList.find(
      (processor) => processor.ProcessorName == selectedMerchant.ProcessorName
    );

  const showMappedDataText = (successData, msg) => {
    if (msg == "message") {
      if (successData.totalMappingDone == 0) {
        return "No Merchants have been mapped with profile.";
      } else {
        if (successData.totalMappingDone < successData.totalCount) {
          return "The merchants are partially mapped.";
        } else if (successData.totalMappingDone == successData.totalCount) {
          return successData.message;
        }
      }
    } else if (msg == "count") {
      return `${successData.totalMappingDone} out of ${
        successData.totalCount
      } ${successData.totalCount > 1 ? "profiles" : "profile"} mapped.`;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        aria-labelledby="sasdasd"
        aria-describedby="simpleasdasdasdan"
      >
        <Zoom in={open}>
          <Paper
            className={classes.dialogBoxBorder}
            style={{
              width: "510px"
            }}
          >
            <div className={classes.statusModalHeader}>
              <div className={classes.statusHeadingText}>
                Assign / Reassign Merchants
              </div>
              <ClearIcon
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => onClose(false)}
              />
            </div>
            <ThemeProvider theme={theme}>
              <Formik
                enableReinitialize
                // eslint-disable-next-line react/jsx-no-bind
                onSubmit={assignReassignCall}
                initialValues={{
                  processorId: selectedProcessor.ProcessorID,
                  profileId: "",
                  userId: "",
                  forever: true,
                  dateFrom: null,
                  dateTo: null
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form
                    style={{
                      padding: theme.spacing(2, 3, 3)
                    }}
                  >
                    <Grid
                      spacing={2}
                      style={{
                        justifyContent: "space-between",
                        marginTop: "24px",
                        alignItems: "center"
                      }}
                      container
                    >
                      <Grid item>
                        <Typography>Processor</Typography>
                      </Grid>
                      <Grid item>
                        <Field
                          as={TextField}
                          SelectProps={{
                            MenuProps: { disablePortal: false }
                          }}
                          name="processorId"
                          id="processorId"
                          placeholder="Processor"
                          variant="outlined"
                          onChange={(e) => {
                            setFieldValue("processorId", e.target.value);
                            setFieldValue("userId", "");
                            setFieldValue("profileId", "");
                          }}
                          select
                          style={{ minWidth: "280px" }}
                          fullWidth
                          disabled
                        >
                          {processorList.map((processor) => (
                            <MenuItem value={processor.ProcessorID}>
                              {processor.ProcessorName}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>

                    {values.processorId && (
                      <Grid
                        spacing={2}
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "16px"
                        }}
                        container
                      >
                        <Grid item>
                          <Typography>Agent</Typography>
                        </Grid>
                        <Grid item>
                          <Field
                            as={Autocomplete}
                            name="userId"
                            id="userId"
                            style={{
                              fontSize: "14px",
                              "&.focused": {
                                // color: theme.palette.primary.main,
                                fontSize: "14px",
                                fontStyle: "Helvetica, Arial,sans-serif"
                              },
                              minWidth: "280px"
                            }}
                            label="Type(s) of goods or services sold"
                            value={
                              (agentsList || []).find(
                                (option) =>
                                  option.UserID === parseInt(values.userId)
                              ) || ""
                            }
                            autoHighlight
                            fullWidth
                            // onInputChange={(e, newInputValue) => {
                            //   if (newInputValue.length >= 3) {
                            //     getAgentList(newInputValue);
                            //   }
                            // }}
                            getOptionDisabled={(option) =>
                              option.UserID === 919863231596669598
                            }
                            onChange={(_, value, action) => {
                              if (action === "clear") {
                                setFieldValue("userId", "");
                              } else {
                                value && setFieldValue("userId", value.UserID);
                                setFieldValue("profileId", "");
                                getAgentProfileRepCodeList({
                                  processorId: values.processorId,
                                  userId: value.UserID
                                });
                              }
                            }}
                            selectOnFocus
                            options={agentsList || []}
                            getOptionLabel={(option) => option.AgentName || ""}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                required
                                {...params}
                              />
                            )}
                            renderOption={(option, { inputValue }) => {
                              const matches = match(
                                option.AgentName,
                                inputValue,
                                {
                                  insideWords: true
                                }
                              );
                              const parts = parse(option.AgentName, matches);

                              return (
                                <div>
                                  {parts.map((part, index) => (
                                    <span
                                      style={{
                                        background: part.highlight
                                          ? "#f3ff0070"
                                          : "",
                                        fontWeight: part.highlight ? 400 : "",
                                        fontSize: "14px"
                                      }}
                                    >
                                      {part.text}
                                    </span>
                                  ))}
                                </div>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {values.userId && (
                      <Grid
                        spacing={2}
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "16px"
                        }}
                        container
                      >
                        <Grid item>
                          <Typography>Agent / RepCode</Typography>
                        </Grid>
                        <Grid item>
                          <Field
                            as={TextField}
                            InputLabelProps={{
                              classes: {
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              classes: {
                                focused: "focused"
                              }
                            }}
                            SelectProps={{
                              MenuProps: { disablePortal: false }
                            }}
                            style={{ minWidth: "280px" }}
                            name="profileId"
                            variant="outlined"
                            id="profileId"
                            select
                            fullWidth
                          >
                            {profilesList.map((repCode) => (
                              <MenuItem value={repCode.ProfileID}>
                                {repCode.RepCode}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </Grid>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "16px"
                      }}
                    >
                      <Grid style={{ width: "45%" }} item>
                        <Grid item>
                          <Typography>Start From</Typography>
                        </Grid>
                        <FormControl>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              // fullWidth
                              id="date-picker-dialog"
                              labelId="label-dateFrom"
                              inputVariant="outlined"
                              name="dateFrom"
                              maxDate={
                                !values.forever
                                  ? values.dateTo
                                  : new Date(2099, 12, 25, 23, 59, 59)
                              }
                              allowKeyboardControl={false}
                              format="MM/yyyy"
                              placeholder="MM/YYYY"
                              views={["month", "year"]}
                              minDateMessage=""
                              invalidDateMessage=""
                              maxDateMessage="From Date cannot be greater than to date"
                              defaultValue={null}
                              value={
                                values.dateFrom
                                  ? moment(values.dateFrom).format("MM-DD-YYYY")
                                  : null
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => {}}>
                                      <TodayIcon
                                        style={{ color: "rgba(0, 0, 0, 0.54)" }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              onChange={(value) => {
                                let dateObj = value;
                                if (dateObj && dateObj.getMonth()) {
                                  const month = dateObj.getMonth() + 1; // months from 1-12
                                  const day = dateObj.getDate();
                                  const year = dateObj.getFullYear();
                                  dateObj = `${year}-${month}-${day}`;
                                }
                                setFieldValue("dateFrom", dateObj);
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "start form"
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                      {values.forever && (
                        <Grid style={{ width: "45%" }} item>
                          <Grid item>
                            <Typography>To</Typography>
                          </Grid>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.forever}
                                onChange={() => {
                                  setFieldValue("forever", !values.forever);
                                }}
                                name="forever"
                                color="primary"
                              />
                            }
                            label="Forever"
                          />
                        </Grid>
                      )}
                      {!values.forever && (
                        <Grid style={{ width: "45%" }} item>
                          <Grid item>
                            <Typography>To</Typography>
                          </Grid>
                          <FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                // fullWidth
                                id="date-picker-dialog"
                                inputVariant="outlined"
                                name="dateTo"
                                label="To"
                                minDate={
                                  !values.forever
                                    ? values.dateFrom ||
                                      new Date(2020, 4, 25, 23, 59, 59)
                                    : new Date(2020, 4, 25, 23, 59, 59)
                                }
                                allowKeyboardControl={false}
                                format="MM/yyyy"
                                placeholder="MM/YYYY"
                                views={["month", "year"]}
                                minDateMessage="From Date cannot be greater than to date"
                                invalidDateMessage=""
                                maxDateMessage=""
                                defaultValue={null}
                                value={
                                  values.dateTo
                                    ? moment(values.dateTo).format("MM-DD-YYYY")
                                    : null
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={() => {}}>
                                        <TodayIcon
                                          style={{
                                            color: "rgba(0, 0, 0, 0.54)"
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                onChange={(value) => {
                                  let dateObj = value;
                                  if (dateObj && dateObj.getMonth()) {
                                    const month = dateObj.getMonth() + 1; // months from 1-12
                                    const day = dateObj.getDate();
                                    const year = dateObj.getFullYear();
                                    dateObj = `${year}-${month}-${day}`;
                                  }
                                  setFieldValue("dateTo", dateObj);
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "start to"
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.forever}
                                onChange={() => {
                                  setFieldValue("forever", !values.forever);
                                }}
                                name="forever"
                                color="primary"
                              />
                            }
                            label="Forever"
                          />
                        </Grid>
                      )}
                    </div>

                    <Grid
                      spacing={2}
                      style={{
                        justifyContent: "end",
                        alignItems: "center",
                        marginTop: "1rem"
                      }}
                      container
                    >
                      <Grid item>
                        <Button
                          color="primary"
                          style={{
                            textTransform: "none"
                          }}
                          type="submit"
                          className={classes.actionButtonStyle}
                          disabled={!values.profileId}
                          variant="contained"
                          contained
                        >
                          Assign
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </ThemeProvider>
          </Paper>
        </Zoom>
      </Modal>
      {/* success modal */}
      <Modal open={successModal} onClose={() => setSuccessModal(false)}>
        <Zoom in={successModal}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalCard} style={{ height: "auto" }}>
              <Grid constainer className={classes.margin}>
                <Grid item style={{ alignItems: "center" }}>
                  <div className={classes.cardDiv} style={{ height: "unset" }}>
                    <div align="center">
                      {successData.totalMappingDone ==
                      successData.totalCount ? (
                        <CheckCircleOutlineIcon
                          style={{
                            color: "#2ba992",
                            fontSize: "5rem"
                          }}
                        />
                      ) : successData.totalMappingDone == 0 ? (
                        <CloseIcon
                          style={{
                            color: "red",
                            fontSize: "5rem"
                          }}
                        />
                      ) : (
                        <ErrorOutlineIcon
                          style={{
                            color: "orange",
                            fontSize: "5rem"
                          }}
                        />
                      )}
                    </div>
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "22px",
                        textAlign: "center"
                      }}
                    >
                      {showMappedDataText(successData, "message")}
                    </p>
                    <div
                      className={classes.successText}
                      style={{
                        whiteSpace: "initial",
                        textAlign: "center",
                        margin: "8px 0"
                      }}
                    >
                      {showMappedDataText(successData, "count")}
                      <br />
                      <span
                        style={{ color: "#0093c9", cursor: "pointer" }}
                        onClick={downloadImportedData}
                      >
                        Click here
                      </span>
                    </div>
                    <div align="center" style={{ paddingTop: "10px" }}>
                      <Button
                        variant="contained"
                        className={classes.buttonStyle}
                        size="sm"
                        onClick={() => {
                          setSuccessModal(false);
                          onClose();
                        }}
                        align="center"
                      >
                        Ok
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </div>
        </Zoom>
      </Modal>
    </>
  );
};

AssignReassignModal.propTypes = {
  agentsList: PropTypes.array,
  profilesList: PropTypes.array,
  processorList: PropTypes.array,
  selectedList: PropTypes.array,
  selectedMerchant: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  assignReassignCall: PropTypes.func,
  getAgentProfileRepCodeList: PropTypes.func
};

AssignReassignModal.defaultProps = {
  profilesList: [],
  agentsList: [],
  processorList: [],
  open: true,
  onClose: () => {},
  assignReassignCall: () => {},
  getAgentProfileRepCodeList: PropTypes.func
};
export default AssignReassignModal;
