import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { saveAs } from "file-saver";
import isEmpty from "lodash/isEmpty";

const checkURL = (val) => {
  // const location = useLocation();
  if (window.location.pathname.indexOf(val) > -1) {
    return true;
  } else return false;
};

export const exportToCSV = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  processorName
) => {
  var result = [];
  const fromMonth = moment(chipValue.fromMonth).format("MM/DD/YYYY");
  const toMonth = moment(chipValue.toMonth).format("MM/DD/YYYY");
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      if (some == "Processor") {
        result.push(`${some}: ${processorName}`);
      } else if (some == "From Month") {
        result.push(`${some} : ${fromMonth} `);
      } else if (some == "To Month") {
        result.push(`${some} : ${toMonth} `);
      } else {
        result.push(` ${some}: ${another}`);
      }
    }
  }
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A6" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `${moment(newCurrentDate)
          .tz("UTC")
          .format("MM/DD/YYYY hh:mm:ss A")} UTC`
      ]
    ],
    { origin: "B2" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A6:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}6`
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }
  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map((width) => ({ wch: width }));

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    fileName +
      `${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}` +
      fileExtension
  );
};

export const exportToPDF = (
  headersName,
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  processorName,
  moduleName
) => {
  const totalCounts = mappedLogs.length;
  const fromMonth = moment(chipValue.fromMonth).format("MM/DD/YYYY");
  const toMonth = moment(chipValue.toMonth).format("MM/DD/YYYY");
  var result = [];
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      if (some == "Processor") {
        result.push(`${some} : ${processorName} `);
      } else if (some == "From Month") {
        result.push(`${some} : ${fromMonth} `);
      } else if (some == "To Month") {
        result.push(`${some} : ${toMonth} `);
      } else if (some == "Status" && checkURL("PayoutApproval")) {
        result.push(
          ` ${some}: ${
            another == 1
              ? "Pending"
              : another == 2
              ? "Under Review"
              : another == 3
              ? "Approved"
              : "Declined"
          }`
        );
      } else if (some == "Status" && checkURL("ImportLogs")) {
        result.push(
          ` ${some}: ${
            another == "C"
              ? "Completed"
              : another == "P"
              ? "Partially Completed"
              : another == "I"
              ? "InProgress"
              : "Failed"
          }`
        );
      } else if (some == "Status" && checkURL("MerchantMapping")) {
        result.push(`${some}: ${another == 1 ? "Mapped" : "Un-Mapped"}`);
      } else if (some == "Status" && checkURL("Adjustment")) {
        result.push(` ${some}: ${another == 1 ? "Active" : "Inactive"}`);
      } else if (some == "Status" && checkURL("PricingSchedule")) {
        result.push(` ${some}: ${another == 1 ? "Active" : "Inactive"}`);
      } else if (some == "Module") {
        result.push(`${some}: ${moduleName}`);
      } else {
        result.push(` ${some}: ${another}`);
      }
    }
  }
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape
  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(12);
  const headers = [!isEmpty(mappedLogs) ? headersName : ""];
  // const data = people.map((elt) => [elt.ResidualMonth, elt.PayoutMonth,elt.Processor]);
  const data = mappedLogs;

  var totalPagesExp = "{total_pages_count_string}";

  let content = {
    startY: 100,
    head: headers,
    body: data,
    drawRow: function (data) {
      if (data.index > 0 && data.index % 10 === 0) {
        doc.autoTableAddPage();
      }
    },
    didDrawPage: function (data) {
      // Footer
      doc.setFontSize(10);
      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      let pageSize = doc.internal.pageSize;
      let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.text(
        "Page " + doc.getCurrentPageInfo().pageNumber + " of " + totalPagesExp,
        745,
        pageHeight - 10
      );
      // doc.text(710, 585, "Total Count: " + totalCount);
    }
  };
  doc.text(`Module: ${fileName}`, marginLeft, 20);
  doc.text(715, 20, "Total Count: " + totalCounts);
  doc.text(
    `Exported on: ${moment(newCurrentDate)
      .tz("UTC")
      .format("MM/DD/YYYY hh:mm:ss A")} UTC`,
    marginLeft,
    40
  );
  doc.text(
    `Exported by: ${exportedByFname} ${exportedByLname} <${exportedBy}>`,
    marginLeft,
    60
  );
  if (labelName == undefined) {
    doc.text(`Filters: ${chipValue}`, marginLeft, 80);
  } else if (isEmpty(result)) {
    doc.text(`Filters: ${chipValue}`, marginLeft, 80);
  } else {
    doc.text(`Filters: ${result}`, marginLeft, 80);
  }
  doc.text(`Filters: ${result}`, marginLeft, 80);
  doc.text(isEmpty(mappedLogs) ? `No Records Found` : "", marginLeft, 100);
  doc.autoTable(content);
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(
    fileName + `${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}`
  );
};

export const importExportToCSV = (
  mappedLogs,
  fileName,
  chipValue,
  exportedBy,
  exportedByFname,
  exportedByLname,
  labelName,
  moduleName
) => {
  var result = [];
  const fromMonth = moment(chipValue.fromMonth).format("MM/DD/YYYY");
  const toMonth = moment(chipValue.toMonth).format("MM/DD/YYYY");
  for (var id in labelName) {
    let some = labelName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      if (some == "Module") {
        result.push(`${some}: ${moduleName}`);
      } else if (some == "From Month") {
        result.push(`${some} : ${fromMonth} `);
      } else if (some == "To Month") {
        result.push(`${some} : ${toMonth} `);
      } else if (some == "Status" && checkURL("ImportLogs")) {
        result.push(
          ` ${some}: ${
            another == "C"
              ? "Completed"
              : another == "P"
              ? "Partially Completed"
              : another == "I"
              ? "InProgress"
              : "Failed"
          }`
        );
      } else {
        result.push(` ${some}: ${another}`);
      }
    }
  }
  var results = [];
  for (var id in moduleName) {
    let some = moduleName[id];
    let another = chipValue[id];
    if (another != "" && another != undefined) {
      results.push(`${some} : ${another}`);
    }
  }
  var myCurrentDate = new Date();
  var date =
    myCurrentDate.getMonth() +
    1 +
    "/" +
    myCurrentDate.getDate() +
    "/" +
    myCurrentDate.getFullYear() +
    " " +
    myCurrentDate.getHours() +
    ":" +
    myCurrentDate.getMinutes() +
    ":" +
    myCurrentDate.getSeconds();
  const newCurrentDate = date;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(
    !isEmpty(mappedLogs) ? mappedLogs : [{ "No Records Found": "" }],
    { origin: "A6" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Module:`]], { origin: "A1" });
  XLSX.utils.sheet_add_aoa(ws, [[`${fileName}`]], { origin: "B1" });
  XLSX.utils.sheet_add_aoa(ws, [[`Exported on:`]], { origin: "A2" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [
      [
        `${moment(newCurrentDate)
          .tz("UTC")
          .format("MM/DD/YYYY hh:mm:ss A")} UTC`
      ]
    ],
    { origin: "B2" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Exported by:`]], { origin: "A3" });
  XLSX.utils.sheet_add_aoa(
    ws,
    [[`${exportedByFname} ${exportedByLname} <${exportedBy}>`]],
    { origin: "B3" }
  );
  XLSX.utils.sheet_add_aoa(ws, [[`Filters:`]], { origin: "A4" });
  XLSX.utils.sheet_add_aoa(ws, [[`${result}`]], { origin: "B4" });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  if (!isEmpty(mappedLogs)) {
    ws["!autofilter"] = {
      ref: `A6:${XLSX.utils.encode_col(Object.keys(mappedLogs[0]).length - 1)}6`
    };
    ws["!sort"] = [{ column: 1, order: "asc" }];
  }

  // Calculate the maximum content length for each column
  const columnWidths = [];
  XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach((row) => {
    row.forEach((cell, colIndex) => {
      const cellLength = cell ? String(cell).length : 10; // Default width if cell is empty
      columnWidths[colIndex] = Math.min(
        Math.max(columnWidths[colIndex] || 0, cellLength),
        25
      );
    });
  });

  // Set the width for each column based on the maximum content length
  ws["!cols"] = columnWidths.map((width) => ({ wch: width }));

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(
    data,
    fileName +
      `${moment(newCurrentDate).tz("UTC").format("MMDDYYYYhhmmss")}` +
      fileExtension
  );
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getQueryParams = function (data) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};

export const accountingNumberformat = (
  number,
  maxdecimal = 2,
  mindecimal = 2
) => {
  const intNumber = typeof number === "number" ? number : parseFloat(number);
  const result = new Intl.NumberFormat("en", {
    maximumFractionDigits: maxdecimal,
    minimumFractionDigits: mindecimal
  }).format(intNumber);
  return result;
};

export const getDataBlankFormat = function (data) {
  let returnData = "";
  if (data) {
    returnData = `(${data})`;
  }
  return returnData;
};

export const exportDateFormat = function (data) {
  const splitParts = data.split(/-(.+)/);
  return `${splitParts[0]} ${splitParts[1]}`;
};

export const exportNumberToDashFormat = function (value) {
  const numFmtStr = `(${Math.abs(Number(value))})`;
  if (/^0+(\.0+)?$/.test(value)) {
    return "-";
  } else if (value < 0) {
    return numFmtStr;
  } else {
    return value;
  }
};
