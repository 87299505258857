import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AssignReassignModal from "./updateMerchantModal";
import {
  fetchMappedMerchantsList,
  fetchProfileRepCodeList,
  fetchMerchantsByName,
  updateMappingStatusUpdate,
  fetchMappedMerchantsListAll,
  updateDBANameUpdate
} from "../../actions/merchantMapping.action";
import { fetchProcessorList } from "../../actions/pricingSchedule.action";
import { fetchAgentList } from "../../actions/agentPayout.action";
import CustomTable from "../../components/CustomizeTable/CustomTable";
import tableStyle from "../../components/CustomizeTable/style";
import {
  exportToCSV,
  getQueryParams,
  handleClickTable
} from "../../utils/common";
import { TablePageData } from "../../utils/constants";
import SearchComponent from "../../components/MerchantSearchComp/SearchFields";
import SearchedChips from "../../components/MerchantSearchComp/SearchChips";
import FilterFields from "./filterFields";
import LongMenu from "./LongMenu";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { exportToPDF } from "modules/reports/utils/common";
import { ReactComponent as ImportIconSvg } from "../../../../assets/svgIcons/ImportIconSvg.svg";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as ExportIcon } from "../../../../assets/svgIcons/ExportIcon.svg";
import UpdateDBA from "./updateDBA";
import { exportToXLS } from "../../../../Utils/commonUtils";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px"
  },
  tooltipPlacementTop: {
    margin: "4px 0"
  }
}))(Tooltip);
const headCellsItems = [
  {
    id: "ProcessorName",
    label: "Processor",
    isSort: true,
    sortProperty: "ProcessorName",
    actionsCellStyle: "left"
  },
  {
    id: "DBAName",
    label: "Merchant",
    isSort: true,
    sortProperty: "DBAName",
    actionsCellStyle: "center"
  },
  {
    id: "MID",
    label: "MID",
    isSort: true,
    sortProperty: "MID",
    actionsCellStyle: "center"
  },
  {
    id: "Agent_Name",
    label: "Agent Name",
    isSort: true,
    sortProperty: "Agent_Name",
    actionsCellStyle: "center"
  },
  {
    id: "RepCode",
    label: "Repcode",
    isSort: true,
    sortProperty: "RepCode",
    actionsCellStyle: "center"
  },
  {
    id: "Opened_Date",
    label: "Start Date",
    isSort: true,
    sortProperty: "Opened_Date",
    actionsCellStyle: "center"
  },
  {
    id: "Start_Date",
    label: "End Date",
    isSort: true,
    sortProperty: "Start_Date",
    actionsCellStyle: "center"
  },
  {
    id: "Open_Date",
    label: "Open Date",
    isSort: true,
    sortProperty: "Open_Date",
    actionsCellStyle: "center"
  },
  {
    id: "Assigned_Date",
    label: "Assigned Date",
    isSort: true,
    sortProperty: "Assigned_Date",
    actionsCellStyle: "center"
  },
  {
    id: "action",
    label: "",
    isSort: true,
    sortProperty: "action",
    actionsCellStyle: "right"
  }
];

const labelName = {
  processorId: "Processor",
  profileId: "Profile",
  userId: "User",
  searchDbaMID: "DBA Name / MID",
  status: "Status",
  fromMonth: "From",
  toMonth: "To"
};

function PayoutSummaryReport({
  fetchMappedMerchantsList,
  fetchProcessorList,
  fetchAgentList,
  fetchProfileRepCodeList,
  fetchMerchantsByName,
  updateMappingStatusUpdate,
  fetchMappedMerchantsListAll,
  users,
  tableData,
  updateDBANameUpdate
}) {
  const useStyles = makeStyles(tableStyle);
  const classes = useStyles();
  const history = useHistory();
  const PROCESSOR_ID = getQueryParams("processorId");
  const PROFILE_ID = getQueryParams("profileId");
  const USER_ID = getQueryParams("userId");
  const searchInitialData = {
    processorId: PROCESSOR_ID || "",
    profileId: PROFILE_ID || "",
    userId: USER_ID || "",
    searchDbaMID: "",
    status: "",
    fromMonth: "",
    toMonth: ""
  };

  const theme = useTheme();
  const dataParameter = "MID";
  const processorParameter = "ProcessorName";
  const searchItems = localStorage.getItem("MerchantMapping");
  const { enqueueSnackbar } = useSnackbar();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  // states
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(true);
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Assigned_Date");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [merchantList, setMerchantList] = React.useState([]);
  const [allmerchantList, setAllMerchantList] = React.useState([]);
  const [assignReassignModal, setAssignReassignModal] = React.useState(false);
  const [updateDBAModal, setUpdateDBAModal] = React.useState(false);
  const [profiles, setProfiles] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState({
    ...searchInitialData
  });
  const [chipValue, setChipValue] = React.useState([]);
  const [searchBar, setSearchBar] = React.useState(false);
  const [agentsList, setAgentsList] = React.useState([]);
  const [processorList, setProcessorList] = React.useState([]);
  const [repCodeList, setRepCodeList] = React.useState([]);
  const [selectedList, setSelectedList] = React.useState([]);
  const [selectedProcessorList, setSelectedProcessorList] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState("");
  const [alterOpen, setAlertOpen] = React.useState(false);
  const isSelected = (value, selected) => selectedList.indexOf(value) !== -1;
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const [updateDbaName, setUpdateDbaName] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [successData, setSuccessData] = React.useState(false);

  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  // const merchantsListAsync = (event) => {
  //   if (event && event.target) {
  //     clearTimeout(typingTimer);
  //     typingTimer = setTimeout(() => {
  //       if ((event.target.value || "").length >= 3) {
  //         getMerchantsListAsync(event.target.value);
  //       }
  //     }, 400);
  //   }
  // };

  const findAgentName = (id) => {
    if (!isEmpty(agentsList)) {
      const name = agentsList.find((x) => x.UserID == id);
      return name ? name.AgentName.split("[")[0] : id;
    }
  };

  const findProcessorName = (id) => {
    if (!isEmpty(processorList)) {
      const name = processorList.find((x) => x.ProcessorID == id);
      return name ? name.ProcessorShortName : id;
    }
  };

  const findProfileName = (id) => {
    if (!isEmpty(repCodeList)) {
      const name = repCodeList.find((x) => x.ProfileID == id);
      return name ? name.RepCode : id;
    }
  };

  const agent_Name = React.useMemo(
    () => findAgentName(searchValues.userId),
    [searchValues.userId, agentsList]
  );

  const processorName = React.useMemo(
    () => findProcessorName(searchValues.processorId),
    [searchValues.processorId, processorList]
  );

  const profileName = React.useMemo(
    () => findProfileName(searchValues.profileId),
    [searchValues.profileId, repCodeList]
  );

  const onCheck = (event, processor) => {
    if (Array.isArray(event) && Array.isArray(processor)) {
      setSelectedList(event.map((item) => item.toString()));
      setSelectedProcessorList(processor.map((item) => item.toString()));
    }
  };

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const handleSubmitSearch = (values) => {
    setSearchValues(values);
    setChipValue(searchValues);
    handleSearchClose();
    setOrderBy("Assigned_Date");
    setOrder("desc");
    setSelectedList([]);
  };

  const handleClearSearch = () => {
    setSearchValues({ ...searchInitialData });
    setChipValue({ ...searchInitialData });
    handleSearchClose();
  };

  const getProcessorList = () => {
    fetchProcessorList(
      (data) => {
        if (data.status) {
          setProcessorList(data.data);
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getAgentList = (agentName = "") => {
    fetchAgentList(
      (data) => {
        if (data.status || data.status === "success") {
          if (
            (data.message === "No record found" && !isEmpty(agentsList)) ||
            isEmpty(data.data)
          ) {
            setAgentsList([
              {
                UserID: 919863231596669598,
                AgentName: "No record found"
              }
            ]);
          }
          if (data.data) {
            setAgentsList(() => [...data.data]);
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      },
      { search: agentName }
    );
  };

  const assignReassignCall = (values) => {
    const midIds = [];
    selectedList.map((x) => {
      midIds.push({ id: x });
    });

    const payload = {
      profileId: values.profileId,
      midIds,
      forever: values.forever ? 1 : 0,
      dateFrom: values.dateFrom
        ? moment(values.dateFrom).format("YYYY-MM-DD")
        : null,
      dateTo: values.dateTo ? moment(values.dateTo).format("YYYY-MM-DD") : null
    };
    updateMappingStatusUpdate(
      payload,
      (data) => {
        setAssignReassignModal(false);

        if (data.status === "success") {
          setSuccessModal(true);
          setSuccessData(data);
          setSelectedList([]);
          getPayoutList();
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const downloadImportedData = () => {
    const content = get(successData, "data", []);
    let mappedData;
    if (!isEmpty(content)) {
      mappedData = content.map((row) => ({
        MID: row.MID,
        Profile: row.Profile,
        "Start Date": row.Startdate,
        "End Date": row.Enddate,
        Forever: row.Forever,
        Message: row.Message
      }));
      exportToXLS(mappedData, "Merchant mapping mapped details");
    }
    setSuccessModal(false);
    setAssignReassignModal(false);
  };

  const updateDbaNameCall = (values) => {
    const payload = {
      DBAName: values.DBAName,
      MID: values.MID
    };
    updateDBANameUpdate(
      payload,
      (data) => {
        if (data.status === "success") {
          setAssignReassignModal(false);
          enqueueSnackbar(`${data.message}`, {
            variant: "success"
          });
          setSelectedList([]);
          getPayoutList();
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getAgentProfileRepCodeList = (payload = {}) => {
    fetchProfileRepCodeList(
      {
        userId: !isEmpty(payload)
          ? payload.userId || ""
          : searchValues.userId || "",
        processorId: !isEmpty(payload)
          ? payload.processorId || ""
          : searchValues.processorId || ""
      },
      (data) => {
        if (data.status) {
          if (isEmpty(payload)) {
            setRepCodeList(data.data);
          } else {
            setProfiles(data.data);
          }
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error"
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error"
        });
      }
    );
  };

  const getMerchantsListAsync = function () {
    fetchMappedMerchantsListAll(
      (records) => {
        if (records.status == "success") {
          setAllMerchantList(records.data);
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const handleDeleteChip = (chip) => {
    if (chipValue) {
      const temp = { ...searchValues };
      const tempChip = { ...chipValue };
      temp[chip] = "";
      setSearchValues({
        ...temp,
        fromMonth: temp.fromMonth != "" ? tempChip.fromMonth : ""
      });
      setChipValue({ ...temp });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setRowsPerPage(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const exportedBy =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname =
    users.users.userDetailsById &&
    users.users.userDetailsById?.Users_Data[0].LName;
  const exportListData = (props) => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    fetchMappedMerchantsList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => ({
              "Processor Name": row.ProcessorName,
              Merchant: row.DBAName,
              MID: row.MID,
              "Agent Name": row.Agent_Name,
              "Opened Date": row.Opened_Date,
              "Start Date": row.Start_Date,
              "Open Date": row.Open_Date,
              Assigned_Date: row.Assigned_Date,
              "End Date": row.END_Date
            }));
            exportToCSV(
              mappedLogs,
              "Mapped Merchant Details",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              processorName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  const exportToPDFList = (props) => {
    const filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: "",
      pageNo: 1,
      ...searchValues
    };
    fetchMappedMerchantsList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          if (content) {
            const mappedLogs = content.map((row) => [
              row.ProcessorName,
              row.DBAName,
              row.MID,
              row.Agent_Name,
              row.Opened_Date,
              row.Start_Date,
              row.Open_Date,
              row.Assigned_Date,
              row.END_Date
            ]);
            const headersName = [
              "Processor Name",
              "Merchant",
              "MID",
              "Agent Name",
              "Opened Date",
              "Start Date",
              "Open Date",
              "Assigned_Date",
              "End Date"
            ];
            exportToPDF(
              headersName,
              mappedLogs,
              "Mapped Merchant Details",
              chipValue,
              exportedBy,
              exportedByFname,
              exportedByLname,
              labelName,
              processorName
            );
          }
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };
  const getPayoutList = function () {
    let filterData = {
      sortColumn: orderBy,
      sortOrder: order,
      recordPerPage: rowsPerPage.value === 0 ? "" : rowsPerPage,
      pageNo: page,
      ...searchValues
    };
    if (searchItems) {
      filterData = { ...filterData, ...JSON.parse(searchItems) };
    }
    // filterData.userId = localStorage.getItem("userId") || "";

    fetchMappedMerchantsList(
      { ...filterData },
      (records) => {
        if (records.status === "success") {
          const content = get(records, "data", []);
          const { totalCount } = records;
          setMerchantList(content);
          setTotalCount(totalCount);
          setPageDetails({
            ...pageDetails,
            lastPage: Math.ceil(totalCount / rowsPerPage),
            from: page === 1 ? 1 : (page - 1) * rowsPerPage + 1,
            to:
              page * rowsPerPage < totalCount ? page * rowsPerPage : totalCount,
            total: totalCount
          });
        } else {
          enqueueSnackbar(records.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("API Request Failed", {
          variant: "error"
        });
      }
    );
  };

  React.useEffect(() => {
    if (searchItems) {
      setSearchValues(JSON.parse(searchItems));
      setChipValue(JSON.parse(searchItems));
      localStorage.removeItem("MerchantMapping");
    }
  }, []);

  React.useEffect(() => {
    getPayoutList();
  }, [orderBy, order, page, rowsPerPage]);

  React.useEffect(() => {
    if (page === 1) {
      getPayoutList();
    }
    setPage(1);
  }, [chipValue]);

  React.useEffect(() => {
    if (searchValues.processorId || searchValues.userId) {
      getAgentProfileRepCodeList();
    }
  }, [searchValues.userId, searchValues.processorId]);

  React.useEffect(() => {
    if (isEmpty(processorList)) {
      getProcessorList();
    }
    if (isEmpty(agentsList)) {
      getAgentList();
    }
    getMerchantsListAsync();
  }, []);

  const selectedMerchant =
    !isEmpty(merchantList) &&
    !isEmpty(selectedList) &&
    merchantList.find((mid) => mid.MID == selectedList[0]);

  const handleAssignReassignModal = () => {
    const allEqual = (arr) => arr.every((val) => val === arr[0]);
    const result = allEqual(selectedProcessorList);
    if (selectedList.length == 1) {
      setAssignReassignModal(true);
    } else if (selectedList.length > 1 && result) {
      setAssignReassignModal(true);
    } else {
      setAlertOpen(true);
    }
  };

  const handleUpdateDBAModal = (data) => {
    setUpdateDBAModal(true);
    setUpdateDbaName(data);
  };

  const AssignReassignAlert = () => {
    const handleAlertClose = () => {
      setAlertOpen(false);
    };

    return (
      <Dialog
        open={alterOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert!</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "1rem" }}
          >
            Merchants with different processors are not allowed to be mapped
            together.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAlertClose()} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const showHistory = (id) => {
    history.push(`MerchantMapping/history?mid=${id}`);
    localStorage.setItem("MerchantMapping", JSON.stringify(searchValues));
  };

  return (
    <>
      <AssignReassignModal
        open={assignReassignModal}
        agentsList={agentsList}
        onClose={() => setAssignReassignModal(false)}
        processorList={processorList}
        profilesList={profiles}
        assignReassignCall={assignReassignCall}
        getAgentProfileRepCodeList={getAgentProfileRepCodeList}
        selectedList={selectedList}
        selectedMerchant={selectedMerchant}
        successModal={successModal}
        setSuccessModal={setSuccessModal}
        downloadImportedData={downloadImportedData}
        successData={successData}
      />
      <AssignReassignAlert />

      <TableContainer className={classes.TableContainer} component={Paper}>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div
                className={classes.searchWrapper}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "nowrap"
                }}
              >
                <div style={{ width: "50%" }}>
                  <Button
                    onClick={() => {
                      handleAssignReassignModal();
                    }}
                    className={classes.buttonStyle}
                    variant="contained"
                    color="primary"
                    disabled={isEmpty(selectedList)}
                  >
                    Assign / Reassign
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    alignContent: "center",
                    flexWrap: "nowrap",
                    flexDirection: "row",
                    minWidth: "600px"
                  }}
                >
                  <SearchComponent
                    searchBar={searchBar}
                    handleSearchClose={handleSearchClose}
                    handleSearchOpen={handleSearchOpen}
                    width="100%"
                    Fields={() => (
                      <FilterFields
                        handleSearchClose={() => handleSearchClose()}
                        searchValues={searchValues}
                        setSearchValues={setSearchValues}
                        agentsList={agentsList}
                        processorList={processorList}
                        repCodeList={repCodeList}
                        getAgentList={getAgentList}
                        handleSubmitSearch={(data) => handleSubmitSearch(data)}
                        handleClearSearch={handleClearSearch}
                        allmerchantList={merchantList}
                      />
                    )}
                  />
                  <UpdateDBA
                    open={updateDBAModal}
                    onClose={() => setUpdateDBAModal(false)}
                    updateDbaNameCall={updateDbaNameCall}
                    selectedMerchant={updateDbaName}
                    setUpdateDbaName={setUpdateDbaName}
                  />
                  <IconsTooltip title="Import" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ImportIconSvg

                      // onClick={handleToggle}
                      />
                    </div>
                  </IconsTooltip>
                  {/* <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    // onClick={exportListData}
                    variant="contained"
                    className={classes.ExportButtonStyle}
                    style={{ width: "150px" }}
                  >
                    Import
                  </Button> */}
                  <IconsTooltip title="Export" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ExportIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleToggle}
                        ref={anchorRef}
                      />
                    </div>
                  </IconsTooltip>

                  <Popper
                    open={openImportFileModal}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    style={{
                      zIndex: "10"
                    }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom"
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              // autoFocusItem={openImportFileModal}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                              <MenuItem
                                onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px"
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid
              item
              className={classes.expirtItemGrid}
              style={{ justifyContent: "space-evenly", alignItems: "center" }}
            >
              <Button
                className={classes.buttonStyle}
                onClick={() => {
                  setAssignReassignModal(true);
                }}
                variant="contained"
                color="primary"
                disabled={isEmpty(selectedList)}
              >
                Assign / Reassign
              </Button>
              <SearchComponent
                searchBar={searchBar}
                handleSearchClose={handleSearchClose}
                handleSearchOpen={handleSearchOpen}
                Fields={() => (
                  <FilterFields
                    handleSearchClose={() => handleSearchClose()}
                    setSearchValues={setSearchValues}
                    searchValues={searchValues}
                    agentsList={agentsList}
                    processorList={processorList}
                    repCodeList={repCodeList}
                    getAgentList={getAgentList}
                    handleSubmitSearch={(data) => handleSubmitSearch(data)}
                    handleClearSearch={handleClearSearch}
                    allmerchantList={merchantList}
                  />
                )}
              />
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                // onClick={exportListData}
                variant="contained"
                className={classes.ExportButtonStyle}
              >
                Import
              </Button>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                variant="contained"
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          style={{
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center"
          }}
          container
        >
          <Grid item style={{ paddingLeft: "1rem" }}>
            {USER_ID && (
              <Button
                style={{
                  background: theme.palette.primary.dark,
                  color: "white"
                }}
                variant="contained"
                onClick={() => {
                  history.push(`/admin/AgentPayout?userId=${USER_ID}`);
                }}
              >
                Back
              </Button>
            )}
          </Grid>
          <Grid item>
            <SearchedChips
              handleDeleteChip={handleDeleteChip}
              searchValues={chipValue}
              labelName={labelName}
              agentName={agent_Name}
              processorName={processorName}
              profileName={profileName}
            />
          </Grid>
        </Grid>
        <CustomTable
          page={page}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          data={merchantList}
          headCells={headCellsItems}
          dataParameter={dataParameter}
          processorParameter={processorParameter}
          isCallInitialization={initialCall}
          selected={selectedList}
          setSelected={onCheck}
          isSelection
          rowsPerPage={rowsPerPage}
          pageDetails={pageDetails}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
        >
          {merchantList.map((row) => {
            const isItemSelected = isSelected(row.MID, selectedList);
            return (
              <TableRow key={row.RowNumber} className={classes.cellHeadSign}>
                <TableCell
                  // onClick={(event) => handleClick(event, row.ItemName)}
                  align="left"
                  padding="checkbox"
                >
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    onChange={(event) =>
                      handleClickTable(
                        event,
                        row.MID,
                        row.ProcessorName,
                        selectedList,
                        selectedProcessorList,
                        onCheck
                      )
                    }
                    name={row.MID}
                    inputProps={{ "aria-labelledby": row.MID }}
                  />
                </TableCell>
                <TableCell className={classes.cellText} align="left">
                  {row.ProcessorName}
                </TableCell>
                <TableCell className={classes.cellText} align="left">
                  {row.DBAName}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.MID}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Agent_Name}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.RepCode}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Start_Date}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.END_Date}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Opened_Date}
                </TableCell>
                <TableCell align="center" className={classes.cellText}>
                  {row.Assigned_Date}
                </TableCell>
                <TableCell align="right" className={classes.cellText}>
                  <LongMenu
                    handleUpdateDBAModal={() => handleUpdateDBAModal(row)}
                    showHistory={() => showHistory(row.MID)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </CustomTable>
      </TableContainer>
    </>
  );
}
PayoutSummaryReport.propTypes = {
  fetchMappedMerchantsList: PropTypes.func,
  fetchProcessorList: PropTypes.func,
  fetchAgentList: PropTypes.func,
  fetchProfileRepCodeList: PropTypes.func,
  fetchMerchantsByName: PropTypes.func,
  updateMappingStatusUpdate: PropTypes.func,
  fetchMappedMerchantsListAll: PropTypes.func,
  users: PropTypes.object,
  tableData: PropTypes.object,
  updateDBANameUpdate: PropTypes.func
};

PayoutSummaryReport.defaultProps = {
  fetchMappedMerchantsList: () => {},
  fetchProcessorList: () => {},
  fetchAgentList: () => {},
  fetchProfileRepCodeList: () => {},
  fetchMerchantsByName: () => {},
  updateMappingStatusUpdate: () => {},
  updateDBANameUpdate: () => {},
  fetchMappedMerchantsListAll: () => {}
};

const mapStateToProps = (app) => ({
  users: app,
  tableData: app.users
});

export default connect(mapStateToProps, {
  fetchMappedMerchantsList,
  fetchProcessorList,
  fetchAgentList,
  fetchProfileRepCodeList,
  fetchMerchantsByName,
  updateMappingStatusUpdate,
  updateDBANameUpdate,
  fetchMappedMerchantsListAll
})(PayoutSummaryReport);
