import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Grid, InputLabel, Typography } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import Select from "@material-ui/core/Select";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import ItemTable from "./itemTable";

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        background: "#F9FAFF 0% 0% no-repeat",
        borderRadius: "6px",
        height: "100%"
      },
      input: {
        padding: "8px",
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26) !important",
          boxShadow: "none !important",
          backgroundColor: "#f1f1f199 !important"
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: "12px"
      }
    },
    MuiFormControl: {
      root: {
        // background: "#F9FAFF 0% 0% no-repeat",
        borderRadius: "6px"
        // maxWidth: "220px",
      }
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        backgroundColor: "#2ba992 !important"
      }
    }
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2} pb={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const PSVersion = ({
  rateList,
  processorList,
  EditFlag,
  fieldsDetails,
  selectedItem,
  setSelectedItem,
  checkRateDisableFun,
  componentRef
}) => {
  const [initialValues, setInitialValues] = React.useState({
    ...fieldsDetails.Pricing_Detail,
    rateType:
      fieldsDetails.Pricing_Detail.rateType == undefined
        ? null
        : fieldsDetails.Pricing_Detail.rateType,
    Groups: [...fieldsDetails.Pricing_Items.Groups]
  });
  const formikRef = React.useRef(null);

  const loginUser = JSON.parse(localStorage.getItem("menu"))
    .Role_Permission_Data.UserName;

  return (
    <ThemeProvider theme={theme}>
      <Box className="agent-payout-container">
        <Box mb={4} className="agent-payout-addNewProfile">
          <Grid className="custflex-dc" container>
            <Formik
              enableReinitialize
              innerRef={(r) => {
                formikRef.current = r;
              }}
              initialValues={{ ...initialValues, EditFlag }}
            >
              {({ values, setFieldValue, resetForm, errors, touched }) => (
                <Form ref={componentRef}>
                  {/*component ref for pdf download starts*/}
                  <div>
                    <Grid
                      style={{
                        display: "flex",
                        marginBottom: "15px",
                        justifyContent: "end"
                      }}
                    >
                      <Box style={{ textAlign: "end" }}>
                        <Typography>
                          Pricing Schedule - ({initialValues.PS_Name})
                        </Typography>
                        <Typography>{`Updated on: ${moment(new Date())
                          .tz("UTC")
                          .format("MM/DD/YYYY hh:mm:ss A")} UTC`}</Typography>
                        <Typography>{`Updated by: ${loginUser}`}</Typography>
                      </Box>
                    </Grid>
                    <Grid
                      spacing={4}
                      container
                      style={{
                        marginBottom: "14px"
                      }}
                      className="custflex-dr-aic"
                      justifyContent="start"
                      item
                    >
                      <Grid
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                      >
                        <Grid
                          className="fieldWithLabel"
                          style={{
                            justifyContent: "start"
                          }}
                          xs={4}
                          item
                        >
                          <InputLabel id="label-PS_Name">
                            Name
                            <span className="spn-required">*</span>
                          </InputLabel>
                        </Grid>
                        <Grid xs={8} item>
                          <FormControl fullWidth>
                            <Field
                              as={TextField}
                              fullWidth
                              name="PS_Name"
                              error={errors.PS_Name && touched.PS_Name}
                              // helperText={touched.PS_Name && errors.PS_Name}
                              labelId="label-PS_Name"
                              variant="outlined"
                              placeholder="Name"
                            />
                          </FormControl>
                          <ErrorMessage name="PS_Name">
                            {(msg) => (
                              <Typography
                                component="span"
                                className="errorAbs"
                                variant="colorError"
                              >
                                {msg}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                      >
                        <Grid
                          className="fieldWithLabel"
                          xs={4}
                          item
                          style={{
                            justifyContent: "start"
                          }}
                        >
                          <InputLabel id="label-ProcessorID">
                            Processor
                            <span className="spn-required">*</span>
                          </InputLabel>
                        </Grid>
                        <Grid xs={8} item>
                          <FormControl fullWidth>
                            <Field
                              as={Select}
                              fullWidth
                              name="ProcessorID"
                              labelId="label-ProcessorID"
                              variant="outlined"
                              displayEmpty
                              disabled={EditFlag}
                              error={errors.ProcessorID && touched.ProcessorID}
                              onChange={(e) => {
                                setFieldValue("ProcessorID", e.target.value);
                              }}
                              placeholder="Processor"
                            >
                              <MenuItem disabled value="">
                                Select Processor
                              </MenuItem>
                              {processorList.map((processor) => (
                                <MenuItem value={processor.ProcessorID}>
                                  {processor.ProcessorName}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                          <ErrorMessage name="ProcessorID">
                            {(msg) => (
                              <Typography
                                component="span"
                                className="errorAbs"
                                variant="colorError"
                              >
                                {msg}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                      >
                        <Grid
                          className="fieldWithLabel"
                          xs={4}
                          item
                          style={{
                            justifyContent: "start"
                          }}
                        >
                          <InputLabel id="label-St_Date">
                            Start Month
                            <span className="spn-required">*</span>
                          </InputLabel>
                        </Grid>
                        <Grid xs={8} item>
                          <FormControl fullWidth>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Field
                                as={KeyboardDatePicker}
                                fullWidth
                                views={["month", "year"]}
                                id="St_Date"
                                name="St_Date"
                                labelId="label-St_Date"
                                error={errors.St_Date && touched.St_Date}
                                minDateMessage=""
                                invalidDateMessage=""
                                maxDateMessage=""
                                disabled={EditFlag}
                                inputVariant="outlined"
                                format="MM/yyyy"
                                placeholder="MM/YYYY"
                                value={moment(values.St_Date).format(
                                  "MM-DD-YYYY"
                                )}
                                onChange={(value) => {
                                  let dateObj = value;
                                  if (dateObj && dateObj.getMonth()) {
                                    const month = dateObj.getMonth() + 1; // months from 1-12
                                    const day = dateObj.getDate();
                                    const year = dateObj.getFullYear();
                                    dateObj = `${month}/${day}/${year}`;
                                  }
                                  setFieldValue("St_Date", dateObj);
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "start month"
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </FormControl>
                          <ErrorMessage name="St_Date">
                            {(msg) => (
                              <Typography
                                component="span"
                                className="errorAbs"
                                variant="colorError"
                              >
                                {msg}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={1}
                        item
                        className="fieldWithLabel"
                        style={{
                          justifyContent: "start"
                        }}
                      >
                        <InputLabel id="label-Description">
                          Description
                          {/* <span className="spn-required">*</span> */}
                        </InputLabel>
                      </Grid>

                      <Grid
                        xs={11}
                        item
                        style={{
                          paddingLeft: "35px"
                        }}
                      >
                        <FormControl fullWidth>
                          <Field
                            as={TextField}
                            fullWidth
                            variant="outlined"
                            name="Description"
                            error={errors.Description && touched.Description}
                            rows={2}
                            multiline
                            labelId="label-Description"
                            placeholder="Description"
                          />
                        </FormControl>
                        <ErrorMessage name="Description">
                          {(msg) => (
                            <Typography
                              component="span"
                              className="errorAbs"
                              variant="colorError"
                            >
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>

                    <Grid container className="custflex-dr-aic" item>
                      <Grid
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        className="custflex-dr-aic"
                        container
                        item
                      >
                        <Grid
                          xs={4}
                          className="fieldWithLabel"
                          item
                          style={{
                            justifyContent: "start"
                          }}
                        >
                          <InputLabel id="label-Split_Type">
                            Split Type
                            {/* <span className="spn-required">*</span> */}
                          </InputLabel>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            alignItems: "center"
                            // justifyContent: "center"
                          }}
                          xs={8}
                          container
                          item
                        >
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="label-Split_Type"
                              row
                              error={errors.Split_Type && touched.Split_Type}
                              style={{ flexWrap: "nowrap" }}
                              name="Split_Type"
                              color="primary"
                              value={values.Split_Type}
                              onChange={(e) => {
                                setFieldValue(
                                  "Split_Type",
                                  e.target.defaultValue
                                );
                                if (e.target.defaultValue === "P") {
                                  setFieldValue("multiProfit", "");
                                  setFieldValue("multiLoss", "");
                                }
                                if (e.target.defaultValue === "I") {
                                  setFieldValue("Profit_Percent", "");
                                  setFieldValue("Loss_Percent", "");
                                }
                              }}
                            >
                              <FormControlLabel
                                value="P"
                                control={<Radio color="primary" />}
                                label="Program"
                                color="primary"
                                disabled={EditFlag}
                              />
                              <FormControlLabel
                                value="I"
                                color="primary"
                                control={<Radio color="primary" />}
                                label="Item"
                                disabled={EditFlag}
                              />
                            </RadioGroup>
                          </FormControl>
                          <ErrorMessage name="Split_Type">
                            {(msg) => (
                              <Typography
                                component="span"
                                className="errorAbs"
                                variant="colorError"
                              >
                                {msg}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                      {values.Split_Type === "P" && (
                        <Grid
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid className="fieldWithLabel" xs={4} item>
                            <InputLabel id="label-Profit_%">
                              Profit&nbsp;%
                              {/* <span className="spn-required">*</span> */}
                            </InputLabel>
                          </Grid>
                          <Grid xs={8} item>
                            <FormControl fullWidth>
                              <Field
                                as={TextField}
                                error={
                                  errors.Profit_Percent &&
                                  touched.Profit_Percent &&
                                  values.Split_Type == "P"
                                }
                                aria-labelledby="label-Profit_%"
                                fullWidth
                                name="Profit_Percent"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  inputProps: { min: 0, max: 100 }
                                }}
                                displayEmpty
                                placeholder="Profit %"
                              />
                            </FormControl>
                            <ErrorMessage name="Profit_Percent">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                      )}
                      {values.Split_Type == "P" && (
                        <Grid
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          className="custflex-dr-aic"
                          container
                          item
                        >
                          <Grid className="fieldWithLabel" xs={4} item>
                            <InputLabel id="label-Loss_%">
                              Loss&nbsp;%
                              {/* <span className="spn-required">*</span> */}
                            </InputLabel>
                          </Grid>
                          <Grid xs={8} item>
                            <FormControl fullWidth>
                              <Field
                                as={TextField}
                                error={
                                  errors.Loss_Percent &&
                                  touched.Loss_Percent &&
                                  values.Split_Type === "P"
                                }
                                aria-labelledby="label-Loss_%"
                                fullWidth
                                name="Loss_Percent"
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  inputProps: { min: 0, max: 100 }
                                }}
                                displayEmpty
                                placeholder="Loss %"
                              />
                            </FormControl>
                            <ErrorMessage name="Loss_Percent">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    {!isEmpty(values.Groups) && (
                      <Box mt={6}>
                        <Box component="p" variant="body1">
                          <Typography variant="h6">Multiple Update</Typography>
                        </Box>
                        <Grid
                          spacing={3}
                          container
                          className="custflex-dr-aic"
                          item
                        >
                          <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            // className="custflex-dr-aic"
                            item
                          >
                            <FormControl fullWidth>
                              <Field
                                as={Select}
                                fullWidth
                                name="rateType"
                                error={errors.rateType && touched.rateType}
                                onChange={(e) => {
                                  setFieldValue("rateType", e.target.value);
                                }}
                                variant="outlined"
                                displayEmpty
                                placeholder="Rate Type"
                              >
                                <MenuItem disabled value={null}>
                                  Rate Type
                                </MenuItem>
                                {rateList.map((rates) => (
                                  <MenuItem value={rates.RateType_ID}>
                                    {rates.RateType}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                            <ErrorMessage name="rateType">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                          <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            lg={2}
                            // className="custflex-dr-aic"
                            item
                          >
                            <FormControl fullWidth>
                              <Field
                                as={TextField}
                                error={errors.rate && touched.rate}
                                fullWidth
                                name="rate"
                                variant="outlined"
                                displayEmpty
                                placeholder="Rate"
                              />
                            </FormControl>
                            <ErrorMessage name="rate">
                              {(msg) => (
                                <Typography
                                  component="span"
                                  className="errorAbs"
                                  variant="colorError"
                                >
                                  {msg}
                                </Typography>
                              )}
                            </ErrorMessage>
                          </Grid>
                          {values.Split_Type === "I" && (
                            <Grid
                              xs={12}
                              sm={6}
                              md={4}
                              lg={2}
                              // className="custflex-dr-aic"
                              item
                            >
                              <FormControl fullWidth>
                                <Field
                                  as={TextField}
                                  fullWidth
                                  error={
                                    errors.multiProfit &&
                                    touched.multiProfit &&
                                    values.Split_Type === "I"
                                  }
                                  name="multiProfit"
                                  variant="outlined"
                                  type="number"
                                  InputProps={{
                                    inputProps: { min: 0, max: 100 }
                                  }}
                                  displayEmpty
                                  placeholder="Profit %"
                                />
                              </FormControl>
                              <ErrorMessage name="multiProfit">
                                {(msg) => (
                                  <Typography
                                    component="span"
                                    className="errorAbs"
                                    variant="colorError"
                                  >
                                    {msg}
                                  </Typography>
                                )}
                              </ErrorMessage>
                            </Grid>
                          )}
                          {values.Split_Type === "I" && (
                            <Grid
                              xs={12}
                              sm={6}
                              md={4}
                              lg={2}
                              // className="custflex-dr-aic"
                              item
                            >
                              <FormControl fullWidth>
                                <Field
                                  as={TextField}
                                  fullWidth
                                  error={
                                    errors.multiLoss &&
                                    touched.multiLoss &&
                                    values.Split_Type === "I"
                                  }
                                  name="multiLoss"
                                  type="number"
                                  InputProps={{
                                    inputProps: { min: 0, max: 100 }
                                  }}
                                  variant="outlined"
                                  displayEmpty
                                  placeholder="Loss %"
                                />
                              </FormControl>
                              <ErrorMessage name="multiLoss">
                                {(msg) => (
                                  <Typography
                                    component="span"
                                    className="errorAbs"
                                    variant="colorError"
                                  >
                                    {msg}
                                  </Typography>
                                )}
                              </ErrorMessage>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    )}
                    {values.Groups.map((tab, index) => (
                      <>
                        <p>
                          {" "}
                          {`${tab.GroupName} (${tab.Included}/${tab.TotalCount})`}{" "}
                        </p>
                        <Box index={index}>
                          <ItemTable
                            tabIndex={index}
                            values={values}
                            setFieldValue={setFieldValue}
                            listData={tab.Items_List}
                            rateList={rateList}
                            selected={selectedItem}
                            setSelected={setSelectedItem}
                            checkRateDisableFun={checkRateDisableFun}
                            disabledFlag={values.Split_Type === "P"}
                            key={`${index}/${tab.GroupID}`}
                          />
                        </Box>
                      </>
                    ))}
                  </div>
                  {/*component ref for pdf download ends*/}
                </Form>
              )}
            </Formik>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

PSVersion.propTypes = {
  rateList: PropTypes.any,
  processorList: PropTypes.any,
  EditFlag: PropTypes.any,
  fieldsDetails: PropTypes.any,
  selectedItem: PropTypes.any,
  setSelectedItem: PropTypes.any,
  checkRateDisableFun: PropTypes.any,
  componentRef: PropTypes.any
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(PSVersion);
