import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import isEmpty from "lodash/isEmpty";
import { actionPermission } from "../../../../Utils/commonUtils";

const payoutHeader = ({
  agentsList,
  selectedUser,
  setSelectedUser,
  otherDetails,
  setIsAgentDataError,
  setSelected
}) => (
  <div>
    {actionPermission(3, 2, 1) && (
    <Box className="agent-payout-header">
      <Grid className="custflex-dr-aic" container>
        <Grid
          spacing={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start"
          }}
          item
          container
        >
          <Grid className="custflex-dr-aic" xs={12} sm={12} md={6} lg={4} item>
            {/* <Typography
              variant="h5"
              color="primary"
              className="header-title-text"
            >
              3rd Federal Bank
            </Typography>
            <IconButton style={{ marginLeft: "10px" }}>
              <CloseIcon />
            </IconButton> */}
            <Autocomplete
              name="agentId"
              id="agentId"
              style={{
                fontSize: "14px",
                "&.focused": {
                  // color: theme.palette.primary.main,
                  fontSize: "14px",
                  fontStyle: "Helvetica, Arial,sans-serif",
                  background: "#f9faff00 0% 0% no-repeat"
                }
              }}
              InputProps={{
                style: { background: "#f9faff00 0% 0% no-repeat" }
              }}
              value={selectedUser}
              autoHighlight
              fullWidth
              // onInputChange={(e, newInputValue) => {
              //   if (newInputValue.length >= 3) {
              //     getAgentList(newInputValue);
              //   }
              // }}
              getOptionDisabled={(option) =>
                option.UserID === 919863231596669598
              }
              onChange={(_, value, action) => {
                setIsAgentDataError(false);
                setSelected(false);
                if (action === "clear") {
                  setSelectedUser({});
                } else {
                  value && setSelectedUser(value);
                }
              }}
              selectOnFocus
              options={agentsList || []}
              getOptionLabel={(option) =>
                option?.AgentName?.split("[")[0] || ""
              }
              renderInput={(params) => (
                <TextField
                  label="Select Agent"
                  InputLabelProps={{
                    classes: {
                      // root: classes.activeInputLableColor,
                      focused: "focused"
                    },
                    style: { background: "#000000" }
                  }}
                  required
                  InputProps={{
                    classes: {
                      // root: classes.activeInputColor,
                      // input: classes.resize,
                      focused: "focused"
                    },
                    style: { background: "#000000" },
                    required: true
                  }}
                  {...params}
                  // label="Agent Name"
                />
              )}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.AgentName, inputValue, {
                  insideWords: true
                });
                const parts = parse(option.AgentName, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        style={{
                          background: part.highlight ? "#f3ff0070" : "",
                          fontWeight: part.highlight ? 400 : "",
                          fontSize: "14px"
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </Grid>
        </Grid>
        {!isEmpty(selectedUser) && (
          <Grid
            spacing={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}
            item
            container
          >
            <Grid
              style={{ marginBottom: "10px" }}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              item
            >
              <Typography>
                <b>Email</b> :{" "}
                {
                  (selectedUser.AgentName || "n/a [N/A]")
                    .split("[")[1]
                    .split("]")[0]
                }
              </Typography>
            </Grid>
            {!isEmpty(otherDetails) && (
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
                spacing={2}
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                container
                item
              >
                <Grid item>
                  <Typography>
                    <b>Active Processor</b> : {otherDetails.active_processors}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Active Profiles</b> : {otherDetails.active_profiles}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Active Merchants</b> : {otherDetails.active_merchants}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
    )}
    {!actionPermission(3, 2, 1) && (
        <p  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop:"25%"
        }}>Access denied.</p>
      )}
  </div>
);
payoutHeader.propTypes = {
  agentsList: PropTypes.array,
  selectedUser: PropTypes.any,
  setSelectedUser: PropTypes.func,
  otherDetails: PropTypes.object,
  setIsAgentDataError: PropTypes.func,
  setSelected: PropTypes.func
};

export default payoutHeader;
