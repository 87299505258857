/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { connect } from "react-redux";
import pageInDevelopment from "assets/page-in-development.png";
import Grid from "@material-ui/core/Grid";

function Dashboard() {
  return (
    <Grid container justifyContent="center">
      <img src={pageInDevelopment} alt="Page under development" width="60%" />
    </Grid>
  );
}

Dashboard.propTypes = {};

const mapStateToProps = (app) => ({});
export default connect(mapStateToProps, {})(Dashboard);
