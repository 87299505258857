import React from "react";
// @material-ui/core components
import {
  makeStyles,
  createStyles,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import TextField from "@material-ui/core/TextField";
import CommentIcon from "@material-ui/icons/Comment";
import Card from "components/Card/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import Modal from "@material-ui/core/Modal";
import ClearIcon from "@material-ui/icons/Clear";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) =>
  createStyles({
    modalDiv: {
      maxWidth: "500px",
      [theme.breakpoints.down("md")]: {
        width: "70%"
      },
      minWidth: "400px",
      [theme.breakpoints.down("md")]: {
        width: "70%"
      },
      borderRadius: "12px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: `translate(-50%,-50%) !important`,
      marginTop: "0px"
    },
    heading: {
      backgroundColor: "#2ba992",
      display: "flex",
      alignItem: "center",
      justifyContent: "center",
      color: "white",
      fontSize: "1rem",
      fontWeight: "bold",
      padding: "12px"
    },
    submitBtn: {
      backgroundColor: "#2ba992",
      "&:hover": {
        backgroundColor: "#2ba992"
      }
    },
    closeBtn: {
      backgroundColor: "#696969",
      "&:hover": {
        backgroundColor: "#696969"
      }
    },
    btnBox: {
      display: "flex",
      gap: "1rem",
      padding: "1rem"
    }
  })
);

const theme = createTheme({
  overrides: {
    MuiFormHelperText: {
      contained: {
        margin: "0px !important"
      }
    }
  }
});

const UpdateDescriptionModal = ({
  isUpdateDescriptionModal,
  handleClose,
  handleSubmit,
  values,
  setFieldValue,
  errors
}) => {
  const classes = useStyles();
  const maxLetters = 1000;
  const [updateDescriptionText, setUpdateDescriptionText] = React.useState("");

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription.length <= maxLetters) {
      setUpdateDescriptionText(newDescription);
      setFieldValue("discription", newDescription);
    }
  };
  let wordCount = updateDescriptionText.length;
  let remainingWords = maxLetters - wordCount;

  return (
    <div>
      <Modal open={isUpdateDescriptionModal} onClose={handleClose}>
        <Zoom
          in={isUpdateDescriptionModal}
          style={{
            transitionDelay: isUpdateDescriptionModal ? "250ms" : "0ms"
          }}
        >
          <Card className={classes.modalDiv}>
            <div className={classes.heading}>Description</div>

            <Box
              sx={{
                width: "100%"
              }}
              marginTop="1rem"
            >
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                container
              >
                <Grid item xs={10}>
                  <ThemeProvider theme={theme}>
                    <TextField
                      required
                      label="Description"
                      multiline
                      rows={2}
                      InputProps={{
                        inputProps: { min: 10, max: 1000 }
                      }}
                      fullWidth
                      variant="outlined"
                      placeholder="Write your description here..."
                      value={values.discription}
                      onChange={handleDescriptionChange}
                      name="discription"
                      id="discription"
                      error={errors.discription}
                      helperText={`${wordCount}/${maxLetters} (${remainingWords} characters remaining)`}
                    />
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Box>
            <Grid container justifyContent="flex-end">
              <Grid item className={classes.btnBox}>
                <Button
                  color="primary"
                  style={{
                    textTransform: "capitalize"
                  }}
                  variant="contained"
                  className={classes.closeBtn}
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  style={{
                    textTransform: "capitalize"
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                  // type="submit"
                  className={classes.submitBtn}
                >
                  Update & Continue
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Zoom>
      </Modal>
    </div>
  );
};

UpdateDescriptionModal.propTypes = {
  isUpdateDescriptionModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.any,
  errors: PropTypes.any
};
const mapStateToProps = (app) => ({});
export default connect(mapStateToProps, {})(UpdateDescriptionModal);
